import React from 'react';
import {Button} from "react-bootstrap";

// The minimum ABI to get ERC20 Token balance
let minERC20ABI = [
// balanceOf
{
    "constant":true,
    "inputs":[{"name":"_owner","type":"address"}],
    "name":"balanceOf",
    "outputs":[{"name":"balance","type":"uint256"}],
    "type":"function"
},
// decimals
{
    "constant":true,
    "inputs":[],
    "name":"decimals",
    "outputs":[{"name":"","type":"uint8"}],
    "type":"function"
},
// symbol
{
    "inputs": [],
    "name": "symbol",
    "outputs": [
    {
        "internalType": "string",
        "name": "",
        "type": "string"
    }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
},
// name
{
    "inputs": [],
    "name": "name",
    "outputs": [
    {
        "internalType": "string",
        "name": "",
        "type": "string"
    }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
},
// approve
{
    "constant": false,
    "inputs": [
        {
            "name": "_spender",
            "type": "address"
        },
        {
            "name": "_value",
            "type": "uint256"
        }
    ],
    "name": "approve",
    "outputs": [
        {
            "name": "",
            "type": "bool"
        }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
},
// allowance
{
    "constant": true,
    "inputs": [
        {
            "name": "_owner",
            "type": "address"
        },
        {
            "name": "_spender",
            "type": "address"
        }
    ],
    "name": "allowance",
    "outputs": [
        {
            "name": "",
            "type": "uint256"
        }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
},
];

class ERC20ApprovalButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            account: props.account,
            tokenAddress: props.tokenAddress,
            spenderName: props.spenderName,
            spenderAddress: props.spenderAddress,
            approvedAmount: props.approvedAmount,

            decimals: 18,
            name: "",
            symbol: "",
            allowance: 0
        };

        this.approveToken = this.approveToken.bind(this);
    }

    componentDidMount() {
        const { tokenAddress, account, spenderAddress } = this.state;

        if (tokenAddress !== null &&
            account !== null &&
            spenderAddress !== null) {
            this.getTokenInfo();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tokenAddress !== this.props.tokenAddress) {
           this.getTokenInfo();
        }
    }

    // run this multiple times by putting in its own function
    
    getTokenInfo = async () => {
        const { tokenAddress, account, spenderAddress } = this.state;

        let symbol = "";
        let decimals = 18;
        let name = "";
        let allowance = 0;

        if(tokenAddress !== null && spenderAddress !== null && account !== null) {
            if(tokenAddress === "0x0000000000000000000000000000000000000000") {
                symbol = "Ξ ETH";
                decimals = 18;
                name = "Ethereum";
            } else {
                try {
                let tokenContract = new window.web3.eth.Contract(minERC20ABI, tokenAddress);
                symbol = await tokenContract.methods.symbol().call();
                decimals = await tokenContract.methods.decimals().call();
                name = await tokenContract.methods.name().call();
               allowance = await tokenContract.methods.allowance(account, spenderAddress).call()
                } catch (error) {
                    symbol = "ERROR";
                    decimals = 18;
                    name = "Not a Valid Token Address";
                }
            }
            this.setState({decimals, name, symbol, allowance});
        }
    }

    approveToken = async () => {
        const { tokenAddress, spenderAddress, approvedAmount, account } = this.state;

        if(tokenAddress !== null) {
            if(tokenAddress !== "0x0000000000000000000000000000000000000000") {
                this.setState({loading: true})
                let tokenContract = new window.web3.eth.Contract(minERC20ABI, tokenAddress);
                tokenContract.methods.approve(spenderAddress, approvedAmount).send({from: account})
                .once('receipt', async (receipt) => {
                    this.getTokenInfo();
                    this.setState({loading: false})
                })
            }
        }
    }

  render() {
    const { tokenAddress, name, symbol, allowance, spenderName, loading } = this.state;

    return(<>
            {(tokenAddress !== "0x0000000000000000000000000000000000000000" && tokenAddress !== null && name !== "Not a Valid Token Address") ? (
                (loading) ? (
                    <Button variant="btn btn-block btn-secondary"  
                    disabled>
                            Approving {symbol} on {spenderName}
                    </Button>
                ) : (
                    (allowance && allowance > 0) ? (
                        <Button variant="btn btn-block btn-success" 
                        disabled>
                            {spenderName} is Approved on {symbol}
                        </Button>
                    ) : (
                        <Button variant="btn btn-block btn-warning"  
                        onClick={() => this.approveToken()}>
                            Approve {symbol} on {spenderName}
                        </Button>
                    )
                )
            ) : (
                <><Button variant="btn btn-block btn-dark"  
                disabled>
                        {name} {symbol}
                </Button></>
            )}
            </>);
  }
}


export default ERC20ApprovalButton;