import React, { Component } from 'react';
import {Table} from "react-bootstrap"
import EnsResolver from "ens-resolver";

class PixelEventTable extends Component {
 getDate = (ts) => {
   var a = new Date(ts * 1000);
   return a.toString();
 }

 render() {
   return(
     <Table striped bordered hover responsive size="sm">
       <thead>
         <tr>
           <th>Block</th>
           <th>Event</th>
           <th>Description</th>
         </tr>
       </thead>
       <tbody>
         {
              this.props.data.map((pEvent,i) =>(
                 <tr key={i}>
                    <td><a href={`${this.props.platform.etherscan_block_link}${pEvent.blockNumber}`} target="_new">{pEvent.blockNumber}</a></td>
                    <td><a href={`${this.props.platform.etherscan_tx_link}${pEvent.transactionHash}`} target="_new">{pEvent.event}</a></td>
                    {pEvent.event === "Transfer" ? (<td>To:&nbsp;
                      <a href={`${this.props.platform.etherscan_link}${pEvent.returnValues.to}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.to}/> ({pEvent.returnValues.to})</a></td>) : null }
                    {pEvent.event === "PixelCreated" ? <td><a href={`${this.props.platform.etherscan_tx_link}${pEvent.transactionHash}`} target="_new">Transaction Hash</a></td> : null}
                    {pEvent.event === "PixelOwnerChangedAlt" ? <td>{pEvent.returnValues.newAlt}</td> : null}
                    {pEvent.event === "PixelOwnerChangedColor" ? <td>{pEvent.returnValues.newColor}</td>  : null}
                    {pEvent.event === "PixelRented" ?
                      <td>
                        By: <a href={`${this.props.platform.etherscan_link}${pEvent.returnValues.pixelRenter}`} target="_new">
                          <EnsResolver lookup={pEvent.returnValues.pixelRenter}/> ({pEvent.returnValues.pixelRenter})
                          </a>
                          Until: {this.getDate(pEvent.returnValues.endTime)} Color: {pEvent.returnValues.rentalColor} Text: {pEvent.returnValues.rentalAlt}
                      </td> : null}
                    {pEvent.event === "Approval" ? <td>Approved: <a href={`${this.props.platform.etherscan_link}${pEvent.returnValues.approved}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.approved}/> ({pEvent.returnValues.approved})</a></td> : null}
                 </tr>
              ))
         }
       </tbody>
     </Table>
   );
 }
}

export default PixelEventTable;
