import React, { Component } from 'react';
import Cell from "./SVGCell";
import CellG from "./SVGCellG";

class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorIndex: 0,
      colors: this.props.colors,
      header: "BillionDollarDapp"
    };

    this.onClick = this.onClick.bind(this);
    this.changeHeader = this.changeHeader.bind(this);
  }

  onClick(e) {
    //this.setState({ colorIndex: this.state.colorIndex ? 0 : 1 });
  };

  changeHeader(newHeader) {
    this.setState(state => ({
      header: newHeader
    }));
  }

  render() {
    const length = this.props.colors.length;
    const square = Math.ceil(Math.sqrt(length));
    const height = square;
    const width = square;

    //const height = (square > 64) ? 64 : square;
    //const width = (square <= 64) ? square : Math.ceil(length / 64);
    const rows = [];
    let i = 0;
    for (let y=0; y<height; y++) {
      const cols = []
      for (let x=0; x<width; x++) {
        if(this.props.guerrillaModeActive) {
          // If we are in guerrilla mode, we want to show the grid in a different way
          cols.push(
          <td>
          <CellG
            key={this.props.pixelIds[i]}
            pixelId={this.props.pixelIds[i]}
            x={x}
            y={y}
            ownerAlt={this.props.ownerAlts[i]}
            renterAlt={this.props.renterAlts[i]}
            bgcolor='#000000'
            changeHeader={this.changeHeader}
            handleClick={this.props.handleClick}
            selected={this.props.selected}
            hash={this.props.pixelHashes[i]}
            setSelectedPixelName={this.props.setSelectedPixelName}
          />
          </td>
          );
        } else {
          cols.push(
          <td>
          <Cell
            platform={this.props.platform}
            key={this.props.pixelIds[i]}
            pixelId={this.props.pixelIds[i]}
            x={x}
            y={y}
            ownerAlt={this.props.ownerAlts[i]}
            renterAlt={this.props.renterAlts[i]}
            bgcolor={this.props.colors[i]}
            changeHeader={this.changeHeader}
            handleClick={this.props.handleClick}
            selected={this.props.selected}
            hash={this.props.pixelHashes[i]}
            ownerOfSelectedPixel={this.props.ownerOfSelectedPixel}
            setSelectedPixelName={this.props.setSelectedPixelName}
          />
          </td>
          );
        }
        i++;
      }
      rows.push(<tr>{cols}</tr>);
    }

    return (
      <div className="center">
        {/*<div className="text-white">{this.state.header}</div>*/}
        {(this.props.guerrillaModeActive && !this.props.selected) ? 
        (
          <div className="gridTable fade-in-gurrellia-text-big">
            <p>BillionDollarDapp.com</p>
            <p>  1024 Ethereum NFT  </p>
            <p>  editions each one  </p>
            <p>  having a value of  </p>
            <p>  1 millon DOLLARS.  </p>
          </div>
        ) : (
          <div>
            <table className="gridTable" onClick={ this.onClick }>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

export default Grid;
