import React, { Component } from "react";

import Contract from "./contracts/BDDPixelFactory.json";
import Dollars from "./contracts/Dollars.json";
import BillionDollarDrop from "./contracts/BillionDollarDrop.json";
import FlashBond from "./contracts/FlashBond.json";

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import {Nav, Navbar, Modal, Button, Form, Spinner, Tabs, Tab, Table} from "react-bootstrap"
//import Navbar from "react-bootstrap/Navbar"
import { ChromePicker  } from 'react-color'

import "./App.css";
//import Navbar from "./components/Navbar";
import Grid from "./components/Grid";
//import Cell from "./components/Cell";
import PixelEventTable from "./components/PixelEventTable";

import ZoraModal from "./components/Zora/ZoraModal.js";
import ZoraNavbarButton from "./components/Zora/ZoraNavbarButton.js";

import 'bootstrap/dist/css/bootstrap.min.css';

// The minimum ABI to get ERC20 Token balance
let minABI = [
  // balanceOf
  {
    "constant":true,
    "inputs":[{"name":"_owner","type":"address"}],
    "name":"balanceOf",
    "outputs":[{"name":"balance","type":"uint256"}],
    "type":"function"
  },
  // decimals
  {
    "constant":true,
    "inputs":[],
    "name":"decimals",
    "outputs":[{"name":"","type":"uint8"}],
    "type":"function"
  },
  // approve
  {
    "constant": false,
    "inputs": [
        {
            "name": "_spender",
            "type": "address"
        },
        {
            "name": "_value",
            "type": "uint256"
        }
    ],
    "name": "approve",
    "outputs": [
        {
            "name": "",
            "type": "bool"
        }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  // allowance
  {
    "constant": true,
    "inputs": [
        {
            "name": "_owner",
            "type": "address"
        },
        {
            "name": "_spender",
            "type": "address"
        }
    ],
    "name": "allowance",
    "outputs": [
        {
            "name": "",
            "type": "uint256"
        }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
];

let airdropActive = true;
let allowList = {
  '0xbb11ddc26f4e55475775310e5cbe188650e75212': {
    recipient: '0xbb11ddc26f4e55475775310e5cbe188650e75212',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2166037000000000000000000',
    nonce: '2',
    signature: '0x88fd92a8fa06b275f35354a75af3d3f17538f2de1b51262d07c9133331ff1567573e3ad6c775483675f384fe705d87f20a6f21c51e692ba814734b950209660e1c'
  },
  '0x29497a865e76ca516c8fd4fa1e071d2f74e056c7': {
    recipient: '0x29497a865e76ca516c8fd4fa1e071d2f74e056c7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '940327000000000000000000',
    nonce: '2',
    signature: '0xaa1cdf39d9feb347b645bcad322a28e9db98ce2419a4e6859808adc5fe97ab7b0fef4bd9575c686a2f83666b3adc7661662c92ce75bd46014b6dfd386934e2c41c'
  },
  '0x3954f5742ffe4e3dc5e0a1ffa220605bbedd8a9d': {
    recipient: '0x3954f5742ffe4e3dc5e0a1ffa220605bbedd8a9d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '476295000000000000000000',
    nonce: '2',
    signature: '0xbda8ec9fa1f3d6f9b904da9078f33daf6fcf9b0107172eb1bb20da45d71aa93e48798bf0d3c9e97e8dce862fea14b007050bfe500ccd04a576d115d79395ca0b1b'
  },
  '0xbff4496bc7e2a759e2b48291695bc1728356aaaa': {
    recipient: '0xbff4496bc7e2a759e2b48291695bc1728356aaaa',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '466409000000000000000000',
    nonce: '2',
    signature: '0x6db5d9dd42e1ac2bdf66737dded61c9393c3776ec418f809bf8033723d0f925147299653904e0961c8b7ed202866445fab90729075c170349c53cc24b6c002841b'
  },
  '0xb8779ec12392f02b1f6dfbce7c8c28088da43622': {
    recipient: '0xb8779ec12392f02b1f6dfbce7c8c28088da43622',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '409213000000000000000000',
    nonce: '2',
    signature: '0xe18ebad5dbd93ce2659b32a8631a7a33fddee59d39ec6932e8a54a045c6fec3325e3ff3869de419b3bf71460236a65b6c3429b0dbdfcdb45b54326cc8931f52d1c'
  },
  '0xf653b85954995fa688d2f22a1468fb8933f4c182': {
    recipient: '0xf653b85954995fa688d2f22a1468fb8933f4c182',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '371191000000000000000000',
    nonce: '2',
    signature: '0x4d0f75aaf92ed78db744a0dd19d4ca933ea4ff454ffeb6df352591fb791aece17af3911775dbe8ed4ff0bae250024fa33c205c728ad5e0850adef6d251ef1d8c1c'
  },
  '0xdae48d7b5c73a95f74caae08f7f92d3b7927f449': {
    recipient: '0xdae48d7b5c73a95f74caae08f7f92d3b7927f449',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '351456000000000000000000',
    nonce: '2',
    signature: '0x09ee3a5969ba0083bffa8acba23c0f08efe13cbded1346a4be30996bf565e2aa127e8eeb6e7c575f19c893ae4357da97dc245fcdeca67f941eac34a952b97ee01c'
  },
  '0xab42c346917f95431799271394c3e061c005886d': {
    recipient: '0xab42c346917f95431799271394c3e061c005886d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '345710000000000000000000',
    nonce: '2',
    signature: '0xceb097e34eea8f0c6f530ca3b94612b16fbbd47a5077a1c51792e2b475792b724dcac35a340724574896f7a2ec5701569f1ff9b82417dd110df6ff49cf340b191b'
  },
  '0x92cb54e85c63f41649a17def0e5d41579fb6dc61': {
    recipient: '0x92cb54e85c63f41649a17def0e5d41579fb6dc61',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '294429000000000000000000',
    nonce: '2',
    signature: '0x7209582188c5fb55b0abd74062696f42fd1047290d11e3c028a4b52003c20f68559e8ac1eb1411b340041105ab5222d82a013a4df19b1f7939f1cfa7b07735191b'
  },
  '0x34cabb62955c367d9ce1bbfeb4b1cfa91f066fee': {
    recipient: '0x34cabb62955c367d9ce1bbfeb4b1cfa91f066fee',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '230624000000000000000000',
    nonce: '2',
    signature: '0x0e53c0dbc16ae0adad3a91e7afc2f11433ea1c846dce216e140619df0d58691302576fcba7282fb8373f327f5a29bdbc14895f2956be2f5ec89cdf8b6dfbb8e11b'
  },
  '0xae96fec405819ca57845382e804cffc2bd016f3e': {
    recipient: '0xae96fec405819ca57845382e804cffc2bd016f3e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '213324000000000000000000',
    nonce: '2',
    signature: '0xd32d68a00694cd729ec67786f25bcf629e8132ed70d963a0a644059cb14b1cb14e3dabc8d90af976ef847e2773550f5b56cf8f39053732b1d76b4a602bc6f0cc1b'
  },
  '0x5eb9abe3f8215a522460b8d8f45147ea10ac9470': {
    recipient: '0x5eb9abe3f8215a522460b8d8f45147ea10ac9470',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '198447000000000000000000',
    nonce: '2',
    signature: '0x4a551f43b77e7762b00511ddf8397b90055031516712c9a0a316cc09e2f70b433ad4733f41549b563fca0eba0c8ccacf17f5a9d16ec2f86361df4d453934a69d1b'
  },
  '0x91381ebe65c3ddfca0fa5bf75a4e560ecc6f5026': {
    recipient: '0x91381ebe65c3ddfca0fa5bf75a4e560ecc6f5026',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '170134000000000000000000',
    nonce: '2',
    signature: '0xd8ec6fb35a9680cf24374cf09008b253a4a5fa5db845937f3a02af711568738c1ec2a4389c4f6e6c7d7b2ba6034a97084a177e091db126424120d332f5188a211c'
  },
  '0x5ad365d5038f653cb183b2da8fba3e203dff6d33': {
    recipient: '0x5ad365d5038f653cb183b2da8fba3e203dff6d33',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '163036000000000000000000',
    nonce: '2',
    signature: '0x3a92b66e4e5dc224c4cfe50155aa7bdfc36246986a0ec159584cc6b2a2fe833c77a90d9fa96067750135c0e84724c146afeca8705d79b8acc285365e0bc098591c'
  },
  '0x86dcf9b70b47c57b065a5c016f394d49a75747c8': {
    recipient: '0x86dcf9b70b47c57b065a5c016f394d49a75747c8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '156171000000000000000000',
    nonce: '2',
    signature: '0x0571139122faa4b89ecaf3bba48fc755ca33e131d522efa33b3987c47ccb4bbb50626aa490e1d07a18a20469773b221636efd303547de9cda0d1318cded858661c'
  },
  '0x45d6d9f604013b4f7442341b81a77fe4c391444f': {
    recipient: '0x45d6d9f604013b4f7442341b81a77fe4c391444f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '152681000000000000000000',
    nonce: '2',
    signature: '0x9735f9ce6a3da9ca0555f1959da79d72bf79d2768988bb6258424ed90e1b093d60fdb0562b866e127d140cd084f95c8e2fd19e8facffaf38d2df0efb010b4e7a1c'
  },
  '0x59f392e6640e4cfcc7af17086e3fc14c34d6355d': {
    recipient: '0x59f392e6640e4cfcc7af17086e3fc14c34d6355d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '131410000000000000000000',
    nonce: '2',
    signature: '0x8d0d90ca292264ca7aecc0d9eeb24848b1e858cb2ef362b8e0808a70c7db406a42780e3979575f50bdbdb748365919302e588594ff3e388b36cdfd36d9c38ad91b'
  },
  '0x4563338ab5fac34d07536a0f14a5efe552952703': {
    recipient: '0x4563338ab5fac34d07536a0f14a5efe552952703',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '128504000000000000000000',
    nonce: '2',
    signature: '0x8ed32b30dde79a0af4811af0e4c9b488f7eb2ad6ddf6ac7e677e9ca212e392905f198bc60f70f5ec6d04892ba1a150792a6784a0d041f23d0c54fdd93a8e687c1b'
  },
  '0x69b1628ab76c8dd68b13c5cec4daed744837518f': {
    recipient: '0x69b1628ab76c8dd68b13c5cec4daed744837518f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '124874000000000000000000',
    nonce: '2',
    signature: '0x148396a37e7a7b5ce931d06d1b28092209c23930c4bf22d202e49b844700d127228257d47198071d493ff44022f0d31359c775e356213208623bc5da4604a10f1b'
  },
  '0x1aee639d1eaf7de0d90d7fdea64f291b3382e11a': {
    recipient: '0x1aee639d1eaf7de0d90d7fdea64f291b3382e11a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '118846000000000000000000',
    nonce: '2',
    signature: '0xa1bf5ff068eeea519cf5ec02beee1bfa13416c960e62b1d1a56c145963c1840c495711f62c30a9fb0b393c6ce5706c0f4ad5bd6ca869851cdc4984b6f5bf81011c'
  },
  '0x85a0e122f5fc84219ef1c9eac43ba19d1ee81b59': {
    recipient: '0x85a0e122f5fc84219ef1c9eac43ba19d1ee81b59',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '112719000000000000000000',
    nonce: '2',
    signature: '0x8f00d15baf56851014889fde34fd608033111b14f09dbcaf9ae6e8f2aa91166503f40f690a0ee5b8280b46b267b2830578b4c44ceb8d3d39d30d0e29eba796a51c'
  },
  '0xf56e7796e42d01109d3eb99905da2d83e6cf2ba3': {
    recipient: '0xf56e7796e42d01109d3eb99905da2d83e6cf2ba3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '111729000000000000000000',
    nonce: '2',
    signature: '0x5a71d060d1f9c4e7bac846c631095487437578393a057e72296f13bb39ed5aa1472625c81fb1da0025329be4d81bba39ba9a3b6699a05f93c86c1b74ba7716281c'
  },
  '0x77646bfafe02fe7d931e1e438c49f4f0a1c9a9dd': {
    recipient: '0x77646bfafe02fe7d931e1e438c49f4f0a1c9a9dd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '104837000000000000000000',
    nonce: '2',
    signature: '0x63958bbf6fca13d9973a6c8fd84a71fcbb1cdbeb5f17d726bce291b35b621cb2695e0cb8bf4a4fee0a620abd2b952b547d9ed1761671a3d7e1d4e05bf1a324681c'
  },
  '0x764abe778aa96cd04972444a8e1db83df13f7e66': {
    recipient: '0x764abe778aa96cd04972444a8e1db83df13f7e66',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '102827000000000000000000',
    nonce: '2',
    signature: '0x8827bd49c02524236e4b3b892f8215ccde8ca22f52bd5a9b8167bef85f60d551175436c6fa86caf65d8ae8f9cb4d5b71470f081d627c032aabe707367c293cbb1b'
  },
  '0x6395ebaedae3420e9d8c1a8c6268df9e829a32ac': {
    recipient: '0x6395ebaedae3420e9d8c1a8c6268df9e829a32ac',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '100842000000000000000000',
    nonce: '2',
    signature: '0x6025cf3c3c7c2e51b697d2f798e2e2ecaa143e4a92d507e2ef8807224d8319e41d1aed64f6644d0ae4a4e121ac118f8bbc7a46b7dba51ab669cf50ed2361ae3e1b'
  },
  '0xb6ac6964234ca4959b5cd157ad4d5fa59c354183': {
    recipient: '0xb6ac6964234ca4959b5cd157ad4d5fa59c354183',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '100421000000000000000000',
    nonce: '2',
    signature: '0xad534fc2eb8327845130f4a66042a50035e22778afe24c4813c354d1acde1a387a04ae4d34ad7ef7c93c5fd82b740796e29c791114705ae40c7096b862cd97271c'
  },
  '0x01c237c520f0409dc338a8db6569907453895238': {
    recipient: '0x01c237c520f0409dc338a8db6569907453895238',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '96821000000000000000000',
    nonce: '2',
    signature: '0x5b7508df0ab7528cc7234a8b27fedc75225884206837ec10d15bf2cc4cda610e63dd83358959aa90cd5447b8972022433f5a0dd880a0555e2c4c35d2924764a01b'
  },
  '0x98c45d5ea9ccd8278fe43ba740e967a49cd19e6e': {
    recipient: '0x98c45d5ea9ccd8278fe43ba740e967a49cd19e6e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '85145000000000000000000',
    nonce: '2',
    signature: '0xe2b82c2bb3e653fb806cf2f918d91a3331dfd8e76a087d6de3f985c59018c30c56dfc4ccc97caac746c8dcdb8a0b1e2b70b943ed08e636f16c37da99a9fe63611c'
  },
  '0xdb21fe5a4c961c9278f7617eb1f8dbb4c5417985': {
    recipient: '0xdb21fe5a4c961c9278f7617eb1f8dbb4c5417985',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '79090000000000000000000',
    nonce: '2',
    signature: '0xfa23219c10c2c75aa38665fe6172e2d87e305fb98522ad944db285ef9430772b01824b54ba3dc26aeca297f47b2b1c6a1052b7b1797aa7889a4512e2fc9db18e1c'
  },
  '0x19a353dacf8c334f60600801e040438d26d5bfa4': {
    recipient: '0x19a353dacf8c334f60600801e040438d26d5bfa4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '78168000000000000000000',
    nonce: '2',
    signature: '0x158ef418d4588d245075c962bfb3a81c94b052dbb527911e1cdbdbb28ad29c565776f267581c8daaf65d38789abb041bb0e275769080fbce345b22073abfb0451b'
  },
  '0x8be5a2df6488b0937299d27f092716f4a9ab2fd8': {
    recipient: '0x8be5a2df6488b0937299d27f092716f4a9ab2fd8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '76012000000000000000000',
    nonce: '2',
    signature: '0x9e31b6867798570577651ecf68af05cf3a0e66cd3c8179a1ce9b0e463fbd931a5452ac3cb7bfe20529497b2fb1b9e8553bffa38bd77579748f1687e8007eb54d1b'
  },
  '0x436a257ab8f9d8579594f493ffb81fa5245ada26': {
    recipient: '0x436a257ab8f9d8579594f493ffb81fa5245ada26',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '73816000000000000000000',
    nonce: '2',
    signature: '0xe7492e0ef27f922278a3eea1437c71002692b02448d25b918e4b925a57beb3f019c2d11cb6fe53a2e1cc3d2e443046eec482017fdb5812ec3be6f6bd493f06af1c'
  },
  '0x1cd75d18bdd26bfe906be4d63c8341474e6a76d6': {
    recipient: '0x1cd75d18bdd26bfe906be4d63c8341474e6a76d6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '71465000000000000000000',
    nonce: '2',
    signature: '0xd74b0298e9db4e18b17f9d60140f755d22d9d64c729cf20f4e3501e8f9fbf55e6ea2480b8a366408f90c414f660f620f3dba439843f5bfcacaec3bba78e4a5ae1b'
  },
  '0xeeaf61935c0c48f8f8cc28c381683d7fa2f75e1c': {
    recipient: '0xeeaf61935c0c48f8f8cc28c381683d7fa2f75e1c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '71017000000000000000000',
    nonce: '2',
    signature: '0x2a202ac33d44224fbb2d1a362abe0c1653f44431e803a0457e52605a25b10dfe56513aeb2af8ee150c89c1c7f02853f3787a2aca9d6e8ee06bbe99e93ee255be1b'
  },
  '0x18b24cf58dd392b9dad6ede481300d33309f3595': {
    recipient: '0x18b24cf58dd392b9dad6ede481300d33309f3595',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '70912000000000000000000',
    nonce: '2',
    signature: '0x324f0bc67b612c1ece32fa1d2a283f861aea40051cc08406cba3f5876569794f39750ff68fa94ccee86bd569094ebe81fccfb8620a39b69f8b4f0e9e97b904401b'
  },
  '0xe4f2d8f2b97c165e508e1f4d71095efedfbcd88b': {
    recipient: '0xe4f2d8f2b97c165e508e1f4d71095efedfbcd88b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '69638000000000000000000',
    nonce: '2',
    signature: '0x5fd45179f26831ce96018eead905ffc17ea459ba7073d1b804fb5486961641504ee41ae1e50247f099ddeb945b9fc4d41bf5eb257bd3b5adc52b56eaccc08c8e1b'
  },
  '0x7124db5cc3e23f7d6ba95c58df56596e3917f2d8': {
    recipient: '0x7124db5cc3e23f7d6ba95c58df56596e3917f2d8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '66722000000000000000000',
    nonce: '2',
    signature: '0x68aa071b5477043fbf1fcd8658191ae46b9271675b3056a64791efd39d4f26014aa93ab33dbc90cc0cb6f5b48170c525ba7f7b5dbdf2fba10f63311f41c938e81c'
  },
  '0x8af6ec0cdc9b4bf2a489cf6db7ecd961df3c00b8': {
    recipient: '0x8af6ec0cdc9b4bf2a489cf6db7ecd961df3c00b8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '66491000000000000000000',
    nonce: '2',
    signature: '0x1e17aa1ee74f6cb11f1f544296bb1ca75029bad4ed65614c215e70b50ebc32e3268ed7db9fff8bc138a7198fb24a3e90d1341db1f5138e38751681958122b4751c'
  },
  '0x8380bb3e5450b19d64886ed90a30af7570505cfc': {
    recipient: '0x8380bb3e5450b19d64886ed90a30af7570505cfc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '63710000000000000000000',
    nonce: '2',
    signature: '0x2cc9c61b021bc01b7a29b12fae33bfe1feb920ca0527d8104d4dff9cb407a4395efe4454c7186e7658888693066b1f8ba12e1f788427b4ace6f45a04bd83cca61c'
  },
  '0x269d89631ba588a6994bf5a0dccb2a86573781fc': {
    recipient: '0x269d89631ba588a6994bf5a0dccb2a86573781fc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '62086000000000000000000',
    nonce: '2',
    signature: '0xe8e4f05b107c3373f452d7e6ecb49ef31ced705092cfadfcc6145f294f1098407f4fd5561a450be2bdd6c4098eecc37e894edf8e1be5fa0dc1d37239debe3aba1c'
  },
  '0xee6357c15b9b6befa1e84a4ae2e1839821a3b91b': {
    recipient: '0xee6357c15b9b6befa1e84a4ae2e1839821a3b91b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '61699000000000000000000',
    nonce: '2',
    signature: '0x5e786947257c109789e162fe727d462b6738fec80cc3cc3163db84f789c5bfd003158da11859bc4a8816ca7434b048f3b362fcfdb85cf82ca090a9ad0403489f1b'
  },
  '0xe5ca890a0ef2f128eb3267e4711c6bf3306ec024': {
    recipient: '0xe5ca890a0ef2f128eb3267e4711c6bf3306ec024',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '61663000000000000000000',
    nonce: '2',
    signature: '0x73996cd5adcc674a0913a9f2b149da0247fdd0ef309054c9d5299fdc8d90b88b777f74f57ccbbf785fc8702f6626cd0fd824db6c4efa4c48296f1fe7d9f27ff01b'
  },
  '0x645955abc771076403aabe60d45228bbc44cc705': {
    recipient: '0x645955abc771076403aabe60d45228bbc44cc705',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '59986000000000000000000',
    nonce: '2',
    signature: '0x2410de601fc8efbd92fe5dca195a58d5519cbd394bd6b5937fa36e2e8a640f2f6e31a58b375d638d7b708d5c15480e6b1279715f0a961077907846519d8fbfb61c'
  },
  '0xc07a4c57a603c86b90b18ee8befa9556378a3843': {
    recipient: '0xc07a4c57a603c86b90b18ee8befa9556378a3843',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '59447000000000000000000',
    nonce: '2',
    signature: '0xdbd642c5b06b0c433dcee09e6d40cdb447ff415faf346e1de294a48be3c7eb666c65c4843b4e8c8faea9717913ba8d0789f308fdcf2cf8fda473e8fbdb35ce241b'
  },
  '0x20c467db9b9fe0fa39d879b3f23c475582da2fba': {
    recipient: '0x20c467db9b9fe0fa39d879b3f23c475582da2fba',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '57819000000000000000000',
    nonce: '2',
    signature: '0xaf5d9f63484fa05192d5bfa7856cb50e873f3e480a39db06b9512f7c331c959a2dbf1fe6f4b8bab305f610da7bafcc2ed39be39549bf4f0163d08310b6e2e0651c'
  },
  '0xb09267486f02a3b1b568f452951b66b0808ccc23': {
    recipient: '0xb09267486f02a3b1b568f452951b66b0808ccc23',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '55074000000000000000000',
    nonce: '2',
    signature: '0xe2240a08d39201dde736b1de4dd58a234cea84c246e2d1031003d6ff7a1675cf6bdde7f300a8ebbaffae36d0e8296a52c6532987ad2d747de2e7d2a343a6fdd21c'
  },
  '0xc0d4a42dd3cf5ac320d82e20a1285b50efe26615': {
    recipient: '0xc0d4a42dd3cf5ac320d82e20a1285b50efe26615',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '54401000000000000000000',
    nonce: '2',
    signature: '0xca1af9ad5cb3fb5d7c905c64bec6afac669e62462d60350e7a23c7aca4c6aaaa29a9b60f469ddec59182daca597bb8f2b4cf21464d0616bf12761eed9a8415701b'
  },
  '0xe22c1d3b82b028eb0ddf481f64df9a077c25278e': {
    recipient: '0xe22c1d3b82b028eb0ddf481f64df9a077c25278e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '54236000000000000000000',
    nonce: '2',
    signature: '0x087878649ca4903f4baa859d1bd096f231ef44e680471ea28bf1b1ea5e73bd79472e8ef97600a3b8f9142ffe43cad915b05b4c56a6eefc3bb219fd1c59b8a8e51b'
  },
  '0x97dfa8a7d0f5c29234bfc15569700c0d4fb5437f': {
    recipient: '0x97dfa8a7d0f5c29234bfc15569700c0d4fb5437f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '53957000000000000000000',
    nonce: '2',
    signature: '0xb6bbdc7c68b02778007ba8670e00608111eb7be4cbfd1190d8722945393f249e2234d16d0deb7bcdf4ab9e62fbfe994ec9b6d1ad0c295c56b61ccc424f8469d21c'
  },
  '0x6282e24baaaf04c984e8ae7cd0c763709743cf9d': {
    recipient: '0x6282e24baaaf04c984e8ae7cd0c763709743cf9d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '52967000000000000000000',
    nonce: '2',
    signature: '0x6a8f16098b7a4953e1d502e9dcd0c0692d8881c7e0ed2d638a842122617a9fb35eb0ba5a55522ddf8ed7e335b301b357fe4de2241611ca6b2bc31b22b2e1c1931c'
  },
  '0x51582e2c703b0d7c745c6a4ae0336c98c3c41802': {
    recipient: '0x51582e2c703b0d7c745c6a4ae0336c98c3c41802',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '51116000000000000000000',
    nonce: '2',
    signature: '0x6530f9b16d862cbdd228393eaac4b7d330ee9fa01d2d342513f00ce18c009ba454385a388d451b8a8cc197d78515a0ee6e9d1676db6bb559d3bd84d790ce606e1b'
  },
  '0x47b5c5040f1d440db91b022774303fbb3cc3bcaa': {
    recipient: '0x47b5c5040f1d440db91b022774303fbb3cc3bcaa',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '50957000000000000000000',
    nonce: '2',
    signature: '0xcf27183c306b78d128eb163c748405be0e02154c7b7ea665ae97855e2fe5b4c93d0433e555e067f3582192e699384e1ae816ec9743f8c8ee34cbe715d3dd9f921b'
  },
  '0x784c92becbc433a39f0c82e8a56c4de921086777': {
    recipient: '0x784c92becbc433a39f0c82e8a56c4de921086777',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '50957000000000000000000',
    nonce: '2',
    signature: '0x192b3c35b27af9a98fc1435f6f331b62fe6b3023e92643c511e37e042d727be520c5fb2670c7bc360b0b8932fe95cb46bb7459f82658d09883ea8e4a29c3e3571b'
  },
  '0xc0b34cc292d0727e488a6a31d1608fa4fb2d2bd4': {
    recipient: '0xc0b34cc292d0727e488a6a31d1608fa4fb2d2bd4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '50904000000000000000000',
    nonce: '2',
    signature: '0x8917fd6bb4613c425e323e0bc98dffa5da90c1ba4019f70f8a8eec9ad732d87e0f3f68fb0c6d037884a9165892187ca771d637a4330d53bae6eae48d25a362911c'
  },
  '0x2c83cd09d8728d1ba059921756b11a1cb8ba493a': {
    recipient: '0x2c83cd09d8728d1ba059921756b11a1cb8ba493a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '50821000000000000000000',
    nonce: '2',
    signature: '0x22e662c16b52637117c1f92a3e35caeec2fa7e16c2e25a5c8714c206aff2852570a54b10de4d3aba534487c1cc7048790a1962e6f986ef4dd1ef9af93d324b9d1b'
  },
  '0x32135aeab90ab298642921fad2069d887349c823': {
    recipient: '0x32135aeab90ab298642921fad2069d887349c823',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '50696000000000000000000',
    nonce: '2',
    signature: '0xb1ce059143dc3f79eb49257a2706603c764cc61e50086951ddc4275585cb27297c31aabc9119fbd9f9fd6c622d5022a247c0c2052a844ecde6f3a22237f660851b'
  },
  '0x8d55ccab57f3cba220ab3e3f3b7c9f59529e5a65': {
    recipient: '0x8d55ccab57f3cba220ab3e3f3b7c9f59529e5a65',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '49617000000000000000000',
    nonce: '2',
    signature: '0x0c92f08d305d8822183364d5c1d66d5e099c1cd01f732d9b5f574dea99e832ea129e8090ae456e17a741eade93463566b1dadfdb3beb59fde28b62d77a2ae6c81c'
  },
  '0x4cc33bf57e8b507c115d253ca5018dcea4ef747a': {
    recipient: '0x4cc33bf57e8b507c115d253ca5018dcea4ef747a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '46692000000000000000000',
    nonce: '2',
    signature: '0xd72fc073a906e1c6f246467c899efd63dcb4881e5f64a47f0f5de6a7e73b0ab6660b4ae75e41b5ba9799c7a474be591cbf7ed00effc3439a6a65764cc2ccd5a41c'
  },
  '0xbc3ced9089e13c29ed15e47ffe3e0caa477cb069': {
    recipient: '0xbc3ced9089e13c29ed15e47ffe3e0caa477cb069',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '45896000000000000000000',
    nonce: '2',
    signature: '0x1052ecf81a207721dc38e486ed69d268f816efab385b1850699268251311fe1e766de2f8325e5e39fbcbe0f34f5f073ebf311388d16adbf053d0f837f2615c701c'
  },
  '0xb5c3814ac0bc5177a22593e3b3d196dfe8954855': {
    recipient: '0xb5c3814ac0bc5177a22593e3b3d196dfe8954855',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '45394000000000000000000',
    nonce: '2',
    signature: '0x666713bd9b17556954287915aaf6d9d473fe74a7784be8961201c06c07d0fd173e05f24d8d782563c1a01a8b90f60751e6ca137276efd26c6cfb2d2500d94ee11c'
  },
  '0x285304c4cc2a512407cda997a805e551a5d5ad89': {
    recipient: '0x285304c4cc2a512407cda997a805e551a5d5ad89',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '45042000000000000000000',
    nonce: '2',
    signature: '0xe207d7cdd2d0c2401c6ecf1ccd2d1f136c80380c51787832ac80bdb721e6cfe87fcf05e7863ac5a08f9d1d361a5de4984198ff313869f73845cc92b71280bcf41c'
  },
  '0x83b0dc29dac89e5369d8fcb04663922dfa6a87d8': {
    recipient: '0x83b0dc29dac89e5369d8fcb04663922dfa6a87d8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '44402000000000000000000',
    nonce: '2',
    signature: '0x7a4b79d3fc6ad91960d31ef781054ebd007bd3f9bd31810e7a00b6358680f0c1546edf3df0918cbb3077b16919a809691881990c0848119bc9b54c0cde26b5921b'
  },
  '0x8b2839591bfda5ba7c8639a56fcb547f51fd6da8': {
    recipient: '0x8b2839591bfda5ba7c8639a56fcb547f51fd6da8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '44317000000000000000000',
    nonce: '2',
    signature: '0xf2739ef7acbc2a0993b6a949266b87804e2e4deeb9bfcd92a2a3d8e637b14d946dfc57b2e73e441f157b1284e2abd4040c026cfb60dd952f6bcbf5278f2d25091c'
  },
  '0xa65a864ca19535e4c06000768c6cfa9f6ebaeb12': {
    recipient: '0xa65a864ca19535e4c06000768c6cfa9f6ebaeb12',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '43363000000000000000000',
    nonce: '2',
    signature: '0x128efedfe844f69c4aa1fb3685cc9357e67b2bd124079331faccf8f2c77bb5a46a23ae960f6f0792237956eba487d5255126aba21f6d5118454f5505cea38c8b1c'
  },
  '0x9ba4006b91b0241b3b12239eecfd16a82dcf5935': {
    recipient: '0x9ba4006b91b0241b3b12239eecfd16a82dcf5935',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '42893000000000000000000',
    nonce: '2',
    signature: '0x8c7d50d0f8b96ec98648ea2dba6b0a13c9785e5c1a299021d7b26ff8b57a2fbf42d42c4230bd31a6cc260252ebc0d9d895e83487ecd2c54e6b8f90ed6d1fa22e1c'
  },
  '0x47144372eb383466d18fc91db9cd0396aa6c87a4': {
    recipient: '0x47144372eb383466d18fc91db9cd0396aa6c87a4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '42523000000000000000000',
    nonce: '2',
    signature: '0xc27366065b1597f143e5a7a52216587c5c604df20442cf4da53e03b26d99b1197cd0bf13e7e9086de6613a9cc9431838b3d8548f067d1d88d8073ca5077cda541b'
  },
  '0x01afc8ff608bfbe4b18f53d004ea7d1023bcda3f': {
    recipient: '0x01afc8ff608bfbe4b18f53d004ea7d1023bcda3f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '42280000000000000000000',
    nonce: '2',
    signature: '0x3bc417d036d231d9b29bf3139cebfc8a1cbb72aa959c9121385f679e45026dd83c000e8ad9ed931bd27b74a6abc06fe521d8127ebb2aae1d3853b80192aed8701b'
  },
  '0x5f60a4bc96269d3721ffd1eb1da373d51c63b6a1': {
    recipient: '0x5f60a4bc96269d3721ffd1eb1da373d51c63b6a1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '42162000000000000000000',
    nonce: '2',
    signature: '0x654bce5b35aa13b4961a1a934e25e113ba2859ccac547a5e7bc275757a269bbc617e20403b994727d8b7a037339e31449fe8a582a3fcf47504db138e007e44721b'
  },
  '0xa1737acda8453f0c6fd70c29cff0afcd1e16a237': {
    recipient: '0xa1737acda8453f0c6fd70c29cff0afcd1e16a237',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '41911000000000000000000',
    nonce: '2',
    signature: '0xa2383f906d2f7af185b2b0aadff13580bd956015b93a8641763bb88e4d477f3a518b0a1256837a688ee5be1703c86f79b2b586d10b9c23ec25468beb7c1f139a1c'
  },
  '0xfc3d126d801d5ce47df73b533d82917854641282': {
    recipient: '0xfc3d126d801d5ce47df73b533d82917854641282',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '41400000000000000000000',
    nonce: '2',
    signature: '0x9ee72488291437ceb1e4e5e0ceecb56c636856c6cfa14b22031788511f58779c26076c5f9f2eef8d773ea6928250e89516fcd1550eb2c6d10dd903418fbe334c1c'
  },
  '0x118058f3b8afd5b6a93c89c0c29df17119044a42': {
    recipient: '0x118058f3b8afd5b6a93c89c0c29df17119044a42',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '41340000000000000000000',
    nonce: '2',
    signature: '0x89cca51bd85540ac7396d32086458d2b98fb7beece95812b60b84537f49411af21ead62fc7494382c1c2f7ffa542cece674a26dfbf29606d6f0ae4803c49a1e01c'
  },
  '0x484ec62385e780f2460feac34864a77ba5a18134': {
    recipient: '0x484ec62385e780f2460feac34864a77ba5a18134',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '40992000000000000000000',
    nonce: '2',
    signature: '0x52ba118f3fd1e14e2fe0851e0373136d96e3a66c2430ae1d91b660198c3976e904acce150ceae6481dafd20d6052799de31c8065571f39c3d857468c96ca52ce1c'
  },
  '0x3d9456ad6463a77bd77123cb4836e463030bfab4': {
    recipient: '0x3d9456ad6463a77bd77123cb4836e463030bfab4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '40699000000000000000000',
    nonce: '2',
    signature: '0xb86d7fde02499d8e95cb0e8a35a297b7ea3636cf0c04928504da74e7bacd9bdb75af511ae55e5d4d5aa729be1cebd886f69d7bb410cb26c74931181ac103576e1b'
  },
  '0x4a15d91a07954bf91bbf40cfe14cebe2a6316cd5': {
    recipient: '0x4a15d91a07954bf91bbf40cfe14cebe2a6316cd5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '40343000000000000000000',
    nonce: '2',
    signature: '0xb6a1b2711fd723151297fd1b7be544ce7985cc4b6c77e763658c82c92d84b8402eeb09d03eae5d8190851b95030b162338fe824e133ccf8e7bcba20dcc25bafd1b'
  },
  '0x7f2fd2eaaf73ce2b4897566aca233244a4524bfb': {
    recipient: '0x7f2fd2eaaf73ce2b4897566aca233244a4524bfb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '40169000000000000000000',
    nonce: '2',
    signature: '0x26116b4d1bc795b7be187e833970aea0fabb1610d0d1c32a9ff1a989354f73d679065432a43e6f96f387c8e481186d799ab769c0582feaf59ebb9313610aff3a1c'
  },
  '0xd462dcff4bd35ab1648c09b355ab71bb68eb92ae': {
    recipient: '0xd462dcff4bd35ab1648c09b355ab71bb68eb92ae',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '40106000000000000000000',
    nonce: '2',
    signature: '0x7e445a79c2ef320b78113fdf69a134d3969e96eddcfcf6b0b51a880bd6fbab006b83337025909dc6efe50c19b0bea1484a98c74f8ce5a03fbc7879a80cb7b48d1c'
  },
  '0x3544f9b6e55db1727be0becfbc71751b04aeebb0': {
    recipient: '0x3544f9b6e55db1727be0becfbc71751b04aeebb0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39774000000000000000000',
    nonce: '2',
    signature: '0x6374a0ac571eb425869405a865822720bb882f631d0c9a90c8e8a98ed2f2829a52b1a884e407bd6a0f0ff1a55c8efce41ac95c342d01f0784ccd569e628ec0091b'
  },
  '0x59215d99869261f3a519a86807f029247bb9f88c': {
    recipient: '0x59215d99869261f3a519a86807f029247bb9f88c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39741000000000000000000',
    nonce: '2',
    signature: '0xae1273171163a4bcd3d9e8c9d9232a24da8a05d0704ae8192f31b2f6b99084f32295418d3ceb684c68f7bdc642aeb0b5b520e46aa2f166c6fd97a552f64cbdbe1b'
  },
  '0xa1f7c4b5a1cdc3fa649aa4cdf1f6670b9aaea881': {
    recipient: '0xa1f7c4b5a1cdc3fa649aa4cdf1f6670b9aaea881',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39512000000000000000000',
    nonce: '2',
    signature: '0xde95dcee401747db9f11f1be34912f6dc3be85d3f866e0311d7915de6910bfdf6f7684c036e23c07787f52a98bd0f53b407b78b20f97412e730a61186425b9871c'
  },
  '0x2c94c6634cef002cec6f2a7d559e797c6df5295b': {
    recipient: '0x2c94c6634cef002cec6f2a7d559e797c6df5295b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39419000000000000000000',
    nonce: '2',
    signature: '0xf5e0c16b63e17fdaad26ac967ba637305fb6a423afca980c7a0495fcbfe43c4711ad3aa2e2771fa1a569a7ab59f8d82e7d99b237967b433a5ff41bfdd1131d051b'
  },
  '0x19fdfa68625d0f8ce3cef4866b7e73f2a4e040e3': {
    recipient: '0x19fdfa68625d0f8ce3cef4866b7e73f2a4e040e3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39400000000000000000000',
    nonce: '2',
    signature: '0xb8af6c0867f14ee65ba0d8f841405a7b0ee75be249a982d93dacfe66143ac6b20991bc018c2e334768834465bdab6b1ee58f7405e3b6d36cd294bed933abca3e1b'
  },
  '0x2b1928b137232e279e11b010c7c0b4e084117d6b': {
    recipient: '0x2b1928b137232e279e11b010c7c0b4e084117d6b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39173000000000000000000',
    nonce: '2',
    signature: '0x5e5ebd8a296b3da4acf66fb8bb89a4fda0dcf3ab8db690245779fbf19750cc1c333ca4a287c023433ce00b4e3d0e9d4935d75c683f6ace2e49325f3eec8f5e9f1b'
  },
  '0xdb5d157e6bbec1a0ed18f765a3c49e9922215cf2': {
    recipient: '0xdb5d157e6bbec1a0ed18f765a3c49e9922215cf2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '39073000000000000000000',
    nonce: '2',
    signature: '0x50477b98371d775061d8c8be89a9ce274252ea7478b19f8c0fc3b03c6c7536a71ca05b3dce4adf8cf099e3d7056c1b8f5b9dfe64a9d96d32a7348dcf49a424521b'
  },
  '0xfb469965ec425d5b83da58dd75a3a77ac06eafa1': {
    recipient: '0xfb469965ec425d5b83da58dd75a3a77ac06eafa1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '38774000000000000000000',
    nonce: '2',
    signature: '0x42576a0c13127f970d3d9d227a4188289c5b28a246ef14803d70a8408f75986f70306e1693cce5879a5e0cf779386c71f9a37f926b9c5a03405d0960abb3e71c1b'
  },
  '0x3f52451f89f94e2227fbd5443e6ef50af1a8a311': {
    recipient: '0x3f52451f89f94e2227fbd5443e6ef50af1a8a311',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '38583000000000000000000',
    nonce: '2',
    signature: '0x09f9379c6b0545ba3929de3c5daea98c66a1d3af5680bac38346b6ab5448ee045f392cd7b20df47aa8405f5256034d2961076da9f1b105767dd8501ecf7f8e721c'
  },
  '0xa1733bc95272fdfb3d14b93e93aab1290b3ab7fe': {
    recipient: '0xa1733bc95272fdfb3d14b93e93aab1290b3ab7fe',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '37785000000000000000000',
    nonce: '2',
    signature: '0x889e722777c05abc45d45da5d632a52401c708ae03da4c959d483a26cf0cc62c5cb999e0f79e31002e0deaa4bb35ed6d69fd146692da2f2c1ce0bad18c97edc31c'
  },
  '0xa0ef713de5cb5d51c5b600698e018e39d401a768': {
    recipient: '0xa0ef713de5cb5d51c5b600698e018e39d401a768',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '37717000000000000000000',
    nonce: '2',
    signature: '0xdb70dd380c1cf9008d8664875d18199152d8db234972219425e64f6decd2a95707f192ec69c6c2ff2bbb9b728c6e253261b76a15d0757574feb373952ae33e3a1c'
  },
  '0x9650ee73e4c26595331660dbe98662532688f72c': {
    recipient: '0x9650ee73e4c26595331660dbe98662532688f72c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '37620000000000000000000',
    nonce: '2',
    signature: '0xf1247c085f6af7af7e47f763a14d47ca3ff6d8d31f3977d4363ac960e363ee8a1655737fc90ac8837f8b2f2f3d266915c6ef860f8ed2d30e2e29fdd1ef6329681c'
  },
  '0xca63a0f3ac9bdaf6dd83bf2646bc2c0e9cf974bd': {
    recipient: '0xca63a0f3ac9bdaf6dd83bf2646bc2c0e9cf974bd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '37409000000000000000000',
    nonce: '2',
    signature: '0xb5aea8b31e65e5a97fa58fd2ad8c7707e09a6c1d0bccd4db6f8468b55c300d3325102d6255739d99443a8d6b39620f980e41cd7df0e7b7467787d2707f89f4321c'
  },
  '0x2723d93109689eac639d1c5445cfcfc80a57d1cd': {
    recipient: '0x2723d93109689eac639d1c5445cfcfc80a57d1cd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '37184000000000000000000',
    nonce: '2',
    signature: '0x262afaefb11ef95560586abc9a51b5f4fc0dc03a7634167a584b228d08c2965201f0fded4ad9422507b5268172b5b2d1e57619789a2e201f7a79e946b9948f861b'
  },
  '0xb4f44c9f6dcde6a04fac6954191f02cc567ee6ea': {
    recipient: '0xb4f44c9f6dcde6a04fac6954191f02cc567ee6ea',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '36465000000000000000000',
    nonce: '2',
    signature: '0x8c2bb6ac974091e1c633ff723cb3ba2fe63758abff5e75d5960a52c7363385377fe34419a92ed03f101cf2efe2b847ff0e99b05d05f76996ce09f8690a6c19b61b'
  },
  '0x061723e247dc58b10d1f5f1b6e536ab26ba4ce22': {
    recipient: '0x061723e247dc58b10d1f5f1b6e536ab26ba4ce22',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '36447000000000000000000',
    nonce: '2',
    signature: '0xb0a83f74431fdc7f3f733a94eb3b0e8c89c89a92f7467668581af1123cafc659107c5189a8b832dcd2def3ca850ac00f0167b4949ccaf62ac258430d42ec21141b'
  },
  '0xa0a1a7e4886924b94941f465f7f1285d7843fbd5': {
    recipient: '0xa0a1a7e4886924b94941f465f7f1285d7843fbd5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '36061000000000000000000',
    nonce: '2',
    signature: '0x7f2728a65f51374d21c4d56fe63ce36eefb68006d569c9a11231cebffce5a6164cc46282799648540a9bbbfec4a1504b1c86ee2577c7c48fee16b653ef0b16281c'
  },
  '0x88b72a454a8f834cf027d3ae57ce56fa3f2e4fc6': {
    recipient: '0x88b72a454a8f834cf027d3ae57ce56fa3f2e4fc6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '35898000000000000000000',
    nonce: '2',
    signature: '0x0e3fe7781a585c7c834483dfedc34840d2aca27a0648d7972d3e862b2fb409ef3dc981b3b56be9172eceebb766012860d0158564697740ed94d5107d07cf50e31b'
  },
  '0xb0a0bcac1f8010af6c63e7190824ff93186d54e3': {
    recipient: '0xb0a0bcac1f8010af6c63e7190824ff93186d54e3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '35630000000000000000000',
    nonce: '2',
    signature: '0xb02abb1d9b2ada156b7111e739132aeed11e9b928d266ea2d473b62290fb4f774f437ed45040f676c327400023a8f1ad9c13088a7faf1af5f932388c13396d7a1b'
  },
  '0x166fbe095ee91924cf7fffeea1cbc75dc263e581': {
    recipient: '0x166fbe095ee91924cf7fffeea1cbc75dc263e581',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '35214000000000000000000',
    nonce: '2',
    signature: '0xa8dd9a5f64b204c7c54ffcbee929358f09c83fde3c1d273ffec47a6bd5f88ae15b6b2887138f5cbf8bdd74a0832984d250b2dda510f43590e456dace65465f761c'
  },
  '0xceb8c1227b97c1f19e4d20f450c6482f8a32df2d': {
    recipient: '0xceb8c1227b97c1f19e4d20f450c6482f8a32df2d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '35099000000000000000000',
    nonce: '2',
    signature: '0x41f6a818338c8143da50aa948d197e7583d51eac41412e9c0aa5d8e1d2b9fd176b2085e39a20b777899f7d31cb849fa27109309d09774a6a58b9267ad75c28621b'
  },
  '0x70d9f98ea60658fd81cc54086006e949aee207f3': {
    recipient: '0x70d9f98ea60658fd81cc54086006e949aee207f3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '35077000000000000000000',
    nonce: '2',
    signature: '0xf0579dc4fc072590c20d9cd49fb10a97a3e7545b9de2749b2c9e57246628a04852f25973e7342a36358f436cfcf281c262b82467c0a62fb00b01b7f904aec4481c'
  },
  '0x4fd71c1619ccaa1ad664575b61963cf86f58f550': {
    recipient: '0x4fd71c1619ccaa1ad664575b61963cf86f58f550',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '34922000000000000000000',
    nonce: '2',
    signature: '0x6c681ce99870e8ff8ebf17073a7f1cb372634aea4638a5e52f76c47870272d5f17e5514c65034a707e304d3458066ecaf69ab90ef56d93fe0107a5a62c6ad0841b'
  },
  '0x225ef95fa90f4f7938a5b34234d14768cb4263dd': {
    recipient: '0x225ef95fa90f4f7938a5b34234d14768cb4263dd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '34466000000000000000000',
    nonce: '2',
    signature: '0xe866f71f4aee1c9ef4adbc796ae8f02013729212e8215bbcc420b5d501a15eaa25565dcf3641fad2a0b127fa9d6a7533fba4fee4818a3a0f84b239ea965fb4061b'
  },
  '0x08267cb203b4cf1066a81b0f218dddd8f0e33e1e': {
    recipient: '0x08267cb203b4cf1066a81b0f218dddd8f0e33e1e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '34017000000000000000000',
    nonce: '2',
    signature: '0xce4edb57398241cfe4436d2c69373041a6b280de9aae08e95c0ef084faba7a067594e45eb1f3e7ee6bb6f08391aeac4b9f50e4dd0270860a9e76ae17a1fd26cf1b'
  },
  '0x19147728b303cc2f62f704893de21f7203fb669d': {
    recipient: '0x19147728b303cc2f62f704893de21f7203fb669d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '33749000000000000000000',
    nonce: '2',
    signature: '0xcd10b1f91c3f5f89083be464c5040a3036b751357539654d2bf7e44b0380c1255ef073f2e6b29f7c5a71a77b432fcbf16062769a3a4b4cb705b7f434f927955c1b'
  },
  '0xcfc96f5249d43deb404bb428861b151c93a16c13': {
    recipient: '0xcfc96f5249d43deb404bb428861b151c93a16c13',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '33689000000000000000000',
    nonce: '2',
    signature: '0x03f7a58717402e22d9eb5f68d25b9a2f9dcc5cde166ff2903a12714c57640b0839d0c50d6b8173bd162d963a0dc440d6ed0ed69e473e6a000a16c6a22964cbf91c'
  },
  '0xa7fa06bb4fdcfc2507726b3706b8aaa22941b12d': {
    recipient: '0xa7fa06bb4fdcfc2507726b3706b8aaa22941b12d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '33301000000000000000000',
    nonce: '2',
    signature: '0xeea89359b05e68c78c6af30dde22f8d28772bb8c745a3e09195ffc50f69763e74d2d9862307badbc2c96c1a625ff346f090b072af8262ff9561eff7ca85e7aad1b'
  },
  '0x715626094ac053150d38265960c29eb2d7dc4c84': {
    recipient: '0x715626094ac053150d38265960c29eb2d7dc4c84',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '32894000000000000000000',
    nonce: '2',
    signature: '0x4c4e427882b079a899c44c5fcd7f79488d4ae15ef5cf486b35f527cc33188fed37edaeb82f2eed89d5e15837aaf96c3f482f53f6009f95c682491ac6119244431c'
  },
  '0x6e34247a8ee476282e07e07a322b934c74641993': {
    recipient: '0x6e34247a8ee476282e07e07a322b934c74641993',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '32343000000000000000000',
    nonce: '2',
    signature: '0x5603492e237c0b132c8181a05c1f37d62b79c98702d339b2cfe9d51ecea03afd001c4af6186916275dd0bed3afac835f0a3cfe971b159ee26946b73b91b0aca91b'
  },
  '0x6b3284d27b554df710cbce25461f53cc6c24c220': {
    recipient: '0x6b3284d27b554df710cbce25461f53cc6c24c220',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '31498000000000000000000',
    nonce: '2',
    signature: '0x64738cd7c8f4c4191beb05f34f4df256ec8712411d442a9261a0917314305ddb4a62a9b7b99194ee3147535508f63ef9d717667ac8f0d5e4581ea1f41a3e1c711b'
  },
  '0x1512bb6c071e8bebb6a24dac0a14e304d2d69941': {
    recipient: '0x1512bb6c071e8bebb6a24dac0a14e304d2d69941',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '31283000000000000000000',
    nonce: '2',
    signature: '0xb2858d97667a05631da6af03a38c51da2ddacf811380d3ac5809d3b103115ba22a1a2afba9f667d06c46a4b2d19c11ac7d05f50107fcd67566beda254e44c9ac1b'
  },
  '0x5bc4fc165d68d1214d3186f7b60d67d85009db56': {
    recipient: '0x5bc4fc165d68d1214d3186f7b60d67d85009db56',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '30488000000000000000000',
    nonce: '2',
    signature: '0x8101b28fc149591040922a979799ac9ace4ebf5cc2f8b7e806ba5ce9acd0920a2b76cd8ddb8becba669c3a1d3d08332c3598ed126cae3c129df310b904c0dbe81b'
  },
  '0xc5e1925d0f592cf4ab3f84865875b4ed4185423c': {
    recipient: '0xc5e1925d0f592cf4ab3f84865875b4ed4185423c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '30476000000000000000000',
    nonce: '2',
    signature: '0x0e4daa8720a2f472b554acd99cacd025b554b41b911657d73b332072e54adc0557cf1bbd5b73ba67b34e4e77717a2ffcc76844ab242e2746b02299f9c0a180951b'
  },
  '0x1c3ac1eff28301c1490d329292c01dcf9082576a': {
    recipient: '0x1c3ac1eff28301c1490d329292c01dcf9082576a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '30447000000000000000000',
    nonce: '2',
    signature: '0x24d739794e34bccb2fda89f6ec76b2aca80622fcadab8fa81e9c590e30770b0c77f9b331a229df6a4b2845f7769dd2766d921ebef6b36a85ce77caabc821e0e21c'
  },
  '0xdf187eaedbb96411b67ea3222689db228c71b6c9': {
    recipient: '0xdf187eaedbb96411b67ea3222689db228c71b6c9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '29914000000000000000000',
    nonce: '2',
    signature: '0x5aab1ff78292e4a845df008623d44b4d9766a2000b39fc3db2ee3f59abf252a22df8266438193451868bc30cce30b2bbf7e481ce60f658a0c23c2e7f7b09de4f1c'
  },
  '0xfc30df763f2aa90b6f43832a906fd3ff5a30e538': {
    recipient: '0xfc30df763f2aa90b6f43832a906fd3ff5a30e538',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '29903000000000000000000',
    nonce: '2',
    signature: '0x6816fff3cb582716da937f6ec227df112f24892f02f7ccaab9f6c464d7079d9661dcebdac2cfc6fc1674e9bf4c89ac1bccbda3ba74b2ca8aace428011367419f1c'
  },
  '0x2f8f503ca38df1c355abfb949b28bf70f9ccd42c': {
    recipient: '0x2f8f503ca38df1c355abfb949b28bf70f9ccd42c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '29887000000000000000000',
    nonce: '2',
    signature: '0xe75cc51fee5c7ac5f28048ea0f80be1bc11e39cd1b19ac36aa04ff5d8586b39453fa65a68ae4728bf34326a6ea454ba1a9eac6f67e98a5abf161bf5b2d8f73b11c'
  },
  '0x0b34ce264ed6ae7474e3a12511700f4524ebd504': {
    recipient: '0x0b34ce264ed6ae7474e3a12511700f4524ebd504',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '29514000000000000000000',
    nonce: '2',
    signature: '0xf7e0ffdf9c04dc60f34942f9c33589c2a89ff3833d4780aaec2582cfbb9668176c645950d0725600ba1cbcd6a6a300396a13009033afc2eb2d2bc65caf58da9c1c'
  },
  '0x39032193d7bf5be28cd214ad938162892059a9f2': {
    recipient: '0x39032193d7bf5be28cd214ad938162892059a9f2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '29413000000000000000000',
    nonce: '2',
    signature: '0xdbe50e80ff966d8fa27c1c04f6514bb8294498d3dd8898405440a78b764871c31d92fe9e46f2e46ec05379d068a5091c05d92b9c8aac0671d18d72e27493e1ea1c'
  },
  '0x7b7081b4ae0bdd9793f88fd00856dc06ab1764bb': {
    recipient: '0x7b7081b4ae0bdd9793f88fd00856dc06ab1764bb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '28511000000000000000000',
    nonce: '2',
    signature: '0x8b38402f45e4616e9352ecc07c4c85dc105483ee0ea0204dcab8a97d2597160d46a1303e569720b472a53c45b7192164966e63130832d45dec2a035a339728b81c'
  },
  '0x652d7e1b706b10de0bfee179c1cc41df73d3ed34': {
    recipient: '0x652d7e1b706b10de0bfee179c1cc41df73d3ed34',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '28475000000000000000000',
    nonce: '2',
    signature: '0xb9730547e564b1887ba6c3f40a6a74fdc94749b5f08d6f0604881739be84003f1de606e665ce07b4b0ff7f5733dfb533b552aba5789958bb8877006b10dc7eec1b'
  },
  '0x8448b2fe4c0d1588f4cf57232423ca80f3277dc4': {
    recipient: '0x8448b2fe4c0d1588f4cf57232423ca80f3277dc4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '28349000000000000000000',
    nonce: '2',
    signature: '0x1321158a1f83ad1835fcbd53033e207224e910682dee2e80fb50ae3a0a55db26606eb5abf074f859c6597c57d8a47d38a18d7d4516ccb41b2ddda4a71baaf1a01c'
  },
  '0xad47e9d28103d74e94ac5a36ecdbdcc573b5a393': {
    recipient: '0xad47e9d28103d74e94ac5a36ecdbdcc573b5a393',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '28337000000000000000000',
    nonce: '2',
    signature: '0xb87bc762601f2fd4ae97bb88d8e0f23cc284ee5c9bb733df3ab3d7f31d773c9619587a5f3333dc235627e1d7cd2762f7e129ecce287b1a47e0a5e57f05ad4aaa1c'
  },
  '0x90ab8269fc847f7e5a74f1582c10d183c40961fd': {
    recipient: '0x90ab8269fc847f7e5a74f1582c10d183c40961fd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '28134000000000000000000',
    nonce: '2',
    signature: '0xac09634062ded3c89ad8a1f7c0988013eb7fb5a706009a759b390e85cd39b8b53e57c7c2d8598bccfa88a06ceb494651abc57b77346d3b7d00576118a09337ca1c'
  },
  '0x2c3fc61e2cd53b6af7ec6217ba5d202db4cda47f': {
    recipient: '0x2c3fc61e2cd53b6af7ec6217ba5d202db4cda47f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '27998000000000000000000',
    nonce: '2',
    signature: '0x6c088a8abc8cebfb7f1f9bb3073fd5637485586b75ea39342bdb40c50eec5f623201ae9e26fcfa1c7c1f7736534dd3d594f2f498611ba82e7ad49a2fc19484ee1c'
  },
  '0x8b473886d1580888c01ad886079ee4162ca4cb87': {
    recipient: '0x8b473886d1580888c01ad886079ee4162ca4cb87',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '27968000000000000000000',
    nonce: '2',
    signature: '0xdb833084b4bb1a182e6a2506836ea8f64b802bf2a659e8e05508ee68035d8ddf15eab941736416ed20f0067604aa12dce51f584c16c3403e71c970f991ad649d1b'
  },
  '0x39beb60bc4c1b8b0ebeedc515c7a56e7dfb3a5a9': {
    recipient: '0x39beb60bc4c1b8b0ebeedc515c7a56e7dfb3a5a9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '26887000000000000000000',
    nonce: '2',
    signature: '0x5fbc179ed50aa0c5be2632a38c289e0c428f2a1c4af6b2dd6fa0d1bfcd02c52416324506664b208c2749b3c09239029292c0ed12e46af24508153a99a0efc1731c'
  },
  '0xbf9af3c007f0b8580b158619eecee74bdabaf7a0': {
    recipient: '0xbf9af3c007f0b8580b158619eecee74bdabaf7a0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '26468000000000000000000',
    nonce: '2',
    signature: '0x6a47babfe66b803f8c8dc611272962c7735abc0507dc09fcd2933465b794f7172017d940e1c5e83b4a5bd48a612a445c9ab9b7b7456ab455d7fcc8b96dfcfb201b'
  },
  '0xfac6b91421ebe1cc176cd50b8458de833fe1720e': {
    recipient: '0xfac6b91421ebe1cc176cd50b8458de833fe1720e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '26273000000000000000000',
    nonce: '2',
    signature: '0x92ba5ae2f07e7cb6bba043d56f60bac2119ead784a5e3f900d47c973a8117afc606a9e88769508f2fd8591c9b780342538a2f3eb381a356976539866b2d661061b'
  },
  '0x17476d0ed31f81d95b5ba8960b2d0b4de4675e64': {
    recipient: '0x17476d0ed31f81d95b5ba8960b2d0b4de4675e64',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '25494000000000000000000',
    nonce: '2',
    signature: '0xe8f195e01affefde62e1fd5ddbf2d1bd0628f1595df1fb82d05149c2e863f0a71e212ea9bc8ffc87e5134a7c1031b17a4ac6c07ccb347ae289244c1bfa350f921b'
  },
  '0xa87cb9fbd11ff8a1519216b9c73353397619a4ae': {
    recipient: '0xa87cb9fbd11ff8a1519216b9c73353397619a4ae',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '25472000000000000000000',
    nonce: '2',
    signature: '0x48643ff761d24e7047231c3f5458d26bc2472a8d029d790425be746c18148f5c52a32ecdd4e1443cba691a1df2cfda2848a2c5ecbabe64061f16f9a634294d031c'
  },
  '0x4e45afcf5ea8446be066a163cb274e719020fc41': {
    recipient: '0x4e45afcf5ea8446be066a163cb274e719020fc41',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '25422000000000000000000',
    nonce: '2',
    signature: '0x5adc606d4aa8e88ec7430dfead4e68a6e056664375ba37bbe6712c67f2c5bd253d59be140e324608c750a48c5ccb5c399708ade3b246e007d56bbc543ad260581c'
  },
  '0x52e8024ab78d50a66126837b984ca410547cfc95': {
    recipient: '0x52e8024ab78d50a66126837b984ca410547cfc95',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '25160000000000000000000',
    nonce: '2',
    signature: '0xd00ed329da5665b08981406b9527c8df70810289bb491435f0d272156778bc81509a54d62ff5ef75b8a5f6835f8c0f2f10e26b8eff3234c1503f7257bebf60a01b'
  },
  '0x4d309554153ef6ebef728d5fd90814e855467fb7': {
    recipient: '0x4d309554153ef6ebef728d5fd90814e855467fb7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '25001000000000000000000',
    nonce: '2',
    signature: '0x4b438d687fe9db611b22fa0182e6a0b3ece2882ef45add0aa13f54a9b75606a71df026989bbf303c63c2a138e1d620eda14a6ecf028592224a54a80fa27280cc1c'
  },
  '0x1ccb7bcfce8efe4c3adfd3396468beb69260317d': {
    recipient: '0x1ccb7bcfce8efe4c3adfd3396468beb69260317d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '24950000000000000000000',
    nonce: '2',
    signature: '0x62c6e1b569140e8ed1410c2ce54f19c8799f3899c61e70a327aca786566b08e019e919738061a01cd935e08cdebc8e9a22a1cde7b03b0e75787630a0c81001f51c'
  },
  '0x2f5fc65b9c6d1fee8ba4ca23fb2d7c363c95133c': {
    recipient: '0x2f5fc65b9c6d1fee8ba4ca23fb2d7c363c95133c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '24713000000000000000000',
    nonce: '2',
    signature: '0xe66e908394a27f5847eaec2dcaa2004a75abbc5322c65b2e8b33eb7efeb341127c7a1634e640ef95906818279da4030d4932e529b8f9f688b3c58876a3513c611b'
  },
  '0xa33e5e1ccf57c0caf78ae399061f270dd24ffcdf': {
    recipient: '0xa33e5e1ccf57c0caf78ae399061f270dd24ffcdf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '24297000000000000000000',
    nonce: '2',
    signature: '0x13be4978cab29d630ccfc8304b8eb027b992eddeabf359b1cdabf11e1e288d01065a824ac7bff5a9645793e806aa64fe077413eb2904e0295d633a8d07bdddf71c'
  },
  '0x40158e1d5eaa2dc589552464010fe81b1b3a23f5': {
    recipient: '0x40158e1d5eaa2dc589552464010fe81b1b3a23f5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '24143000000000000000000',
    nonce: '2',
    signature: '0x100c5c81d582e085bb92c1253964ed5ca122bed81882c4ee2cfbb1d906cc7e0a019a4a9bd9434c01a6ec52f1f5e5ce1553c994a88483a345ba7aba098a54a55e1c'
  },
  '0xdf8ce71a151c6af023245819d60ddf4be792b38f': {
    recipient: '0xdf8ce71a151c6af023245819d60ddf4be792b38f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '24021000000000000000000',
    nonce: '2',
    signature: '0xddd807d521debc8c16184c25f4383c46b4bd20f184ab296435c70b288fd802fa7f392531d64451b6519604abc9be9f55fb79ee57daf10905486723f6fceddc441c'
  },
  '0x7415dcf6b2298cf5b9ee63c00c3c73c942c3f220': {
    recipient: '0x7415dcf6b2298cf5b9ee63c00c3c73c942c3f220',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23987000000000000000000',
    nonce: '2',
    signature: '0x1a44d38611a84a68283be046b544216619e949e691cbaa7c921a7456d7cc436c2c0ea1d5ae15f9522df895e77b309dbd9877c0c0f99d954a3c3865d742932bee1c'
  },
  '0x1baf16c71acdc044bd7b6e5805d93a8b2be90e78': {
    recipient: '0x1baf16c71acdc044bd7b6e5805d93a8b2be90e78',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23816000000000000000000',
    nonce: '2',
    signature: '0xff62c879109b01e67667a2378219f9c2f82180f01ae7772d1e31a36ab2b06a8d4d643dd141d93ea8f7132ac44b3c35a466abfbdda61af703859b5262d95cf00a1b'
  },
  '0x9592c2c910c62570381686024d9d53bc357c97fe': {
    recipient: '0x9592c2c910c62570381686024d9d53bc357c97fe',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23815000000000000000000',
    nonce: '2',
    signature: '0x8713419e235f43f37b161126777ada0071be7548290adda3b4e0c614c99f17a1327712a0088cf7f6b367414353668d496964c282f7a8021b2eafc26c4b6368621b'
  },
  '0x8de1290b5b4292a506b542115edcecd86e9d6e94': {
    recipient: '0x8de1290b5b4292a506b542115edcecd86e9d6e94',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23682000000000000000000',
    nonce: '2',
    signature: '0x70aee194d743965c2ea38810c94dd27855c4a8c9d3d71be1565897d5a2c76bff587dce55ea0cf4098de4da5c95c3cdb02294b3c5c0b5096accace3661bd9b04b1c'
  },
  '0x25de6ee9bb67dca9654259843682be875ab85b5f': {
    recipient: '0x25de6ee9bb67dca9654259843682be875ab85b5f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23394000000000000000000',
    nonce: '2',
    signature: '0x82a5ebac085722417a89f5e691fdb0d4d9ba12db299aef5618b8769bbde4168e0ad757fa1939bc5fed88588aacb6b9313dcceaef4281ec0fe91160d2a4ebb3d71b'
  },
  '0xc94dd5caa29fb3681f08594668db10bdb1ebf316': {
    recipient: '0xc94dd5caa29fb3681f08594668db10bdb1ebf316',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23280000000000000000000',
    nonce: '2',
    signature: '0x23a6cf92781b73b1f60bae22baa3fc2b4eb2f4db940efc73124a36c35d56bdf90357da556bfc89a5e42945377ab87db0e50944d7eba842b0063928430932e0f01b'
  },
  '0xacdc434144ce2f9ddf7836c82f4717e5929102f5': {
    recipient: '0xacdc434144ce2f9ddf7836c82f4717e5929102f5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '23140000000000000000000',
    nonce: '2',
    signature: '0x0b69977873683bd34b2601e2237d7969167e8b82df7d0f70f6b99acc049a8c974e31ed807c8128fe0b1ab4bbd4ff60b06ec5d5df6b68c70f219353f980a102331c'
  },
  '0xe1c163f5eacd153803104f189018ee58750e628c': {
    recipient: '0xe1c163f5eacd153803104f189018ee58750e628c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22576000000000000000000',
    nonce: '2',
    signature: '0x3bcbbfd371d10f7bb32578f3e478e8df207b363a2052f09f271050b5b781cdd83d7355ee6e63a11dde727cd618e19d88c3907e545e1fee8fb1d6f4acbef95da61b'
  },
  '0x615f0b17f8f92e2038c481323527c734f9e2366a': {
    recipient: '0x615f0b17f8f92e2038c481323527c734f9e2366a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22501000000000000000000',
    nonce: '2',
    signature: '0x0fbdc697d3fb3b68fd362f5268f77cca22191df346189b4ffe0b30db1b63126701f12f3efff8fe0c628fc9511f10589f2fea3791a841515d4c79f725a9ed4e741c'
  },
  '0xb4e747c6a485ff2b19e9bf716899cb6d3e20e357': {
    recipient: '0xb4e747c6a485ff2b19e9bf716899cb6d3e20e357',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22263000000000000000000',
    nonce: '2',
    signature: '0x3d8523032be56f78068646d38544053bae5ec5d75d772782e2765ed356b381ed04a3f4de4bb380cd6640674de588f16a4badfd4b30fe1b54a15269e407f273041c'
  },
  '0x5fd4fc7cbde1413af8f889d6ddc2b412897132a0': {
    recipient: '0x5fd4fc7cbde1413af8f889d6ddc2b412897132a0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22240000000000000000000',
    nonce: '2',
    signature: '0x166849833f53c0e91b6b4fa587c5920a7a24c758b757b96d51e21a61293ec10a0df9f5e131a82948f7307c7ad1c8a05699bb8fdf946c4f79971641175f8bcf121b'
  },
  '0xc3e5416d13f15e58699fa043910ce28dd341411f': {
    recipient: '0xc3e5416d13f15e58699fa043910ce28dd341411f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22233000000000000000000',
    nonce: '2',
    signature: '0xeb67476bb6ed7f4fbcc8d0f03120341260f79e932e71f3a22f56c47a3d3fe4493d9fad3fa63b5a5ab9d5f1b4f2734d55c3586dcdff58ae17977a888c85b949a11c'
  },
  '0xb97167440c95fd6df9053b813855d5bdca557409': {
    recipient: '0xb97167440c95fd6df9053b813855d5bdca557409',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22195000000000000000000',
    nonce: '2',
    signature: '0xc4d9848e749a25a0e98dd1c87a45dec747da58c3d0c111c672ff43d5bd70478005170f4619cc746d94e2848d2d57bcfb8c76235c435edc12e23e69daeae5a8491c'
  },
  '0x32e594fe54f615c8ca52ee4d01269ad4b0b9ea09': {
    recipient: '0x32e594fe54f615c8ca52ee4d01269ad4b0b9ea09',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22156000000000000000000',
    nonce: '2',
    signature: '0xcdbe02ced4daf76e54551ea4f62cc8797e790c8065ae5958491d5958d06d78020a23923bf1e5c3021ac22110d90fb1ec1d7ad6d44231f74d71a23f77364c3fbb1c'
  },
  '0x565196152f8a4066a51b498a44a9981145d441ff': {
    recipient: '0x565196152f8a4066a51b498a44a9981145d441ff',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '22097000000000000000000',
    nonce: '2',
    signature: '0x1cdda26652a970350820c73b4b09c66b18d496a57695f44c8f4750d422e0ee602a4471f60e4c85955327a1554a79976c62ce1a6f523f4927c14ed9577de565361c'
  },
  '0x8c7e486cf9020a40e05119e2f7523bafcdd8a1c3': {
    recipient: '0x8c7e486cf9020a40e05119e2f7523bafcdd8a1c3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21890000000000000000000',
    nonce: '2',
    signature: '0x9fe1da7911a216cc71630289a1563d013d4e11e95fe56459f468753a573aa4f97beea05eed46f04a9afa01f74b6a971f3c07a78e7f920e394b95434533ba2af11c'
  },
  '0xc881ba24fea61d9c629bb7eb79b3b20392584115': {
    recipient: '0xc881ba24fea61d9c629bb7eb79b3b20392584115',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21769000000000000000000',
    nonce: '2',
    signature: '0xad4d6900eb5406137b4bdd6e5d77342a5f67d6d3e54711d9f4629e4b06dadf08779b291f7da6b061d34eed3ba7472edc602582922b2fba48bf5c8016047257281b'
  },
  '0xa17138c0675173b8ea506fb1b96fa754bc316cc2': {
    recipient: '0xa17138c0675173b8ea506fb1b96fa754bc316cc2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21744000000000000000000',
    nonce: '2',
    signature: '0x7aff2ae258790ca3856a66f27c7d0108e8f8bc0bed80db9e36e538ce1dd34f8e38f23335a8836aae3e1446e9218b8b8374fd79cca606a73b9a4c2690148660471b'
  },
  '0x0eefa7e7877aeb0ce0ffced291f492458aae19eb': {
    recipient: '0x0eefa7e7877aeb0ce0ffced291f492458aae19eb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21581000000000000000000',
    nonce: '2',
    signature: '0x8835b56e7ab76bfc287b937922eb39e26e9b373b444c5741e475323cafab6386236c00a53dac680ebca3602c89973bdc2855fe4f90da226e870dd706707df82e1b'
  },
  '0xb4b5690d2513049e7b519c7ec21da6355552b15a': {
    recipient: '0xb4b5690d2513049e7b519c7ec21da6355552b15a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21381000000000000000000',
    nonce: '2',
    signature: '0xaf05f66a28a281c754196340e3fffcfd0b319ffe0b1565587fd042b7bfea74e0270b8a1d41fc9a2747e7217752a547af79f07228ee02d5333d6ef94d259d67e41c'
  },
  '0xfe5d96f817e684ba20473e7c54f1ba3f0ad5207e': {
    recipient: '0xfe5d96f817e684ba20473e7c54f1ba3f0ad5207e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21273000000000000000000',
    nonce: '2',
    signature: '0x17e65fe6299b01221829d1f608396281be02b40530b9d4914117567c7aa8059471351a8a7c147faf6e8d320c6077773a5ee25fc4c8715de29c8e91c1c393c6b41b'
  },
  '0x6d84096ba79cda4ad8a4a324966690a7792c3b1e': {
    recipient: '0x6d84096ba79cda4ad8a4a324966690a7792c3b1e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21137000000000000000000',
    nonce: '2',
    signature: '0x6c579e71fc9e83df2291e1be205200dea900e43bd5e36ab203803e471564589d11ea67701b33f58dedeb1150f42cd6e0cba07b99b79726d0c7c065d73e69a22e1c'
  },
  '0xf82d46e02dd50c280050d45f22d7e22aceb0f0ae': {
    recipient: '0xf82d46e02dd50c280050d45f22d7e22aceb0f0ae',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '21096000000000000000000',
    nonce: '2',
    signature: '0x97fc712a2a818480535b8c27428953d136280c2c5f375ba1f28a00772448de051096fa3dd241c807fab36b520269f1a5bc426b5b6ec1d19a882a1a61fbf263981c'
  },
  '0x86190c50d1dd3eeb2ab1dbb8e7195f2195af321f': {
    recipient: '0x86190c50d1dd3eeb2ab1dbb8e7195f2195af321f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20996000000000000000000',
    nonce: '2',
    signature: '0x2ef5efadaf7722105760f737a24eb08c0f35c39245a1ac852707ce0102517af7541c1185ed55c10045c9635c200ad43ee3780b0438866c6d2583291bb8b3efe41c'
  },
  '0x00777f41fe3ba1b2f8bcf439b6df74fe0d46e53f': {
    recipient: '0x00777f41fe3ba1b2f8bcf439b6df74fe0d46e53f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20918000000000000000000',
    nonce: '2',
    signature: '0xdf3a0b854df2561d08f004dd883fb3b2d6122651d7fdfe89da8e5bc9a26f197f7ec49f9c861b1b180739fcbe36a6ae02a05c89f2bad477fc72b40d822777b0841c'
  },
  '0xae89fb115c4ca200034b427b2d8ad3eba1efacdd': {
    recipient: '0xae89fb115c4ca200034b427b2d8ad3eba1efacdd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20912000000000000000000',
    nonce: '2',
    signature: '0xa0a0554347c59eec057924be2727278f7d028b5eac16205b0f0edebdadde380e0da8b1678ea3469fcfb3fc8e93e1185a6b4b754ee5a5e80effffbdf7d09930c31b'
  },
  '0x9f8c1ed0334b06d62d04e6bfe4c8a3adb2e15ad4': {
    recipient: '0x9f8c1ed0334b06d62d04e6bfe4c8a3adb2e15ad4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20506000000000000000000',
    nonce: '2',
    signature: '0x730b40985cc72bb152a062e16679e189958a1a545584eaef8ff6060c91cede3613fb2ad91848704129293da66d907d980f3b1724ad5603a18e538e5ec44be0f41c'
  },
  '0x2b2894d4d1e0233fdda02246371b9e37bb18e10d': {
    recipient: '0x2b2894d4d1e0233fdda02246371b9e37bb18e10d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20165000000000000000000',
    nonce: '2',
    signature: '0xbd8f435f58b1d6b0cfa4dff33a2cf448f8866411bce099ae4003426e8350bf803e719ed36590235608cf760c838d08596200612c64d7e12e69aa5dbd843198e21c'
  },
  '0x391f451a476049d5b78fcb4add254d6483e8fbea': {
    recipient: '0x391f451a476049d5b78fcb4add254d6483e8fbea',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '20030000000000000000000',
    nonce: '2',
    signature: '0x60391aac9b2be76852f33e199e2c82cc9f7ac75faa5dfbe610fd6c928cfb2ace1504950d0178096de316f598ae1f79dad6e8684268b9676c85cdedce97f8b2c51b'
  },
  '0x1c6aeb6f93f36d9fa6d2d3123698d5663ec5e2f7': {
    recipient: '0x1c6aeb6f93f36d9fa6d2d3123698d5663ec5e2f7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19937000000000000000000',
    nonce: '2',
    signature: '0x7050f6b3c02001cd5fa28a36232baaa2b35b1aeccce766cf2886fbaea83155df3f76cc9fcc57a5ae3ba786aec79075321bd5bfe98e48d3cd02a267946f82ae161c'
  },
  '0x073cf670ad2cf0e048924ac2c787903e76a0f389': {
    recipient: '0x073cf670ad2cf0e048924ac2c787903e76a0f389',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19899000000000000000000',
    nonce: '2',
    signature: '0x3ab069405a657d668936a372be732817de06cdd30de99159a3241075669668be4bca71aa9c4a1128258d7da133688799a9c65396bbeaf56bce00d9a7f2c8e4d71c'
  },
  '0x03d1a11f5e98a02ecd31962dc787000451e0c1d2': {
    recipient: '0x03d1a11f5e98a02ecd31962dc787000451e0c1d2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19849000000000000000000',
    nonce: '2',
    signature: '0xb782cefcd485ae796ba3d8eb3adab30c7cbdfb87c85e524f292e88be4f37b7321fc4d5835cfb7dbb1287fabc92ca9ebe4e2a5cc4cc0ca782ccd412240725861c1b'
  },
  '0xc95810899e94233ca09fbb5302092e813ba07ff1': {
    recipient: '0xc95810899e94233ca09fbb5302092e813ba07ff1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19839000000000000000000',
    nonce: '2',
    signature: '0xd741e071b2e0e4820e03acd509f25eadedbf7059d62600e00d8257c16053a9d74952b6f8ba2003fe398fad373421d8d825d3eeeba59a7fdac0c44a39f729d6811c'
  },
  '0xf11bd906a24ee74ae9ccae2c5b68404cc2b52972': {
    recipient: '0xf11bd906a24ee74ae9ccae2c5b68404cc2b52972',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19764000000000000000000',
    nonce: '2',
    signature: '0x87df693ed5e666e81b9d1852e512d6f2c242c02fda3b723a253a66defd61b93f6cf835dfa516d9b01e6b681fcf1be28590cd93a1f23998783cc87fc870ff568f1b'
  },
  '0xbf0cc3917b3fd004d649479148b6e67a936777c7': {
    recipient: '0xbf0cc3917b3fd004d649479148b6e67a936777c7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19690000000000000000000',
    nonce: '2',
    signature: '0xf2f696faa55bb994f14fdad1fd6d2385414bd081a33c31101af92fd1acaafcd225b5eb6557824427f543e16b1ad348a76fea72f2044ba2ec4374c103abbdfcba1c'
  },
  '0x3f9f89bbcc6302f2076c06fcdd18e4c824fbf941': {
    recipient: '0x3f9f89bbcc6302f2076c06fcdd18e4c824fbf941',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19506000000000000000000',
    nonce: '2',
    signature: '0x10a3cc01b0f3e7e8a377a2b95ef9b5344eb98a5b9f7bc12d5a9d9e5a3dc9534666e548ee448339320345be36b3bf65ce88277eaa43298ac1085d5080fe3f20621c'
  },
  '0x6457c04405f5e89a8ee6ee15de00cea7e4298c1d': {
    recipient: '0x6457c04405f5e89a8ee6ee15de00cea7e4298c1d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19490000000000000000000',
    nonce: '2',
    signature: '0x466d439ee0bc589f551327a297ca5523c10744a2b9c240355d2b35ab2b9cf913327568b03361436cc627730b4dfe06df3165d1efa3b6e6e8e5099454270d07651c'
  },
  '0x3af97ab22c00b27d2ca9c9e0ea53fe4f0fb34fef': {
    recipient: '0x3af97ab22c00b27d2ca9c9e0ea53fe4f0fb34fef',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19446000000000000000000',
    nonce: '2',
    signature: '0xb7cf6cc6c68b33906f209bc2278b137d921da7fa7da7e44bdd2df495d352c5e026b8c263e0efea9ccd92f8a5faaf69f61b58f7b5fb72b99ea20f293e670e1bd21b'
  },
  '0xc6aef9394574777c237fc10bb122589d36d13dc7': {
    recipient: '0xc6aef9394574777c237fc10bb122589d36d13dc7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19408000000000000000000',
    nonce: '2',
    signature: '0xf2aa0e241ffa10db2548301fe080c8178940ca4127b82e1e134204e26c54f92334f0ffecf3ae314407aa4a8fe697ed71b468b2aa1886bb857b0f5be58e4bad5d1c'
  },
  '0xe3672ddd7cf52ba8d5f4413e7bb5bbb5e7dc0efd': {
    recipient: '0xe3672ddd7cf52ba8d5f4413e7bb5bbb5e7dc0efd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19291000000000000000000',
    nonce: '2',
    signature: '0xc489e10188d3f7476f0a2d8906482a0fdce999c082038618d6f95bf01668cb7b152ed0e8baaaec2435f1624bf17a3fc27b226e2575fc43808fb3ad015e6cfa851c'
  },
  '0x897f3a711f33deb2a8b64ba1e64a3ec34d669a89': {
    recipient: '0x897f3a711f33deb2a8b64ba1e64a3ec34d669a89',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19243000000000000000000',
    nonce: '2',
    signature: '0x88368338b0e632cc0cae7d8d2a76f995d97f575bb4cf88c9e44a088023f314e76347069105df1917a6742eea49de18fef52c0f2006796aadb59db51af1ec2c6d1b'
  },
  '0x006a8a496889e9983a5256f34b608ee9569ba319': {
    recipient: '0x006a8a496889e9983a5256f34b608ee9569ba319',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '19078000000000000000000',
    nonce: '2',
    signature: '0x78724a452b9c52e6fad2e91143310f8629b00113c872635e79b41e2f27dd48737668038aef1fd49bcf47f317f12bf40308c6e600f861373a3d88b12c38285f0d1c'
  },
  '0xc7d7800cf372657326593e56b9136e048cc94710': {
    recipient: '0xc7d7800cf372657326593e56b9136e048cc94710',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18922000000000000000000',
    nonce: '2',
    signature: '0xb825f2da33647abd76e1753577b8fc368b0c6ade0fac5ee0ad978cfdbbde32fa3150ee26974f70f90fe1f38a7e626b028f2258a645c3659552fd0b04e9758e4e1c'
  },
  '0x4783caa6645b566465978b5d19cd55329ae6e964': {
    recipient: '0x4783caa6645b566465978b5d19cd55329ae6e964',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18908000000000000000000',
    nonce: '2',
    signature: '0x3b7015445113fe98a1ca1e4a4b3c10f604e6421ccd743e2d9dbca2c7f7f0dcc538894ad2d929f8019872f0f43bd369bf61e5b897d2797b0f9ca17c991c7d60001b'
  },
  '0x3e0b4f5dcdbd20665d407fedcf0b8a38cf5fa6e1': {
    recipient: '0x3e0b4f5dcdbd20665d407fedcf0b8a38cf5fa6e1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18744000000000000000000',
    nonce: '2',
    signature: '0xa255b9f56aa839b43e96364fe5d4357d3992f208cf395a4d3e1e8377f4554a48458d627ff48b80fac1ff492cdaa30d43151f48ea757c1259e015d1ddada9ef141c'
  },
  '0x7d60a1268052c019faa4220b168bc023432484b0': {
    recipient: '0x7d60a1268052c019faa4220b168bc023432484b0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18721000000000000000000',
    nonce: '2',
    signature: '0x4b0cb908949a799d5224ec95eed59fc97ceab624679197117b7908ed78b21c1e6024d7a8d43ebcf2f59389e34eea900c04ae4a56374d86b00942905b53a391741c'
  },
  '0x8c3e3ed2da1ffb6bd6fefcbc938fe22a390ea579': {
    recipient: '0x8c3e3ed2da1ffb6bd6fefcbc938fe22a390ea579',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18529000000000000000000',
    nonce: '2',
    signature: '0x0de91c8ef534e3f886118c0ee82fe77a7539699f3796984eabb7f2a756295770296e27b266f5254413b9cb85237bc54fa536aef7ff2e2ce986b5144c874a92791c'
  },
  '0xe68f5d9d05a1e0753ed965037ccf12866bad360e': {
    recipient: '0xe68f5d9d05a1e0753ed965037ccf12866bad360e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18521000000000000000000',
    nonce: '2',
    signature: '0x52b232cc7c0c5ba3e2fc4339058e12c2c6b10bceb3e3e1d21f767eee88ab5a337842740e1b24f93f7bbff50ac403f2f7a648386bdb67051b90c7eac355650a601b'
  },
  '0x7136b65ace372c9d8208ed0f79319c175498fac2': {
    recipient: '0x7136b65ace372c9d8208ed0f79319c175498fac2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18197000000000000000000',
    nonce: '2',
    signature: '0xa1fdee33367584f1279387f7088870e49c25270c1b324df0de13b46454e20cd03e9aead7fa4cdc125a6e19e62ce714996bd559febdc602f24b651f1cc36433581b'
  },
  '0x6e0bf58c9a093d091b303ef07a55ce1435e0a0f5': {
    recipient: '0x6e0bf58c9a093d091b303ef07a55ce1435e0a0f5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18069000000000000000000',
    nonce: '2',
    signature: '0x1bf2cae7dad470a24d9045cc40853c8dd782c17d65e73dd7303619a0a85dcc4e497413a40bc31e043fffc9db6918f781cd449d4cd1b839347d20345f1c4551a71c'
  },
  '0xf4083bab63d1affa5721dc9fc4477ba221895d0e': {
    recipient: '0xf4083bab63d1affa5721dc9fc4477ba221895d0e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '18023000000000000000000',
    nonce: '2',
    signature: '0x0ed1abd47c26e85e1894b061a2ad9871582d3b95b2269437f523aea37a7a90f64710239fe9a2f8d8d779dfa8e1ae49b504a221470f5a0a2cd85bb6ec50305e4f1b'
  },
  '0x5978302f1d534a0d950da04e8d5beb0ea0da352e': {
    recipient: '0x5978302f1d534a0d950da04e8d5beb0ea0da352e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17979000000000000000000',
    nonce: '2',
    signature: '0x9b65c180c2bd1fbba30baaf928b5cbb35136286e01957849b2bac039849f35f013489c7cf1d0c37bbf83cb4b308e9f775a2e1fe3dd4a690945803632207560e11c'
  },
  '0x7f04eb7ba1a3a05f6cb1100dd0173d68af94a728': {
    recipient: '0x7f04eb7ba1a3a05f6cb1100dd0173d68af94a728',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17850000000000000000000',
    nonce: '2',
    signature: '0xcfd29cc417ca52e2be7c94ede33929e7232444193413bebeef1329e0a0963c2c7c0dea4bff33861547e6ff52ca91ef5651187f7914602481ef295e065e78e48d1c'
  },
  '0xffa002483f8243039e90638ff65774d908b08c52': {
    recipient: '0xffa002483f8243039e90638ff65774d908b08c52',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17648000000000000000000',
    nonce: '2',
    signature: '0x08fb0143c41d54c8f9c9ffd45eca23ed8606fd1c0f3acbe9ceedd5ee7cac8f6538e6fe354cf886928d96fd3273cad57a88ec65386f87f8d42972a30a1ec849dd1c'
  },
  '0xbfe278325a3a10cfee52cb477c39dcaa194d8fee': {
    recipient: '0xbfe278325a3a10cfee52cb477c39dcaa194d8fee',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17633000000000000000000',
    nonce: '2',
    signature: '0xd4b8c407180f4ab53939a119c913a5f0425362ec1c98df4ed57652bdcca95d2b6d2449ee2ea3846aed683de341deb34caa4f54d027a185a8554a17c1f652399e1b'
  },
  '0xdcf37d8aa17142f053aaa7dc56025ab00d897a19': {
    recipient: '0xdcf37d8aa17142f053aaa7dc56025ab00d897a19',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17324000000000000000000',
    nonce: '2',
    signature: '0x1c16e52d021bcef31e8a3dee54dcc40fa619c5d322d5e8f46eebf92d2b5a674c6ffd19cd16961d5a2accd7c4c5b42cd4cd2d1fe9fbacb2bda88605b9d5bde4731c'
  },
  '0x2b6e1413d6c35657452ff16dc9c7f13c643593e5': {
    recipient: '0x2b6e1413d6c35657452ff16dc9c7f13c643593e5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17174000000000000000000',
    nonce: '2',
    signature: '0x37e911d209fa2d4a33df4d2c33b8642aa4000df710657155fb408feec67310a676856a85453b9d38cf702700c1fb2cd19c7ab51304c7dbb9e463f5a115bc4ca81b'
  },
  '0x066a8eaa4d847ccc800a97803aeba665dc09eae5': {
    recipient: '0x066a8eaa4d847ccc800a97803aeba665dc09eae5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '17080000000000000000000',
    nonce: '2',
    signature: '0xbd5a2c18ff91f103737c58106b9d85d87ea99c3447c215f30fa9984cc3c2a20428f8971ca828291ac9f91b387b7330525667b5784c2cb429f6a37f1acbb718c51c'
  },
  '0x9fe8a10c013a0d12f58e06a77097ef9bb0d2902a': {
    recipient: '0x9fe8a10c013a0d12f58e06a77097ef9bb0d2902a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16947000000000000000000',
    nonce: '2',
    signature: '0x1455411ee63f11f4797616c8db50c0049eb174229da8b61bacc2c95dc18a2e1459144c063e6ab16eff26b105845f5cd5665246afe3320e6d1d9e7081ae9dc1221b'
  },
  '0x0f73622476e604d34ddf24ef4d709968a264a259': {
    recipient: '0x0f73622476e604d34ddf24ef4d709968a264a259',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16478000000000000000000',
    nonce: '2',
    signature: '0x9888eb3ab2d3d19f707e31835c0a8c339efa2fff5e1e1d0f4aeef89e237dd8a7144d4686ad50688e884f92ccf381c61bef70483b65e5881512c77107f0f1ffb21b'
  },
  '0xb4c5631230c8591221526d3eebe7b571e3add4a1': {
    recipient: '0xb4c5631230c8591221526d3eebe7b571e3add4a1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16433000000000000000000',
    nonce: '2',
    signature: '0x6ca7d88cfb269c1e4643f48932b473576bf247e1743bf312003b3350ebf5b33c0caed044c937a8467ac320e2ab349d0f83b1dfe4c35f2eae451cfc118a8f5a131c'
  },
  '0x57a678f36b3978d3af834636397af03181dc4696': {
    recipient: '0x57a678f36b3978d3af834636397af03181dc4696',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16286000000000000000000',
    nonce: '2',
    signature: '0x9b9b5df1698d90dd72223f8adf3161e77adf22238a9adadde7e1eadd143954c30d463a502627a29274dc3391b5461984dadd044208c2e672a4b5f4dce13dfd211c'
  },
  '0x0e5e906d99dc9b4467940e8e3d2d69a8c4ed7fdd': {
    recipient: '0x0e5e906d99dc9b4467940e8e3d2d69a8c4ed7fdd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16210000000000000000000',
    nonce: '2',
    signature: '0x6d9098129e22fb019298f515519e3cc0085a4e0978575304990029954a75381d0ad2450fef84cf3724a463ba6423713b8c554bd6f9979d7140f5044d56ae0ad21c'
  },
  '0xfec33a90737254dcf9aa33188a11f32797197f93': {
    recipient: '0xfec33a90737254dcf9aa33188a11f32797197f93',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16150000000000000000000',
    nonce: '2',
    signature: '0xe8ceac3bb29bab92a819cc91e01d0f26de6d9589e9c5f7deb393c2efe1ba55ce0530c285524db9325d95b40b24b7b13c3f012651fca8d3eafed0abf38de2f25d1b'
  },
  '0x696969917d14af0eccd470b0797a447087aba094': {
    recipient: '0x696969917d14af0eccd470b0797a447087aba094',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16056000000000000000000',
    nonce: '2',
    signature: '0xedbfca49f0ae4211b69b8385503b0c68d4d715affb910cb71f6de5cd698dd033197f6e5976b514a82d81b55ea28fe433adea82efeda08e02d61a43375a1cde1a1b'
  },
  '0x251216dd3473ea5fcdbaffd0dd0826017f8e1b24': {
    recipient: '0x251216dd3473ea5fcdbaffd0dd0826017f8e1b24',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '16033000000000000000000',
    nonce: '2',
    signature: '0x3748900f88d8fafde66402198c381d0643bf0fc11e719b22bc831954b6a3b00231d5fdf03c6a377e0e7778b7e577057ea4c0209791b39199926af41d32479ef21b'
  },
  '0x4c85efd6655bd3ca9eab49970588d81b2ceee3eb': {
    recipient: '0x4c85efd6655bd3ca9eab49970588d81b2ceee3eb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15973000000000000000000',
    nonce: '2',
    signature: '0x8844a71eb34f16d6582970780f7e092a8b04dcf710eead950689cc0b12cffb5351350c36ec4eac96223c192f98c224928558b78201e270ebfb27a75242b286341b'
  },
  '0x88a309eacdce9a556eca487df8a365bda3a41033': {
    recipient: '0x88a309eacdce9a556eca487df8a365bda3a41033',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15964000000000000000000',
    nonce: '2',
    signature: '0x993657034d67d5ff015fd0f8873e837631ab490e2cdd2d780b0a68fed7f73b4d25f2b01c607dd41c8dc11d5f1357303b8020ab41adc728261e441e11491ac7271b'
  },
  '0xdb70eed3578f12929eae82e0f13e51ca01216789': {
    recipient: '0xdb70eed3578f12929eae82e0f13e51ca01216789',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15825000000000000000000',
    nonce: '2',
    signature: '0xc3fa7954e840395506a74f895587969dbe508b0ef30403bc9be084b5edb51d9810b2889df45ce917baaa65d79b3e8e35b00022eb61fd8659f5f20b1864355dd81b'
  },
  '0x5fb7e645fafa08a5dcdafbdfaccab5b9a2807d41': {
    recipient: '0x5fb7e645fafa08a5dcdafbdfaccab5b9a2807d41',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15754000000000000000000',
    nonce: '2',
    signature: '0x78ea39d86b8af4003a5290e4fa4471861fa611094c80a972f8b1a0ae17e25db043fed0921afdb28590a669a526e13c24545ffa49f83bec76e635c61d40dbcc181b'
  },
  '0xb6d67af8303ff659a8d00d3a615f9e082982130b': {
    recipient: '0xb6d67af8303ff659a8d00d3a615f9e082982130b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15706000000000000000000',
    nonce: '2',
    signature: '0xbcd7e5776356ae5cf00589ab716dd4f12d9cd9a00d75c004f998fb68a633a9f66306ad7b93408abca9a1cf6aa16096f4b943dd3c13ead4f0b46c0d37abaeddd81c'
  },
  '0x06138a8b3118bb190b87c56117fe8b7315c84ffc': {
    recipient: '0x06138a8b3118bb190b87c56117fe8b7315c84ffc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15182000000000000000000',
    nonce: '2',
    signature: '0xd79a3f46203a285dfeabe8d0eaebc7c1a7be17cce97819c5b20268f9dca940a82bc4467d28b619e2d15aea4ce8674baf3da338a5f7ac04e629293ca30e303fc71c'
  },
  '0xe7e207e94d07a370de80e7d38093b49a7217ec23': {
    recipient: '0xe7e207e94d07a370de80e7d38093b49a7217ec23',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15151000000000000000000',
    nonce: '2',
    signature: '0xac815c1988c674bea9dd71d313c5fe9eee5e3e137b9be59662bb02384728be525748e49d010e4f3cd266a5e2af7c1d5203bba67c6bb9bdcaa553729d1e8aad6d1c'
  },
  '0xe0b7fc12b6829880aaade5b33c92eb3b592e3f15': {
    recipient: '0xe0b7fc12b6829880aaade5b33c92eb3b592e3f15',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15136000000000000000000',
    nonce: '2',
    signature: '0x46620f9c221493e1cf8bc233e4108eed0fccb1e4203d9be60a2b34efbe058e9958f2c8fde6c85dfd937fff2e91d532955901d07ffed5c8516cbad5591ebf5d701b'
  },
  '0x394bc1ee5687397b354943516d04f07b28967ff3': {
    recipient: '0x394bc1ee5687397b354943516d04f07b28967ff3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15123000000000000000000',
    nonce: '2',
    signature: '0xb8ee9c51cfed4dbd443da4f2004a92a644f469720599520c66c494534c76816226993ce27610a8a307da235d8c3a4092587c758546bfe757fc36709a4688a0391c'
  },
  '0xcb06bede7cb0a4b333581b6bdcd05f7cc737b9cc': {
    recipient: '0xcb06bede7cb0a4b333581b6bdcd05f7cc737b9cc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '15094000000000000000000',
    nonce: '2',
    signature: '0x098703283028df65adbf5451f6ff67b08f2d3a7fa83482cc4e976a49716b52d048791c5d1d76df9c8e25b21c66084cc556f15e53100f9ed80110316130be39c31c'
  },
  '0x5bfeb4ca066c9458842ac89b6e5cd983bd1a1034': {
    recipient: '0x5bfeb4ca066c9458842ac89b6e5cd983bd1a1034',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14870000000000000000000',
    nonce: '2',
    signature: '0x1c1969da39dc7708291c671bf7f45e8bcdebd16df9e69a94dff7bc4da1ff8a33198c32f1e5f1d922a37622d6d6d57bbb8de50eaea0446928294ea44f97a1dd861b'
  },
  '0x5cb8db8812c4b69d9cd21ed656ff46681e350b3c': {
    recipient: '0x5cb8db8812c4b69d9cd21ed656ff46681e350b3c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14799000000000000000000',
    nonce: '2',
    signature: '0x4ea717d4917c4fbd2bc92b9d4ebcaf3f4d7f26eadccea80290b469d4586d7c2347e54164b2c8f3860a509b25d492d20a45d7d6929f9c2d514de962b1eb242dff1b'
  },
  '0xe59f57c91bb2684796167eca78dc6aeeefa49cd8': {
    recipient: '0xe59f57c91bb2684796167eca78dc6aeeefa49cd8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14724000000000000000000',
    nonce: '2',
    signature: '0x4a96deafcd241309155695af6efe17272992be0a5de1fe7246772f89ae68a173349dfae11393b0708dbbbb26d6cf99a5e6cc9fdba5a210ac1ed62bd13f7282b01c'
  },
  '0x0cd6fb99aa250cbada1f6000c9913f2f97a21a30': {
    recipient: '0x0cd6fb99aa250cbada1f6000c9913f2f97a21a30',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14623000000000000000000',
    nonce: '2',
    signature: '0x7eb12ccaee8f7885566b5cbd0f841e4f19c484d2e6f776b0cb9185b0e78e345c11ef065504e4fea7a0df99366be2ee6ff740dff893cba746b05b4326a6ac7ec11b'
  },
  '0xd216c30b24dddcc69e781813e0cb6fc17768a6f7': {
    recipient: '0xd216c30b24dddcc69e781813e0cb6fc17768a6f7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14620000000000000000000',
    nonce: '2',
    signature: '0x809f6798d504eb1a48fb3ab5676aef316b1dc80eebca899b806f78ae7bba8717274d7608c2da9f3b00dedf9b0e33b837b4e55ba5e1890531fbcf23b67e5d8c491b'
  },
  '0x04260d88482651e914eefdcd6b3ca33a2bb81d41': {
    recipient: '0x04260d88482651e914eefdcd6b3ca33a2bb81d41',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14427000000000000000000',
    nonce: '2',
    signature: '0xa3160e2b24af02f8d870ffd6dd2890ab96db3fcc7f6a7c6b740e793a184f71822e190c9b8b07b92cd1ca56e954781e6aa95815013be84401808f88c1e916efae1c'
  },
  '0x0d1d74535bcabda2da2cff5a53c2b899901d423b': {
    recipient: '0x0d1d74535bcabda2da2cff5a53c2b899901d423b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14120000000000000000000',
    nonce: '2',
    signature: '0xe7877956ec3c8d1168b5d1304c4b7fc3e93c1e26d03c94d3f2bd81c972aa15462baa136417ed425c9f7be54f1830ee42cd89080c8d0d073ab098d8d7a27c260d1c'
  },
  '0x8e2e1cc9fe0d2d89efb715b806c3ade7bd27115a': {
    recipient: '0x8e2e1cc9fe0d2d89efb715b806c3ade7bd27115a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '14008000000000000000000',
    nonce: '2',
    signature: '0x391ed2b6bccc3a5d36edb8871d01a4d70cc8d3aa07e7c91b03fdc564d26a33f86df11ae8af9de9b47ac48e5b84bc3daba7da4f26501a32472fd91c95ecd734ea1b'
  },
  '0xc6317e94699c7f4b10ff27b4d16ff90aa0a95bc6': {
    recipient: '0xc6317e94699c7f4b10ff27b4d16ff90aa0a95bc6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '13798000000000000000000',
    nonce: '2',
    signature: '0xc20778f5c155664808eca0e0d89905151e6e60d3727a7df2a512bce103064456539c6a4a582bdb863627e7f71be4db8382ac86fde4dc2bc6f0782d54b2eac65c1b'
  },
  '0xdff3537f07dd448160735fd75a94fab30294233a': {
    recipient: '0xdff3537f07dd448160735fd75a94fab30294233a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12984000000000000000000',
    nonce: '2',
    signature: '0xb1d83d94222a5cd4cb0124161101e2ba3cd8f6de9530e47183cc3246bc0520b94be4218eb050f64313800f212f197496e48e90953efeb9c0a5530231670341121c'
  },
  '0xdb54c320a0b1e994d2bf7dd2ec939f6c25918011': {
    recipient: '0xdb54c320a0b1e994d2bf7dd2ec939f6c25918011',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12878000000000000000000',
    nonce: '2',
    signature: '0x194a110472f12c1805a10e95474acff7bfa895e890107c95d73be3d23eb3e2b84ede546000eee49efb1fc0b55c983b0b6355d8600f25c919b031e662a8c896d51b'
  },
  '0x3cf5e4ec868ec08f71e6a3fad4234191bd0d0a50': {
    recipient: '0x3cf5e4ec868ec08f71e6a3fad4234191bd0d0a50',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12868000000000000000000',
    nonce: '2',
    signature: '0x7630b58d58fc71979528f855a3f6295600535ad26c40691acebfa3720f0dd1510e2bb3947effa5622670ea98cb2eb72af96ec04553c0d6fa76f68ba3c5539b501c'
  },
  '0x46797eab11aae32e2aa4c5058f71b072cde48dfa': {
    recipient: '0x46797eab11aae32e2aa4c5058f71b072cde48dfa',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12529000000000000000000',
    nonce: '2',
    signature: '0x29be88a718a73e533a1ec0a373f029416f5eaf0f779dad39a63d6d17bd201baf40af396796abeb19349944837e20364fac50560625186a78ce37a4c36b79088a1c'
  },
  '0xa6c9f9fc6f3569d025d71fdc47393b03fc0e707b': {
    recipient: '0xa6c9f9fc6f3569d025d71fdc47393b03fc0e707b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12282000000000000000000',
    nonce: '2',
    signature: '0x8c13be45c3621f07ad49cbd6bb6b0e5f64dfa548c9567c23441b8a93c1bf3f38478bd2d75ee624903c49ad8906ce378a4c37db623bea2b2fff6af5366f764f031c'
  },
  '0xa079f7de410de57517f8dd45b7a14232b2d09dd3': {
    recipient: '0xa079f7de410de57517f8dd45b7a14232b2d09dd3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '12215000000000000000000',
    nonce: '2',
    signature: '0xf9d2da1f8d30c53c5e749a1e2540de9194c5d35f4783a0d7e13053cd9e8ba44b27d17851f43e5566b32ffaea7d4e9f13da7ccb260db7ffc367ef29eb7bb7dbf21c'
  },
  '0x8c48b40dba656187896147089545439e4ff4a01c': {
    recipient: '0x8c48b40dba656187896147089545439e4ff4a01c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11908000000000000000000',
    nonce: '2',
    signature: '0xabd19bead1e8e236e34e4623d68410caddb2b29cc544cf7b3ee0676177aebf6b63d7d683b623c6faf2619cbe8cb49c7e2f081a9fdebcfaf49efe9da1177870191c'
  },
  '0x00ef256c967703ec84acdbee0b9690d3bbcbd6dc': {
    recipient: '0x00ef256c967703ec84acdbee0b9690d3bbcbd6dc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11831000000000000000000',
    nonce: '2',
    signature: '0xde1798835571f6cac27188f6520d9ccefc93c05a0b84cfe895ffd0e33efa7f603b90c01aa964c3d141031b218f49e2c737457b5759851f73c0fa489196b78d1c1b'
  },
  '0xdbaf6f4434a3b60b7461a0ae50c2083fe19d5cdb': {
    recipient: '0xdbaf6f4434a3b60b7461a0ae50c2083fe19d5cdb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11820000000000000000000',
    nonce: '2',
    signature: '0x15a5a3d14f7e5054ad0fd303a2b2981f69c45916210dbe682a143eac8771b0681d8e6e27d7741f4ec23a6e8020e5d7f95151b1d3f2913ea0156a243db5df1a131c'
  },
  '0xa3d68044341e4119f438ad9d1dbc20caddaee397': {
    recipient: '0xa3d68044341e4119f438ad9d1dbc20caddaee397',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11790000000000000000000',
    nonce: '2',
    signature: '0xe706c9faabd51eef5d2c43ff000b645efad455a87894d557811889999dff1a1559059fe6dc6d65872677de967e89ef6c16869322a6de28327eafadef3964d1711b'
  },
  '0x5d4afbfdf94e12cbc4ea63b515c1e3dae73b8fa4': {
    recipient: '0x5d4afbfdf94e12cbc4ea63b515c1e3dae73b8fa4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11298000000000000000000',
    nonce: '2',
    signature: '0x0e1e0053e4aff06ca93bd6df86ef289261736d1c7ddc74e6c8794f56f7e5abce108e2b0dc906d3fee75dcec39e8d56cbfff6303d767f5d6bd72ace3f12368aa71c'
  },
  '0xc8fcbfaa1588118ef28cb0290d12e7e931830619': {
    recipient: '0xc8fcbfaa1588118ef28cb0290d12e7e931830619',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '11022000000000000000000',
    nonce: '2',
    signature: '0x7a0e04250dacbf1546351cd06bc420e46477cbee5eb753428b64d35d1b919538052edf4f194d5a16c5bf354e481672075e57d10ed209bc5b870daf32590d1dbb1b'
  },
  '0x162bc01387ce534a46279b4f3fe16663d31a99a7': {
    recipient: '0x162bc01387ce534a46279b4f3fe16663d31a99a7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '10491000000000000000000',
    nonce: '2',
    signature: '0x22685c246e9c50a9526713d8ebab382b3f75e22c901ceb6249a789c27896f57b7ebd05b05844557debff7f2a61f8dc2f733074a2c7064e6df782f8fb166ee9de1c'
  },
  '0x679b3e6c39c07bac7d4d55b8b7e9a9aa40c94c8f': {
    recipient: '0x679b3e6c39c07bac7d4d55b8b7e9a9aa40c94c8f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '10266000000000000000000',
    nonce: '2',
    signature: '0x97fbec6d29158160bbf5ebfe5d86c313b8e00167afcf45adb296a467e05ca82232f17f474642851f1541bd22d4164d46c1521993fcec50dac061881ce55652bd1c'
  },
  '0x0044dee777e4f5d960731d6b22543c499c4abd2a': {
    recipient: '0x0044dee777e4f5d960731d6b22543c499c4abd2a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd07428aedd6509ca9a0cb1da804d8666fd691c3ecea0ec01ef4ce7d7ad28b42e45b43add730ecd6f0b67be5fd78aa6f7e4ceb241ea9d49505853f24caa6069d61b'
  },
  '0x0068e91105b0d2c52de69c6efb6329b66b1cdac5': {
    recipient: '0x0068e91105b0d2c52de69c6efb6329b66b1cdac5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x07188730833cec40d4f941d41c518f812837784740a7d1e35b2e65f6872d362e6850cc105ab33f9a776054a9adeb5385a7349048f63fa1497a0fc19ac1d4f9291c'
  },
  '0x01f57397a11956cc5b944c7f711a95c2cbca4fa8': {
    recipient: '0x01f57397a11956cc5b944c7f711a95c2cbca4fa8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7bc3b7e8a7ce36f844d899408d27fdde2ae40f847004991be5e78477585ca1494aaa795256feb8fa6661fb68a4ae476927a988bfcaece7b80f0129914f4312861b'
  },
  '0x0216a845ef1a7db8767989cbf51f54fddddb7a1d': {
    recipient: '0x0216a845ef1a7db8767989cbf51f54fddddb7a1d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x28f9deddaad2fe4ee03261f4b4122f422441a6ff00ee9e5834dfd8bbea4d3825027b697792d1a2be32891a5ec13bb7201385d8c88b3ea8ceb781c38d9bc2a59d1b'
  },
  '0x026d33d55b6b385f1754c1e59f4a7ef60ed8d885': {
    recipient: '0x026d33d55b6b385f1754c1e59f4a7ef60ed8d885',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x83c2cad98fb3c840daeefc5c8eca1cec8a92183eab54ddead145802b2598092d4e13c456d92f71ee15e8ed6611661638784dd10e09253deeafcd9bcbe0bbfb231c'
  },
  '0x0358c133d4e3e4b9198af1d4e97ce3f89e9e465e': {
    recipient: '0x0358c133d4e3e4b9198af1d4e97ce3f89e9e465e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x3bdcfefdae0eeebc704a138ac7b0dcf69b716bc17345a3bf7b594755810fbf8a4595f8adfec2aa7a301f141bc0213b46cc767ab0eb7be76859d20a327db95bb31b'
  },
  '0x037cea5b36425c1ef5b39dffc8d09738b61ee8ee': {
    recipient: '0x037cea5b36425c1ef5b39dffc8d09738b61ee8ee',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb8417787e820ccd2ccb06ddf08adb57ec849ec426abdee0da1472e6485074c5553fb4878efb7a1d424f900f7c84cb900c49505b7d65fdccce2664cf5b874eae41b'
  },
  '0x05fbbb76051745937e247922ed0182225ceaf9a8': {
    recipient: '0x05fbbb76051745937e247922ed0182225ceaf9a8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe4700d4f26a3560a285fe9525fa8b76f5a1ec989f56319b2dbbc9208bbe986460386bbc4a73ee99e09e399f3b297270bec0adba26b9393910befd53a3a86c34f1c'
  },
  '0x07061922b7711a94fe4bc66dae29e504dbe4c489': {
    recipient: '0x07061922b7711a94fe4bc66dae29e504dbe4c489',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf414a969324daba80eb0ba905586dc4ca040b0f17efd2b74f7105bd6503bb2e67f4afb347149a75b5e71648658d4b6ca1d2795fe5133adcbc654afd5bdfba4ea1b'
  },
  '0x099f66e39c8fbd0a78fc2241daf67f4ab162d2e1': {
    recipient: '0x099f66e39c8fbd0a78fc2241daf67f4ab162d2e1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x9ac82ee366c5a59cb9a211ab1d8e14a008df8f113e7c24ea8f3073492ed46361023cb139d500af29bad998bff0b7c368011a2dface494e939d54b142682233681c'
  },
  '0x09c1af0a51f735372cd104c93cf9ad4d5dd3c73e': {
    recipient: '0x09c1af0a51f735372cd104c93cf9ad4d5dd3c73e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6a0de82f9a875cde16e69e39515a01caeef418b9f2ff459c8e19937421a99a9e186a6901cd70ac5d13f4177d053ff3f00daafe7b69a3d10c619a7efb08e3f7b11b'
  },
  '0x0a3054d14933a8fb0f0bb3dca3c91bc27b637857': {
    recipient: '0x0a3054d14933a8fb0f0bb3dca3c91bc27b637857',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8f77da232a855fee71f7229617753f7e0656b0d7f35c7be74bd977a3f7474f1f75b601aa423eab4c28b6eeb71c58d3138b1b8c4053f44aab66a843c1cac9a3281c'
  },
  '0x0a57cfbeebe2f0327e132772209b9712f1dc5762': {
    recipient: '0x0a57cfbeebe2f0327e132772209b9712f1dc5762',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x9d3447418b36f94e178822f0d623b435091a3870834cb0b84484aa2bf921a6a666848afe62288779f9a96861cc22f053c60114a778ef1468ab4943763dcd63af1c'
  },
  '0x0bbe3f0ac0b331d37d64d974047ed56d766de32d': {
    recipient: '0x0bbe3f0ac0b331d37d64d974047ed56d766de32d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc7174855f6067a0fdb485ed8df868ac3071e675252d448b9b54536f247eb75ef356de81c6e14585875873250f26465760afcbea29ba74a97e27a3a048bcc4ffa1c'
  },
  '0x0bf8f3011f6d0aee5b7c0db2757ed25b924de7bc': {
    recipient: '0x0bf8f3011f6d0aee5b7c0db2757ed25b924de7bc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8af9aa00792146b97c0da13a20ca94048d3dfed177b94ab2217bf2c9181152717cbef9c841f49fd6493087242bba9966b4c9ac5f7894dc4f016faf6968c758561c'
  },
  '0x0e3b08406f9f3fd3c10912edbb92777227eb0a97': {
    recipient: '0x0e3b08406f9f3fd3c10912edbb92777227eb0a97',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfe7fb7e3ced52842a7d60889030f62ff1db539993d00187e9ed3023adcdcdd840beca774d3db0c82ab527a5063b828ae659585a7af369516abd8a2e4ecf3fec31c'
  },
  '0x0e8c4cadbf3a6753c58edaed49b92d27cd40f76a': {
    recipient: '0x0e8c4cadbf3a6753c58edaed49b92d27cd40f76a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd772fcbc0966948cc45f622e151131228cad8ef0dc505d4ea8596e43cc01bea8738aacae07c5f588e144467d6631bdaeb3de550ce9d331027ace87cb3f23362e1c'
  },
  '0x0fbc5647f243ffa73603fbe128ebd0f9c3e292dd': {
    recipient: '0x0fbc5647f243ffa73603fbe128ebd0f9c3e292dd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa947b19fe945286f6cebd4d226fea08a618894e6568d5f7e91abc8f489f1d0633e799c085ea5fe082dcde9cf6e7ebb7e926638a704fe43fd6a4e397cc28236111b'
  },
  '0x11ce1194e502616588de653de12bf8927d5e0cd7': {
    recipient: '0x11ce1194e502616588de653de12bf8927d5e0cd7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe40f26b79438aa7e703468ced851170542b0173538eb1c6edbee6af9a84967dc0adab7729e8f94cd44f7d862898bbc41970bf4c932bf8b9d4c41972b9b5c77951c'
  },
  '0x12cacaf2ac219331a88d478fa9ec26a263b0b1cf': {
    recipient: '0x12cacaf2ac219331a88d478fa9ec26a263b0b1cf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa3c8ac537eb4d46910e032360f6cebd011e841492c78128cfe72abefe94c53372ef63865e95d72551732602c9d351c03f31de54a97ede892d77c07ede2da69331c'
  },
  '0x136968d920cee796526606fef67aeab859e3f84d': {
    recipient: '0x136968d920cee796526606fef67aeab859e3f84d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x5d368852815f9eb3adb0fc6c0ebea1c6852838b46149d783cb5e1a1338c2b4de46950851f9898b9ee0086c8c76d3a2e9587a0f22495c1eed00a6e643e00588071c'
  },
  '0x152630e4d4de80edfdb727cc35cfda7649df375f': {
    recipient: '0x152630e4d4de80edfdb727cc35cfda7649df375f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x92f2d2ee328946fd929d3037341de24a711551939aaaa78e7d973a33f7e85c410f50e0d1134124d44dbfcdf4cd06875dacc55203848a1148b8867f6471b16a4d1c'
  },
  '0x1550160f55f98c150e2d41a00b81f993a5412545': {
    recipient: '0x1550160f55f98c150e2d41a00b81f993a5412545',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0653a6ce6505b39cfdc8db399d4b6a8258249c67e3b1223da4b8a5c1c6778d6a197a4974574f12b07b677fef2feaf8cd04a8f262aad975782e5e6e12dbd1956f1b'
  },
  '0x170b30a3e31fec3e372f9e3e66aa5345b6cf257d': {
    recipient: '0x170b30a3e31fec3e372f9e3e66aa5345b6cf257d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfefdcd7098f225b775e6c36c80db96d777934e185726b98926f35253a183489d1d4e029c1c1b171e119d5bcfa58e937189677b2f6a8ed073c8b71d7704a762ab1b'
  },
  '0x17b71184a7998de965ce1207a55da1dc8415d17f': {
    recipient: '0x17b71184a7998de965ce1207a55da1dc8415d17f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xef674c48f8285743ca86c1c6c4658e0bfed3bc6013b183d8eb66317fe6fca7de6ee74d22ddf45b2947d0426dcbd0243ce13f7cc0f0ea819f254655d523633f1d1b'
  },
  '0x17e9fa71f6f260959b1d0dba9464aca536a18efb': {
    recipient: '0x17e9fa71f6f260959b1d0dba9464aca536a18efb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6d0b08c54aac46ec6cbf0e33dd1a8bada0be688786b8fb43c3856c103375df0b1e867dcbe65b6815be3157470f5df8a816ff40f3b2deac3fc15819d27031f4c81c'
  },
  '0x180d1448c92de66bc3460b7c4a7365ab6a5a6c90': {
    recipient: '0x180d1448c92de66bc3460b7c4a7365ab6a5a6c90',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbf0935ca722f48a22bab63b2adcddafca7e7ed766f20ef8aa69e0aad47bf2beb7d477a8691ca88f6f75e6bdce2a9f5089b60b1bd3c6d7740145b5447d4afc6351c'
  },
  '0x1815231adaeada37318db0880180bb3841d542ea': {
    recipient: '0x1815231adaeada37318db0880180bb3841d542ea',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf87851553488a82e78e1ee96f7419821e2b7b59d8b52c81e2be3283a970e889b3e0f3de5375479a99e2b7caa1f16963adcfbe40070f87235477fb3e1df07f0e91c'
  },
  '0x18d01c127b0c01acde22e43ac0a98a0267131115': {
    recipient: '0x18d01c127b0c01acde22e43ac0a98a0267131115',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xea7f4ba9ead812403b5af95b811573c4f91efc64cf6902cc19769ae8f93365b22d436cc9046d4447580b7e4fd7662ba284de83b7b7ed3bc9cc450d8e62f6048b1c'
  },
  '0x194f4f094b2d014d56443c7043d9ca52b64eed4c': {
    recipient: '0x194f4f094b2d014d56443c7043d9ca52b64eed4c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8651663d52b7c1748f72c9601a53ce9e0b346ba605779d5abbe66630ada4ab7766d5fd73c83f936d5ca8ed195ffda24a06ae4b887991cc8cba755e8e6ceecd031c'
  },
  '0x1990d47d32fcb2b4aee050ccb0460c25474aa7cf': {
    recipient: '0x1990d47d32fcb2b4aee050ccb0460c25474aa7cf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa893dc6cb1156ae56a081bd77726c060c824a17d72f0abefb4353887a08ebce2377fabb56b63aad8508c38d2a7b92ac3762023410d55ad93b9a0786b4e3f29a71c'
  },
  '0x19e59933b124fdfb4c573d3409585b47d5c5858f': {
    recipient: '0x19e59933b124fdfb4c573d3409585b47d5c5858f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x565da024408b703aa0078f42d7dbd041aec46f8a5bd487ab0564e75ef79db92a21b2091ff33631f69ed076b8f13032f42a36bfa57af6c86353f0fed50a5f9f2d1b'
  },
  '0x19fc5c4de8b3c861cd27f2d270660fe4105e2c10': {
    recipient: '0x19fc5c4de8b3c861cd27f2d270660fe4105e2c10',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa8a48d212ea79e46347c5087da9ca76b32bc4d210f6010cd01256450aa0a67d24c10eba2f876fd9c3f760e47c28b20f263c95ed1e5d4f73c3b9499c4765ffcfa1c'
  },
  '0x1a44ab5df1344099837feced34302a41ce22171a': {
    recipient: '0x1a44ab5df1344099837feced34302a41ce22171a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x00be96fd6a93af1aa435e73730570271a4390fbf239676672426cf7a805f507766636c7938bdbfd512451b5862b680aa8250235f2f1815479961ee1c6417ff681b'
  },
  '0x1c43461a82e17481de87a0724a8076bf923cd926': {
    recipient: '0x1c43461a82e17481de87a0724a8076bf923cd926',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x71d0fc89cdfcce8cb22b623e12c7ff34bc217778a28117797ca48f03f1ca13bc2a67e059c2edd7c0037404a68b0ba573b6c9a9e2a1a2e29c1edd8def996c4d111c'
  },
  '0x1e8e749b2b578e181ca01962e9448006772b24a2': {
    recipient: '0x1e8e749b2b578e181ca01962e9448006772b24a2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x35cb2e96e34dcd8d6dc038cb97d3ae2be2123b6e7ca084738872d0f0eb864edb4dedda1adad0ab03ed5046ca18572ab34adfa2ed4baaae544d12d830be8a461d1b'
  },
  '0x1f7fd65b5f4e573997558455a33e7b03e10ad3a3': {
    recipient: '0x1f7fd65b5f4e573997558455a33e7b03e10ad3a3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe5648165bcc69ce312aff6c37a2936f3a0e9832171e2d68c935d71e35512230e0a32ed3910a79993e330206b954e2c95aff5c3573762599eeeac688cc9ddc1f91b'
  },
  '0x1fbf18aece3eec6c409f2909fcbd96193609003f': {
    recipient: '0x1fbf18aece3eec6c409f2909fcbd96193609003f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe3d2d3e1669355c62fa52419c0b0d1b62866a37e89eb4257faf8476861b6613c066526afe8d9ebdf6acc57c5f2345ba0a781dd38b8e827573ad4b2c41ff6a5a51b'
  },
  '0x2023bc44a9395ca08b1c650970126203b7e84f66': {
    recipient: '0x2023bc44a9395ca08b1c650970126203b7e84f66',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc5bc70cd52b97fda6b5c2476196b17ae6c41d70a405cab9598c1e6150881f73d50f96253f89c9fb5bc74c51c92cfee3881775490ad7d0617d7ec180159ae4e311b'
  },
  '0x20d241ce7ebe1029b850666bff96680467a10901': {
    recipient: '0x20d241ce7ebe1029b850666bff96680467a10901',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xcb1c795f6d2d3d1407329189f3131d927b9ed447180de1a48fc58b8d67e6c1e816b15f4f84a1ecb1fdc3bc922db2384a67f51287a18b9a64b35dc54dc07f06741c'
  },
  '0x221789e66d3456915aa4836dfe4e1b71251019bb': {
    recipient: '0x221789e66d3456915aa4836dfe4e1b71251019bb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7a7fe3b05fbdf5422fbf542f874b03d2c10494d7516e5aa2c8077079543b0e304eb807fe9b73bda396da9682fc72fda54f5fd4483232e5c67c2422daec4311b71c'
  },
  '0x240c269792679f35b33e8ec72c56ee033803b9cd': {
    recipient: '0x240c269792679f35b33e8ec72c56ee033803b9cd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x82c8d7fb09d78d9e778ac4a79c291f67e325c09173f50c934ea0deba288a142a76b9985364c16e26d210c5dffd3b71954d1875d0da42b5d4ae083d8596666c471b'
  },
  '0x24f8683d2cab59a2ff48a6b2a829be893bfc83c4': {
    recipient: '0x24f8683d2cab59a2ff48a6b2a829be893bfc83c4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc287355ea0e79ec9b11fd242f9a46e013516afcc8c21ea696209d74119ed715c15e2b6a4eabe69fed3eb2ffbb3b4369b2be80373a5ed015c5a0c9ff91a4909cf1b'
  },
  '0x2636b3bf41e9486cea9e936f60000d2c0602d90d': {
    recipient: '0x2636b3bf41e9486cea9e936f60000d2c0602d90d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdc160af104a288b51916bf713a56c73b9c0ab30e55eed5cec16c8d89cb7d7562406796a294c5f21efa786be573200c8227f74f88a15f54e409f1e0770a5cf0711b'
  },
  '0x268d6a78833bb8b4167102145ce29615e846c07e': {
    recipient: '0x268d6a78833bb8b4167102145ce29615e846c07e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xca4b664806c3aaf236fc2e2d8c554a311f6f0cbdd3cc548fe4cf3fc6be65332477c0cac2efdc50a6255973399733a174765220e065f39a306484fb09347916b01c'
  },
  '0x27617261271a7d4e0cc752a1d69be347877b6c1f': {
    recipient: '0x27617261271a7d4e0cc752a1d69be347877b6c1f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0b7b9f3207efeab7da6b8986fa7e5fd907eca7de7b1ede46b64892d1f7949a033c67f3986c8ce615413c0aafbafa2a35908ece573255c03244f5c3642bd19e331c'
  },
  '0x27ed7265669b24642036533e9648c36b97412fef': {
    recipient: '0x27ed7265669b24642036533e9648c36b97412fef',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4e91668879a91443dcb5b1f2a55d0f37d26b5757921e42d51b3e67449aeb18e4557b2995df0f46d82eeb40e539e9cada01993bb0b8d9d6799271b7140a68d7151c'
  },
  '0x28eb5f2d48a35d4ed0f95ae3edd862837c2dd098': {
    recipient: '0x28eb5f2d48a35d4ed0f95ae3edd862837c2dd098',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa2222b572630f7aacb1ce62c4928cbb714b22432af81c7b1c03718a3e4ffcd077eb9b2c0184991f3012319afbdefcc195414a0fc659aaf0ce7771505240d6e8b1b'
  },
  '0x28f383a1602fe89c579328c4eb1efc1984128ca7': {
    recipient: '0x28f383a1602fe89c579328c4eb1efc1984128ca7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb46e55abff3a676bfb8ef82572622cac564104504d9174cca7c58df2ba35a6cd2b72ded8043bcd093ad6b43a4d47ec6e40246173a50abb2d600d83c91cf573151c'
  },
  '0x2a6b2f69dc9880adc8b5f388ee0ddab81cbbfca9': {
    recipient: '0x2a6b2f69dc9880adc8b5f388ee0ddab81cbbfca9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2c0fe76c331091dbc49484ba98758e5c9ddc510417394b7e781f2a86a94ce3f10bf4ae41f0ed8a85c62038cd48faf5020d93655ac5e66bd30a19923090287db41b'
  },
  '0x2a77d492e9d8781b1cf562ce1e0727c2a9f0eea0': {
    recipient: '0x2a77d492e9d8781b1cf562ce1e0727c2a9f0eea0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4e26a532dac11c8142612d5ba9d41d204d61f35b806bfa3332033437a206996e26da1841b4d595afa50e13a7123b4dc1725a16e4c6d4bafcf9f3f9b684c78a661b'
  },
  '0x2b1d21ef479739cf9b898d0a00f915fb020e1e11': {
    recipient: '0x2b1d21ef479739cf9b898d0a00f915fb020e1e11',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd7fb229668757fb4f8ec57a8576da1422d1b98398cbe7ba6a1800627ae5084c94a9d777112bb717ac491336db7f28d84dc122f2e6326d5b3a5a0ac97164d4bb91b'
  },
  '0x2bce824dc3596ff856205c33554e4c37634efa97': {
    recipient: '0x2bce824dc3596ff856205c33554e4c37634efa97',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0e41c3b74c04dfc948ee59bbe494effe96dea4ceca152a111e0e68b40f337ded6f9b9104fbf9c042874cfec1d8cdcc66355e8f3c2f4945aa56adca6dc4a778811c'
  },
  '0x2c4c3c57cd3468ee15b5ea732964e6c883fa4a55': {
    recipient: '0x2c4c3c57cd3468ee15b5ea732964e6c883fa4a55',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0153d6be85fe57304bf431209e6dcf232728817681c58747b5248a25b88db802480e1a7e0e70effb0becf366a0d09e0095f62c764f7580549064053fcb1ab0ae1b'
  },
  '0x2c9d7c926eb0b4488d7657e55cc8ead96752b5ff': {
    recipient: '0x2c9d7c926eb0b4488d7657e55cc8ead96752b5ff',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x111b98f8bfb355b3ea1619e86b71949833881acc2e3dfe4d25c0eb1941692ed261b2cf4a958c21f6238769480ca60729005e3045c2bab8cb0115dedc23d790591b'
  },
  '0x2e649bd496af2e1e719f7089a47908e1351bd673': {
    recipient: '0x2e649bd496af2e1e719f7089a47908e1351bd673',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xec5f13e6e1073f501091bdfaa14345cb16f6617feb6b1c6d4b462cc9e84545125f451af4e9dc426bd29275b8db7676f4bc230312dbf17dabb231828d2c4968bf1b'
  },
  '0x3007ce5fef425f4313085d7ee9eb85a3e326e641': {
    recipient: '0x3007ce5fef425f4313085d7ee9eb85a3e326e641',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbc053372a2084c10380f71700fb6ca6aca9d29df6a9e4a6a6d9a6cb7c58622b05b58158f2d81596d7c9f778dcb256dd808f62ae4790f31a9fd21845d67ec79b01c'
  },
  '0x31fd1f311b6797e11b4316a2cadaa6ef8a2ed9cc': {
    recipient: '0x31fd1f311b6797e11b4316a2cadaa6ef8a2ed9cc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x39972f890cabcac29ee416cdcf2195753e108b191950605e54469441c724e2f21825caa2924044bfc5830b62485a37de885d0743fe103c53c434f6bcbcd7e0021c'
  },
  '0x32ab68b0b060593293aefe82d3ad0f40931f50ad': {
    recipient: '0x32ab68b0b060593293aefe82d3ad0f40931f50ad',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0448418e972d40b419cdee44eba2579ce68030feb50693087326bc24b07137a561ecd90cf9b357a8e74c6e8eb051d5337c21688b7a776a066c4f194857fe18ea1c'
  },
  '0x340fd207971fa8bca145f7f7053dc9891de7dcf8': {
    recipient: '0x340fd207971fa8bca145f7f7053dc9891de7dcf8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1de710c03391315e78732a2c26069c2ae4a8f9ab318c5a4608045d0a20c949023746d9d1858f7b84d574b0da91bbdbd6e08e5f53e700c93836c0cf4605bf97251c'
  },
  '0x359270193f502be313b77c1f23f84beebdac7e5e': {
    recipient: '0x359270193f502be313b77c1f23f84beebdac7e5e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x9234def5e0395490e0a65eec11a51eac627dd4aa6ad900607fc4f86b40ae80480f3698176527a768dd4c744a652537de8568da8bec8e885093ba867a83ccd6d01b'
  },
  '0x37c0481f3f3fa45e866de0311013ffbcb642cca6': {
    recipient: '0x37c0481f3f3fa45e866de0311013ffbcb642cca6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x3b022b02f887361eeb733b6dc09a3c10acebebcf541d6d062290be3e4cc90fdd5f8ae8f7853a5342a15bac66c240f4fb6b1ccc6f9cf291e43a62f40554a6a2f31b'
  },
  '0x39abf902cbee89a3c05a38dd43a94e6a2fadb29b': {
    recipient: '0x39abf902cbee89a3c05a38dd43a94e6a2fadb29b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xab4e95b64f0522a195406c3d6c00760dd3e3b1a1efc36f277767ac11774e9e9a4228e36f4cfab2b2fb7d2d550484a920030a0e8881c28d25093a364cb67f4d211c'
  },
  '0x3a580ef3546a7e979ad5a569804fa66d0ce7d2ad': {
    recipient: '0x3a580ef3546a7e979ad5a569804fa66d0ce7d2ad',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0d9b7b7497961f68f0316f54a312f6f6fd874e8d644d7a3516676e148eec62f0444034d3a2bdef9295289a4ed7b5310a10f8c32c54a001523a18d68b8a097e301b'
  },
  '0x3f6776004e5151a120bdabe95e708b923ba88cdb': {
    recipient: '0x3f6776004e5151a120bdabe95e708b923ba88cdb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0c56dd9ba9e517ef4c52b2d04af93b23b2717d30561cd68daa53e90127e24b9d07fef40f7519cdcedc673a86039af3be173891338fe83af0be005981cf4bb2ea1b'
  },
  '0x403089b876128b97012aee31e3f91b4f6cfebede': {
    recipient: '0x403089b876128b97012aee31e3f91b4f6cfebede',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x24b71eea47b7ee61097dba3b36b7a631ca46a682f1503c2683d011b7bad8db2e6febd30a8813a02a236aa076fba6fd9d73d3fb20d91a86876439a244015578ed1b'
  },
  '0x41c06e3ab683dca6051ccfbacfe0cff3d480d1b5': {
    recipient: '0x41c06e3ab683dca6051ccfbacfe0cff3d480d1b5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x999333bd1333101376ca350bb249f54afbb593aadd290892da1d0091d6ab0f860de82dd88781ea9d0e661b9f6830a30363a0d1f8755450c4ae493121796ab0611c'
  },
  '0x431450cba78916183a1fb837776e759aa4d3dd9f': {
    recipient: '0x431450cba78916183a1fb837776e759aa4d3dd9f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x91ac0244c4fdad70ca856520a068eb80c9f8931e3d8ebf71a5cdf297cf5b075153207fb46d891269d6017d4f26a70ff274ead83d82e5e47105b8876eae6f758f1b'
  },
  '0x43a72603a7e9de53713f6ff8a835b74bad96d8e1': {
    recipient: '0x43a72603a7e9de53713f6ff8a835b74bad96d8e1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd6e970371fae836ee65346725c60bab4696fded55fe3447e30545d4c9172fa695d6c7691d85b9d20713dd6e34b09ed75ec928c616e1aaf2b5613ad84b1b6b6051b'
  },
  '0x43b98bd8384dde39c9a13560078fa14e8fff4813': {
    recipient: '0x43b98bd8384dde39c9a13560078fa14e8fff4813',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0fe81e957497f69465205d581181404fdf303bafb303d71907f63047f16316bb2aa0ee0e9c26e6c23427a085ec654a42259d1d09b879c528ec80cac5380dabfe1c'
  },
  '0x43ea0d1bef1e3570effad8aebeb4f1e646756947': {
    recipient: '0x43ea0d1bef1e3570effad8aebeb4f1e646756947',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xef69bc8b895edb0d018d8d21f34c05ccaa2415abfdf1257f6ffaa1635f8aefb474d32dce89a34ea9949a7fe8531aad5d3abf1b8997645b26d15b1e90c8ee96fb1b'
  },
  '0x44d5633ad65c170dc11b4f66fd8bb6b3fe1fb0da': {
    recipient: '0x44d5633ad65c170dc11b4f66fd8bb6b3fe1fb0da',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x42d323931d3405a124502898593ba6f6879db5f0278e7241c79083206eec5d0d3caad69a11526d775533afbbfe00ab5d27e2ea33d12e85fa13787c814b0553c91c'
  },
  '0x4521ce73b25c4704c228660f087d21574311c85f': {
    recipient: '0x4521ce73b25c4704c228660f087d21574311c85f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x501a6c76c53fec8c7447b2c6af0efde683b3ee12941c9b9abda7bfd4368414380e9423144432e26f82dd80c14bb4a6b7e9c0d64b5b5eb192db40e72e8cac7cbb1b'
  },
  '0x452671bb51a1ab67364515988dbbfee08a3e4ede': {
    recipient: '0x452671bb51a1ab67364515988dbbfee08a3e4ede',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x64ba9148307aab5fa204d6db6ccc0e68f48da1a83f10136638ad3edc22bd38a04dc76648cabc53fe8e056e222790726551aa1378c9921086ca39d60d4c336bd81b'
  },
  '0x4610ce830b6cebeacd0e50f9c67aa7c5dfb3c7b2': {
    recipient: '0x4610ce830b6cebeacd0e50f9c67aa7c5dfb3c7b2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2fe55196b0612f7dc302bfb2f5db1fa93eb25423730eda2a435c6d09db163dcc372efc113695fd7efc611f48318f916314a574c8f84ecc278251b74a2d70e0c41c'
  },
  '0x46978fa839fa0ba86e8f18e8b8335a3b356a95e4': {
    recipient: '0x46978fa839fa0ba86e8f18e8b8335a3b356a95e4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x96aca031aed43adb7eebea67126c5a7b1be9b04631f3bdcaecd3f1cfcc808c7542f191879f6292f5e54e9f7b37fba9b9aed3ed0584549c4435a3e0984dae68ee1b'
  },
  '0x46f047bd373598056303eca1cf3aa6cb2c42ebdd': {
    recipient: '0x46f047bd373598056303eca1cf3aa6cb2c42ebdd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x09379449c782c1443229c44bda7a7e8a65b1ae04dde7c48152b33e2e099a969c7347cbe1e34d1286720e3b089b2c1e6315fc6dfad21ddec969015d71807ceaee1c'
  },
  '0x47fa2f2f8916a3bc381a8ed0d6ed3de96f5f7e0f': {
    recipient: '0x47fa2f2f8916a3bc381a8ed0d6ed3de96f5f7e0f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8482bd5a62e2f1a5ead19b59c62c692462656cea44e77b6113b56f659b1179651f2678287b4bb48d5c2faf915646c1ea9131f532bf59419e12646829673c6ebb1b'
  },
  '0x4a0d47260bd8ed949f4cfbee476b0f0e2d203ceb': {
    recipient: '0x4a0d47260bd8ed949f4cfbee476b0f0e2d203ceb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xddc0e97a90ff0ea7f4d8e94f7ae6cd666ac1d6e498db08e39f7acac4767ca1684238baa11ff14633044a8645223b08f2f8f0ff1cc127ed126ccf52c94ddb89311c'
  },
  '0x4a7e8dcb760c1068bb6a5828c0d0a57a6143daf0': {
    recipient: '0x4a7e8dcb760c1068bb6a5828c0d0a57a6143daf0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x181e3ba6d9be7c4d0f6d6b530e8ae4dcf7acca2798e11cea3025b39ec338b6c50e4248fb61381288938e29c9eb8e514ce735acb63eae27b04e102fd21ba926ef1c'
  },
  '0x4b2af2a19b61471cc131e277685870c682124e9c': {
    recipient: '0x4b2af2a19b61471cc131e277685870c682124e9c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4c788907bca4b974fadb85e075bc1bee555f147860f81061f55c910d7ea1b30801626a955be02d6095a0c62f6c23bf73c06b82cd1d2ad52e2759896b871e473d1b'
  },
  '0x4ca1d361ed2788855594ace0435349cbfa957853': {
    recipient: '0x4ca1d361ed2788855594ace0435349cbfa957853',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa79e6fdfc575064aae636cc656d22f12b97131ee243dd41b95f2af74a108a71e370dc8a68ddacca98fc4a6cbb0dbeb5e06ddb8d62bb0c2a4dfd226ad7d9ebbb61b'
  },
  '0x4d46c3739b39851098a006ac163245943b48f75f': {
    recipient: '0x4d46c3739b39851098a006ac163245943b48f75f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xff759cbf0c39fd900e819524d1d495b5c5036f9913eff314f6d91acef95d6fed2b120f445014cd25d2af566523589a7a6d4c8c79d5a3f9be9017111caeed53c71b'
  },
  '0x4deb857ce675e6c1a4c1c4504f853fb46c9ce59d': {
    recipient: '0x4deb857ce675e6c1a4c1c4504f853fb46c9ce59d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x797aa58283a241e50e3d26af8f267ddaa544cebb6a9223756f67552c3ec184c27c667d22e457718dc1bed9ee4b80d40dfed2a1c2a5172c0a969a1f2af0c826861c'
  },
  '0x4f9512e36346ddace0d9ea12de0d676a7bd9cb01': {
    recipient: '0x4f9512e36346ddace0d9ea12de0d676a7bd9cb01',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x5ef6dfacbe5594c46b4f7829a7abb62522c6b96ec7fdc0489a7b5c15de4f9c9d640d6d74bf7a2338441adc86a241fbb136800120041b58d3af708a931a1f891a1c'
  },
  '0x4ff2ce32027258947263e227d77edabd6d28a90f': {
    recipient: '0x4ff2ce32027258947263e227d77edabd6d28a90f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xba353872d93c54b53c7ad7aedcc3f5131077da3cec1942c3fcb60e37d4ddabfb709413e8bcebdbc26b39d146472f31d7140707f919b061f369c228fea04cdc301b'
  },
  '0x5131c75dc1083fc74ba37bb5414149aa924a4fbf': {
    recipient: '0x5131c75dc1083fc74ba37bb5414149aa924a4fbf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8597d5861d121b5f7983b86e1b09ca10729e013ede7ebc9682c47a61bb637a0e4f7c6a61a2525448f7ca45dde4f9a1932cdea592efef3b8873691fbdc951834d1b'
  },
  '0x527e7dfd1bd379bb67bf2bdb55b0ac923f47692a': {
    recipient: '0x527e7dfd1bd379bb67bf2bdb55b0ac923f47692a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdaa236b170332a3583efcd1d8ef32fd21ac5e8a58773f67ad7d2f622314d96fa59dfc76025c7165641b3219f8b9d78ec506b92d240536994cee1b7ffc58bd4ee1c'
  },
  '0x52c93ea4240d68b8f0d50ba9eac2bd75163f75c3': {
    recipient: '0x52c93ea4240d68b8f0d50ba9eac2bd75163f75c3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x770c551b30db3ee6593b9af5b38d19990495a6347f814b4495c47eb6b3cc151158517d50bcd32ac551aa1d6d2572108f6d1cf1eea5b451f808d25d9a01dc33de1b'
  },
  '0x5343f545b5f26a274eb30de720e83c0bd64a4e83': {
    recipient: '0x5343f545b5f26a274eb30de720e83c0bd64a4e83',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb035f3e91d660dd87e265c6e420c4de54bcabf39a9a5a65a1aa0e498d65abdf004accc1c7cf86c20581b55be876753e91df61f510c1eac44511be01d1805b6911c'
  },
  '0x55bfd36daa7381e5040aabf912aefb1b11048a70': {
    recipient: '0x55bfd36daa7381e5040aabf912aefb1b11048a70',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf5b0dc059d8b7d872921f71f79e29f951d721fb6a5f48e6b58fa91b0887c5794426d41b4e99c2173c9b26c0f396964b11a63e14acbfbd67aac49727cebc0276a1b'
  },
  '0x5607ba32db0f83f303e5fc54c0b241ed8ec35f48': {
    recipient: '0x5607ba32db0f83f303e5fc54c0b241ed8ec35f48',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc34fa8cf1f61464438d7144d6165b1741aa79beaa145a25028863b95760365ea68a78392168867dc95db1283caa2464688c55974a733d48c4dfb49662c4743b41c'
  },
  '0x56a102879bec934dd924484f19dbef23f4c5a869': {
    recipient: '0x56a102879bec934dd924484f19dbef23f4c5a869',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x83e82c3f17c73e542aabfa9b58d9d17a433a9de18823797f9107e6eb622613d47a181cb2264db9ee56ec7e4c5b9905bf3f091bf8de8a84951738e14f46670aa91b'
  },
  '0x56d1a69680c4e3d193db9f025595ec8cfeab4db7': {
    recipient: '0x56d1a69680c4e3d193db9f025595ec8cfeab4db7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa6fe77a668f2e44ecaafc16eb8c7331601390c78859c667ef88326fec74af1b112f8da89af6bd952621f4c5e5e7e30a316b8a48a25055ac2e2e5881f09cf7b221c'
  },
  '0x597fbeab930df8a4cf8c0f36d4753d8813166343': {
    recipient: '0x597fbeab930df8a4cf8c0f36d4753d8813166343',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x318e0cc0f31f457c16c861d14f1ccce72123ac3d29bd7c8ee9efb7fa265aba0f0d067fed5eb5ca8848593a8c84eabbb172895dc0b5e930b0c0a46281aea672101b'
  },
  '0x5a0a6fea1fe5819447603a22797e05cc3de1ae42': {
    recipient: '0x5a0a6fea1fe5819447603a22797e05cc3de1ae42',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdbf0d5770f0c10b8854657a7f94f30b887c66ee3897bb1b68a5f1f0c5efbc7bd62bdabbc4e8069c073b7a63914072aa365a34595649a5955ec80f8dfea387bfe1b'
  },
  '0x5b10aaad342246949ce32baef4bc99c4586d256b': {
    recipient: '0x5b10aaad342246949ce32baef4bc99c4586d256b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x57d3e701dc7ea17b2dfe934fbe7cd083e31780eddc637d7b778f22c748627a2b5566d2365e2316dfe58b46c5df4383d402f48df19317bac2de077ad03babd1571b'
  },
  '0x5b4c9859afc90ee9300edcfbedbb39bad33e2988': {
    recipient: '0x5b4c9859afc90ee9300edcfbedbb39bad33e2988',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x5a284656f3f4ddecd91d70f4ebbf27da14a44deff6f4f9fc7513f37c3c8f15295b78049b4affa41fb1f9ac55e249bd3f40cef4f3d5b374bc205104dd65c53be31b'
  },
  '0x5ba35f28f0e79346e7c8fdf9149446b1c869e13b': {
    recipient: '0x5ba35f28f0e79346e7c8fdf9149446b1c869e13b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2a0a7e1d755d9b2f43c137dc35e1aa29d2690c25e50a2a6e7aa62a0ffd82a0d95a1c4cb63f5ee3726867f53e1ee138b32246f2991987301abdf490b6c04c36f91c'
  },
  '0x5bad6fcd98d603faaddd1f38f2900e7568e6f8c2': {
    recipient: '0x5bad6fcd98d603faaddd1f38f2900e7568e6f8c2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x52bd4c924aaa4ad39914006431130bb6cbd5bc2a2a1dccde50f4801f4c75fd546f323409b886a1a02f000e7e4b4d399ee3aab469fa4bb4e301f4b376ca62b8921c'
  },
  '0x5bca5d5d7a5d53acf98b2ab3a98388d9e7c25cef': {
    recipient: '0x5bca5d5d7a5d53acf98b2ab3a98388d9e7c25cef',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8af0ee87f3c960f93bc31f7fc3202a886ea0247d0eb27f84dfb487ec9947930e153169ed813177a1334d5b5b8cdc4053eeb9f2a83faf138427d177ba1c381b8b1c'
  },
  '0x5c4a6d01fc7b8f6d8c3b75c0a520e69b0084c9d5': {
    recipient: '0x5c4a6d01fc7b8f6d8c3b75c0a520e69b0084c9d5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x452478625c320eeb030f74330a392fd60d10d3aeddb81e9797066c0fa0aef029673ef987e1eee9002b6f0d82a1366093083504ac7ff72a97edccb04300be81171c'
  },
  '0x5c528d7151175e48b781813c6efac1fdfea7e32e': {
    recipient: '0x5c528d7151175e48b781813c6efac1fdfea7e32e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xabfbdc48c28383349a151601fa33cbb41fb9d949b3f5a6f456a3898f79b603704758c8e928e6ecd81a2a2d06a400525fb22e1f8171395ed6944f9aae3f577ee81b'
  },
  '0x5de75bc9de241fd44f8661daed108bad97224e9f': {
    recipient: '0x5de75bc9de241fd44f8661daed108bad97224e9f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7bfc8593f87c9b2a78c503944cd6bb34fab0e84cac87226fad39cf87396c1b6c0a359beae81c142e415ae3b7e5adb79f38c4a0c91cd7a630bffe0109b72cd2f91b'
  },
  '0x5e2e287a85e0e00c99705bddb6d69d6824c185f3': {
    recipient: '0x5e2e287a85e0e00c99705bddb6d69d6824c185f3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x3ddefb29b8e6dc7a0fc902640b2d5591f692719c90969c4964af9fa6e86787bc1d3a5c0716d3a8103becb76bbc88a2667d5b9e53a7e823b6ee9ae63ea4eba3721c'
  },
  '0x5e49b8d2aa98b665cfab0de5a487c0a1ad9c70b7': {
    recipient: '0x5e49b8d2aa98b665cfab0de5a487c0a1ad9c70b7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfb0efb87032491abcb0092ce1040dc2c14c001510420ad7d0d5cabc9147941a43d0db1711ca6036af989e40f42fd8eb03f002f588b5afaeeca2a163770290c141c'
  },
  '0x5f429371c3b930d3998a9f06b0ba56d7ae9d21cd': {
    recipient: '0x5f429371c3b930d3998a9f06b0ba56d7ae9d21cd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x180ed9676611334dce4bb52d9a196a767cbb129150cb7f85899500b557a5e96b3f706f17a0e0d2381e795461a6dcf964cdb3b74d29d0e9a99c7081920cadb5ec1c'
  },
  '0x6278ba5259cb87a8baaf829b990df26e2eab2cd4': {
    recipient: '0x6278ba5259cb87a8baaf829b990df26e2eab2cd4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbee97e72e52fec7cdc0f3d1c1f3855c43968afe398642b4898031ab26d7334cb6cb80fae835e416bf9aa85d94c85aafe16d01330ccd57e90d6ef0321e73ce1651b'
  },
  '0x6336c2f83b3a52e11b66582b6e27305e5a07a79a': {
    recipient: '0x6336c2f83b3a52e11b66582b6e27305e5a07a79a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x5af921d2e61899a77f79fbb3091969ab66fda595d8f387597bb7b45aaacce6695f8c40959090001ba13fec361c11c9f2d13f23a051f118d4c0272b34d79807a01b'
  },
  '0x666171ab8e7d78e4b31bcb096fc7c001b2f42720': {
    recipient: '0x666171ab8e7d78e4b31bcb096fc7c001b2f42720',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfcd964f235781204e8d2b0cc6345952a16dd1ff31cb3beaa1d88baeea84abfec42111e60ae128a53bd643c29d0ec0ff882565b95478324878a0aeda1718838581b'
  },
  '0x66c5eed8526c66d46df8e5f65311cc1cf82eff0e': {
    recipient: '0x66c5eed8526c66d46df8e5f65311cc1cf82eff0e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc022479e785c8b8a6d67b34648203364e9a6f4a5b5470d018e199e6b99de43a327dfb3b1324842861e85092951775e5c1d3232c3a96731c3d1ac7e519bda4d9b1b'
  },
  '0x67edb720fe21e2d70b69bfb94734c8f9f528bb43': {
    recipient: '0x67edb720fe21e2d70b69bfb94734c8f9f528bb43',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x87cc4c918729844e2626b28e4633112936a9d13c2d4907ed21247ca5ff9ce5ac3a5c20fc1f6436785b43907a76b223ff15b9c819fb69b8284e0ee05f49d848bf1c'
  },
  '0x6bc64155632bc33a09ec6b70e93ed005b657bd0f': {
    recipient: '0x6bc64155632bc33a09ec6b70e93ed005b657bd0f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe94175184e7ef83460bc1f00d33edb07bcd7056d13286825d25293d100d50dfb56394d47aaeddd44c9b8fdb8f992879c5e7d336cf4c20731cb6fea69c9fb0b2d1b'
  },
  '0x6c2997d7e61fa2584d43e6bacc8eaade0fa1dc53': {
    recipient: '0x6c2997d7e61fa2584d43e6bacc8eaade0fa1dc53',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x99368a824f3d3fcaea4604cc4a377c37a8f8a7872de6bbffa1fcbdb0b4f337a3506dfbd56e4068c1525d83a7e33c8a812ba7cceee0f1a120751c508ec10a57101b'
  },
  '0x6e3b02bf9c4404c72c3f006ce39a5666458cbe18': {
    recipient: '0x6e3b02bf9c4404c72c3f006ce39a5666458cbe18',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xda1d62016ac5414e153efebd1b7190366513f78a2bd4580781e17c029b6a079947cf6a633ad5d32cb22ba23820896f154a92cfe616b8cf7dd35f4cd85e9bddf61b'
  },
  '0x7110733ab02b2a18a947e3912bf54136fbced169': {
    recipient: '0x7110733ab02b2a18a947e3912bf54136fbced169',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1a6fee4f7a60104e3f8fd6ac3b0e526a5b6c5fecd59369fb5a85ea3ebc91f4a723b717a59ec0b23412748a28ee2e058f307ea21302bc224b164bf0f97fee7bd21c'
  },
  '0x711f293b1fb376f85132118842343010efc1b1c8': {
    recipient: '0x711f293b1fb376f85132118842343010efc1b1c8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbda764433013108fddf729886d8e9d973c8789ade72a940e8e0053031cb7ee451a661ded11263f9f8b6a08c7b3651af00c7392cd6d0bfe7c2cc62c857ab7de201b'
  },
  '0x71bfe08010b623592b0bee923b9dbaa7e0c3b1ec': {
    recipient: '0x71bfe08010b623592b0bee923b9dbaa7e0c3b1ec',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc56f7fbe50a49a34b33c96fcc44cc310b294597c1a94e4551ecca72d3bfe90992713d5e9ca5044429b484fa375cf1d3839ea2dac9e561b00a2d607cfbf2ac36e1b'
  },
  '0x7200e2624375d6ec5a4c2a075b19f423e258c42f': {
    recipient: '0x7200e2624375d6ec5a4c2a075b19f423e258c42f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6ee09f60157a72d7418cf2eb31f4b81bb77db48835598a5ec33f5498f371d1a02e441d0c2ef436c73634d6b03fe698b6b0e4a17ace1e40edece1623fb17a374a1b'
  },
  '0x72ff149f3d22eec5e3d4b2814d3c4bb8a2ff9647': {
    recipient: '0x72ff149f3d22eec5e3d4b2814d3c4bb8a2ff9647',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe7ba540ce19034e1ecf3cd4c8e2c3ec9ead63a9554abb0bd047427aad1fb029957baf5832def00f5af327b178fcdc90c86e16472a4a1acde597af6b356a08f4d1c'
  },
  '0x74c924ce35935f50f5ff7753e723dc5796f73807': {
    recipient: '0x74c924ce35935f50f5ff7753e723dc5796f73807',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x312d949a53897a13b25965480d6950fe65e5eeacd66d3b9938d509eb2aab8f7477884c4b0d43f1fa5c3a520ca7800926ae3141d8fbdff0d75f4bbcd11f73422b1c'
  },
  '0x75796149fe7fa9d0d78f7f9cd0ab36f825e225eb': {
    recipient: '0x75796149fe7fa9d0d78f7f9cd0ab36f825e225eb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0422a68446ee16193636898b2cb0d26be68df296c340542b4de4942f524969416e7fc5de0d3b7438c05296253b444fc80dcc123981a0b609c56a537ae14c3c621c'
  },
  '0x76bc7e737fd3ff0f0fe83ffba037d48fb2c13639': {
    recipient: '0x76bc7e737fd3ff0f0fe83ffba037d48fb2c13639',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2d6c9fbbabd8a95afff47eb0578b7bede22ecefec43d766dad91e8703d66ffaf1e446da92a3894c2378c38aaff14fe6b75ba4e222e035d25e4db7fb56959446a1b'
  },
  '0x776ecd9b5fb71ef15264ece29a57a5462aa448a0': {
    recipient: '0x776ecd9b5fb71ef15264ece29a57a5462aa448a0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x31f4e06915dfc1a3eabfbb9e138f1a5b1954e18220edec6832a5044d48434c012ef841b1bb2adb3efbba00ea5512628e80fa66e4f278c4e907d1ad5569079c461b'
  },
  '0x79ba04010b99570f471b667a608ee720290ef6a7': {
    recipient: '0x79ba04010b99570f471b667a608ee720290ef6a7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0674e59e1b147a65b08f4648bf5e1c954a5d649f153b0a0e8ec2e32db9a72d1f31f13a82c164da7ac48f7c7bb145f06b5786d9e10d7ee82adbebfe5cde21c9891c'
  },
  '0x7a62042c009655295f51fa8c94ffb595f25d4862': {
    recipient: '0x7a62042c009655295f51fa8c94ffb595f25d4862',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe4c534d5ef590d4fa36ac84457893f963cba788b67193bd62bc704391f95731d717a246f6c380b4442293c113e025fc2aac94b79f2f1632ae3f4b9e62f3cf0c01b'
  },
  '0x7a99516cd994275b631fabce6511f33b74a1a72c': {
    recipient: '0x7a99516cd994275b631fabce6511f33b74a1a72c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8358b42e8994aa647f2be8711ea59a08eae4ab89b5bf2dcb0b461c8a0e52fc635f45d7aefa33ceffc691ff583e7dc7a47a864665167221243e74954129b9fb601b'
  },
  '0x7bd2eb630b5a91044d781b8a21e88c5c2eb9b8b8': {
    recipient: '0x7bd2eb630b5a91044d781b8a21e88c5c2eb9b8b8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x60f88b40ffefd98b35f04a3e1a9d3d7bc5f64080aa8de3303ddf0a04d9fab74d16c628fd32c2a1ed81ac97a90981de2e4f0d2b21b5ca22a68fc8175aa2dee86c1c'
  },
  '0x7c0edc1bdaf1fb24933c5cb2337edff7100dec8d': {
    recipient: '0x7c0edc1bdaf1fb24933c5cb2337edff7100dec8d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xaf0e6eb7032162d7e120825b31f312f52811ee08daf6231ce465acd5a9207e78496fdff7f2646f6669ce27353dc73015c2bd68bdde3f420c059a1057dd8831e81b'
  },
  '0x7c958c1229a4eb1301b19b8a9623dbe39bdc254b': {
    recipient: '0x7c958c1229a4eb1301b19b8a9623dbe39bdc254b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x43d851a22f1b4dd36873a9e2ad70564c01273841e8b6589e7b85d6c437d9d0bf0a4a2558fc070fc8c229e900dc4f8239fb89ee04fe3167981de5b253aaac18d01c'
  },
  '0x7e1be4ec7ce33867d372273dd27d0aa5411b153b': {
    recipient: '0x7e1be4ec7ce33867d372273dd27d0aa5411b153b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf8168b31d6916cb145d10808fd7cf844de0d95a9d4860bd5f8f80a95267e8cf74432bfdc71902838f372ba30a6a34b9b18b191ca2e8e10d67873bbb25da277f51c'
  },
  '0x7e7dc95cd5b2cf7b5642ccb24c0c00af251c8861': {
    recipient: '0x7e7dc95cd5b2cf7b5642ccb24c0c00af251c8861',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd58dd05a564792bed6f4058bb3309e92b587429a94bfee15a27c9a52f3abfa9a745535f71c6afb93782943ecc0402f5e2afc17c83b8985fefa971bd73b12fc671b'
  },
  '0x813484c106c4c4e3fae545a054c4788011292354': {
    recipient: '0x813484c106c4c4e3fae545a054c4788011292354',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf56283dc03b99c2b2d67f9c1cabda4ad5e19211ec31523b7a89529a85d205e06698f9ccaed35378460edd73311de61fd64e748566bb8534a2aa01653804548b71b'
  },
  '0x830303ee09740021a9473ea77dda46ddec1f5f68': {
    recipient: '0x830303ee09740021a9473ea77dda46ddec1f5f68',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x70de8ce222a971b76a4c71962f5042e752a00f5d40cacb581691cb1aeb4c360d1d7b05e0fc4e3ab1da69aeee01beb266477fe58ca4f83fbf199667b88f41a4371c'
  },
  '0x8330adb145e95f5a922f48303f102bce3bd974ef': {
    recipient: '0x8330adb145e95f5a922f48303f102bce3bd974ef',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xff6e5bfd203b5f4be2e95035bd780fe1479686039ebf5efb520120827922b31154d33c3f2b030e8341f7b12a2545f7b4809d8e3c1c149da1b2fe7c1f223e3f541c'
  },
  '0x8370f0911dc7911450056bc665d3c7d2146392ff': {
    recipient: '0x8370f0911dc7911450056bc665d3c7d2146392ff',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x60c36dfe3f7b0dc2acb2348c6ca65b86167bb5b4e272ccb976db202de58caa5d3a8af68c6c3868a0475111ac712047d50e9de8a91b6d8311f47bfb5a7a2829741c'
  },
  '0x894f5bc9f4797eceb5bb731295b9446dcceb274a': {
    recipient: '0x894f5bc9f4797eceb5bb731295b9446dcceb274a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8cac2a201db686cdd7fc969ba783fdc15efe08b3c2bf78e0f81ec9dca4b0c6140a1aa333c929e61a7b966f104597dee72f728405bf0ea9d801a30dc5e5caa33d1b'
  },
  '0x89e6c1f087be382ee9171b539c5faf2785e8f1c1': {
    recipient: '0x89e6c1f087be382ee9171b539c5faf2785e8f1c1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x19761eb167dcad9c367efce9d072b019b7c16bc11c7866f57d33b8068bda60345453db13d72405b76b809c70ab0c9fac2070731d2a578bd7726a107f4d22b0ff1b'
  },
  '0x8af62fa6f4b4ca6296367ec67a9b505ecb977fe0': {
    recipient: '0x8af62fa6f4b4ca6296367ec67a9b505ecb977fe0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfdb376f1ab5a5522d95ec2b80ae9dbaa923377e6be9cbc164bee2120bb49d6f555460fd1921f88edee25f97baee2cde138270c55b8c5062783227240b985bfd21b'
  },
  '0x8c349bdc30e7cf5fbc1cb2f1bf3bbac8a8ebae87': {
    recipient: '0x8c349bdc30e7cf5fbc1cb2f1bf3bbac8a8ebae87',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x38891d9302b033441d6666c464037bd5f9270beb9431ab497ed8c53b1178fefa75448bdc723cab08d06860d8a369765fc83e390ce475e9579d6af7a26985454a1c'
  },
  '0x8c425c1d4731a059396c91efa2df32f8400dfa26': {
    recipient: '0x8c425c1d4731a059396c91efa2df32f8400dfa26',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8cec8dc21308959eb089ee816e774a38c2ce1645f83c376ca0b427f95566f63840ef2469efaa251a617754e80818e1adf39ef61eb0a1b366bf558b62ea095e1c1c'
  },
  '0x8e3dc908f97a000c281d9de0cdadb8cee842c8d2': {
    recipient: '0x8e3dc908f97a000c281d9de0cdadb8cee842c8d2',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4c1ccecb55864c855fd828ef6455a69846adf023824b8c76f806d20914d726fc106743a49d157107e34e939eda0d38b4f394d6a17b3277ecf6f9aeba025d486a1c'
  },
  '0x8fbcb07684cb7cdf098d4db63c989a77fe05aae0': {
    recipient: '0x8fbcb07684cb7cdf098d4db63c989a77fe05aae0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb9588736042197be5313ef4fc9cfa0eeeb94867513246f22225cb6ef58d780935e383a53ca8cd260a1b70f162f917194cc3bfa26b2aebd58b2d9105db14d10061b'
  },
  '0x904e2bc2058f172f0f5acb217013ac374338e265': {
    recipient: '0x904e2bc2058f172f0f5acb217013ac374338e265',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1726c7ab418c4fc8b5d4973cef0d2cea59df50a67f82b2c687b66d7f95b4e03a454914abbbd1e8d52dca9b121e1c1d28fae6ca89068ca1f136b66e9961ff07be1c'
  },
  '0x911d556e489b6907365faf8f96206cf29cf9907d': {
    recipient: '0x911d556e489b6907365faf8f96206cf29cf9907d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf3f7b4e59833e59a9bb6dbd228372325c6695959e81f74e58994e99b1ed0c0bb2b7ba1aedb593a6f23003dd195276c2d48024db4a7bb18d060c6ecc7cfa142031b'
  },
  '0x91c771d7728966d8db5469d0e32f2d18862c8712': {
    recipient: '0x91c771d7728966d8db5469d0e32f2d18862c8712',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2147329c223f72ec9cb1d91692984749d0aab3c4629cbfc6ea1ad2d4b46a68e83c6fea83edd90f689a4546edb146ede727aa1fc056751086fd6c8aafde52cccf1b'
  },
  '0x93764f2451037424997938e5fe0e8cfe5361ab4f': {
    recipient: '0x93764f2451037424997938e5fe0e8cfe5361ab4f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe7a9b4c3d8ad07d96f8a2917e1df4c006a83be2af9d8f4d60f47120e8ad130971a30fbaa4efc2f4567f4cdd4893e51b99f82058feeabc23586702d82a4bfb7521b'
  },
  '0x949ba18ebcad9c175830cb5a6efaaed9f76a925c': {
    recipient: '0x949ba18ebcad9c175830cb5a6efaaed9f76a925c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xef59232ee89eede9a8e4442c6fb465aacdf2717cb4d964b5b7b2fd633a0fe39939651b881f46f4aade16d6aa8fb1e0641c287a1281def6494ce0e599764e321d1b'
  },
  '0x96e5bcf3d2d7290ff65f47f7da3175e6f12dd863': {
    recipient: '0x96e5bcf3d2d7290ff65f47f7da3175e6f12dd863',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x704bb468df5b38188c5f09178f2e358c39f56b33d4b718872f89246456ce39307fb93599bc0d520ffc997bfb20176df8a269216ac7a3a33d71dd79a86cb6d2831c'
  },
  '0x983bb17f312d79422a81ff1d1f7be925c6c124cf': {
    recipient: '0x983bb17f312d79422a81ff1d1f7be925c6c124cf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x5e62ee1aab5d00e44aea57bb44eddc428f94cf44bc605a2eefc1f7f0760c31b22635a5b17d8a351e7c70d7db4d9afb7c9de45638e2f941c4f5ba6150114a21cf1b'
  },
  '0x994f9e5ea67a88ebca024a179d76a03c1f92da61': {
    recipient: '0x994f9e5ea67a88ebca024a179d76a03c1f92da61',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2b48b6e94e897840156df8862ed979125b5314bbe2409232f2c1193fd908e35d7d326dd5eecbb3ab62c376bc84ca030d49a2de658978e92d9e1712b969797ea91b'
  },
  '0x999999cf1046e68e36e1aa2e0e07105eddd1f08e': {
    recipient: '0x999999cf1046e68e36e1aa2e0e07105eddd1f08e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xce7f39879636798b7bf985a33bb2f987cedc18294488e51a744b856975ed8745411359b18fa5a62065323c38f1ebcc7d8770eb2b4180c8ddef13761720b21bc21b'
  },
  '0x9ba4a7221b7cfa1cda5b5a7d239e82b287c3d255': {
    recipient: '0x9ba4a7221b7cfa1cda5b5a7d239e82b287c3d255',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe462fa60a72b2eb46e04f9ff0d424bd40222229cca0075b0f2cfa49fe4fab90173f63c28bd3ce735eb7b8401574e2c78ea174be7c5f8ea3e50d24fed98d2305b1c'
  },
  '0x9bc910f14b0ee83689b9f49337b1dfd2962dbb94': {
    recipient: '0x9bc910f14b0ee83689b9f49337b1dfd2962dbb94',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0c03e7302168c4fc0f6a31b70d01e9acd30e5dd6ad95d0bb21c988df8997981a4080b9e90b77a0c27ca2554f1e64931753e96226653dece0583a4ce8159963dd1c'
  },
  '0x9c14ca538429e355a4a8548e24bb5714f549285d': {
    recipient: '0x9c14ca538429e355a4a8548e24bb5714f549285d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe606887bcfc0a6c21c09d1cddee31e986dbe88b2243612545955ba2298e3417225384534103fa0ac814cbc0e1d8a554a97c1176da035082e35953d0925a0efd21b'
  },
  '0x9cf2f15ec8626063bac5a6767bf4802a36ea572d': {
    recipient: '0x9cf2f15ec8626063bac5a6767bf4802a36ea572d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0ab1b72911e9b4c8897e0599c5619a576f60df506e023eb83ce56734ba04d7315d86b3b9b0ed33a04bdb983bd968034cfece9fce082b6020f3038acba945aafd1c'
  },
  '0x9d4407d7834d9a9a1b8f09d31ee6c108a5a98b91': {
    recipient: '0x9d4407d7834d9a9a1b8f09d31ee6c108a5a98b91',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xecc9f0f6de2e4e28750b68e7f042ba77a51481581f5b97b163f191d5704b887c6b09ed073db103460dec8c507914f233d3cbfa1038895669baf8ec6054dc799d1c'
  },
  '0x9e2b2be6416d21b63e0e748fcac4c75bb8927689': {
    recipient: '0x9e2b2be6416d21b63e0e748fcac4c75bb8927689',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xca72ebeae746d7b4f92528fe1c02a9ee2c6918bcc4c9e52e8569ed959ca991c3134d40bb8431eb35b4be24af03f7649a7b35d605e94276e798ba38efe3947d941c'
  },
  '0x9ee864cbeb71d3a9cd71779cd7b00d3439f555dd': {
    recipient: '0x9ee864cbeb71d3a9cd71779cd7b00d3439f555dd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfe3d21f9d8553381f740ee20da2bef217dedad5bd0ff19b9a0daf5fd906200e5119e1d4d8995f0ca759cc710275132804d45da130e1dc6a4a88de5123fa85a1d1b'
  },
  '0x9fef296d8dc2e5c3ceae453b88ea69632d7b4a5e': {
    recipient: '0x9fef296d8dc2e5c3ceae453b88ea69632d7b4a5e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x43351f858b94067e3dda4a4ede643218a1215498c7d4c99b6a93dae49984068d45e81c8f2664f9e2ef25be47d89fe8b0544ccf5d49653c1e38e1c4d7f66758f41c'
  },
  '0xa022f2bc32a4028b95cbbee917ef7ca69c755b61': {
    recipient: '0xa022f2bc32a4028b95cbbee917ef7ca69c755b61',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf0ffca77f6d8d9a33022bbfdbc4f4a2059f33a8e82bd13d9911590f8bc2cf90a0d624cf5b26b1b4596fb5b990f18c3b3acbaa7e2dfea6b146a0f003de4dd2cca1c'
  },
  '0xa07873b25689f1d7d4b91f1d5db9cd8c277c5e23': {
    recipient: '0xa07873b25689f1d7d4b91f1d5db9cd8c277c5e23',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x474d71b85d6bdc6fc021ee97a395282ca395cc1455071d3aa39c2922f4b6bf8d6b0a4c662bcbbc135e1b3a7c4c731b8b6ef81c3eabcb74eb07af72ce2c6951ee1b'
  },
  '0xa0a3829eafdb10f85711f92bfc433519ddc37e53': {
    recipient: '0xa0a3829eafdb10f85711f92bfc433519ddc37e53',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x100bb26dacad827906d350105668696778867220a757a7ea81d2a7a8b67eb3ba30a801b83d0e687738f1ad5c376b21ee3e0f0a1f86f85bace10c1a79be8204ee1c'
  },
  '0xa21c578f03d0fab1c8fac072876bd5ce650ac784': {
    recipient: '0xa21c578f03d0fab1c8fac072876bd5ce650ac784',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x077009b36411b726964d276cf7f4274ccfd7b1b015714587f7547523c5dfd43f4ed97f7a78a9df29030ccc1113da268f3f656e3b78a8ecbb4851ce2ad044b9561b'
  },
  '0xa33185f7c6c11ee80476fbdbe51f16d5119139c6': {
    recipient: '0xa33185f7c6c11ee80476fbdbe51f16d5119139c6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbcffa149f11d9e0348bf51f270af9fd088fc96a9b03bad2469397a14d12949090925c20fad84067eda5142fe7e663ec2d29b154929650fe08506a391112816ab1c'
  },
  '0xa3efbc30bea30b2778ebc32b66cf1e25d5c3aa6a': {
    recipient: '0xa3efbc30bea30b2778ebc32b66cf1e25d5c3aa6a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x78ed1c295d1e61fe661c2755d0b5c51318b97abb61d0b81e1f6e2ac9ca30c290708ca0281b56d61145f3d4225c2654cdca8bbdcfba795c8ec984ffdc16fd39af1b'
  },
  '0xa5430730f12f1128bf10dfba38c8e00bc4d90eea': {
    recipient: '0xa5430730f12f1128bf10dfba38c8e00bc4d90eea',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7862f66ad95b126c4ffe30bc8368e2860bffad560a4a0812768aba8600bf1d764097d2bc0cf6faff262a172d52de4b9857225b536798807293c229716e65246a1c'
  },
  '0xa609eb8b2da7ec150b0d2ad0955f54c50923d8e0': {
    recipient: '0xa609eb8b2da7ec150b0d2ad0955f54c50923d8e0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x055f2c8a979507966d8c8b8f4b8c7ab7d116db96764a07fb8780835734c68e711e80274bdb02b5a59633ab86f54071dac03655161dc89119c78480e7d102db6f1c'
  },
  '0xa83f6bec55a100ca3402245fc1d46127889354ec': {
    recipient: '0xa83f6bec55a100ca3402245fc1d46127889354ec',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x75fb9a3678bea87df86e3c74efa833e2550238d5e342ff0a5bcf72615e5504b30dd2305ca81f878c5aebd3c4a96aa9b991250676777e0c7c9894a8df5e6a50f91b'
  },
  '0xa9f6147f7b59d4980de9687dd7429fbbb9ebb398': {
    recipient: '0xa9f6147f7b59d4980de9687dd7429fbbb9ebb398',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe1c3e7952ac8adcee876cb674bf4e649a37a17c527c68b3b6d00e1ffbf7d3c1d01d47f8b65506f4a3ecbc67fc2b4425e62cceef6771aa26b19882e0d6717ebd51c'
  },
  '0xaca3d7d603d4669acadae2671af6d43b9d2485a0': {
    recipient: '0xaca3d7d603d4669acadae2671af6d43b9d2485a0',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf10bb173ce68debab7141b0f422b0eb085b7ff02aa97053b973863403e2754683e516b0d3ec8ae288339d312f185dc6515fe1f84dbd509edf628caabd76155ad1c'
  },
  '0xacfaf9ef6871cad4fdfa92d70468bb3387bd030d': {
    recipient: '0xacfaf9ef6871cad4fdfa92d70468bb3387bd030d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0e87314688346e5d3c3f88434815c7dbf07faf5d0a93951def8fc1a8a35947983d43d6d8b986204459c18fa560578d5fc7402561109b407fe0d927f0d410260b1b'
  },
  '0xacfe5b617b0bffa00e3113c84270ed49bedcdbc9': {
    recipient: '0xacfe5b617b0bffa00e3113c84270ed49bedcdbc9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa677804596e7dc2deb4b146c6c7b8d646b350189de557b64ad27c955350d3e7f134a82d7eef07fbae171025d7ab90027b89662528c70f56a456ccbd6f14d80ff1c'
  },
  '0xad097fdcd58535250c59807d6683e0a6b688d6cc': {
    recipient: '0xad097fdcd58535250c59807d6683e0a6b688d6cc',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0d109dc121ee018eb9078c07836747645472618bed14979bd3a152fafecad32a5fc70b62dd5f91068b0369a77b1fd1ad0178f3877e20e89aa7a63693ed75acd31c'
  },
  '0xaeacbd3c33ff651dc2a7762712c000566b5aee71': {
    recipient: '0xaeacbd3c33ff651dc2a7762712c000566b5aee71',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0ac36183af946e773037253a469edec44c0032bdc2c277f9778035ce62e86da92c85947efc0cbfb61748d1a8c0d4e23f545a67a62a7d268faf2a08b791ba8f8e1b'
  },
  '0xaec1011881aa517d8a6e26ad46553e614d86a01c': {
    recipient: '0xaec1011881aa517d8a6e26ad46553e614d86a01c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x3085383e20b390e5357e4cca4caa7b01bf1e3ac4a0f7469b29b640008a2c30933bb28ded655c601751d5698d2a6f634b13f78a49e823cb5dd7b581978b5266291c'
  },
  '0xafd8a5280361a9fabec2cca6756b35b9816ee470': {
    recipient: '0xafd8a5280361a9fabec2cca6756b35b9816ee470',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x93c7072ea4aabcc6bb8e31a76958b88086701b75f9d97f2e93fa3cf20a4895fc65c0e598ee1fd4d472d8e4cf5f8026a57371387313bad9df56fbc216755977871c'
  },
  '0xb008c63acc7404711c4ae03fcbd9be62c83fb6d9': {
    recipient: '0xb008c63acc7404711c4ae03fcbd9be62c83fb6d9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x10d055ffc71e13c7baa2df9035b6ad60c10e74c8c04f1bb719d68e48971039dc6e3c7a2715af217a2db43da2ee751e6787f50d6f8aeb415638ed1dcfa0cf38441b'
  },
  '0xb017a7b97974924d5e2138222b20ec71ff48a1ff': {
    recipient: '0xb017a7b97974924d5e2138222b20ec71ff48a1ff',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdfffac9c7c8e12c01283f1a3753409a12cf6dc32098c0c492db7fa586a0847bb1e264ab7a720c2aacae2063b46cc74175baee655ce22f67c73aed2994f9293f61b'
  },
  '0xb2a2a280044e64d054fecb84b1c696ef088cb151': {
    recipient: '0xb2a2a280044e64d054fecb84b1c696ef088cb151',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf6e20b10ed3eb49f922608515e08900e0b100c4ccf824cc23b8caf7c3ce7279d3af6cd4d89e9cc9658ae328fcd9b9be8fcb9cbcd3d30d2eab721a86b0465b7ff1b'
  },
  '0xb30d280be4eccc2216bc8903da4ef3cdc340c83f': {
    recipient: '0xb30d280be4eccc2216bc8903da4ef3cdc340c83f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x93b820866819525319573d0f51024ae87967c3318f5555959492f9fa1bf8bce72b00bc452b462b1d7bc1018d97e23d8a1bdd38ac181af3d1d36ca0506246e7231b'
  },
  '0xb38478b7f4a243e5aa2090ac8fd75bcd7ae391c5': {
    recipient: '0xb38478b7f4a243e5aa2090ac8fd75bcd7ae391c5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1d742057b79dc97261a7793f35c5380ee73f647e566c584d87ad172b8964b806504a9e9726accc63272b571976777cf4264dcb183e99d38c3d704bd707058cd91c'
  },
  '0xb3c6ae35693643eebd199003a0d433a17c6735bf': {
    recipient: '0xb3c6ae35693643eebd199003a0d433a17c6735bf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe4333bfd127fe5212c166c0937d6ca42ef34b40274d3f1907b8b9e1c9876f0dc6d20769b98ffb8f9247a1e36965b1e8d6b8453fd6e3aa1bfad2620d34a10a2af1b'
  },
  '0xb3c8acdc765b51e873d27d1b31702ccf4630c75b': {
    recipient: '0xb3c8acdc765b51e873d27d1b31702ccf4630c75b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb6fa6b34601127de2257f7b3df3b05e13e28d1d497caa9fa6f3419c009208920276127972c46016ff9c378e03283923132fdc94ef23751386e7279293b3835a31c'
  },
  '0xb448543e33443abd0bd8fd9576603ead9e8151b7': {
    recipient: '0xb448543e33443abd0bd8fd9576603ead9e8151b7',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4bd13d4f38d85cae4b2538b0cbc509cb631010d5a713228fbf9cb5bb9792ed1d47aa9629dcb4589f61a6ebe103b1fad278d693b27b18e2cede1e1ec6dc44533e1c'
  },
  '0xb67d58cffae63a95fb380b952e65b1a8eac44d0b': {
    recipient: '0xb67d58cffae63a95fb380b952e65b1a8eac44d0b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa9a1ba3b06c127e6d6540c2127ed6b605624c3db238c7447c9f5c75393a004d475b0600150994d1ef2aae8a36705a46f6fa61d4c6d1b6638b18e577f1898b3a71b'
  },
  '0xb7d3a787a39f25457ca511dc3f0591b546f5e02f': {
    recipient: '0xb7d3a787a39f25457ca511dc3f0591b546f5e02f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa308aa8d36d9a3269e035caab42a3734d47ec84ee3c436c9432aee87f99f52f46c96b0d1ff15073f454f47ebf8496e31b707f935665f10901d7be99a8dc5326b1c'
  },
  '0xb8a899f7368b67c7dfe3a0a4af6764c5b639ed7b': {
    recipient: '0xb8a899f7368b67c7dfe3a0a4af6764c5b639ed7b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4c5e6212cfc99fe78e29e31d8220899fd5c4fda9a3931e7b52fe4bbe1432cddc0337ac3a17dca3be2b83a15cb52322b186ea6d5f89cad443e06392fdca0c35c61c'
  },
  '0xbaf287cb2281841d9f5ba929d7dde87048fcaf1b': {
    recipient: '0xbaf287cb2281841d9f5ba929d7dde87048fcaf1b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x94795c47821ba9f33673142a559bae4a8a1c4f1c44ee0eec4adc9817cd4104020e95a88222bbe7e2a1a2215774ff8bcdc7aa352d172da0ae77f53a609fd6ee121b'
  },
  '0xbc43d3c51d497f619173c46efa906ea23fbd96bb': {
    recipient: '0xbc43d3c51d497f619173c46efa906ea23fbd96bb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1898df0d42cff83010775de7a548457c0c52a6e298976db1b71d6b18351b3e79436db59f4d44a6127b9b819487acc18092d4791cf0669b2345e78988d47a3c041b'
  },
  '0xbca819420c0610c74ebcd7bb39a3cddd55e53a6b': {
    recipient: '0xbca819420c0610c74ebcd7bb39a3cddd55e53a6b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbcac0182723812252c9cac0a7a74db384000a9008365e56c3598d85c9695bb7b32705cfcf311ec03aa7e53c7c5dd7b3e18caf45dad89ef2184cad05ff74ff8b01c'
  },
  '0xbcfd6a9bdcd8d5426bbc81201f667831ca87f692': {
    recipient: '0xbcfd6a9bdcd8d5426bbc81201f667831ca87f692',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf729d992b975caba503a3dbb51ffebaf610c1a9f95b2c47fa183754bc3995a14118d3f93b54c9bdffd0620ffe8dc8d878ae19068bca1b47b2413c12fcc56f7b51b'
  },
  '0xbe540ff17e5008c9698dc1ffc2abdfbbd47a57ba': {
    recipient: '0xbe540ff17e5008c9698dc1ffc2abdfbbd47a57ba',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb795cf37c4fde0ed0184efba18dc781bfca3234a3ae3272edcf5a84c1d0c4a1811b411195e3e63f0863b7349380386f10630eb7a21aae3359dacb40980a40f3b1b'
  },
  '0xbf48fdfbd1309c7c95f12a1f8b2bc3d5bfd22d14': {
    recipient: '0xbf48fdfbd1309c7c95f12a1f8b2bc3d5bfd22d14',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x20fd161eb3f2d70c0a8992d0b0f2f5e410332fe2ca46e5c0f38e02fe36778ba97867e3347021a1e2738cad2fe3fd3c9fa71e7a90fc7d21db756fe70dedb4ac091b'
  },
  '0xbf8afa76bcc2f7fee2f3b358571f426a698e5edd': {
    recipient: '0xbf8afa76bcc2f7fee2f3b358571f426a698e5edd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x44dd92843aadf981fca4535b983c5ce1379f3712e93659c23f3f2a88d1b425aa26898247547bcbdd5a0cbc3fe91af807262259ca625c63a9ba12beffd1e8007a1c'
  },
  '0xc0020dfa0fa86d7c60c75a421a5e32382736084c': {
    recipient: '0xc0020dfa0fa86d7c60c75a421a5e32382736084c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xae9ed50acd36aabce8d4071ba5e002e8fb251df6a5b25f33f488ad52933fbfeb7d4c4a3b079d49440d5da4d57ccbc13ad72f2f2c5965db4d77c5ab317683164c1c'
  },
  '0xc0ffee254729296a45a3885639ac7e10f9d54979': {
    recipient: '0xc0ffee254729296a45a3885639ac7e10f9d54979',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb4702a3ed61d952720841c8b957ba394dcfef3effc46c40c46957c4996a118711c55a6a8528fbaf377f6ce4efa7806c08a38eac3981f87260224f4a2071785151b'
  },
  '0xc218d847a18e521ae08f49f7c43882b6d1963c60': {
    recipient: '0xc218d847a18e521ae08f49f7c43882b6d1963c60',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x9967f69a1cade87f1ef43037890697b450f51b04e79f6f2e343c712b61c49b4c20033c1f687d5638d1495aab10e9aa516fc9772d3dbe9c847b879203d424765f1c'
  },
  '0xc2e5ab2f97e6d6f860a6abeafde7d4c53603d0bb': {
    recipient: '0xc2e5ab2f97e6d6f860a6abeafde7d4c53603d0bb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x293ad5b1e36a5a50aa52ae82f265069613d6153bb4a44300df546393d394e3d10ac561a54dbaf12a10373113c7a201c47345f3c95051113f59ee910f3cae31da1b'
  },
  '0xc35f3f92a9f27a157b309a9656cfea30e5c9cce3': {
    recipient: '0xc35f3f92a9f27a157b309a9656cfea30e5c9cce3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x89f89ac891f07bce65d0fb6474e031d826a7d9f4343c40a71a90878fb304c94f05b334fd7d4b9316734dc0ab9e4be9be723b1c9fd8f1a2caf9440d12dca424ee1b'
  },
  '0xc3c38bffd0cdb78e96a5e183d4b7348e16c773ba': {
    recipient: '0xc3c38bffd0cdb78e96a5e183d4b7348e16c773ba',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbef6fcd55b20bbe3bb187ba55ae3c9861a0519ebf15f2aa2aaea487cc5efe85a601a300d45ac73c6601c5c09ed3a5cd8604fca5deadd411cb7f7c082448c1a751b'
  },
  '0xc4c1a084c0b3685d4559b633035cadada321dfd9': {
    recipient: '0xc4c1a084c0b3685d4559b633035cadada321dfd9',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd6c7ea6f7a58e75a4c69ddf0fed0b14d0eca78ab3c048528047b06afeb8af9672bdddb7989e88284d86baeced82de7a6ac1097a57fa309ace5b0982d0ce9bd781b'
  },
  '0xc4db8889689318d29c8fd795b70c211ead369e3f': {
    recipient: '0xc4db8889689318d29c8fd795b70c211ead369e3f',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x44ed5c35d7b0b977f237110e50393f41c80bcecba9bd54a11d7fd4d7a69dc322416379871486eae64d185d6a596c4a341620cde3cd9e306e75b0b0e1724818dd1b'
  },
  '0xc553d91f846c6cb58c9293118cd1ac980d6fe827': {
    recipient: '0xc553d91f846c6cb58c9293118cd1ac980d6fe827',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x04bd997d156c3454072a370c6399db21049c0db9f02151605401d6e15b2b1a633cd67060cb1f7f7b2112aea3e559ae55a9aad3399b7978ce7fb5af767dff2e8f1c'
  },
  '0xc7cae017f3df44e26b36f4c25d48d3e4fe625ef4': {
    recipient: '0xc7cae017f3df44e26b36f4c25d48d3e4fe625ef4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf6eda456757b4612cb9f7c42eaa33e3c8ad900ac7e5706cd2d79e24262cec36603b48439a708b8107e93549f1a465b3b790d83574ffe9ad2e24f096f3dde69b21b'
  },
  '0xc87012e87e0464bd3d821a911da9e4fe777ed1ce': {
    recipient: '0xc87012e87e0464bd3d821a911da9e4fe777ed1ce',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x61ff2cf43eccb5559c1fc418a69b5747cb3e6406cd4c3c3e5d5d2d17fc833b4c6cf7ad06c71a26d952c62f86a0513274dcfe5eb8b876b76a14bac902eb6df14e1b'
  },
  '0xc8775e19bfe14d8246a91a82950f97becf086ef4': {
    recipient: '0xc8775e19bfe14d8246a91a82950f97becf086ef4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x74004f5cf8c3d44e4c5ffa34008340ffeaa609731404df5f6ded35cb2ae6f020512236d27d0763a8a6fd0fd881774eb36bba94c90bcac33b094fd541f0afca5b1b'
  },
  '0xc8d2d31be2ce818f380f8abd67e9579c9bae58de': {
    recipient: '0xc8d2d31be2ce818f380f8abd67e9579c9bae58de',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfd7a522402f5ca1f3dd8d73217c3faa2894873ebf03e6f712b92f0aa4f326a354dd80ce8be1f4b11003957e091bf925b459c49494f880a4d116447b92e7538481b'
  },
  '0xc9c85f8d374acb2fbfc42e038b270dce2cfaf74d': {
    recipient: '0xc9c85f8d374acb2fbfc42e038b270dce2cfaf74d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x13db6d1ad290e238ae49efad3f61d5f2d848497dc507ea4fc4a7463a4702360945d265261a37bb2ac02e1e36fdc11b93f7dd09e9beb6303af317bb62e7ebcb311c'
  },
  '0xc9de001145cb1e1d1752665f30115e14ae6ce910': {
    recipient: '0xc9de001145cb1e1d1752665f30115e14ae6ce910',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x911f09045910aa2606d13d18c1493a2bbd44e59ad60184629d31cba7d459967d749fd120df6a5fffc710ff503b28151b5f9e3827b2915f2799ae9f60a53369ba1c'
  },
  '0xcb4b6bd8271b4f5f81d46cbc563ae9e4f97b5a37': {
    recipient: '0xcb4b6bd8271b4f5f81d46cbc563ae9e4f97b5a37',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7c272f9d3a29b20b3fab3ea594786b56f93204af99eef08ed0e142594d223c4066493c044cba201a94ae092de827c1068dc84a8154d266118af1323b86c3e03f1b'
  },
  '0xccd6e06f66b328f9afde4730e5fe82dfe2f8c735': {
    recipient: '0xccd6e06f66b328f9afde4730e5fe82dfe2f8c735',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xfcccd0d0d2b35c55219b00293b9eb75ea6ae86501b715688bacb6b695ed45c8a3a96f09ad5b6002c5176503712927c0de6c0e381af0c01d7ac0e999b54f6a6c01b'
  },
  '0xcd824e7e3f7400b4ceb307c34b9c1f560958feba': {
    recipient: '0xcd824e7e3f7400b4ceb307c34b9c1f560958feba',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd39e280ade50ecbcd97fb8a65c518ea57ab38ed56b93a8a8121b2c6732d5b1485141cc5d37f31925090fa74aa5b8275cfb678629751cd253808872ba21b3e1c81b'
  },
  '0xcf16b76c63cdc39eefe595612450f726e59c797d': {
    recipient: '0xcf16b76c63cdc39eefe595612450f726e59c797d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbad9f39f5e27ae289ffb2dfd019018b549953ea5c8b02d53ae070ffbdc0770e3255d5b6effb615dcd02af7121922dcb14cf1f5c329eb85fb208e00880b537f661c'
  },
  '0xd05c555f61b4c4ee44e0104c007b86186cec19b4': {
    recipient: '0xd05c555f61b4c4ee44e0104c007b86186cec19b4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xad6527363656575f6da16677318552086ea5fdb8f62efdc3c97df1458bf688cd072e558f836e1e42a363460862892d6336f5a8db056ac046d8a6e96d9f5aa86e1c'
  },
  '0xd09ef6a875c1de026ca3da8b14ceac3f1881351c': {
    recipient: '0xd09ef6a875c1de026ca3da8b14ceac3f1881351c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x835c03669a34f25e4faf31d43d14d4757011a1e2e63d0350e56173bbeda537ba29a86c168dabe7a807de2004aab9b31f99bfe2f80d60838f1fcaaa47bdbd4e471c'
  },
  '0xd0b9f86b7e66b8b1b4fe5bbc9857cb6685af5ee4': {
    recipient: '0xd0b9f86b7e66b8b1b4fe5bbc9857cb6685af5ee4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc717109f073187766d8d10ee9b7065557a53dff5d9b7fbaaeda856ff1dddbb9158ab3538d4e4c05a620fe38ac293f26af54f37d979758c1eef01a5bffed02ace1c'
  },
  '0xd0f595f2590aa6f6b059154964edb91ca5425215': {
    recipient: '0xd0f595f2590aa6f6b059154964edb91ca5425215',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x7e1bee2f9bc59d13baee6ffb82cbf730e66a8774dace2fc88dd646b114f7d13355982ef2908c477283b7839b54c660509e0b5b7c4d0056038c92650aad8870841c'
  },
  '0xd7b9d5ea5440b6e585699960d19170dc084de343': {
    recipient: '0xd7b9d5ea5440b6e585699960d19170dc084de343',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x443e92c7daaf9e1d30b54c2a63e1fbddfd111094d997d5043056c4b5c06069566d99248bd3ebca0b50babe86d67d660ca9373464e270dfe56e0d541323345d0a1c'
  },
  '0xd84885a6ce4ee45e7948b6d7b2da6ba3338bc4b8': {
    recipient: '0xd84885a6ce4ee45e7948b6d7b2da6ba3338bc4b8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x8c576df88e4795ca715305f2793948f3423de584125b98b20d4778cdb4cff7e151ec9bf6a2d339338d19b266c9254423af1391ce793ecdda723d81894fc21cfb1c'
  },
  '0xd94d73cd02c7beb342a0e90e697ebfe95bdc3df8': {
    recipient: '0xd94d73cd02c7beb342a0e90e697ebfe95bdc3df8',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xedf0d5d6f84fd2deab49b6f51b9c361a343fbe719e501c26c964ab692827552e7fdea27759109a4c490dfbfa70c09d6ab09ab55b3864211fe3f1a154e03ed6671b'
  },
  '0xd9cf0b6cae424b001498ab249aaf42fb0aeb09c4': {
    recipient: '0xd9cf0b6cae424b001498ab249aaf42fb0aeb09c4',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1bff015e36b1603f31dcf38d13ac52305c015cc1c6400074d26fc1f24c212260711dbcdb78f1cae7f275607f29821dfd4ca04da6bd7826f3d1cdb7e83439ba7b1b'
  },
  '0xda21780db3e5584c536cf7eb5d80a5cdc08b8efe': {
    recipient: '0xda21780db3e5584c536cf7eb5d80a5cdc08b8efe',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x85067f04700d2b5afe6de60391f408add3ed314204cc052263706673352131ad07a641388b72af5e60e956af241fe7e92a601e4bb9b1ef5aaaa506becc0440821c'
  },
  '0xdae5b69b40180a6d74fcd6f473801d5b2093fad3': {
    recipient: '0xdae5b69b40180a6d74fcd6f473801d5b2093fad3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x04dad89d1011073a8f1c62782a71cfa3939a80e32242f9adc03ee1946418d769366f27465f8d7983188e41ce609c318ee6bb7e3080f1511e316f5464a0906ac21b'
  },
  '0xdb694f901bebf8be79a85a01f510796ca004ab72': {
    recipient: '0xdb694f901bebf8be79a85a01f510796ca004ab72',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x11339f60a2682157e0b6759f7a8f669b43449630970e3f02b75d6bba2914dd64738d31812bad9e0703270d62e3e05273d6c97783f4a4a4cfe5f02715ae13d0061c'
  },
  '0xdc190d7cea8e8bf0b381617062200c10cfff0a91': {
    recipient: '0xdc190d7cea8e8bf0b381617062200c10cfff0a91',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa750c6d809cb5ef9a506ec0c74fede7e6b844594470c3acecfd03eedf4bd409f06458e743539649b75961e1ccf4a5798d316be390d479bd90e28d4feee9f49771b'
  },
  '0xdd1bac9100d61f1d21be0dd289b7406f3a216608': {
    recipient: '0xdd1bac9100d61f1d21be0dd289b7406f3a216608',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x65d2935ae44f3cf222938086eba78148f0544c764aa535531596729140fb3cb168ef80cd06d2f178f9dabef809f06d22ad07e03ed83c5b28a063a9fcd384ac161c'
  },
  '0xdd90663c9310a5750aa4abb2bdf66a47281b6b43': {
    recipient: '0xdd90663c9310a5750aa4abb2bdf66a47281b6b43',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xd3461035e1330809aceefe837902ac7bcc45652445581bf8b22b82534fbfb20a7fafde498f8f71b4d191aaf8e4d0f29af07d37834ea9653ec6d9460613025fe81b'
  },
  '0xde9ec42be7213f3751d39b62c35e8ae2957a8fe1': {
    recipient: '0xde9ec42be7213f3751d39b62c35e8ae2957a8fe1',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x37204caff172dfbd469fcbce2a230dd5933739a238bae26b9b45093e0343435d5029f8ebc550aaa999390d763924b327cfa8b40514c2bc34afe354d075ff5e371c'
  },
  '0xded1eae95396b2f7b6520a84c69c76e228171157': {
    recipient: '0xded1eae95396b2f7b6520a84c69c76e228171157',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x680c7a673a6c4c730ecbc06e1c28467b5325692cdf59f5ef4aa5c0b24ecbd3b745938a763beaa61a0c542f25ac1c4f18fd03bdcd60e0c486e7bda505e1b41ba61c'
  },
  '0xded385138c8f610c0f8cb5b12b326ddf34872b51': {
    recipient: '0xded385138c8f610c0f8cb5b12b326ddf34872b51',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x05963243e57caf487c5cdbef8d2dca74156ab602dbd8dc3c1d32a197c15229d24304e15d6ebf1e4631442b7025d7daea7a451a45594201e8d8142759a9c845971c'
  },
  '0xded74f03749046916ae5560f94ef4365a448ff98': {
    recipient: '0xded74f03749046916ae5560f94ef4365a448ff98',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6af641b23927b38c708b6ed6cc12c41ec27c0c48103b5da0976f080cb2f159eb395e04eaf18fbf6cb6bc35fbaff50501e40b23d972a7ae5663372deff2c47b221c'
  },
  '0xded9ed1ffaf536372896971fd5c4379d8edfaa4a': {
    recipient: '0xded9ed1ffaf536372896971fd5c4379d8edfaa4a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1e4074494ce1f367f6640640a87261be1940dd962da5d226dd3a9062da4bab59111348db4c3059dec8cb680b2a6bc7e8cedaf78989092bd2efc21a43bd8bc0f71b'
  },
  '0xdeda043c35474e668e5d8701b1f03313b9654182': {
    recipient: '0xdeda043c35474e668e5d8701b1f03313b9654182',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xcb819840c42001c56ad1d1b5d75467c5f590e7a9112f6c3034f810e8420fb7450e27fa3e50bfc08c9c8c54028cdba98d28f5033875d8e0ea663195da89fe5eef1b'
  },
  '0xdedfc8614ff22e0a2e14cfc6dfd48ebbdc8d1592': {
    recipient: '0xdedfc8614ff22e0a2e14cfc6dfd48ebbdc8d1592',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x41584975148de5188bc6d8c0b668dec16d036b5a0f17f26369a1e872a57c76193236d8be54061c6afb905552d3fbc305cbe74537217e81d36616509efc66fb251b'
  },
  '0xdee79ff0afeeb0bcb30e612bc038eac8d4c1de31': {
    recipient: '0xdee79ff0afeeb0bcb30e612bc038eac8d4c1de31',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x08e13d6fa8243be8a3938b869ffb00bf4fe70141c1ad8eff82b22c34c1e35ddf41a421b53747734a66e3639b833aa3a6fce7f325d220499da7cb42d8436de96b1b'
  },
  '0xdf40018da6249a7008ca08933f1327a5804781f5': {
    recipient: '0xdf40018da6249a7008ca08933f1327a5804781f5',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdca5663bce15121cc4287a6b09f902df918714459c5db957c707be7cf182887c6b8002d7cfb1038825f8872f6d34daf3acfb5bed169ad6db360dd476a578aa371b'
  },
  '0xdfaf4129aa84a9fd51a25599add35b35c55a503c': {
    recipient: '0xdfaf4129aa84a9fd51a25599add35b35c55a503c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x9d3a9f08a46db50bd7af652c85daa12e8df95e2888cd96f03e99cacbee3e05f8207d33e5d03c636f244b6de4cd08896cb5f3af79b55ad28096e7fce4f489db3a1c'
  },
  '0xe2299aaafe469875225ab5668a8b395b2787ca54': {
    recipient: '0xe2299aaafe469875225ab5668a8b395b2787ca54',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x66927c6555e5cf9b9e5f8a85d94f2dc16ec7970d4695d29f8a77c72251c636746b0e827f4e540519136517301e37cda92bb7ceba861731394ecfe16cc8c01d051b'
  },
  '0xe24d193345c180a5ba16f5d8d5ca27c8d8e04306': {
    recipient: '0xe24d193345c180a5ba16f5d8d5ca27c8d8e04306',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xaae8f43b9e4d2f653eb7b4b944b4f8b170e8f737fec413bf960e11109e1f7867668a727a378041049331c3d4bf95bb8a45ea81729e6054c170b08dd9a83ec7ac1b'
  },
  '0xe254819fc30320d14eefcff882544bf4971a3778': {
    recipient: '0xe254819fc30320d14eefcff882544bf4971a3778',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xc1f3cdd22b5b3a259cc115e5a03bd3872891f4000d5d093505188f2af013bd4063dd0f17044343cf115201bccddec858759947ff26d894637cd0d3db39354bfc1c'
  },
  '0xe2c16dc2610ff536bb0eb8f50771d3a65f8029bf': {
    recipient: '0xe2c16dc2610ff536bb0eb8f50771d3a65f8029bf',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2befb46fcced1aeecade74e1228cfcc0e366c40668f1d364e039a52c2584cd5e7957d09a677e020a010e67fe0c7afbdfad66fd0b196252bba95108eab62c9a091c'
  },
  '0xe2cbfb4f5eb3aa7b1b0589c915f2de54e14015d6': {
    recipient: '0xe2cbfb4f5eb3aa7b1b0589c915f2de54e14015d6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xdc533d259e88fc2f782de1a368e237d3e0246400cbfd8303aeb72a151af3455e1fbae1c63801bca142b9209e8ff2c4c633089293d66643c7368668b01e92c9261c'
  },
  '0xe3a5e05ba5aee8f19c1626dec9ce7ff8e1267230': {
    recipient: '0xe3a5e05ba5aee8f19c1626dec9ce7ff8e1267230',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xbf206d9cbc6f2441aa7e810d6631f40d9ad6a8a2ade333af7cd00c441cb16a2c65f33b74fc30df19a001d6161457192e03dbbe7417de23dab8c25c6713941c231b'
  },
  '0xe3a8ce6195b17685921bcfbfd538b9372faa7c4a': {
    recipient: '0xe3a8ce6195b17685921bcfbfd538b9372faa7c4a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x234eed978ee8c6efc27068b93f6b0a15cfb68145e998d201ab163df975d73698105aaccadb0218ccf9e1712ef0c07e4830523ce2f4f8d9333d8d7aa4638dad331c'
  },
  '0xe4e08dfe8227a07dd38b8e7fb92c2168b5e9e864': {
    recipient: '0xe4e08dfe8227a07dd38b8e7fb92c2168b5e9e864',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe7188111a27101ea67001182ff941146dd15e99e87fd973d0e052076b8a3df220ea2b762f2860b3d9290ac181d89d404e83044ac4a46e84ca253270c863624a11b'
  },
  '0xe579cb4431b92f64a6e02fa4ca9e5091049b8392': {
    recipient: '0xe579cb4431b92f64a6e02fa4ca9e5091049b8392',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa6f076278c94f47c185009c7f71bce3f50ec38a45c1a290b687532d99260b4fc781d0d7739eaa567394ccdd8e38cfbd4bdc90ab23e62373c280891da25a7ed621b'
  },
  '0xe6330a0f39b34efbaff9dcdfe02ef8cc2ede1a39': {
    recipient: '0xe6330a0f39b34efbaff9dcdfe02ef8cc2ede1a39',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x670931d44fe342c069fd45cbf44676b072263cb463145b434fa2232e6e9301e17bbff14f67b43a0d0abadf820c993d84e1c39199494f0a1c2840f6f30a8068321c'
  },
  '0xe8050bb4811d73ce52141d8fc9306a2c17636b36': {
    recipient: '0xe8050bb4811d73ce52141d8fc9306a2c17636b36',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x572ae446a0dee1e275180303ae03944528ae60ca60ff3da833fb70cd19c1cb7f7bd70b34ece03994f6039befd9b2fb1785abb3680fa7381c3917714930798b2b1c'
  },
  '0xe9ef7dba12ab73a28bb8f539b8f3b950c40f725e': {
    recipient: '0xe9ef7dba12ab73a28bb8f539b8f3b950c40f725e',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x09926325dc51a3ac466d2bc0fcc60253d96e6aff62a01b46f0de37db8fbaf69c05f26e47404920fe92413075b19eb64b755e22da32db4823c870c5c30b3b44731c'
  },
  '0xeb1b12a158ec47167038ecd293c51699c656f42b': {
    recipient: '0xeb1b12a158ec47167038ecd293c51699c656f42b',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x2b7342f5acf9a250cdf5c14435ea346e95991e83779e919f33bb118352c9dfe25206e0f1a2653a3301b970b21afb968504956357673499984d39c60b636d47181c'
  },
  '0xebe569dda9e1bd34e181abf32368fbe5c9b4f74c': {
    recipient: '0xebe569dda9e1bd34e181abf32368fbe5c9b4f74c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x98b86dbe3a28a6f968c0bf26df038750bca1947e4ceb6a9f3ec220b3879954a2553041beedc8339fdad5d0d8a8e4de6b0a51764869ee5f7cba0b0f83f33b43101b'
  },
  '0xec5a39ad9006af45b323ddaa245a14327c6319cd': {
    recipient: '0xec5a39ad9006af45b323ddaa245a14327c6319cd',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb6e8dab3377296d59555268ecde738ec0650e45392170873cbb9a1aa0a6079af65a919c79cc1f7a1c99b9e42599c573b5322228a9df8765125cccd8391f7f6da1c'
  },
  '0xed0d4aaedb89ea4f62eb16e4033f05acc359f938': {
    recipient: '0xed0d4aaedb89ea4f62eb16e4033f05acc359f938',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xb7df4f9021b498fe74844d167ea7097545d6e8ed1684bca6b0fecc61be15a90c479552f584d4b9750ae3e1abc2d12c32862b759caf95d692697341f354c84a091c'
  },
  '0xee407d2bca91e83cdaa0a29e4ffbace5c2b0bf4a': {
    recipient: '0xee407d2bca91e83cdaa0a29e4ffbace5c2b0bf4a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xa394b72d7c6a8d28afdba088f4c8bf0d38a61ba9d2f8e820a0907c2f543b6efc29f7a2cef14a89d8e0dca173342d32dac8311782fd17c7e06ec6503a527cbeda1b'
  },
  '0xf16b11b50d64d9b65ad8065964f7d68193df285d': {
    recipient: '0xf16b11b50d64d9b65ad8065964f7d68193df285d',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1f580fe124516210a3bee6be5b19f70ab197edb9f5c0a7389009e7f927220d127e03d680f5a853dfa7bf6ddc66969921757b6f6403c2fb312f17a77569b619361c'
  },
  '0xf2de13954cc05c91d4058db09e94100006bf5c9c': {
    recipient: '0xf2de13954cc05c91d4058db09e94100006bf5c9c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x1a8f353ec61b9563f10e0bf9050f9cc9c9694a98a251ebc578819bfa977aaf6a1dcbc762406a2b9030f23aa3a5613d6610fe60fccb2df281d2d6ad56c22c49311b'
  },
  '0xf5a7124b6a06914e37a7d406663b375f1e0da2eb': {
    recipient: '0xf5a7124b6a06914e37a7d406663b375f1e0da2eb',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x4c4f347067c4345d10a03560a6dda4fe1f7b74a87fa82d96293e02ab4529f9db4877f70620c5cae1008b18ae2c8f715ca8a6393e2d4ef748bc077f62f8cb75d41b'
  },
  '0xf65a0a2247866a75ad1927752fa7e33f9e7bdbad': {
    recipient: '0xf65a0a2247866a75ad1927752fa7e33f9e7bdbad',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x51196214a10647c65a3f5bd40e49b234328254d26fd2ea34947a7d75d5b9547e7a0ac92e3928f9a03b6c075673f58045a181704d1bdd732a4139b66f5745bbbc1c'
  },
  '0xf741c106f0fa67f4858f134da5cb351e10f6d552': {
    recipient: '0xf741c106f0fa67f4858f134da5cb351e10f6d552',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x10b2e7edc48b090f873de41318f790c2cf4b67a5b2017b32463b2e0e5115b0325c2eb0933f1265174cbbe1163a8fb3c588d8fd4e130da87c3caa0d4d5b140a821b'
  },
  '0xf7a3cbd7d9f61fdff344cd9ab598bbd8e133136a': {
    recipient: '0xf7a3cbd7d9f61fdff344cd9ab598bbd8e133136a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x211d488ab14c7f6444870f99a3f96995a8febaf60c6cd8813b27d22fc9ff53181f3f5bc4e70bf7beba065f9f4c896ac7ed8bfa188cec1c98aeb43af08fc2c7e81c'
  },
  '0xf9e693eac4b1365eb16c0bc8a3edb0ec64b30be6': {
    recipient: '0xf9e693eac4b1365eb16c0bc8a3edb0ec64b30be6',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6507ba396f62cf3f2ccfbca89b7ebe45ed118abb910d8cba8d401db23956e676716d5298550594cc81bdb3e567f89da7e7dc181db2324e403a39eacfd58d8a8f1b'
  },
  '0xfa4f3e47db696035142f0bd6b5e013c295177734': {
    recipient: '0xfa4f3e47db696035142f0bd6b5e013c295177734',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xf4623a82d2d280795236653c661803d1c67610ae6f77246ca56b85767a54700e07bdb59c3a97016f5d9e2009826c430bca16e18e7b70b1f7a43eb97b21759de51b'
  },
  '0xfa6f3ff3a9b988cb795c764311bb3ec6912fa50a': {
    recipient: '0xfa6f3ff3a9b988cb795c764311bb3ec6912fa50a',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x0a9f08cd7d1816a4e986de144c47a1ae555b8ef33d535c616dc7e13844e64e3d3d9a3ea63432ef8678820698ca060950381db18f8b067ed3047836662370fa681b'
  },
  '0xfbd9aeda1c246c234787e603934d7b79a3719cc3': {
    recipient: '0xfbd9aeda1c246c234787e603934d7b79a3719cc3',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6abcf04baaad6976fd215d90b6315ebc00fc44c574ae1559cba5cc7c63b379086b4860e31fd546d21d13ac122c94fd09224359850c963353237d5b50123ee3c51b'
  },
  '0xfd5a23499eb4c7682b6420b789a2610839ed8d05': {
    recipient: '0xfd5a23499eb4c7682b6420b789a2610839ed8d05',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0xe1c9382c33e582fa0a3fd006af018996c55d5a5c505ca869ba38b6654eb0cf725152d56c34901e82a5977f85c0fbab96cada460ac3f31ca16ccd6a549cbefaae1c'
  },
  '0xff46b61261495d3f263eea4b19221a37c739fb7c': {
    recipient: '0xff46b61261495d3f263eea4b19221a37c739fb7c',
    domain: 'HappyWormNoises',
    token: '0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce',
    amount: '2900000000000000000000',
    nonce: '2',
    signature: '0x6f9bf589478026c799277116bcb4d9f87b7bec3230c811b0efb92c9154d65b7a47abbafa5b52901ef7ae13b104c6dd5e15d8d8abab6171fb79dadc92ffbceeb11c'
  }
};

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      web3: null,
      accounts: null,
      contract: null,
      dollars: null,
      billiondollardrop: null,
      flashbond: null,
      daiContract: null,
      fcmContract: null,

      loading: false,
      
      currentTokenId: 0,

      pixels: [],
      pixelIds: [],
      colors: [],
      ownerAlts: [],
      renterAlts: [],
      rentalPricePerTimeUnit: 0,

      nextPixelPrice: 0,
      maximumCreationPrice: 0,
      ownerOfSelectedPixel: null,
      selectedPixel: null,
      selectedPixelName: null,
      selectedPixelEventHistoryData: null,
      selectedPixelIsRentable: null,
      header: "BillionDollarDapp",

      isAboutOpen: false,
      isCausesOpen: false,
      isMuseumOpen: false,
      isCreateOpen: false,
      isMetamaskOpen: false,
      isDetailsOpen: false,
      isUpdateOpen: false,
      isSellForDollarsOpen: false,
      isBuyForDollarsOpen: false,
      isBuyDollarsOpen: false,
      isPreOwnedPixelOpen: false,
      isRentOpen: false,
      isNetWorthOpen: false,
      isAirdropOpen: false,
      isZoraModalOpen: false,
      isFlashBondModalOpen: false,

      formColor: null,
      formTimeUnits: null,
      formAltText: null,

      dollarsAllowance: null,
      dollarsApprovedOnBdd: null,
      dollarsLoading: false,
      dollarsBalance: 0,
      dollarsTotalSupply: 0,

      numberOfBonds: 1,

      pixelBalance: 0,
      pixelsStaked: 0,

      accountDaiBalance: 0,
      accountFcmBalance: 0,
      dollarsDaiBalance: 0,
      dollarsFcmBalance: 0,

      museumModeActive: false,
      museumModeSelectedTime: 1000 * 10,
      museumModeGridTime: 1000 * 5,
      guerrillaModeActive: false,
      CSSStyleOverride: "",
      featuredPixelQueue: [],
      featuredPixelQueueFrontIndex: 0,
      featuredPixelQueueBackIndex: 0,
      featuredPixelQueueLength: 0,

      platform: {
        name: "BillionDollarDapp",
        opensea_link:"https://opensea.io/",
        opensea_collection_link:"https://opensea.io/collection/billiondollardapp",
        opensea_asset_base_link:"https://opensea.io/assets/",
        etherscan_link:"https://etherscan.io/address/",
        etherscan_token_link:"https://etherscan.io/token/",
        etherscan_tx_link:"https://etherscan.io/tx/",
        etherscan_block_link:"https://etherscan.io/block/",
        etherscan_token_query_after_contract: "?a=",
        rainbow_link: "https://rainbow.me/",
        rainbow_suffix: "?family=billiondollardapp",
      },
    };

    // Bind Functions
    this.setSelectedPixel = this.setSelectedPixel.bind(this);
    this.resetSelectedPixel = this.resetSelectedPixel.bind(this);

    this.setSelectedPixelName = this.setSelectedPixelName.bind(this);

    this.openZoraModal = this.openZoraModal.bind(this);
    this.closeZoraModal = this.closeZoraModal.bind(this);
  }

  componentDidMount = async () => {
    await this.loadWeb3() // Try to get web3 or show MetaMask modal
  }

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else if(!this.state.triedWeb3Modal) {
      this.setState({triedWeb3Modal:true})
        // Start Web3Modal
        const providerOptions = {
          walletconnect: {
            display: {
              name: "Mobile"
            },
            package: WalletConnectProvider,
            options: {
              infuraId: "779fa948393b48408c88eb528ca38430", // required
              qrcodeModalOptions: {
                mobileLinks: [
                  "rainbow",
                  "metamask",
                  "argent",
                  "trust",
                  "imtoken",
                  "pillar",
                ],
              }
            }
          }
        };

       const web3Modal = new Web3Modal({
         //network: "mainnet", // optional
         cacheProvider: false, // optional
         providerOptions: providerOptions, // required
       });

       const provider = await web3Modal.connect();
       window.ethereum = provider;
       window.web3 = new Web3(provider);
       // End web3Modal
    } else {
      this.openMetamaskModal();

      //window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
      console.log("loadWeb3 - no window.ethereum, no window.web3, else: this.openMetamaskModal()")
      this.setState({loading: false});
    }

    if(window.web3) {
      await this.setupAccountsAndContract(); // Try to setup contract.

      // Lets watch for account changes
      window.ethereum.on('accountsChanged', accounts => {
        // Time to reload your interface with accounts[0]!
        this.setState({accounts, account: accounts[0]})
        window.location.reload();
      })

      window.ethereum.on('chainChanged', function (networkId) {
        // Time to reload your interface with the new networkId
        window.location.reload();
      })

      // Subscribe to provider connection
      window.ethereum.on("connect", (info: { chainId: number }) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      window.ethereum.on("disconnect", (error: { code: number; message: string }) => {
        console.log(error);
      });
    } else {
    }
  }

  setupAccountsAndContract = async () => {
    // Load account
    const web3 = window.web3

    this.setState({loading: true});

    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();

    // Get the contract instance.
    const networkId = await web3.eth.net.getId();

    const deployedNetwork = Contract.networks[networkId];
    if(deployedNetwork) {
      const instance = new web3.eth.Contract(
        Contract.abi,
        deployedNetwork && deployedNetwork.address,
      );

      this.setState({ web3, accounts, contract: instance, networkId });

      // Setup event listeners

      instance.events.PixelCreated({}, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          console.log("Log data: " + data);
          this.loadBlockchainData();
        }});
  
        instance.events.PixelOwnerChangedColor({}, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          console.log("Log data: " + data);
          this.loadBlockchainData();
          //this.continueMuseumModeWithPixel(data.returnValues.pixelId);
          this.enqueueFeaturedPixelQueue(data.returnValues.pixelId);
        }});
  
        instance.events.PixelOwnerChangedAlt({}, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          console.log("Log data: " + data);
          this.loadBlockchainData();
          //this.continueMuseumModeWithPixel(data.returnValues.pixelId);
          this.enqueueFeaturedPixelQueue(data.returnValues.pixelId);
        }});
  
        instance.events.PixelRented({}, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          console.log("Log data: " + data);
          this.loadBlockchainData();
          //this.continueMuseumModeWithPixel(data.returnValues.pixelId);
          this.enqueueFeaturedPixelQueue(data.returnValues.pixelId);
        }});

      // Setup DOLLARS Contract

      const dollarsDeployedNetwork = Dollars.networks[networkId];
      if(dollarsDeployedNetwork) {
        const dollarsInstance = new web3.eth.Contract(
          Dollars.abi,
          dollarsDeployedNetwork && dollarsDeployedNetwork.address,
        );

        this.setState({ dollars: dollarsInstance });

        // Setup DOLLARS event listeners

        dollarsInstance.events.NFTConvertedToDollars({}, (error, data) => {
          if (error) {
            console.log("Error: " + error);
          } else {
            console.log("Log data: " + data);
            this.loadBlockchainData();
          }});
  
        dollarsInstance.events.DollarsConvertedToNFT({}, (error, data) => {
          if (error) {
            console.log("Error: " + error);
          } else {
            console.log("Log data: " + data);
            this.loadBlockchainData();
          }});

        dollarsInstance.events.Transfer({}, (error, data) => {
          if (error) {
            console.log("Error: " + error);
          } else {
            console.log("Log data: " + data);
            this.loadBlockchainData();
          }});
      }
        // Setup BillionDollarDrop Contract

      const dropDeployedNetwork = BillionDollarDrop.networks[networkId];
      if(dropDeployedNetwork) {
        const dropInstance = new web3.eth.Contract(
          BillionDollarDrop.abi,
          dropDeployedNetwork && dropDeployedNetwork.address,
        );

        this.setState({ billiondollardrop: dropInstance });

        // Drop event listeners

        dropInstance.events.RecipientClaimedErc20({}, (error, data) => {
          if (error) {
            console.log("Error: " + error);
          } else {
            console.log("Log data: " + data);
            this.loadBlockchainData();
          }});
      }

      // Setup FlashBond Contract

      const flashbondDeployedNetwork = FlashBond.networks[networkId];
      if(flashbondDeployedNetwork) {
        const flashBondInstance = new web3.eth.Contract(
          FlashBond.abi,
          flashbondDeployedNetwork && flashbondDeployedNetwork.address,
        );

        this.setState({ flashbond: flashBondInstance });
        // Drop event listeners
      }

      this.setState({loading: true, loadingDescription: "loading pixels"});
      await this.loadBlockchainData();

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      
      // Reset State Varialbes if Network is Test Net
      if(networkId === 4) {
        this.setState(prevState => ({
            platform: {                   // object that we want to update
                ...prevState.platform,    // keep all other key-value pairs
                opensea_link:"https://testnets.opensea.io/",
                opensea_collection_link:"https://testnets.opensea.io/collection/billiondollardapp-pixel-v4",
                opensea_asset_base_link:"https://testnets.opensea.io/assets/",
                etherscan_link:"https://rinkeby.etherscan.io/address/",
                etherscan_token_link:"https://rinkeby.etherscan.io/token/",
                etherscan_tx_link:"https://rinkeby.etherscan.io/tx/",
                etherscan_block_link:"https://rinkeby.etherscan.io/block/",
            }
        }));
      }
    } else { // if not deployedNetwork
       //window.alert('Contract not deployed to detected network.')
       console.log("setupAccountsAndContract - if not deployed network: this.openChainModal()")
       this.setState({loading:false, loadingDescription: ""});
       this.openMetamaskModal();
     }
   }

  
  loadBlockchainData = async () => {    
    //const account = this.state.accounts[0];
    const contract = this.state.contract;

    let pixels = []
    let pixelIds = []
    let mycolors = []
    let ownerAlts = []
    let renterAlts = []
    let pixelHashes = []
    let rentalRevenue = []
    let currentTokenId = null;
    let nextPixelPrice = null;
    let maximumCreationPrice = null;
    let allPixelsRentalRevenue = 0;
    let allPixelsRentalRevenueEth = "";
    let rentalCommissionPercent = 0;

    if(this.state.web3) {
      
      // Get DOLLARS State
      if(this.state.dollars) {
        let dollarsApprovedOnBdd = await contract.methods.isApprovedForAll(this.state.accounts[0], this.state.dollars.options.address).call()
        let dollarsAllowance = await this.state.dollars.methods.allowance(this.state.accounts[0], this.state.accounts[0]).call()
        let dollarsTotalSupply = await this.state.dollars.methods.totalSupply().call()
        dollarsTotalSupply = (dollarsTotalSupply / 10 ** 18)
        let dollarsBalance = await this.state.dollars.methods.balanceOf(this.state.accounts[0]).call()
        dollarsBalance = (dollarsBalance / 10 ** 18)
        let pixelsStaked = await this.state.contract.methods.balanceOf(this.state.dollars.options.address).call()

        //let daiAddress = await this.state.dollars.methods.DAI.call()
        //let fcmAddress = await this.state.dollars.methods.FCM.call()
        let daiAddress, fcmAddress;
        if(this.state.networkId === 1) {
          daiAddress = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
          fcmAddress = "0xcA5228D1fe52D22db85E02CA305cddD9E573D752";
        } else {
          daiAddress = "0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa";
          fcmAddress = "0x5eD8BD53B0c3fa3dEaBd345430B1A3a6A4e8BD7C";
        }

        let daiContract = new this.state.web3.eth.Contract(minABI,daiAddress);
        let fcmContract = new this.state.web3.eth.Contract(minABI,fcmAddress);

        let accountDaiBalance = await daiContract.methods.balanceOf(this.state.accounts[0]).call();
        let accountFcmBalance = await fcmContract.methods.balanceOf(this.state.accounts[0]).call();

        let dollarsDaiBalance = await daiContract.methods.balanceOf(this.state.dollars.options.address).call();
        let dollarsFcmBalance = await fcmContract.methods.balanceOf(this.state.dollars.options.address).call();

        let dollarsDaiAllowance = await daiContract.methods.allowance(this.state.accounts[0], this.state.dollars.options.address).call()
        let dollarsFcmAllowance = await fcmContract.methods.allowance(this.state.accounts[0], this.state.dollars.options.address).call()

        accountDaiBalance = (accountDaiBalance / 10 ** 18)
        accountFcmBalance = (accountFcmBalance / 10 ** 18)
        dollarsDaiBalance = (dollarsDaiBalance / 10 ** 18)
        dollarsFcmBalance = (dollarsFcmBalance / 10 ** 18)

        this.setState({dollarsApprovedOnBdd, 
          dollarsAllowance, dollarsBalance, dollarsTotalSupply, pixelsStaked, 
          accountDaiBalance, accountFcmBalance, 
          dollarsDaiBalance, dollarsFcmBalance, 
          daiAddress, fcmAddress, 
          daiContract, fcmContract, 
          dollarsDaiAllowance, dollarsFcmAllowance});
      }

      // Get Airdrop State
      if(this.state.billiondollardrop) {
        console.log("Allow List: ", allowList);
        console.log("Allow List - accounts[0]: ", this.state.accounts[0].toLowerCase());
        console.log("Allow List[account]: ", allowList[this.state.accounts[0].toLowerCase()]);
        let signatureData = allowList[this.state.accounts[0].toLowerCase()];

        let airdropAlreadyClaimed = false;
        let airdropSignerAuthorized = false;
        if(signatureData) {
          let messageHash = await this.state.billiondollardrop.methods.getMessageHash(signatureData.domain, this.state.accounts[0].toLowerCase(), signatureData.token, signatureData.amount, signatureData.nonce).call();
          let ethSignedMessageHash = await this.state.billiondollardrop.methods.getEthSignedMessageHash(messageHash).call();
          airdropAlreadyClaimed = await this.state.billiondollardrop.methods.signedMessageHashUsed(ethSignedMessageHash).call();

          let recoveredSigner = await this.state.billiondollardrop.methods.recoverSigner(ethSignedMessageHash, signatureData.signature).call();
          airdropSignerAuthorized = await this.state.billiondollardrop.methods.authorizedSigner(recoveredSigner).call();
        }

        let airdropBalance = 0;
        if(this.state.dollars) {
          airdropBalance = await this.state.dollars.methods.balanceOf(this.state.billiondollardrop.options.address).call();
        }

        this.setState({signatureData, airdropAlreadyClaimed, airdropSignerAuthorized, airdropBalance});
      }

      let rentalPricePerTimeUnit = await this.state.contract.methods.getCurrentRentalPriceForTimeUnits(1).call()
      rentalPricePerTimeUnit = await this.state.web3.utils.fromWei(rentalPricePerTimeUnit, 'ether');

      rentalCommissionPercent = await this.state.contract.methods.rentalCommissionPercent().call()

      // Get BillionDollarDapp Pixel State
      currentTokenId = await contract.methods.currentTokenId().call()
      let pixelBalance = await this.state.contract.methods.balanceOf(this.state.accounts[0]).call()

      console.log(`trying to get next pixel price`);
      nextPixelPrice = await contract.methods.getCreationPriceForNextAvailablePixel().call()
      console.log(this.state.web3.utils.fromWei(String(nextPixelPrice),"ether"))

      maximumCreationPrice = await contract.methods.maximumCreationPrice().call()

      console.log(`trying to get next pixel array`);
      pixels = await contract.methods.getAllPixels().call()
      console.log(`pixels: ${pixels}`);

      for (var i = 1; i <= currentTokenId; i++) {
        //const pixel = await contract.methods.pixels(i - 1).call()
        const pixel = pixels[i-1]

        pixelIds.push(i)
        pixelHashes.push("");

        //let timeNow = Math.floor(Date.now() / 1000)
        //console.log(`timeNow: ${timeNow}`);
        //console.log(`pixel.rentTimeEnd: ${pixel.rentTimeEnd}`);
        //console.log(`pixel.rentalColor: ${pixel.rentalColor}`);

        //if((pixel.rentTimeEnd !== 0 && pixel.rentTimeEnd !== "0x")  && pixel.rentTimeEnd > timeNow) {
        if(this.pixelIsRented(pixel)) {
          // if the pixel is rented -- add the rented color to the array
          try {
            mycolors = [...mycolors,pixel.rentalColor]
          } catch(e) {
            console.log(e);
            mycolors = [...mycolors,pixel.color]
          }
        } else {
          // else if the pixel is not rented -- add the owner's color to the array
          mycolors = [...mycolors,pixel.color]
        }

        if(pixel) {
          // if the pixel is rented -- add the rented color to the array
          try {
            ownerAlts = [...ownerAlts,pixel.ownerAlt]
          } catch(e) {
            console.log(e);
            ownerAlts = [...ownerAlts,""]
          }
        }

        if(pixel) {
          // if the pixel is rented -- add the rented color to the array
          try {
            rentalRevenue = [...rentalRevenue,pixel.totalRentalRevenue]
            allPixelsRentalRevenue += parseInt(pixel.totalRentalRevenue);
          } catch(e) {
            console.log(e);
            rentalRevenue = [...rentalRevenue, 0]
          }
        }
        //allPixelsRentalRevenue = allPixelsRentalRevenue //TODO: Convert from Wei to Eth
        allPixelsRentalRevenueEth = this.state.web3.utils.fromWei(String(allPixelsRentalRevenue),"ether");

        if(this.pixelIsRented(pixel)) {
        //if((pixel.rentTimeEnd !== 0 && pixel.rentTimeEnd !== "0x")  && pixel.rentTimeEnd > timeNow) {
          // if the pixel is rented -- add the rented color to the array
          try {
            renterAlts = [...renterAlts,pixel.rentalAlt]
          } catch(e) {
            console.log(e);
            renterAlts = [...renterAlts,""]
          }
        } else {
          renterAlts = [...renterAlts,""]
        }

        //if(this.props.match.params.pixelId) {
        //  this.setSelectedPixel(this.props.match.params.pixelId)
        //}
      }
      this.setState({
        currentTokenId: currentTokenId,
        nextPixelPrice: nextPixelPrice,
        pixels: pixels,
        pixelIds: pixelIds,
        colors: mycolors,
        ownerAlts: ownerAlts,
        renterAlts: renterAlts,
        pixelHashes: pixelHashes,
        pixelBalance: pixelBalance,
        rentalRevenue: rentalRevenue,
        allPixelsRentalRevenue: allPixelsRentalRevenue,
        allPixelsRentalRevenueEth: allPixelsRentalRevenueEth,
        rentalPricePerTimeUnit: rentalPricePerTimeUnit,
        rentalCommissionPercent: rentalCommissionPercent,
        maximumCreationPrice: maximumCreationPrice,
      })
      console.log(this.state.colors)

      // SVGPixel
      this.state.contract.getPastEvents('PixelCreated', {
          fromBlock: 0,
          toBlock: 'latest'
      })
      .then(function(events){
          for(var i=0;i<events.length;i++){
                console.log(events[i]);
                pixelHashes[i]=events[i].transactionHash;
              }
          }).catch(function(error) { console.log(error) });
      // END SVGPixel
      this.setState({pixelHashes, loading: false, loadingDescription: ""})
    } else {
      console.log("NO WEB3")

      let result = [["#4caf50","\"Energy and persistence conquer all things.\"","","","0","0"],
                    ["#ffeb3b","","","","0","0"],
                    ["#03a9f4","Have fun staying poor","","","0","0"],
                    ["#ffffff","","","","0","0"],
                    ["#64b5f6","","","","0","0"],
                    ["#64b5f6","","","","0","0"],
                    ["#607d8b","","","","0","0"],
                    ["#d9d9d9","","","","0","0"],
                    ["#1976d2","","","","0","0"],
                    ["#4caf50","","","","0","0"],
                    ["#f57c00","","","","0","0"],
                    ["#ffa000","","","","0","0"],
                    ["#e65100","","","","0","0"],
                    ["#f57c00","","","","0","0"],
                    ["#f57f17","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#f57c00","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#ff6f00","","","","0","0"],
                    ["#ffffff","","","","0","0"],
                    ["#f06292","","","","0","0"],
                    ["#ba68c8","","","","0","0"],
                    ["#795548","","","","0","0"],
                    ["#000000","","","","0","0"],
                    ["#e91e63","","","","0","0"],
                    ["#81c784","","","","0","0"],
                    ["#4dd0e1","","","","0","0"]
                  ]

      console.log(`result: ${result}`);
      console.log(`result len: ${result.length}`);
      currentTokenId = result.length;

      for (var j = 1; j <= currentTokenId; j++) {
        const pixel = result[j-1]

        pixelIds.push(j)

        //let timeNow = Math.floor(Date.now() / 1000)
        //console.log(`timeNow: ${timeNow}`);
        //console.log(`pixel.rentTimeEnd: ${pixel[4]}`);
        //console.log(`pixel.rentalColor: ${pixel.rentalColor}`);

        if(this.pixelIsRented(pixel)) {
        //if((pixel[4] !== 0 && pixel[4] !== "0x")  && pixel[4] > timeNow) {
          // if the pixel is rented -- add the rented color to the array
          try {
            mycolors = [...mycolors,pixel[2]]
          } catch(e) {
            console.log(e);
            mycolors = [...mycolors,pixel[0]]
          }
        } else {
          // else if the pixel is not rented -- add the owner's color to the array
          mycolors = [...mycolors,pixel[0]]
        }

        if(pixel) {
          // if the pixel is rented -- add the rented color to the array
          try {
            ownerAlts = [...ownerAlts,pixel[1]]
          } catch(e) {
            console.log(e);
            ownerAlts = [...ownerAlts,""]
          }
        }

        if(this.pixelIsRented(pixel)) {
        //if((pixel[4] !== 0 && pixel[4] !== "0x")  && pixel[4] > timeNow) {
          // if the pixel is rented -- add the rented color to the array
          try {
            renterAlts = [...renterAlts,pixel[3]]
          } catch(e) {
            console.log(e);
            renterAlts = [...renterAlts,""]
          }
        } else {
          renterAlts = [...renterAlts,""]
        }

        let pixelHashes = ["0xb58f41998911e8c960c0e465c22e58b77b66943098a59c90d2c38b09947893ad",
                           "0x06b7ff1481b86e2d72f2d6617b6b900b4b8841b054581ede34cab572d9c45c37",
                           "0xe9a10d48c8ae3e629c5eb1f33461354dbfa788fa2a83b3b632fed4684653eb49",
                           "0x24f5f9f8a7a7173964d6f820c747ca750a794fe2425da4b9f32b81979426ea9e",
                           //"0x568f9cd0ca10467fe4d0f345d12760925f3eabbd0564755e5f01720baf32c323",
                           "0x411510fb3ae5a456ed3f2e8576eda30f9bb70635d701d19e40ac9de09a9e3832",
                           "0x2cd33a2bdea38952284a7487043eb52026ca1f7e6c05edc57c19f7fe305c93ed",
                           "0x0e4c054bc4f8ced02b96345b99e30c3c0a517413e73a58ca536dda57be514890",
                           "0x3d122634c0bee74abaa79b1f0f69921c3b0ad8535be22b149d28416e90bda054",
                           "0xec22139eb767e150cf38d372beb15f9eb6737eef84f9b709803447070d47e89f",
                           "0xd5489d2cf57d34510739b927140feed788bb2aa31b8eb69a07ff033d80903877",
                           "0x1100e2ddbb97199dfdef63f336cc94ef11bd063ac10c6f4d3850407b2da04e51",
                           "0x590ea162c90a501d884980d62eb8513f32b0f7efde6b571e3368725e0bac7043",
                           "0xbf6f8f65ac603c343e7d518cdf6df4f7add9f8a526ff5da2b6b2040044e38d81",
                           "0x56924f797634ace3619ac31bc72325824545c7bdd01bd62a1e9d4e731ba608c2",
                           "0x313569aa6ecff125ad279a0c907af71ff81ad3d5bbd0f7cc047743728995a668",
                           "0x31263920d93079e3f14a86fc491dc60b6f35fe40540578af606749d5c6bcf853",
                           "0x71f07da7659e03cf288e9466e7ba3399b01395fe88febac0de8d326a8955311b",
                           "0x73bc28efe0f1b19605419be6c8f9b03baa3a302056ffed548d238efce7f1a306",
                           "0x7966ab199c585f23a74ba7ff00a53d1c0291f075860ab2f387086e8b38db8c5f",
                           "0xa69264e3620d899b640dd647d6fb1369e7bccd199bd47ecaeec6d7947104618f",
                           "0x676162b31e965f247dfd9c396bf979d91fae1f18c89ad7bfe0d6f89630684e5c",
                           "0xc5d2e3817cc2f2c00753bdcd810be4dc4d8a5dc063687411a0d975dd39e8e15d",
                           "0xeb8e79ae80a7f1496b9847bbe40272a4058580f5625fd2c691723b7230b25468",
                           "0x8f40c30cfe58d7f749aaa9a78d4774ad2995fbb07ff7b9f8921842798c2b1cc3",
                           "0xf91e4c24ce61c52f9bedb527a6cd3b863ee8f6afe922bf96870cec2a65515829",
                           "0x8246ecaf7c588e585152c76a5f37db5e9471a14b450cc940dc1419cb41fe3a63",
                           "0xcc1966846d5dc4e2ea6bf243ab7da99579e2b3d8699b0d106293fbedacd446fb",
                           "0xcbf3c1d163dda6eac6cca66c4ec29c2cee5d86957808a419433d51b2d43cb840",
                           "0x3607bc1eea215b77d64aaa91dade57c1ff8a38d4ff54f34f243dd3d692735176",
                           "0x045b1ebeed701704485334e5e46bcd87322744b6a26f726544abcd16ac295c77"]/*
                           "0x5c00fdf1ca2ef6dbf0e010d97685f4e4ad1b7344a5db550185733c51791a1c1c",
                           "0x95d5a6a8223228954cc9376d45987e7738510d1f13c4ff42aee9a1281db25b4a",
                           "0x9762434ff7d08182e67858b0806b7ebb4b03fe9f40453a7d551ee0e83d4c9b36",
                           "0xeba24dfcfeada513222c6736f3a47101c710b511b57f34055c99f67c0cfb7cfb",
                           "0x67fac26a5e10319bad8424803834cf29f8b2d2c84f5b8a218f6cdb656d67d433",
                           "0x49246bafc1b31c38c2b9fdf00f3ef98d042b680feef3ae901aac2b67943d1f60",
                           "0x983d429b819cb3b60179c42cef0cb3e46a910e71ff0ec9171957ffe1b5338af9",
                           "0x8b15cd578312cfec1cb97b74b3d76ff4b230e7bf08eefa97f236feaa12d63640",
                           "0x9a8bd04181b7b875befdaead32bd05b19638c6a64e64122e5f5521fcbd6f84d3",
                           "0x100625b92fdfc94f695948b945984deca4f65f6242f1bf2c5bfa0659d7cb8bda"]*/

        this.setState({
          pixels: result,
          pixelIds: pixelIds,
          colors: mycolors,
          ownerAlts: ownerAlts,
          renterAlts: renterAlts,
          currentTokenId: currentTokenId,
          pixelHashes: pixelHashes
        })
        //console.log(this.state.colors)
      }

    }
  };

  // BillionDollarDapp Helper Functions

  pixelEventHistory = async (pixelId) => {
    let pixelEvents = [];
    if(this.state.contract) {
      this.state.contract.getPastEvents({}, {
          fromBlock: 0,
          toBlock: 'latest'
      })
      .then(function(events){
        //console.log(events)
          for(var i=0;i<events.length;i++){
              // This needs to only have two == to work (not ===), otherwise table is empty...
              if(events[i].returnValues.tokenId == pixelId || events[i].returnValues.pixelId == pixelId) {
                console.log(events[i]);
                //console.log(events[i].transactionHash, events[i].event, events[i].returnValues);
                pixelEvents.push(events[i]);
              }
          }
      }).catch(function(error) { console.log(error) });
    }
    return pixelEvents;
  }

  setSelectedPixelName = (selectedPixelName) => {
    console.log("App.js setSelectedPixelName: ", selectedPixelName);
    this.setState({selectedPixelName});
  }

  setSelectedPixel = async (pixelId) => {
    console.log(`selectedPixel: ${pixelId}`)
    //this.props.history.push(`/${pixelId}`)
    if(this.state.web3) {
      let pixelOwner = null;
      let isRentable = false;
      let selectedPixelTotalRentalRevenue = 0;

      try {
        pixelOwner = await this.state.contract.methods.ownerOf(pixelId).call();
      } catch(error) {console.log(error);}

      try {
        isRentable = await this.state.contract.methods.isPixelAvailableToRent(pixelId).call();
      } catch(error) {console.log(error);}

      try {
        selectedPixelTotalRentalRevenue = this.state.rentalRevenue[pixelId-1];
      } catch(error) {console.log(error);}

      console.log(`ownerOfSelectedPixel: ${pixelOwner}`)
      let eventHistory = await this.pixelEventHistory(pixelId);
      this.setState({
        ownerOfSelectedPixel: pixelOwner,
        selectedPixel: pixelId,
        selectedPixelEventHistoryData: eventHistory,
        selectedPixelIsRentable: isRentable,
        selectedPixelTotalRentalRevenue: selectedPixelTotalRentalRevenue
      })
      //this.loadBlockchainData();
    } else {
      this.setState({
        ownerOfSelectedPixel: null,
        selectedPixel: pixelId,
        selectedPixelEventHistoryData: null,
        selectedPixelIsRentable: null
      })
    }
  }

  resetSelectedPixel = () => {
    console.log(`resetSelectedPixel`)
    //this.props.history.push(`/`)
    this.setState({
      ownerOfSelectedPixel: null,
      selectedPixelEventHistoryData: null,
      selectedPixel: null,
      selectedPixelIsRentable: null,
    })
    //this.loadBlockchainData();
  }

  pixelIsRented = (pixel) => {
    let timeNow = Math.floor(Date.now() / 1000)
    try {
      console.log(`timeNow: ${timeNow}`);
      console.log(`pixel.rentTimeEnd: ${pixel.rentTimeEnd}`);
      //console.log(`pixel.rentalColor: ${pixel.rentalColor}`);
      return ((pixel.rentTimeEnd !== 0 && pixel.rentTimeEnd !== "0x") && pixel.rentTimeEnd > timeNow)
    } catch(e) {
      console.log(e, "WEB3 Error determining if pixel is rented.")
      try {
        console.log(`timeNow: ${timeNow}`);
        console.log(`pixel.rentTimeEnd: ${pixel[4]}`);
        //console.log(`pixel.rentalColor: ${pixel.rentalColor}`);
        return ((pixel[4] !== 0 && pixel[4] !== "0x") && pixel[4] > timeNow)
      } catch(e) {
        console.log(e, "WEB2 Error determining if pixel is rented.")
      }
    }
  }

  // Museum Mode

  /* View in fullscreen */
  openFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  setDarkMode = () => {
    let darkModeCSS = `body { background-color: #000000; }
    .bg-light { background-color: transparent!important; }
    .navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {color: rgb(170, 170, 170);}
    .modal-content {background-color: #000000; color: #ffffff;}
    .table {color: #ffffff;}
    .navbar-toggler {background-color: #333333;}
    a {color: #00ff00;}}`
    this.setState({CSSStyleOverride: darkModeCSS})
  }

  toggleDarkMode = () => {
    if(this.state.CSSStyleOverride === "") {
      this.setDarkMode();
    } else {
      this.setState({CSSStyleOverride: ""})
    }
  }

  // Museum Mode

  isFeaturedPixelQueueEmpty = () => {
    let length = this.state.featuredPixelQueue.length - this.state.featuredPixelQueueFrontIndex;
    console.log("isFeaturedPixelQueueEmpty - length: ", length)
    if(length === 0 && this.state.featuredPixelQueue.length > 0) {
      this.resetFeaturedPixelQueue();
    }
    return (length === 0);
  }

  getFrontFeaturedPixelQueue = () => {
    let front = this.state.featuredPixelQueue[this.state.featuredPixelQueueFrontIndex];
    console.log("getFrontFeaturedPixelQueue - front: ", front);
    return front;
  }

  enqueueFeaturedPixelQueue = (pixelId) => {
    console.log("euqueueFeaturedPixelQueue: ", pixelId);
    let featuredPixelQueue = this.state.featuredPixelQueue;
    if(featuredPixelQueue[featuredPixelQueue.length - 1] !== pixelId) {
      featuredPixelQueue = [...featuredPixelQueue,pixelId]
    }
    console.log("featuredPixelQueue: ", featuredPixelQueue);
    this.setState({featuredPixelQueue});
  }

  dequeueFeaturedPixelQueue = () => {
    const pixelId = this.getFrontFeaturedPixelQueue()
    console.log("dequeueFeaturedPixelQueue - pixelId: ", pixelId);

    let featuredPixelQueueFrontIndex = this.state.featuredPixelQueueFrontIndex + 1
    console.log("dequeueFeaturedPixelQueue - featuredPixelQueueFrontIndex: ", featuredPixelQueueFrontIndex);

    this.setState({featuredPixelQueueFrontIndex})
    return pixelId
  }

  resetFeaturedPixelQueue = () => {
    let featuredPixelQueue = []
    let featuredPixelQueueFrontIndex = 0
    let featuredPixelQueueLength = 0
    this.setState({
      featuredPixelQueue, 
      featuredPixelQueueFrontIndex, 
      featuredPixelQueueLength})
  }

  startMuseumMode = () => {
    if(!this.state.museumModeActive) {
      let museumModeActive = true;
      this.openFullscreen()
      this.randomPixel();
      let intervalId = setInterval(this.randomPixel, this.state.museumModeSelectedTime + this.state.museumModeGridTime);
      this.setState({museumModeActive, intervalId});
    }
  }

  stopMuseumMode = () => {
    if(this.state.museumModeActive) {
      let museumModeActive = false;
      clearInterval(this.state.intervalId)
      clearTimeout(this.state.timeoutId)
      //this.closeFullscreen()
      this.setState({museumModeActive, intervalId: null, timeoutId: null});
    }
  }

  toggleMuseumMode = () => {
    if(this.state.museumModeActive) {
      this.stopMuseumMode();
    } else {
      this.startMuseumMode();
    }
  }

  continueMuseumModeWithPixel = (pixelId) => {
    if(this.state.museumModeActive) {
      this.stopMuseumMode();    
      let museumModeActive = true;
      this.museumFocusOnPixel(pixelId);
      let intervalId = setInterval(this.randomPixel, this.state.museumModeSelectedTime + this.state.museumModeGridTime);
      this.setState({museumModeActive, intervalId});  
    }
  }

  toggleGuerrillaMode = async () => {
    if(this.state.guerrillaModeActive) {
      let guerrillaModeActive = false;
      let museumModeSelectedTime = 1000 * 10;
      let museumModeGridTime = 1000 * 5;
      this.setState({
        museumModeSelectedTime, 
        museumModeGridTime,
        guerrillaModeActive
      });
      this.stopMuseumMode();
      this.setState({guerrillaModeActive});
    } else {
      let guerrillaModeActive = true;
      let museumModeSelectedTime = 1000 * 3;
      let museumModeGridTime = 1000 * 2;
      await this.setState({
        museumModeSelectedTime, 
        museumModeGridTime,
        guerrillaModeActive
      });
      this.startMuseumMode();
      this.setDarkMode();
    }
  }

  randomPixel = () => {
    let selectedPixel = null;
    if(this.isFeaturedPixelQueueEmpty()) {
      let randomPixel = this.state.pixelIds[Math.floor(Math.random() * this.state.pixelIds.length)];
      selectedPixel = randomPixel;
    } else {
      let randomPixel = this.dequeueFeaturedPixelQueue();
      selectedPixel = randomPixel;
    }
    this.setSelectedPixel(selectedPixel);
    let timeoutId = setTimeout(this.resetSelectedPixel, this.state.museumModeSelectedTime);
    this.setState({timeoutId});
  }

  museumFocusOnPixel = (pixelId) => {
    this.setSelectedPixel(pixelId);
    let timeoutId = setTimeout(this.resetSelectedPixel, this.state.museumModeSelectedTime);
    this.setState({timeoutId});
  }

  // BillionDollarDapp Update Functions

  mint = (color) => {
    console.log(color)
    //const reqValue = this.state.web3.utils.toWei("0.1", "ether")
    this.state.contract.methods.anyoneCreatePixel(color).send({from: this.state.accounts[0], value: this.state.nextPixelPrice})
    .once('receipt', async (receipt) => {
      let nextPixelPrice = await this.state.contract.methods.getCreationPriceForNextAvailablePixel().call()
      console.log(this.state.web3.utils.fromWei(String(nextPixelPrice),"ether"))
      this.setState({ nextPixelPrice })
      const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      this.setState({ currentTokenId })
      alert(`Congratulations! You now own BDD Pixel # ${ currentTokenId }!`)
      this.setState({
        colors: [...this.state.colors, color]
      })
    })
  }

  poUpdateColor = (pixelId, newColor) => {
    console.log(pixelId, newColor)
    //const reqValue = this.state.web3.utils.toWei("0.1", "ether")
    this.state.contract.methods.pixelOwnerUpdatePixelColor(pixelId, newColor).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //let nextPixelPrice = await this.state.contract.methods.getCreationPriceForNextAvailablePixel().call()
      //console.log(this.state.web3.utils.fromWei(String(nextPixelPrice),"ether"))
      //this.setState({ nextPixelPrice })
      //const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      //this.setState({ currentTokenId })
      //alert(`Congratulations! You now own BDD Pixel # ${ currentTokenId }!`)
      //this.setState({
      //  colors: [...this.state.colors, color]
      //})
    })
  }

  poUpdateAlt = (pixelId, newText) => {
    console.log(pixelId, newText)
    //const reqValue = this.state.web3.utils.toWei("0.1", "ether")
    this.state.contract.methods.pixelOwnerUpdatePixelAlt(pixelId, newText).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //let nextPixelPrice = await this.state.contract.methods.getCreationPriceForNextAvailablePixel().call()
      //console.log(this.state.web3.utils.fromWei(String(nextPixelPrice),"ether"))
      //this.setState({ nextPixelPrice })
      //const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      //this.setState({ currentTokenId })
      //alert(`Congratulations! You now own BDD Pixel # ${ currentTokenId }!`)
      //this.setState({
      //  colors: [...this.state.colors, color]
      //})
    })
  }

  anyoneRent = async (pixelId, timeUnits, rentalColor, rentalAlt) => {
    console.log(pixelId, timeUnits, rentalColor, rentalAlt)
    //const reqValue = this.state.web3.utils.toWei("0.1", "ether")

    const priceToPay = await this.state.contract.methods.getCurrentRentalPriceForTimeUnits(timeUnits).call()

    this.state.contract.methods.anyoneRentPixel(pixelId, timeUnits, rentalColor, rentalAlt).send({from: this.state.accounts[0], value: priceToPay})
    .once('receipt', async (receipt) => {
      //let nextPixelPrice = await this.state.contract.methods.getCreationPriceForNextAvailablePixel().call()
      //console.log(this.state.web3.utils.fromWei(String(nextPixelPrice),"ether"))
      //this.setState({ nextPixelPrice })
      //const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      //this.setState({ currentTokenId })
      //alert(`Congratulations! You now own BDD Pixel # ${ currentTokenId }!`)
      //this.setState({
      //  colors: [...this.state.colors, color]
      //})
    })
  }

  executeFlashBond = async (number) => {
    console.log("Execute FlashBond");
    const pricePerBond = this.state.web3.utils.toWei("0.000625", "ether")
    const priceToPay = number * pricePerBond;
    this.state.flashbond.methods.executeFlashBond().send({from: this.state.accounts[0], value: priceToPay})
    .once('receipt', async (receipt) => {
    })
  }

  // DOLLARS FUNCTIONS

  approveDollarsOnBDD = () => {
    //this.setState({dollarsLoading: true})
    this.state.contract.methods.setApprovalForAll(this.state.dollars.options.address, true).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  buyDollarsWithPixel = (pixelId) => {
    console.log(pixelId)
    this.setState({dollarsLoading: true})
    //this.state.dollars.methods.buyDollarsWithPixel(pixelId).send({from: this.state.accounts[0]})
    this.state.web3.eth.sendTransaction({
      from: this.state.accounts[0],
      to: this.state.dollars.options.address,
      data: this.state.dollars.methods.buyDollarsWithPixel(pixelId).encodeABI()
    })
    .once('receipt', async (receipt) => {
      //this.loadBlockchainData()
      this.setState({dollarsLoading: false})
      this.closeSellForDollarsModal()
      window.location.reload()
    })
  }

  approveDollars = () => {
    //this.setState({dollarsLoading: true})
    this.state.dollars.methods.approve(this.state.accounts[0], '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  sellDollarsForPixel = (pixelId) => {
    console.log(pixelId)
    this.setState({dollarsLoading: true})
    //this.state.dollars.methods.sellDollarsForPixel(pixelId).send({from: this.state.accounts[0]})
    this.state.web3.eth.sendTransaction({
      from: this.state.accounts[0],
      to: this.state.dollars.options.address,
      data: this.state.dollars.methods.sellDollarsForPixel(pixelId).encodeABI()
    })
    .once('receipt', async (receipt) => {
      //this.loadBlockchainData()
      this.setState({dollarsLoading: false})
      this.closeBuyForDollarsModal();
      window.location.reload()
    })
  }

  // DOLLARS : DAI

  approveDAI = () => {
    this.setState({dollarsLoading: true})
    this.state.daiContract.methods.approve(this.state.dollars.options.address, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Approved");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  buyDollarsWithDAI =  (amount) => {
    this.setState({dollarsLoading: true})

     this.state.dollars.methods.buyDollarsWithDAI(amount).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Bought Dollars with DAI!");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  // prereq: call approveDollars();
  sellDollarsForDAI =  (amount) => {
    this.setState({dollarsLoading: true})
     this.state.dollars.methods.sellDollarsForDAI(amount).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Sold Dollars for DAI!");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  // DOLLARS : FASTCASH

  approveFCM = () => {
    this.setState({dollarsLoading: true})

    this.state.fcmContract.methods.approve(this.state.dollars.options.address, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Approved FASTCASH");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  buyDollarsWithFCM =  (amountFCM) => {
    this.setState({dollarsLoading: true})

     this.state.dollars.methods.buyDollarsWithFCM(amountFCM).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Bought Dollars with FASTCASH!");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  // prereq: call approveDollars();
  sellDollarsForFCM =  (amountDOLLARS) => {
    this.setState({dollarsLoading: true})
     this.state.dollars.methods.sellDollarsForFCM(amountDOLLARS).send({from: this.state.accounts[0]})
    .once('receipt', async (receipt) => {
      //alert("Sold Dollars for FASTCASH!");
      this.setState({dollarsLoading: false})
      this.loadBlockchainData()
    })
  }

  // BILLION DOLLAR DROP 

  verifyAndClaimToken = (domain, token, amount, nonce, signature) => {
    this.setState({dollarsLoading: true})
    this.state.web3.eth.sendTransaction({
      from: this.state.accounts[0],
      to: this.state.billiondollardrop.options.address,
      data: this.state.billiondollardrop.methods.verifyAndClaimErc20(domain, token, amount, nonce, signature).encodeABI()
    })
    .once('receipt', async (receipt) => {
      //this.loadBlockchainData()
      this.setState({dollarsLoading: false})
      this.closeAirdropModal();
      window.location.reload()
    })
  }

  // RENDER 

  openAboutModal = () => this.setState({ isAboutOpen: true });
  closeAboutModal = () => this.setState({ isAboutOpen: false });

  openCausesModal = () => this.setState({ isCausesOpen: true });
  closeCausesModal = () => this.setState({ isCausesOpen: false });

  openMuseumModal = () => this.setState({ isMuseumOpen: true });
  closeMuseumModal = () => this.setState({ isMuseumOpen: false });

  openCreateModal = () => this.setState({ isCreateOpen: true });
  closeCreateModal = () => this.setState({ isCreateOpen: false });

  openMetamaskModal = () => this.setState({ isMetamaskOpen: true });
  closeMetamaskModal = () => this.setState({ isMetamaskOpen: false });

  openDetailsModal = () => this.setState({ isDetailsOpen: true });
  closeDetailsModal = () => this.setState({ isDetailsOpen: false });

  openUpdateModal = () => this.setState({ isUpdateOpen: true });
  closeUpdateModal = () => this.setState({ isUpdateOpen: false });

  openSellForDollarsModal = () => this.setState({ isSellForDollarsOpen: true });
  closeSellForDollarsModal = () => this.setState({ isSellForDollarsOpen: false });

  openBuyForDollarsModal = () => this.setState({ isBuyForDollarsOpen: true });
  closeBuyForDollarsModal = () => this.setState({ isBuyForDollarsOpen: false });

  openPreOwnedPixelModal = () => this.setState({ isPreOwnedPixelOpen: true });
  closePreOwnedPixelModal = () => this.setState({ isPreOwnedPixelOpen: false });

  openBuyDollarsModal = () => this.setState({ isBuyDollarsOpen: true });
  closeBuyDollarsModal = () => this.setState({ isBuyDollarsOpen: false });

  openRentModal = () => this.setState({ isRentOpen: true });
  closeRentModal = () => this.setState({ isRentOpen: false });

  openNetWorthModal = () => this.setState({ isNetWorthOpen: true });
  closeNetWorthModal = () => this.setState({ isNetWorthOpen: false });

  openAirdropModal = () => this.setState({ isAirdropOpen: true });
  closeAirdropModal = () => this.setState({ isAirdropOpen: false });

  openZoraModal = () => this.setState({ isZoraModalOpen: true });
  closeZoraModal = () => this.setState({ isZoraModalOpen: false });
  
  openFlashBondModal = () => this.setState({ isFlashBondModalOpen: true });
  closeFlashBondModal = () => this.setState({ isFlashBondModalOpen: false });

  render() {
    
    const handleColorChange = ({ hex }) => {
      this.setState( {color: hex });
      document.getElementById("submit").style.background = hex;
    }

    const footerLinkArtAlgorithm = <Button variant="outline-secondary btn-sm" href="https://editor.p5js.org/BillionDollarDapp/sketches/OW0H9Qr1F">Art Algorithm on editor.p5js.org</Button>
    const footerLinkBDDEtherscan = <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.contract.options.address}`)}>BDD on Etherscan</Button>
    const footerLinkDollarsEtherscan = (this.state.dollars) ? <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.dollars.options.address}`)}>DOLLARS on Etherscan</Button> : ""
    const footerLinkDAIEtherscan = (this.state.dollars) ? <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.daiAddress}`)}>DAI on Etherscan</Button> : ""
    const footerLinkFCMEtherscan = (this.state.dollars) ? <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.fcmAddress}`)}> FASTCASH on Etherscan</Button> : ""

    const footerLinkBDDFlashBondEtherscan = (this.state.flashbond) ? <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.flashbond.options.address}`)}> FlashBond on Etherscan</Button> : ""

    const footerLinkDropEtherscan = (this.state.billiondollardrop) ? <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.billiondollardrop.options.address}`)}> BDD Airdrop on Etherscan</Button> : ""


    const footerLinkOpenseaCollection = <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.opensea_collection_link}`)}>OpenSea</Button>
    const footerLinkTwitter = <Button variant="outline-secondary btn-sm" href="https://twitter.com/billionusddapp/">Twitter</Button>
    const footerLinkDiscord = <Button variant="outline-secondary btn-sm" href="https://discord.gg/pJEDZMc3dp">Discord</Button>
    
    const footerLinkPixelDetails = <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.etherscan_token_link}${this.state.contract.options.address}?a=${this.state.selectedPixel}`)}>Mint Transaction on Etherscan</Button>
    const footerLinkOpenseaPixelDetails = <Button variant="outline-secondary btn-sm" onClick={() => window.open(`${this.state.platform.opensea_asset_base_link}${this.state.contract.options.address}/${this.state.selectedPixel}`)}>Metadata Details on OpenSea</Button>

    const footerLinkDAIDOLLARSUniswap = (this.state.networkId === 1) ? (
      <Button variant="outline-secondary btn-sm" onClick={() => window.open(`https://etherscan.io/dex/uniswapv3/0xf166cccf143b2994b8e20f6c1fba88c4e488f3a9`)}>DAI/DOLLARS on UniswapV3</Button>
    ) : ("")
    

    const footerLinkToggleDarkMode = (this.state.CSSStyleOverride === "") ? (
      <Button variant="outline-secondary btn-sm" onClick={this.toggleDarkMode}>Dark Mode</Button>
    ) : (
      <Button variant="outline-secondary btn-sm" onClick={this.toggleDarkMode}>Light Mode</Button>
    )

    const buttonForPreOwnedOnOpensea = (this.state.dollars) ? <Button variant="primary btn-lg" onClick={() => window.open(`${this.state.platform.rainbow_link}${this.state.dollars.options.address}${this.state.platform.rainbow_suffix}`)}>Browse Certified Pre-Owned Pixels</Button> : ""
    
    const buttonForAccountOwnedOnOpensea = (this.state.dollars) ? (
      (this.state.networkId === 1) ? (
        <Button variant="primary btn-lg" 
                onClick={() => window.open(`${this.state.platform.opensea_link}${this.state.accounts[0]}/billiondollardapp?search[sortBy]=LISTING_DATE`)}>
                  Browse Your Pixels on OpenSea
        </Button>
      ) : (
        <Button variant="primary btn-lg" 
        onClick={() => window.open(`${this.state.platform.opensea_link}${this.state.accounts[0]}/billiondollardapp-pixel-v4?search[sortBy]=LISTING_DATE`)}>
          Browse Your Pixels on OpenSea
        </Button>
      ) 
    ): ("")
    const writeContractLinkDollarsEtherscan = (this.state.dollars) ? <Button variant="primary btn-lg" onClick={() => window.open(`${this.state.platform.etherscan_link}${this.state.dollars.options.address}#writeContract`)}>DOLLARS on Etherscan</Button> : ""

    const aboutModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={this.state.isAboutOpen} onHide={this.closeAboutModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>About BillionDollarDapp</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p></p>
              <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                <Tab eventKey="about" title="About BillionDollarDapp">
                  <p></p>
                  <p><div style={{fontSize: "larger"}}>BillionDollarDapp explores the relationship between the value of art, and the value of money.</div></p>
                  <p>
                    <div style={{fontSize: "larger"}}>What makes art valuable?</div>
                  </p>
                  <p>
                    Art is valuable because it is a form of expression. It can be used to communicate ideas and emotions. It can also be used to create something that is beautiful or meaningful.
                    Some art is valuable because it is by a well-known artist, or it is a rare piece. Other art is valuable because it has sentimental value to the owner. 
                    Exclusivity can also make art valuable. Other art is interactive and is valuable because of its community. Maybe collectors just want bragging rights or a store of value.
                    Perhaps there is a secret to the value of art that we can unlock together. <b>What if someone created a work of art that had all of these qualities?</b>
                  </p>

                  <p>
                    <div style={{fontSize: "larger"}}>Similarly, what gives money value?</div>  
                  </p>
                  <p>
                    One of the most important functions of money is that it serves as a store of value.
                    Many world currencies were historically backed by gold, but are now only backed by faith.  
                    Moreover, in the past few years, governments have been unrestrained in printing new money leading to historically high levels of inflation.
                    In addition, algorithmic stablecoins like Terra/Luna, backed by nothing, have imploded and their value almost completely vanished.
                    In both cases, the money has failed to serve as a store of value. <b>What if a currency was backed by something valuable again?</b>
                  </p>
                  <p>
                    <div style={{fontSize: "larger"}}>What if we a currency was backed by the art, and the arts value guaranteed by the currency?</div> 
                  </p>
                  <p></p>
                  <p>
                    <div style={{fontSize: "larger"}}>Welcome to BillionDollarDapp.</div>  
                  </p>
                  <p></p>
                </Tab>
                <Tab eventKey="pixels" title="PIXELS">
                  <Tabs defaultActiveKey="pixels_artwork" id="uncontrolled-tab-example2">
                    <Tab eventKey="pixels_artwork" title="The Artwork">
                      <Tabs defaultActiveKey="pixels_artwork_connections" id="uncontrolled-tab-example3">
                        <Tab eventKey="pixels_artwork_connections" title="Beauty">
                          <p></p>
                          <p>BillionDollarDapp consists of a Pixel grid that can grow to a dimension of 32x32 Pixels — or a 1024 Pixels total. 
                            Each Pixel contains an abstract modern artwork — an edition of Connections by Gutenblock.</p>
                          <p>The threads depicted on each pixel represent our shared connections to different people, milestones, or causes that we individually care about.</p>
                          <p>Dedication text can be added to each pixel on the Ethereum blockchain to represent the people, milestones, or causes the owner of the piece wants to bring attention to.</p>
                          <p>As the larger collaborative artwork grows, the threads on each pixel interact with adjacent pixels. 
                            These connections shift as more pixels are created.</p>
                          <p>Despite these shifting connections, each pixel is still intertwined with each other pixel in the artwork.</p>
                          <p></p>
                          <hr/>
                          <ul>
                            <li>This artwork is absolutely stunning! The colors are so vibrant and the lines are so perfectly placed. It's definitely one of the most beautiful pieces of art I've ever seen.</li>
                            <li>I love the way the different colors of the lines interact with each other. It's mesmerizing to look at.</li>
                            <li>The artist did an amazing job of creating a cohesive design with the different colors and lines. It's truly beautiful.</li>
                            <li>This is one of the most unique and beautiful pieces of art I've ever seen. The colors and lines are perfectly placed and create an amazing visual effect.</li>
                            <li>I'm not typically a fan of abstract art, but this piece is an exception. It's absolutely gorgeous and I can't take my eyes off it.</li>
                            <li>Don’t even get me started on Museum Mode, looking at one of the works brings me to nirvana. Seeing so many in fast succession is like being on one long, extended acid trip.</li>
                          </ul>
                        </Tab>
                        <Tab eventKey="Sentimental_value" title="Sentimental Value">
                          <p></p>
                          <p>
                            Each artwork is generated based on the Ethereum transaction hash generated at minting time. 
                            The background color of each Pixel is set by the minter. This color can be updated by the owner. 
                            The owner may also set or update dedication text associated with the artwork.
                          </p>
                          <hr/>
                          <ul>
                            <li>I minted a BillionDollarDapp pixel at the date and time of my wedding, to commemorate my special day. Now, every time I look at it, it reminds me of that happy day.</li>
                            <li>I updated my BillionDollarDapp pixel to the date and time of my graduation, to commemorate all my hard work. Now, every time I look at it, it reminds me of how far I've come.</li>
                            <li>I minted a BillionDollarDapp pixel to the date and time of my grandmother's funeral, to commemorate her life. Now, every time I look at it, it reminds me of her and how much she meant to me.</li>
                            <li>I updated my BillionDollarDapp pixel to the date and time of my best friend's wedding, to commemorate their special day. Now, every time I look at it, it reminds me of that happy day and how much my friend means to me.</li>
                            <li>I minted a BillionDollarDapp pixel to the date and time of my dog's adoption, to commemorate the day he became a part of our family. Now, every time I look at it, it reminds me of how much joy he brings to our lives.</li>
                            <li>I rented a BillionDollarDapp pixel to commemorate my grandmother's life. She passed away last year, and this was a way for me to keep her memory alive. The pixel is very sentimental to me now, and I love that it's part of the artwork.</li>
                            <li>I rented a BillionDollarDapp pixel to commemorate my wedding day. It was a special day for me and my husband, and we loved being able to become part of the artwork. The pixel is very sentimental to us now, and we love that it's part of the artwork.</li>
                            <li>I rented a BillionDollarDapp pixel to commemorate the birth of my child. It's a special day for me and my family, and we loved being able to become part of the artwork. The pixel is very sentimental to us now, and we love that it's part of the artwork.</li>
                            <li>I rented a BillionDollarDapp pixel to commemorate the life of a loved one. They passed away recently, and this was a way for me to keep their memory alive. The pixel is very sentimental to me now, and I love that it's part of the artwork.</li>
                            <li>I rented a BillionDollarDapp pixel to commemorate an important cause that I care about. I wanted to show my support for the cause, and this was a way for me to do that. The pixel is very sentimental to me now, and I love that it's part of the artwork.</li>
                          </ul>
                        </Tab>
                        <Tab eventKey="Interactivity" title="Interactivity">
                          <p></p>
                          <p>The 32x32 grid dynamically updates in real time as pixels are minted, updated, and rented. Observers can become participants in the artwork.</p>
                          <p>The BillionDollarDapp is a work of art in and of itself, as well as a functional application that allows people to mint, own, and rent unique pieces of digital art.</p>
                        </Tab>
                        <Tab eventKey="Rarity" title="Rarity">
                          <p></p>
                          <p>Only up to 32 x 32 Pixels (1024) will exist. Then, these pixels will only be available on the open market or rented.</p>
                          <p>Each pixel is unique because it is generated based on the cryptographic hash produced by the Ethereum network during the creation process.</p>
                        </Tab>
                        <Tab eventKey="Exclusivity" title="Exclusivity">
                          <p></p>
                          <p>If you have a BillionDollarDapp Pixel, you are in a small and exclusive group of people.  Not just anyone can get their hands on one of these Pixels.</p>
                          <p>
                            Mint price is calculated as a linear function from 0 Eth to {this.state.maximumCreationPrice / 10**18} Ether.
                            This causes the average mint price to be about {(this.state.maximumCreationPrice / 10**18) / 2} Ether.
                            The next pixel's mint price is {this.state.nextPixelPrice / 10**18} Ether.
                          </p>
                          <p>
                            On the secondary market, these are worth 1 million DOLLARS.
                          </p>
                        </Tab>
                        <Tab eventKey="FOMO" title="FOMO">
                          <p></p>
                          <ul>
                          <li>I'm so happy I decided to become a BillionDollarDapp pixel owner! I was worried I would miss out on this incredible opportunity, but thankfully I took the plunge and it's been one of the best decisions I've ever made.</li>
                          <li>The community is incredibly supportive and welcoming, and I've already made some great connections and friendships. I can't wait to see what the future holds for this amazing project!</li>
                          <li>I was on the fence about whether or not to buy a BillionDollarDapp pixel, but I'm so glad I did! I would have regretted missing out on this opportunity. The community is amazing and it's been really exciting to watch the project grow. I can't wait to see what the future holds! </li>
                          <li>I was a bit hesitant at first, but I'm so glad I decided to buy a BillionDollarDapp pixel! I would have regretted missing out on this incredible opportunity. The community is amazing and supportive, and I've already made some great friends. I can't wait to see what the future holds for this project!</li>
                          <li>I was really afraid of missing out on the opportunity to own a piece of the world's first billion dollar dapp. I'm so glad I made the decision to become a pixel owner!</li>
                          <li>I never thought I could own a piece of something so valuable. Fear of missing out definitely helped me make the decision to become a pixel owner!</li>
                          <li>I'm so glad I decided to become a pixel owner! I would have been so upset if I missed out on this opportunity.</li>
                          <li>I'm so happy I made the decision to become a pixel owner! I was really afraid of missing out and I'm glad I didn't!</li>
                          <li>I'm so excited to be a pixel owner! I was really afraid of missing out on this incredible opportunity.</li>
                          </ul>
                        </Tab>
                      </Tabs>
                    </Tab>
                    <Tab eventKey="ip_rights" title="License to IP Rights & Branding">
                      <p></p>
                      <div align="center">
                      <img src="billiondollar_ny_pizza.png" width="100%" alt="BillionDollar NY Pizza - IP Rights and Branding" />
                      </div>
                      <p></p>
                      <p>BillionDollarDapp Pixel owners get a license to use the Artwork on their pixel commercially. They can use the “dollar” “hundred dollar“ “thousand dollar“ “million dollar” and “billion dollar“ prefixes to describe their products and services.</p>
                      <p>This helps Pixel owners in marketing and advertising their products and services with an unparalleled level of exclusivity, which will result in more sales and better profits. for example a BillionDollarDapp pixel owner could open a cafe and call it the “BillionDollarCafe”. This will attract a lot of customers who are curious to see what a billion dollar cafe looks like and what it has to offer. Some of the menu items could be the hundred dollar latte, or the thousand dollar avacado toast. Imagine a latte with with lines of coffee in the foamed milk that look like a million dollars because it is a copy of a BillionDollarDapp pixel art. This would be a great way to get people talking about your cafe and generate a lot of word-of-mouth marketing.</p>
                      <p>Think of the various branding opportunities for businesses that currently have generic or otherwise lackluster names:</p>
                      <ol>
                        <li>BillionDollarVodka</li>
                        <li>BillionDollarClothing</li>
                        <li>BillionDollarSpa</li>
                        <li>BillionDollarTacocart</li>
                        <li>BillionDollarFoodtruck</li>
                        <li>BillionDollarCleaningService</li>
                        <li>BillionDollarConstruction</li>
                        <li>BillionDollarWeddingPlanner</li>
                        <li>BillionDollarGraphicDesigner</li>
                        <li>BillionDollarRealEstate</li>
                      </ol>
                      <p>The possibilities are endless for BillionDollarDapp pixel owners, and with this license they can easily turn their small business into a million or billion dollar business. And they can look great doing it too, with the high-quality art from BillionDollarDapp. Now with the BillionDollarDapp pixel art license, you can make your business look as exclusive and luxurious as you want, without spending a fortune.</p>
                      <p>This is an opportunity for small businesses to get ahead and make a real name for themselves in the industry with the BillionDollarDapp logo letting everyone know that this business means business.</p>
                    </Tab>
                    <Tab eventKey="cashflow_potential" title="Cashflow Potential">
                      <p></p>
                      <p>
                        Pixels may also be rented for Cashflow! 
                        Anyone can rent a pixel by paying the pixel owner a fee of {this.state.rentalPricePerTimeUnit} Ether per hour.
                        Each pixel may be rented for up to 720 hours at a time, which is about a month.  
                        That means, a fully rented Pixel can generate {this.state.rentalPricePerTimeUnit * 720} Ether per month!
                        That is {12 * (this.state.rentalPricePerTimeUnit * 720)} Ether per year!
                        The Dapp as a whole, once fully minted can generate {this.state.rentalPricePerTimeUnit * 720 * 1024} Ether per month which is {this.state.rentalPricePerTimeUnit * 720 * 1024 * 12} Ether per year (if rully rented)!
                        The pixel owners receives {100-this.state.rentalCommissionPercent}% of this payment with the remainder ({this.state.rentalCommissionPercent}%) supporting BillionDollarDapp.  
                      </p>
                      <p>
                        Renters can set a new background color for the pixel during the renal period, and 
                        provide additional dedication text that will appear with the pixel during the rental period.
                        Apart from bragging rights, renters do not get any other benefits from renting a pixel.
                      </p> 
                    </Tab>
                    <Tab eventKey="Charity" title="Charity">
                      <p></p>
                      <p>Let's make a positive impact on the world together.</p>
                      <p>We love partnering with causes to raise awarness and resources.</p>
                      <p>Partner with us and receive a portion of the fees collected from the creation and rental of BillionDollarDapp pixels, and encourage those that are donating to color and dedicate the piece to your cause.</p>
                      <p>At the end of the event, we will send an Ethereum transaction to the verified address for your cause.</p>
                      <p><b>Past Events:</b></p>
                      <ul>
                      <li><a href="https://etherscan.io/tx/0xe33ab31cf4cedacc12a75d2616b933596378f6f88123d256f4d7b60d54537dc0" target="_new">Our Donation to the India Covid Crypto Relief Fund</a></li>
                      </ul>
                    </Tab>
                    <Tab eventKey="art_appraisers" title="Appraisals">
                      <p></p>
                      <p> 
                        I am an art appraiser and I have been asked to appraise the three Pixel works of art by BillionDollarDapp. 
                        Upon first inspection, it is clear that each work is unique and consists of different sets of lines and color combinations. 
                        After further analysis, I have determined that each work is individually worth 1 million DOLLARS and the combined work is worth 1 billion DOLLARS. 
                        The reason each work is worth so much is because of the unique nature of the lines and colors used in each piece. 
                        It is rare to find such combinations that are so pleasing to the eye, and I believe that these works will only become more valuable over time. 
                        Thank you for your time and I hope this appraisal is helpful to you. 
                        Sincerely, 
                        Madame Nadine Leroux, Appraiser of Fine European Art
                      </p>
                      <hr/>
                      <p>
                        I am writing to you regarding the BillionDollarDapp Pixel artwork collection. 
                        I have had the opportunity to appraise this collection and I believe it to be of great value. 
                        Each item in the collection is unique, consisting of three different sets of lines, either angular or smooth. 
                        The lines are assigned a unique color by the Ethereum transaction, but the original pixel creator chooses the background color. 
                        This gives each work its own individual appearance and makes it impossible to replicate. 
                        The works are also titled based on the types of lines and colors used, such as "#62 - Angular Indigo, Biscay, and Cinnabar on Blue Bell." 
                        This adds to the uniqueness of each piece and makes the collection as a whole even more valuable. 
                        In comparison to other valuable modern art series, the BillionDollarDapp Pixel artwork collection is of similar or greater value. 
                        Each work is individually worth at least 1 million DOLLARS, and the combined work of 1024 Pixels is worth in excess of 1 billion DOLLARS. 
                        Thank you for the opportunity to appraise this collection, 
                        Monsieur Antoine Laurent, Appraiser of Fine Art
                      </p>
                    </Tab>
                    <Tab eventKey="secondary_sales" title="Secondary Sales">
                      <p></p>
                      <p>Because BillionDollarDapp Pixels are ERC721 complaint, they are able to be bought and sold on secondary markets like OpenSea.</p>
                      <p>With the DOLLARS contract, your Pixels can always be bought and sold directly from our contract for 1 million DOLLARS.</p>
                      <p>If you are a BillionDollarDapp Pixel owner, before listing a Pixel for sale consider whether it makes sense to list them on a secondary market place for less than 1 million DAI or an equivalent value of alternate currency, when you can always exchange them for 1 million DOLLARS here.</p>
                      <p></p>
                      <Table striped bordered hover responsive size="sm">
                        <tbody>
                        <tr><th>PIXELS</th><th>Less Than $1 million</th><th>More than $1 million</th></tr>
                        <tr><th>Buy</th>
                          <td>Yes, because the pixel is worth more than the price of the underlying assets.</td>
                          <td>Maybe. The answer depends on how much someone is willing to pay for a Pixel. If someone is willing to pay more than $1 million for a Pixel, then it makes sense to buy the Pixel.</td></tr>
                        <tr><th>Sell</th>
                          <td>No, it does not make sense to sell a pixel for less than $1 million because it is worth the same amount as 1 million DOLLARS, which is pegged to 1 million DAI and 9.55 FASTCASH.</td>
                          <td>Yes, it makes sense to sell a pixel for more than $1 million if the demand for the pixel is high. If there are many people who want to buy the pixel, then the seller can charge a higher price.</td>
                        </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </Tab>
                <Tab eventKey="dollars" title="DOLLARS">
                  <Tabs defaultActiveKey="dollars_overview" id="uncontrolled-tab-example-dollars">
                    <Tab eventKey="dollars_overview" title="Overview">
                      <p></p>
                      <p>DOLLARS stands for Decentralized On-Chain Ledger with Locked Asset Reserves Stablecoin.  Every DOLLARS issued is backed by something of value — an asset that is locked in reserve.  The assets held by the contract are chosen to give stability to DOLLARS.</p>
                      <p>The DOLLARS contract backs the value of each BillionDollarDapp Pixel. Each Pixel can be exchanged at any time for 1 million DOLLARS.  Each 1 million DOLLARS is backed by one BillionDollarDapp pixel or another equally valuable asset.</p>
                      <p>DOLLARS may also be purchased individually for 1 DAI each, or for 1/(0.25*(1.2**71)) FASTCASH.  That is 9.55413 FASTCASH per 1 million DOLLARS.</p>
                      <p>Thus, each DOLLAR is always backed by 1/(1 million)th of a BDD Pixel, 1 DAI, or 1/(0.25*(1.2**71)) FASTCASH.</p>
                      <p>Both 1 DAI and 1/(0.25*(1.2**71)) FASTCASH (0.00000955413 FASTCASH) are also equivalent to $1 USD.</p>
                      <p>Therefore, each Pixel is worth 1 million DOLLARS, which is equivalent to 1 million DAI (and backed by $1 million USD).</p>
                    </Tab>
                    <Tab eventKey="dollars_exchange" title="Exchange">
                        <p></p>
                        <p>Does it make sense to buy or sell DOLLARS?</p>
                        <Table striped bordered hover responsive size="sm">
                          <tbody>
                          <tr><th>DOLLARS</th><th>Less Than $1 million</th><th>More than $1 million</th></tr>
                          <tr><th>Buy</th>
                            <td>Yes, it does make sense to buy 1 million DOLLARS for less than $1 million. The value of the DOLLAR is pegged to the value of the DAI and FASTCASH, so buying 1 million DOLLARS for less than $1 million is essentially buying the underlying assets at a discount.</td>
                            <td>No, it does not make sense to buy 1 million DOLLARS for more than $1 million.  This is because 1 million DOLLARS is pegged to 1 million DAI and 9.55 FASTCASH.  Both of these are worth $1 million.  Therefore, buying 1 million DOLLARS for more than $1 million would be equivalent to overpaying for the underlying assets.</td></tr>
                          <tr><th>Sell</th>
                            <td>No, it does not make sense to sell 1 million DOLLARS for less than $1 million. This is because 1 million DOLLARS is pegged to 1 million DAI and 9.55 FASTCASH. Both are worth $1 million. Therefore, selling 1 million DOLLARS for less than $1 million would result in a loss.</td>
                            <td>Yes, it does make sense to sell a 1 million DOLLARS for more than $1 million if the market conditions are right. For example, if there is high demand for the 1 million DOLLARS and not enough supply, then the price of the 1 million DOLLARS will go up.</td>
                          </tr>
                          </tbody>
                        </Table>
                      </Tab>
                  </Tabs>
                </Tab>
                <Tab eventKey="community_provenance" title="Community & Provenance">
                  <p></p>
                  <p>BillionDollarDapp traces back to April 2021. This makes it one of the earliest generative art projects on Ethereum only mere months after the launch of ArtBlocks.</p>
                  <p>BillionDollarDapp also gave rise to the technology that became <a href="https://co.immutables.eth.link" target="_new">][ immutables.co</a>, 
                  a fully decentralized content publishing platform, and <a href="https://art.immutables.eth.link" target="_new">][ immutables.art</a>, 
                  the first fully decentralized generative art platform.</p>
                  <p>BillionDollarDapp still serves as a testing ground for new features for the Immutables ecosystem.</p>
                  <p>If you are interested in fully decentralized generative art and content publishing platforms, join our community via Twitter and Discord. We hope to see you soon.</p>
                </Tab>
              </Tabs>
              </Modal.Body>
              <Modal.Footer>
                {footerLinkArtAlgorithm}
                {footerLinkBDDEtherscan}
                {footerLinkDollarsEtherscan}
                {footerLinkOpenseaCollection}
                {footerLinkTwitter}
                {footerLinkDiscord}
                {footerLinkToggleDarkMode}
                <Button variant="secondary btn-sm" onClick={this.closeAboutModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    const causesModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={this.state.isCausesOpen} onHide={this.closeCausesModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>Causes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <p>Let's make a positive impact on the world together.</p>
              <p>We love partnering with causes to raise awarness and resources.</p>
              <p>Partner with us and receive a portion of the fees collected from the creation and rental of BillionDollarDapp pixels, and encourage those that are donating to color and dedicate the piece to your cause.</p>
              <p>At the end of the event, we will send an Ethereum transaction to the verified address for your cause.</p>
              <p><b>Past Events:</b></p>
              <ul>
              <li><a href="https://etherscan.io/tx/0xe33ab31cf4cedacc12a75d2616b933596378f6f88123d256f4d7b60d54537dc0">Our Donation to the India Covid Crypto Relief Fund</a></li>
              <li><a href="https://www.cancer.org/involved/donate/more-ways-to-give/donate-cryptocurrency/cryptocurrency-campaign-fund.html">American Cancer Society Crypto Fund</a></li>
              </ul>
              </Modal.Body>
              <Modal.Footer>
                {footerLinkTwitter}
                {footerLinkDiscord}
                {footerLinkToggleDarkMode}
                <Button variant="secondary btn-sm" onClick={this.closeCausesModal}>Close</Button>
              </Modal.Footer>
            </Modal>

    const buttonToggleMuseumMode = (this.state.museumModeActive) ? (
      <Button variant="warning btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleMuseumMode}><b>stop museum mode</b></Button>
    ) : (
      <Button variant="primary btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleMuseumMode}><b>start museum mode</b></Button>
    )

    const buttonToggleDarkMode = (this.state.CSSStyleOverride === "") ? (
      <Button variant="secondary btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleDarkMode}><b>dark mode</b></Button>
    ) : (
      <Button variant="secondary btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleDarkMode}><b>light mode</b></Button>
    )

    const buttonToggleGuerrillaMode  = (this.state.guerrillaModeActive) ? (
      <Button variant="warning btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleGuerrillaMode}><b>stop guerrilla projection mode</b></Button>
    ) : (
      <Button variant="primary btn-lg" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleGuerrillaMode}><b>start guerrilla projection mode</b></Button>
    )

    const museumModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={this.state.isMuseumOpen} onHide={this.closeMuseumModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>galleries & museums</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs defaultActiveKey="museum_officcial" id="uncontrolled-tab-example-museum">
                  <Tab eventKey="museum_officcial" title="Consentual Museum Exhibits">
                    <img src="BDD_Chi_Art.png" width="100%" alt="BillionDollarDapp Pixels on Exhibit in a Museum"></img>
                    <p></p>
                    <p>We love partnering with galleries & museums.</p>
                    <p>BillionDollarDapp is an interactive grid that can be manipulated in real-time by patrons.</p>
                    <p>Partner with us and receive a portion of the fees collected from the creation and rental of BillionDollarDapp pixels while on Exhibition at your institution.</p>
                    <p>At the end of the event, we will send an Ethereum transaction to the verified address for your institution.</p>
                    <p></p>
                    <p>Start Museum Mode to randomly show different BillionDollarDapp Pixels on an unattended display.</p>
                    <div align="center">
                    {buttonToggleDarkMode}
                    <p></p>
                    {buttonToggleMuseumMode}
                    </div>
                  </Tab>
                  <Tab eventKey="museum_guerrilla" title="Guerrilla Projections">
                    <p></p>
                    <img src="BDD_MOMA_gm.png" width="100%" alt="BillionDollarDapp Pixels Projected onto the MOMA PS1 Exterior"></img>
                    <p></p>
                    <p>There is a 1 million DOLLARS bounty for anyone who can project the BillionDollarDapp Pixel artwork onto the exterior of museums, and provide pictures as proof.</p>
                    <p>This artwork is to be projected onto the exterior of museums to increase the number of institutions in which BillionDollarDapp Pixels have been exhibited.</p>
                    <p>In order to claim the bounty, you must provide pictures of the projected artwork on the exterior of museum, as well as the name of the museum. The projection should be adjacent to the museum logo or other signage, so that it is clear that the projection is taking place at the museum.</p>
                    <p>If you attempt to claim this bounty, please do this in accordance with all local laws and regulations. If asked to stop please comply and be respetful. We do not take any responsibility for any actions taken by individuals in attempt to claim this bounty.</p>
                    <p>You can read more about guerrilla projection at the following links:</p>
                    <ul>
                      <li><a href="http://theilluminator.org/wp-content/uploads/projection-tip-sheet.pdf" target="_new">Guerrilla Projections Projection Tip Sheet</a></li>
                      <li><a href="https://www.noo.world/articles/102-guide-guerrilla-projections" target="_new">Guide to Guerrilla Projections</a></li>
                      <li><a href="https://beautifultrouble.org/toolbox/tool/guerrilla-projection/" target="_new">Guerrilla Projection - Beautiful Trouble</a></li>
                      <li><a href="https://harvardlawreview.org/2020/02/property-and-projection/" target="_new">Property and Projection - Harvard Law Review</a></li>                      
                    </ul>
                    <p>Guerrilla Projection Mode simultaneously turns on Dark Mode, Museum Mode, and also draws each of the BillionDollarDapp Pixels as white lines on a black background to help ensure a vivid projection.</p>
                    <div align="center">
                      {buttonToggleGuerrillaMode}
                    </div>
                  </Tab>
              </Tabs>
              </Modal.Body>
              <Modal.Footer>
                {footerLinkTwitter}
                {footerLinkDiscord}
                {footerLinkToggleDarkMode}
                <Button variant="secondary btn-sm" onClick={this.closeMuseumModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    const metamaskModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={this.state.isMetamaskOpen} onHide={this.closeMetamaskModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>Get MetaMask</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              In order to interact with our contract on the Ethereum network (so you can create, rent, or update your pixels) you need to get <a href="https://metamask.io/">MetaMask</a>.
              </Modal.Body>
              <Modal.Footer>
              <Button variant="outline-secondary btn-sm" href="https://metamask.io/">Get MetaMask</Button>
                {footerLinkToggleDarkMode}
                <Button variant="secondary btn-sm" onClick={this.closeMetamaskModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
    
    const createPixelForm = <form onSubmit={(event) => {
              event.preventDefault()
              const color = this.state.color
              this.mint(color)
            }}>
            <Form.Check
              required
              label={`I agree to the License Terms`}
            />
            <p></p>
            <ChromePicker
              color={ this.state.color }
              onChangeComplete={ handleColorChange }
            />

            <input
              id="submit"
              type="submit"
              className="btn btn-block btn-primary"
              value="Mint a Pixel"
            />
            Mint for {this.state.nextPixelPrice / 10**18} Ether
            </form>
            
    const createModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={this.state.isCreateOpen} onHide={this.closeCreateModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>mint a pixel</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Tabs defaultActiveKey="mint" id="uncontrolled-tab-example">
                <Tab eventKey="mint" title="Mint a Pixel">
                  <p></p>
                  <p>Each pixel is unique and generated based on the cryptographic hash produced by the Ethereum network when the pixel is created.</p>
                  <p>You select a background color. This, along with many other factors, will influence the transaction hash during the pixel creation process.</p>
                  <p>The transaction hash is then used to generate the artwork for the pixel.  You will see the artwork for the first time after creating the pixel.</p>
                  {/*<p>Only 32 x 32 pixels will ever exist.  Then, these pixels can only be exchanged on the open market or rented.</p>
                  <p>We all are connected in one way or another. Each pixel represents this via the generated artwork.</p>
                  <p>Dedication text can be added to the piece on the Ethereum blockchain to represent the people, milestones, or causes the owner of the piece wants to bring attention to.</p>
                  <p align="center"><b>Select a Color and Mint a Pixel</b></p>*/}
                  <p></p>
                  <p>
                    Mint price is calculated as a linear function from 0 Eth to {this.state.maximumCreationPrice / 10**18} Ether.
                    This causes the average mint price to be about {(this.state.maximumCreationPrice / 10**18) / 2} Ether.
                    <p></p>
                    <div align="center" style={{fontSize:"larger"}}>
                    The mint price for the next Pixel is {this.state.nextPixelPrice / 10**18} Ether.
                    </div>
                  </p>
                  <p></p>
                  <div className="col-lg-12 d-flex text-center">
                    <div className="content mr-auto ml-auto">
                      {createPixelForm}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="license" title="License Terms">
                    <p></p>
                    <div align="center" style={{fontSize:"larger"}}>TERMS & CONDITIONS</div>
                    <p>BillionDollarDapp is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing 
                      participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum 
                      wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the BillionDollarDapp 
                      smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
                    <p>This website and its connected services are provided “as is” and “as available” without warranty of any kind. 
                      By using this website you are accepting sole responsibility for any and all transactions involving BillionDollarDapp digital collectibles.</p>
                    <div align="center" style={{fontSize:"larger"}}>OWNERSHIP</div>
                    <ol>
                    <li><b>BillionDollarDapp Owns the Entire Copyright.</b> Minters grant BillionDollarDapp an assignment to any potential copyright claim you have for selecting the color of the Pixel and therefore influencing the minting process.  
                    This ensures that there is uniform ownership of the entire set of pixels, so that when they are traded all necessary rights can be conferred to subsequent owners.</li>
                    <li><b>You Own the NFT.</b> Each BillionDollarDapp Pixel is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying BillionDollarDapp Pixel,
                    the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify 
                    the ownership of any BillionDollarDapp Pixel.</li>
                    <li><b>Personal Use.</b> Subject to your continued compliance with these Terms, BillionDollarDapp grants you a worldwide, royalty-free sole license to use, copy, and
                    display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: 
                    (i) for your own personal, non-commercial use;
                    (ii) as part of a marketplace that permits the purchase and sale of your BillionDollarDapp Pixel / NFT, provided that the marketplace cryptographically verifies 
                    each BillionDollarDapp Pixel owner’s rights to display the Art for their BillionDollarDapp Pixel to ensure that only the actual owner can display the Art; or 
                    (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your BillionDollarDapp Pixel, 
                    provided that the website/application cryptographically verifies each BillionDollarDapp Pixel owner’s rights to display the Art for their 
                    BillionDollarDapp Pixel to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the
                      owner of the BillionDollarDapp Pixel leaves the website/application.</li>
                    <li><b>Commercial Use.</b> Subject to your continued compliance with these Terms, BillionDollarDapp grants you an unlimited, worldwide license to use, 
                    copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use 
                    would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing
                    in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of BillionDollarDapp Pixels generally,
                      provided that the marketplace cryptographically verifies each BillionDollarDapp Pixel owner’s rights to display the Art for their BillionDollarDapp Pixel to ensure 
                      that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation 
                      of BillionDollarDapp Pixels generally, provided that the third party website or application cryptographically verifies each BillionDollarDapp Pixel owner’s 
                      rights to display the Art for their BillionDollarDapp Pixel to ensure that only the actual owner can display the Art, and provided that the Art is no 
                      longer visible once the owner of the Purchased BillionDollarDapp Pixel leaves the website/application; or (iii) earning revenue from any of the foregoing.</li>
                    </ol>
                </Tab>
              </Tabs>
              </Modal.Body>
              <Modal.Footer>
                {footerLinkArtAlgorithm}
                {footerLinkBDDEtherscan}
                {footerLinkDollarsEtherscan}
                {footerLinkOpenseaCollection}
                {footerLinkTwitter}
                {footerLinkDiscord}
                {footerLinkToggleDarkMode}
                <Button variant="secondary btn-sm" onClick={this.closeCreateModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

    let pixelEventHistoryTable;
    if(this.state.web3) {
      pixelEventHistoryTable = <PixelEventTable platform={this.state.platform} data={this.state.selectedPixelEventHistoryData}></PixelEventTable>
    } else {
      pixelEventHistoryTable = "";
    }

    const detailsModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                size="lg" show={this.state.isDetailsOpen} onHide={this.closeDetailsModal}>
                  <Modal.Header closeButton>
                    <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>pixel #{this.state.selectedPixel} cashflow & details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Tabs defaultActiveKey="cashflow" id="uncontrolled-tab-example">
                      <Tab eventKey="cashflow" title="Cashflow">
                        <p>
                          <div align="center" style={{fontSize:"larger"}}>
                            <p></p>
                            {(this.state.selectedPixelTotalRentalRevenue > 0) ? (
                              `This Pixel has generated cashflow of ${this.state.web3.utils.fromWei(String(this.state.selectedPixelTotalRentalRevenue),"ether")} Ether!`
                            ) : ( "This Pixel will generate cahsflow when it is rented!" )}
                          </div>
                        </p> 
                      </Tab>
                      <Tab eventKey="details" title="Transaction Details">
                        <p></p>
                        <p>Additional pixel details can be found on OpenSea.  The pixel creation transaction information can be found on Etherscan.</p>
                        { pixelEventHistoryTable }
                      </Tab>
                    </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                    {footerLinkPixelDetails}
                    {footerLinkOpenseaPixelDetails}
                    {footerLinkToggleDarkMode}
                    <Button variant="secondary btn-sm" onClick={this.closeDetailsModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

    const updatePixelTextForm = <form onSubmit={(event) => {
                  event.preventDefault()
                  const newAltText = this.state.formAltText
                  this.poUpdateAlt(this.state.selectedPixel, newAltText)
                  this.closeUpdateModal()
                }}>

                <Form.Group controlId="newAltText">
                  <Form.Label>Dedication Text: </Form.Label>
                  <Form.Control type="text" placeholder=""
                      onChange={e => this.setState({ formAltText: e.target.value })}/>
                  <Form.Text className="text-muted">
                    Dedication text up to 255 characters.
                  </Form.Text>
                </Form.Group>

                <input
                  id="altTextSubmit"
                  type="submit"
                  className="btn btn-block btn-primary"
                  value="Update Dedication Text"
                />
                </form>
    const updatePixelColorForm = <form onSubmit={(event) => {
                  event.preventDefault()
                  const color = this.state.color
                  this.poUpdateColor(this.state.selectedPixel, color)
                  this.closeUpdateModal()
                }}>

                <ChromePicker
                  color={ this.state.color }
                  onChangeComplete={ handleColorChange }
                />

                <input
                  id="submit"
                  type="submit"
                  className="btn btn-block btn-primary"
                  value="Update Background Color"
                />
                </form>
    const updateModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                size="lg" show={this.state.isUpdateOpen} onHide={this.closeUpdateModal}>
                  <Modal.Header closeButton>
                    <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>update this pixel</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p></p>
                  <p>Congratulations, you own this pixel!</p>
                  <p>You can update the background color and dedication text at any time.</p>
                  <p></p>
                  <p align="center"><b>Update On-Chain Dedication Text</b></p>
                  <div className="col-lg-12 d-flex text-center">
                    <div className="content mr-auto ml-auto">
                      {updatePixelTextForm}
                    </div>
                  </div>
                  <p></p>
                  <p align="center"><b>Update On-Chain Background Color</b></p>
                  <div className="col-lg-12 d-flex text-center">
                    <div className="content mr-auto ml-auto">
                      {updatePixelColorForm}
                    </div>
                  </div>

                  </Modal.Body>
                  <Modal.Footer>
                    {footerLinkDollarsEtherscan}
                    {footerLinkOpenseaCollection}
                    {footerLinkToggleDarkMode}
                    <Button variant="secondary btn-sm" onClick={this.closeUpdateModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

        const approveForm = <form onSubmit={(event) => {
            event.preventDefault()
            this.approveDollarsOnBDD()
          }}>
          <input
            id="submit"
            type="submit"
            className="btn btn-block btn-warning"
            value="Approve Dollars Contract on the BillionDollarDapp Pixel Contract"
          />
          </form>

        const buyDollarsWithPixelForm = <form onSubmit={(event) => {
            event.preventDefault()
            this.buyDollarsWithPixel(this.state.selectedPixel)
          }}>
          <input
            id="submit"
            type="submit"
            className="btn btn-block btn-primary"
            value="Sell This Pixel for 1 million DOLLARS"
          />
          </form>

        const sellForDollarsModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                            size="lg" show={this.state.isSellForDollarsOpen} onHide={this.closeSellForDollarsModal}>
                              <Modal.Header closeButton>
                                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>sell this pixel for dollars</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p></p>
                              <p>Congratulations, you own this Pixel!</p>
                              <p>You can sell this Pixel for 1 million DOLLARS!</p>
                              <p></p>
                              {(this.state.dollarsLoading) ? (
                                <>
                                <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                <div align="center"><Spinner animation="grow" /></div>
                                </>
                              ) : (
                                (!this.state.dollarsApprovedOnBdd) ? (
                                <>
                                  {approveForm}
                                  <p></p>
                                </>
                                ) : (
                                  <>
                                    {buyDollarsWithPixelForm}
                                    <p></p>
                                  </>
                                )
                              )}
                              </Modal.Body>
                              <Modal.Footer>
                                {footerLinkBDDEtherscan}
                                {footerLinkDollarsEtherscan}
                                {footerLinkOpenseaCollection}
                                {footerLinkToggleDarkMode}
                                <Button variant="secondary btn-sm" onClick={this.closeSellForDollarsModal}>
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>

        const approveDollarsForm = <form onSubmit={(event) => {
            event.preventDefault()
            this.approveDollars()
          }}>
            <p></p>
          <input
            id="submit"
            type="submit"
            className="btn btn-block btn-warning"
            value="Approve DOLLARS Contract to Spend Your DOLLARS"
          />
          </form>

        const sellDollarsForPixelForm = <form onSubmit={(event) => {
            event.preventDefault()
            this.sellDollarsForPixel(this.state.selectedPixel)
          }}>
            <p></p>
          <input
            id="submit"
            type="submit"
            className="btn btn-block btn-primary"
            value="Buy this Pixel for 1 million DOLLARS"
          />
          </form>

        const buyForDollarsModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                            size="lg" show={this.state.isBuyForDollarsOpen} onHide={this.closeBuyForDollarsModal}>
                              <Modal.Header closeButton>
                                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>buy this pixel for dollars</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                              <p>This Pixel is owned by the DOLLARS contract.</p>
                              <p>You can buy this Pixel for 1 million DOLLARS!</p>
                              <p>You currently have {this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS.</p>
                              <p></p>
                              {(this.state.dollarsLoading) ? (
                                <>
                                <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                <div align="center"><Spinner animation="grow" /></div>
                                </>
                              ) : (
                                (this.state.dollarsAllowance < 1000000) ? (
                                <>
                                  {approveDollarsForm}
                                  <p></p>
                                </>
                                ) : (
                                  <>
                                  {sellDollarsForPixelForm}
                                  <p></p>
                                  </>
                                )
                              )}
                              </Modal.Body>
                              <Modal.Footer>
                                {footerLinkBDDEtherscan}
                                {footerLinkDollarsEtherscan}
                                {footerLinkOpenseaCollection}
                                {footerLinkToggleDarkMode}
                                <Button variant="secondary btn-sm" onClick={this.closeBuyForDollarsModal}>
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>

        const approveDAIForDollarsForm = <form onSubmit={(event) => {
                                      event.preventDefault()
                                      this.approveDAI()
                                    }}>
                                    <p></p>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-warning"
                                      value="Approve DOLLARS Contract to Spend Your DAI"
                                    />
                                    </form>
        const approveFCMForDollarsForm = <form onSubmit={(event) => {
                                      event.preventDefault()
                                      this.approveFCM()
                                    }}>
                                    <p></p>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-warning"
                                      value="Approve DOLLARS Contract to Spend Your FASTCASH"
                                    />
                                    </form>
        const buyDollarsWithDAIForm = <form onSubmit={(event) => {
                                        event.preventDefault()
                                        this.buyDollarsWithDAI(this.state.web3.utils.toWei(event.target.amount.value, 'ether'))
                                      }}>
                                      <div align="center">
                                      DAI Amount: <input type="number" id="amount" min="0" placeholder="0" step="any" required></input>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Buy DOLLARS With DAI"
                                      />
                                      </div>
                                      </form>
        const buyDollarsWithFCMForm = <form onSubmit={(event) => {
                                        event.preventDefault()
                                        this.buyDollarsWithFCM(this.state.web3.utils.toWei(event.target.amountFCM.value, 'ether'))
                                      }}>
                                      <div align="center">
                                      FASTCASH Amount: <input type="number" id="amountFCM" min="0" placeholder="0" step="any" required></input>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Buy DOLLARS With FASTCASH"
                                      />
                                      </div>
                                      </form>
        const sellDollarsForDAIForm = <form onSubmit={(event) => {
                                        event.preventDefault()
                                        this.sellDollarsForDAI(this.state.web3.utils.toWei(event.target.amountDollars.value, 'ether'))
                                      }}>
                                      <div align="center">
                                      DOLLARS to Sell for DAI: <input type="number" id="amountDollars" min="0" placeholder="0" step="any" required></input>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Sell DOLLARS to Buy DAI"
                                      />
                                      </div>
                                      </form>
        const sellDollarsForFCMForm = <form onSubmit={(event) => {
                                        event.preventDefault()
                                        this.sellDollarsForFCM(this.state.web3.utils.toWei(event.target.amountDollars.value, 'ether'))
                                      }}>
                                      <div align="center">
                                      DOLLARS to Sell for FASTCASH: <input type="number" id="amountDollars" min="0" placeholder="0" step="any" required></input>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Sell DOLLARS to Buy FASTCASH"
                                      />
                                      </div>
                                      </form>
                                      

        const titleBuyDollarsModal = "buy & sell dollars"
        const buyDollarsModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                          size="lg" show={this.state.isBuyDollarsOpen} onHide={this.closeBuyDollarsModal}>
                            <Modal.Header closeButton>
                              <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{titleBuyDollarsModal}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p></p>
                            <p>Each DOLLARS is pegged to other USD-based cryptocurrencies.  You can buy DOLLARS with DAI or FASTCASH!</p>
                            <p></p>
                            <div align="center" style={{border:"2px", borderColor: "green"}}>
                              <p><b>Exchange Rates:</b></p>
                              <p>1 DOLLAR = 1 DAI</p>
                              <p>1 DOLLAR = 0.00000955413 FASTCASH</p>
                            </div>
                            <p></p>
                            <Tabs defaultActiveKey="buy_dollars_with_dai" id="uncontrolled-tab-example">
                              <p></p>
                              <p>You currently have {this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS.</p>
                              <Tab eventKey="buy_dollars_with_dai" title="Buy DOLLARS with DAI">
                                <p></p>
                                { (false) ? (
                                  <>
                                  <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                  <div align="center"><Spinner animation="grow" /></div>
                                  </>
                                ) : (
                                  <>
                                  <p></p>
                                  { (this.state.dollarsLoading) ? (
                                    <>
                                    <p></p>
                                    <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                    <div align="center"><Spinner animation="grow" /></div>
                                    </>
                                  ) : (
                                    (this.state.dollarsDaiAllowance < 1000000) ? (
                                      <>
                                        {approveDAIForDollarsForm}
                                        <p></p>
                                      </>
                                      ) : (
                                        <>
                                        <p></p>
                                        <p>You currently have {this.state.accountDaiBalance.toLocaleString("en-US")} DAI.</p>
                                        DAI available to use to purchase DOLLARS:
                                        <p></p>
                                        <Table striped bordered hover responsive size="sm">
                                          <tbody>
                                            <tr><td><b>MakerDAO DAI:</b></td><td>{this.state.accountDaiBalance.toLocaleString("en-US")} DAI</td></tr>
                                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{this.state.accountDaiBalance.toLocaleString("en-US")} DOLLARS</b></td></tr>
                                          </tbody>
                                        </Table>
                                        {buyDollarsWithDAIForm}
                                        </>
                                      )
                                  )}               
                                  </>
                                )}
                              </Tab>
                              <Tab eventKey="sell_dollars_for_dai" title="Sell DOLLARS for DAI">
                                <p></p>
                                { (this.state.dollarsLoading) ? (
                                  <>
                                  <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                  <div align="center"><Spinner animation="grow" /></div>
                                  </>
                                ) : (
                                  (this.state.dollarsAllowance < 1000000) ? (
                                    <>
                                      <p></p>
                                      <p>You need to approve the DOLLARS contract to spend your DOLLARS.</p>
                                      <p></p>
                                      {approveDollarsForm}
                                      <p></p>
                                    </>
                                    ) : (
                                      <>
                                        <p></p>
                                        <p>You currently have {this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS.</p>
                                        <p></p>DAI available to be purchased for DOLLARS:
                                        <p></p>
                                        <Table striped bordered hover responsive size="sm">
                                          <tbody>
                                            <tr><td><b>Staked MakerDAO DAI:</b></td><td>{this.state.dollarsDaiBalance.toLocaleString("en-US")} DAI</td></tr>
                                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{this.state.dollarsDaiBalance.toLocaleString("en-US")} DOLLARS</b></td></tr>
                                          </tbody>
                                        </Table>
                                        {sellDollarsForDAIForm}
                                      </>
                                    )
                                )}
                              </Tab>
                              <Tab eventKey="buy_dollars_with_fcm" title="Buy DOLLARS with FASTCASH">
                                  <p></p>
                                  { (this.state.dollarsLoading) ? (
                                    <>
                                    <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                    <div align="center"><Spinner animation="grow" /></div>
                                    </>
                                  ) : (
                                    (this.state.dollarsFcmAllowance < 1000000) ? (
                                      <>
                                        {approveFCMForDollarsForm}
                                        <p></p>
                                      </>
                                      ) : (
                                        <>
                                        <p></p>
                                        <p>You currently have {this.state.accountFcmBalance.toLocaleString("en-US")} FASTCASH.</p>
                                        FASTCASH available to use to purchase DOLLARS:
                                        <p></p>
                                        <Table striped bordered hover responsive size="sm">
                                          <tbody>
                                            <tr><td><b> FASTCASH:</b></td><td>{this.state.accountFcmBalance.toLocaleString("en-US")} FASTCASH</td></tr>
                                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{(this.state.accountFcmBalance * (0.25*(1.2**71))).toLocaleString("en-US")} DOLLARS</b></td></tr>
                                          </tbody>
                                        </Table>
                                        {buyDollarsWithFCMForm}
                                      </>
                                      )
                                  )}
                              </Tab>
                              <Tab eventKey="sell_dollars_for_fcm" title="Sell DOLLARS for FASTCASH">
                                <p></p>
                                  { (this.state.dollarsLoading) ? (
                                    <>
                                    <p></p>
                                    <div align="center"><p>Please wait while the changes are processed ...</p></div>
                                    <div align="center"><Spinner animation="grow" /></div>
                                    </>
                                  ) : (
                                    (this.state.dollarsAllowance < 1000000) ? (
                                      <>
                                        {approveDollarsForm}
                                        <p></p>
                                      </>
                                      ) : (
                                        <>
                                        <p></p>
                                        <p>You currently have {this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS.</p>
                                        FASTCASH available to be purchased for DOLLARS:
                                        <p></p>
                                        <Table striped bordered hover responsive size="sm">
                                          <tbody>
                                            <tr><td><b>Staked FASTCASH:</b></td><td>{this.state.dollarsFcmBalance.toLocaleString("en-US")} FASTCASH</td></tr>
                                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{(this.state.dollarsFcmBalance * (0.25*(1.2**71))).toLocaleString("en-US")} DOLLARS</b></td></tr>
                                            </tbody>
                                        </Table>
                                        {sellDollarsForFCMForm}
                                      </>
                                      )
                                  )}
                              </Tab>
                              <Tab eventKey="dollars_market" title="DOLLARS Marketplace">
                                <p></p>
                                <p></p>
                                <p>You currently have {this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS.</p>
                                <p>You can buy and sell DOLLARS for any other tokens on:&nbsp;
                                    <a href="https://illiquid.market/tokens/0x02aC5Aa7571B05702F5537348a0201DCe832E7Ce?network=1" target="_new">Ethereum Mainnet illiquid.market for DOLLARS</a>
                                </p>
                                <p> For more information on illiquid.market, see the following resources:
                                  <ul>
                                    <li><a href="https://unvest.medium.com/illiquid-market-tutorial-how-to-buy-or-sell-any-erc20-token-with-no-liquidity-b4e56947bdd6" target="_new">Medium Article on illiquid.market</a></li>
                                    <li><a href="https://youtu.be/-G1n_2ZV7Yw" target="_new">YouTube Explainer Video on illiquid.market</a></li>
                                  </ul>
                                </p>
                                <p></p>
                              </Tab>
                            </Tabs>
                            </Modal.Body>
                            <Modal.Footer>
                              {footerLinkDAIDOLLARSUniswap}
                              {footerLinkDAIEtherscan}
                              {footerLinkFCMEtherscan}
                              {footerLinkBDDEtherscan}
                              {footerLinkDollarsEtherscan}
                              {footerLinkOpenseaCollection}
                              {footerLinkToggleDarkMode}
                              <Button variant="secondary btn-sm" onClick={this.closeBuyDollarsModal}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>

        const preOwnedPixelsModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                          size="lg" show={this.state.isPreOwnedPixelOpen} onHide={this.closePreOwnedPixelModal}>
                            <Modal.Header closeButton>
                              <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>buy & sell certified pre-owned pixels with dollars</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Tabs defaultActiveKey="buy_preowned_pixel" id="uncontrolled-tab-example_buy_sell_preowned_pixels">
                              <Tab eventKey="buy_preowned_pixel" title="Buy a Certified Pre-Owned Pixel">
                                <p></p>
                                <p>You can buy pre-owned Pixels for 1 million DOLLARS each.</p>
                                <p></p>
                                <p>Explore the grid to find certified pre-owned Pixels that can be bought with DOLLARS.</p>
                                <p>Alternatively, you can see a filtered list of certified pre-owned pixels avaialbe to be bought with DOLLARS on OpenSea by looking at the Pixels owned by the DOLLARS contract.</p>
                                <p>Then, purchase the Pixels for DOLLARS here by selecting the pixel to make it full screen and click the "buy-with-dollars" button.</p>
                                <p>You can also buy Pixels individually or in bulk through the DOLLARS contract on Etherscan.</p>
                                <div align="center">
                                  {buttonForPreOwnedOnOpensea}
                                  <p></p>
                                  {writeContractLinkDollarsEtherscan}
                                </div>
                              </Tab>
                              <Tab eventKey="sell_preowned_pixel" title="Sell a Certified Pre-Owned Pixel">
                                <p></p>
                                <p>You can sell pre-owned Pixels for 1 million DOLLARS each.</p>
                                <p></p>
                                <p>Explore the grid to find certified pre-owned Pixels that you currently own and can sell for DOLLARS.</p>
                                <p>Alternatively, you can see a filtered list of certified pre-owned pixels avaialbe to be sold for DOLLARS on OpenSea by looking at the Pixels you own.</p>
                                <p>Then, sell the Pixels for DOLLARS here by selecting the pixel to make it full screen and click the "sell-for-dollars" button.</p>
                                <p>You can also sell Pixels individually or in bulk through the DOLLARS contract on Etherscan.</p>
                                <div align="center">
                                  {buttonForAccountOwnedOnOpensea}
                                  <p></p>
                                  {writeContractLinkDollarsEtherscan}
                                </div>
                              </Tab>
                            </Tabs>
                            </Modal.Body>
                            <Modal.Footer>
                              {footerLinkBDDEtherscan}
                              {footerLinkDollarsEtherscan}
                              {footerLinkOpenseaCollection}
                              {footerLinkToggleDarkMode}
                              <Button variant="secondary btn-sm" onClick={this.closePreOwnedPixelModal}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>

        const titleNetWorthModal = "net worth"
        const netWorthModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                          size="lg" show={this.state.isNetWorthOpen} onHide={this.closeNetWorthModal}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{titleNetWorthModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p></p>
                    <p>Check your BillionDollarDapp Net Worth!</p>
                    <p>Your Net Worth is the sum of the value of the Pixels you own, plus the amount of DOLLARS you have.
                       Each DOLLAR is pegged to one DAI or 1/(0.25*(1.2**71)) FASTCASH, which are both equivalent to $1 USD.
                       DOLLARS come into existence when a Pixel, an amount of DAI, or an amount of FASTCASH is transferred into the DOLLARS contract.</p>
                    <p></p>
                    <Tabs defaultActiveKey="net_worth" id="uncontrolled-tab-example">
                      <Tab eventKey="net_worth" title="Your Net Worth Spreadsheet">
                        <p></p>
                        <Table striped bordered hover responsive size="sm">
                          <tbody>
                            <tr><td><b>Pixels Owned:</b></td><td>{this.state.pixelBalance}</td></tr>
                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{(this.state.pixelBalance * 1000000).toLocaleString("en-US")} DOLLARS</b></td></tr>
                            <tr><td><b>Dollars Held:</b></td><td><b>{this.state.dollarsBalance.toLocaleString("en-US")} DOLLARS</b></td></tr>
                            <tr><td><b>MakerDAO DAI:</b></td><td>{this.state.accountDaiBalance.toLocaleString("en-US")} DAI</td></tr>
                            <tr><td><i>--- Equivalent USD:</i></td><td><b>${this.state.accountDaiBalance.toLocaleString("en-US")}</b></td></tr>
                            <tr><td><b>FASTCASH:</b></td><td>{this.state.accountFcmBalance.toLocaleString("en-US")} FASTCASH</td></tr>
                            <tr><td><i>--- Equivalent USD:</i></td><td><b>${(this.state.accountFcmBalance * (0.25*(1.2**71))).toLocaleString("en-US")}</b></td></tr>
                            <tr>
                              <td style={{backgroundColor: "#98ff98"}}><b>Your Net Worth:</b></td>
                              <td style={{backgroundColor: "#98ff98"}}><b>${(this.state.dollarsBalance + (this.state.pixelBalance * 1000000) + (this.state.accountFcmBalance * (0.25*(1.2**71))) + this.state.accountDaiBalance).toLocaleString("en-US")}</b></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="bdd_treasury" title="BillionDollarDapp Valuation Spreadsheet">
                      <p></p>
                      <div align="center">
                        <Table striped bordered hover responsive size="sm">
                          <tbody>
                            <tr><td><b>Unminted Pixels:</b></td><td>{1024-this.state.currentTokenId}</td></tr>
                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{((1024-this.state.currentTokenId) * 1000000).toLocaleString("en-US")} DOLLARS</b></td></tr>
                            <tr><td><b>Minted Pixels:</b></td><td>{this.state.currentTokenId}</td></tr>
                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td><b>{(this.state.currentTokenId * 1000000).toLocaleString("en-US")} DOLLARS</b></td></tr>
                            <tr><td><b>Staked Pixels:</b></td><td>{this.state.pixelsStaked}</td></tr>
                            <tr><td><i>--- Equivalent DOLLARS:</i></td><td>{(this.state.pixelsStaked * 1000000).toLocaleString("en-US")} DOLLARS</td></tr>
                            <tr>
                            <td style={{backgroundColor: "#000000"}}></td>
                            <td style={{backgroundColor: "#000000"}}></td>
                            </tr>
                            <tr>
                              <td><i>(Percent of Pixels Minted):</i></td>
                              <td>{(this.state.currentTokenId / 1024 * 100).toFixed(2)}%</td></tr>
                            <tr>
                              <td><i>(Percent of Minted Pixels Staked):</i></td>
                              <td>{(this.state.pixelsStaked / this.state.currentTokenId * 100).toFixed(2)}%</td></tr>
                            <tr>
                              <td><i>(Percent of Total Pixels Staked):</i></td>
                              <td>{(this.state.pixelsStaked / 1024 * 100).toFixed(2)}%</td></tr>
                            <tr>
                            <td style={{backgroundColor: "#000000"}}></td>
                            <td style={{backgroundColor: "#000000"}}></td>
                            </tr>
                            <tr>
                              <td><i>(Total Pixel Rental Revenue):</i></td>
                              <td>{this.state.allPixelsRentalRevenueEth} ETH</td>
                            </tr>
                            <tr>
                              <td style={{backgroundColor: "#000000"}}></td>
                              <td style={{backgroundColor: "#000000"}}></td>
                            </tr>
                            <tr><td><b>Staked MakerDAO DAI:</b></td><td>{this.state.dollarsDaiBalance.toLocaleString("en-US")} DAI</td></tr>
                            <tr><td><i>--- Equivalent USD:</i></td><td><b>${this.state.dollarsDaiBalance.toLocaleString("en-US")}</b></td></tr>
                            <tr><td><b>Staked FASTCASH:</b></td><td>{this.state.dollarsFcmBalance.toLocaleString("en-US")} FASTCASH</td></tr>
                            <tr><td><i>--- Equivalent USD:</i></td><td><b>${(this.state.dollarsFcmBalance * (0.25*(1.2**71))).toLocaleString("en-US")}</b></td></tr>
                            <tr><td><i>Total DOLLARS Supply:</i></td><td>{this.state.dollarsTotalSupply.toLocaleString("en-US")} DOLLARS</td></tr>
                            <tr>
                              <td style={{backgroundColor: "#98ff98"}}><b>Total BillionDollarDapp Value:</b></td><td style={{backgroundColor: "#98ff98"}}>
                              <b>
                                ${((1024 * 1000000) + this.state.dollarsDaiBalance + (this.state.dollarsFcmBalance * (0.25*(1.2**71)))).toLocaleString("en-US")}
                              </b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      </Tab>
                    </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                      {footerLinkDAIEtherscan}
                      {footerLinkFCMEtherscan}
                      {footerLinkBDDEtherscan}
                      {footerLinkDollarsEtherscan}
                      {footerLinkOpenseaCollection}
                      {footerLinkToggleDarkMode}
                      <Button variant="secondary btn-sm" onClick={this.closeNetWorthModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

        const airdropForm = <>
                            <div align="center">
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>congratulations!</h4>
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>{(this.state.signatureData) ? `${(this.state.signatureData.amount/10**18).toLocaleString("en-US")} dollars are waiting for you!` : ""}</h4>

                            <p></p>
                            <p>We have already filled out the paperwork:</p>
                            <p></p>
                            <p>{(this.state.airdropSignerAuthorized) ? "✅ The Signature is Authorized!" : "❌ We detected a problem with the paperwork..."}</p>
                            <p>{(!this.state.airdropAlreadyClaimed) ? "✅ DOLLARS are available to be CLAIMED!" : "❌ You already claimed your DOLLARS."}</p>
                            <p></p>
                            <p>All you have to do is claim your DOLLARS, below!</p>
                            <p></p>
                            <p></p>
                            {(this.state.dollarsLoading) ? (
                                <>
                                <div align="center"><p>Please wait while we get your DOLLARS ...</p></div>
                                <div align="center"><Spinner animation="grow" /></div>
                                </>
                              ) : (
                            <form onSubmit={(event) => {
                            event.preventDefault()
                            this.verifyAndClaimToken(
                              event.target.domain.value, //event.target.amountDollars.value
                              event.target.token.value,
                              event.target.amount.value,
                              event.target.nonce.value,
                              event.target.signature.value
                            )
                            }}>
                            <Table>
                              <tbody>
                                <tr>
                                  <th colspan="2">DOLLARS Airdrop Claim Form</th>
                                </tr>
                                <tr>
                                  <td>Domain:</td>
                                  <td><input type="text" size="66"id="domain" disabled required value={(this.state.signatureData) ? this.state.signatureData.domain : ""}/></td>
                                </tr><tr>
                                  <td>DOLLARS Token Address:</td>
                                  <td><input type="text" size="66" id="token" disabled required value={(this.state.signatureData) ? this.state.signatureData.token : ""}/></td>
                                </tr><tr>
                                  <td>Amount:</td>
                                  <td><input type="text" size="66" id="amount" disabled required value={(this.state.signatureData) ? this.state.signatureData.amount : ""}/></td>
                                </tr><tr>
                                  <td>Nonce:</td>
                                  <td><input type="number" id="nonce" min="0" disabled placeholder="0" step="any" required value={(this.state.signatureData) ? this.state.signatureData.nonce : ""}/></td>
                                </tr><tr>
                                  <td>Signature:</td>
                                  <td><input type="text" size="66" id="signature" disabled required value={(this.state.signatureData) ? this.state.signatureData.signature : ""}/></td>
                                </tr>
                              </tbody>
                            </Table>
                            <p></p>
                            <input
                              id="submit"
                              type="submit"
                              className="btn btn-block btn-primary"
                              value="Claim Your DOLLARS"
                            />
                            </form>
                            )}
                            </div>
                            </>
        
        const airdropSignatureProblem = <>
                            <div align="center">
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>congratulations!</h4>
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>{(this.state.signatureData) ? `${(this.state.signatureData.amount/10**18).toLocaleString("en-US")} dollars are waiting for you!` : ""}</h4>
                            <p></p>
                            <p>We have already filled out the paperwork, but ...</p>
                            <p></p>
                            <p>{(this.state.airdropSignerAuthorized) ? "✅ The Signature is Authorized!" : "❌ We detected a problem with the paperwork ..."}</p>
                            <p></p>
                            <p>Contact us via Twitter or Discord so we can sort out this clerical error.</p>
                            </div>
                            </>

        const airdropAlreadyClaimed = <>
                            <div align="center">
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>congratulations!</h4>
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>{(this.state.signatureData) ? `you received ${(this.state.signatureData.amount/10**18).toLocaleString("en-US")} dollars!` : ""}</h4>
                            <p></p>
                            <p>{(!this.state.airdropAlreadyClaimed) ? "✅ DOLLARS are available to be CLAIMED!" : "✅ You already claimed your DOLLARS."}</p>
                            <p></p>
                            <p>Now, put your DOLLARS to work! Join our Discord and team up with people to buy and sell DOLLARS or pool your DOLLARS to buy a PIXEL!</p>
                            </div>
                            </>
        
        const airdropNotForYou = <>
                            <div align="center">
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>hummmm ...</h4>
                            <h4 style={{fontFamily: 'Major Mono Display, monospace'}}>{(!this.state.signatureData) ? `we dont have anything for you at this time ...` : ""}</h4>
                            <p></p>
                            <p>Join our Discord so you can be a part of future DOLLARS related opportunities!</p>
                            </div>
                            </>

        // if signatureData && airdropSignerAuthorized && !airdropAlreadyClaimed = show claim button
        // if signatureData && !airdropSignerAuthorized && !airdropAlreadyClaimed = show error message
        // if signatureData && airdropAlreadyClaimed = show already claimed message
        const titleAirdropModal = "dollars airdrop"
        const airdropModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                          size="lg" show={this.state.isAirdropOpen} onHide={this.closeAirdropModal}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{titleAirdropModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p></p>
                    {(this.state.signatureData && this.state.airdropSignerAuthorized && !this.state.airdropAlreadyClaimed) ? airdropForm : ""}
                    {(this.state.signatureData && !this.state.airdropSignerAuthorized && !this.state.airdropAlreadyClaimed) ? airdropSignatureProblem : ""}
                    {(this.state.signatureData && this.state.airdropAlreadyClaimed) ? airdropAlreadyClaimed : ""}
                    {(!this.state.signatureData) ? airdropNotForYou : ""}
                    <p></p>
                    </Modal.Body>
                    <Modal.Footer>
                      {footerLinkDiscord}
                      {footerLinkTwitter}
                      {footerLinkDropEtherscan}
                      {footerLinkBDDEtherscan}
                      {footerLinkDollarsEtherscan}
                      {footerLinkToggleDarkMode}
                      <Button variant="secondary btn-sm" onClick={this.closeAirdropModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

        const rentPixelForm = <form onSubmit={(event) => {
                                event.preventDefault()
                                const timeUnits = this.state.formTimeUnits
                                const color = this.state.color
                                const rentalAlt = this.state.formAltText
                                this.anyoneRent(this.state.selectedPixel, timeUnits, color, rentalAlt)

                                this.setState({timeUnits: null, rentalAlt: null})
                                this.closeRentModal()
                              }}>
                              <p></p>
                              <p align="center"><b>Rent Pixel and Set On-Chain Color and Dedication Text</b></p>
                              <Form.Group controlId="timeUnits">
                                <Form.Label>Hours to Rent (1 to 720):</Form.Label>
                                <Form.Control type="number" required placeholder="" step="1"
                                    required onChange={e => this.setState({ formTimeUnits: e.target.value })}/>
                                <Form.Text className="text-muted">
                                  Pixels can be rented in increments of 1 hour up to 720 hours (about 1 month).
                                </Form.Text>
                              </Form.Group>
                              <Form.Group controlId="rentalAltText">
                                <Form.Label>Dedication Text: </Form.Label>
                                <Form.Control type="text" required placeholder=""
                                    onChange={e => this.setState({ formAltText: e.target.value })}/>
                                <Form.Text className="text-muted">
                                  Dedication text up to 255 characters.
                                </Form.Text>
                              </Form.Group>

                              <Form.Check
                                required
                                label={`I agree to the Rental License & Agreement`}
                              />

                              <p> </p>
                              <p align="center"><b>Update Background Color</b></p>
                              <div className="col-lg-12 d-flex text-center">
                                <div className="content mr-auto ml-auto">

                                  <ChromePicker
                                    color={ this.state.color }
                                    onChangeComplete={ handleColorChange }
                                  />


                                  <input
                                    id="submit"
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                    value={`Rent this Pixel`}
                                  />
                                  {`${this.state.rentalPricePerTimeUnit} ETH per hour.`}
                                </div>
                              </div>

                              </form>
        let rentModal;
        if(this.state.selectedPixel != null && !this.state.selectedPixelIsRentable) {
          rentModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                      size="lg" show={this.state.isRentOpen} onHide={this.closeRentModal}>
                        <Modal.Header closeButton>
                          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>rent this pixel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p></p>
                        <p>This pixel is currently rented.</p>
                        <p>When the rental time period ends, you can rent it to update the background color and dedication text for a set period of time.</p>
                        </Modal.Body>
                        <Modal.Footer>
                          {footerLinkBDDEtherscan}
                          {footerLinkDollarsEtherscan}
                          {footerLinkOpenseaCollection}
                          {footerLinkToggleDarkMode}
                          <Button variant="secondary btn-sm" onClick={this.closeRentModal}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
        } else {
          rentModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
                      size="lg" show={this.state.isRentOpen} onHide={this.closeRentModal}>
                        <Modal.Header closeButton>
                          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>rent this pixel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Tabs defaultActiveKey="rent_form" id="uncontrolled-tab-example">
                            <Tab eventKey="rent_form" title="Rent this pixel">
                              <p></p>
                              <div align="center" style={{fontSize:"larger"}}>
                                <p>Rent this Pixel for {this.state.rentalPricePerTimeUnit} ETH per hour!</p>
                              </div>
                              <p>You can rent it to update the background color and dedication text for a set period of time.</p>
                              <p></p>
                              <div className="col-lg-12 d-flex text-center">
                                <div className="content mr-auto ml-auto">
                                  {rentPixelForm}
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="rental_agreement" title="Rental License & Agreement">
                              <p></p>
                              <div align="center" style={{fontSize:"larger"}}>TERMS & CONDITIONS</div>
                              <p>BillionDollarDapp is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing 
                                participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum 
                                wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the BillionDollarDapp 
                                smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
                              <p>This website and its connected services are provided “as is” and “as available” without warranty of any kind. 
                                By using this website you are accepting sole responsibility for any and all transactions involving BillionDollarDapp digital collectibles.</p>
                              <div align="center" style={{fontSize:"larger"}}>OWNERSHIP</div>
                              <ol>
                              <li><b>Bragging Rights Only.</b> No rights are conferred with rental other than the ability to 
                              set the background color and add additional dedication text for the period of time specified in the rental transaction.</li>
                              </ol>
                            </Tab>
                          </Tabs>
                        </Modal.Body>
                        <Modal.Footer>
                          {footerLinkBDDEtherscan}
                          {footerLinkDollarsEtherscan}
                          {footerLinkOpenseaCollection}
                          {footerLinkToggleDarkMode}
                          <Button variant="secondary btn-sm" onClick={this.closeRentModal}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
        }

        const flashBondForm = <form onSubmit={(event) => {
          event.preventDefault()
          const timeUnits = this.state.formTimeUnits
          const color = this.state.color
          const rentalAlt = this.state.formAltText
          this.executeFlashBond(this.state.numberOfBonds)
          this.closeFlashBondModal()
        }}>
        <p></p>
        <p align="center"><b>Execute Flash Bond</b></p>
        <Form.Group controlId="Number of Bonds">
          <Form.Label>Number of Bonds (1 to 100):</Form.Label>
          <Form.Control type="number" required placeholder={this.state.numberOfBonds} step="1" min="1" max="100"
              required onChange={e => this.setState({ numberOfBonds: e.target.value })}/>
          <Form.Text className="text-muted">
            Flash Bonds can be executed in batches of 1 to 100 for 0.000625 Eth each.
          </Form.Text>
          <p></p>
          <h4>{this.state.numberOfBonds*0.000625} ETH (about ${this.state.numberOfBonds*1600*0.000625}) will yield {(10000*this.state.numberOfBonds).toLocaleString("en-US")} DOLLARS</h4>
        </Form.Group>

        <Form.Check
          required
          label={`I agree to the Terms`}
        />

        <p></p>
        
        <div className="col-lg-12 d-flex text-center">
          <div className="content mr-auto ml-auto">

            <input
              id="submit"
              type="submit"
              className="btn btn-block btn-primary"
              value={`Execute Flash Bond`}
            />
          </div>
        </div>

        </form>

        let flashBondModal = "";
        if(this.state.flashbond) {
          flashBondModal = <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg" show={this.state.isFlashBondModalOpen} onHide={this.closeFlashBondModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>execute flashbond</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="flashbond_form" id="uncontrolled-tab-example">
              <Tab eventKey="flashbond_form" title="Execute FlashBond">
                <p></p>
                <div align="center" style={{fontSize:"larger"}}>
                  <p>Execute 1 to 100 Flash Bonds for 0.000625 ETH each.</p>
                </div>
                <p></p>
                <div className="col-lg-12 d-flex text-center">
                  <div className="content mr-auto ml-auto">
                    {flashBondForm}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="rental_agreement" title="Terms">
                <p></p>
                <div align="center" style={{fontSize:"larger"}}>TERMS & CONDITIONS</div>
                <p>BillionDollarDapp is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing 
                  participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum 
                  wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the BillionDollarDapp 
                  smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
                <p>Flash Bonds, DOLLARS, and Pixels are artwork that explores the relationship of art and perceived value, and a parody of the current state of the financial system.</p>
                <p>This website and its connected services are provided “as is” and “as available” without warranty of any kind. 
                  By using this website you are accepting sole responsibility for any and all transactions involving BillionDollarDapp digital collectibles.</p>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            {footerLinkBDDFlashBondEtherscan}
            {footerLinkBDDEtherscan}
            {footerLinkDollarsEtherscan}
            {footerLinkOpenseaCollection}
            {footerLinkToggleDarkMode}
            <Button variant="secondary btn-sm" onClick={this.closeFlashBondModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        }

            let grid;
            if(this.state.selectedPixel == null) {
              grid = <Grid selected={false}
                          platform={this.state.platform}
                          handleClick={this.setSelectedPixel}
                          pixelIds={this.state.pixelIds}
                          colors={this.state.colors}
                          ownerAlts={this.state.ownerAlts}
                          renterAlts={this.state.renterAlts}
                          pixelHashes={this.state.pixelHashes}
                          guerrillaModeActive={this.state.guerrillaModeActive}
                          >
                      </Grid>

            } else {
              grid = <Grid selected={true}
                          platform={this.state.platform}
                          handleClick={this.resetSelectedPixel}
                          pixelIds={[this.state.pixelIds[this.state.selectedPixel-1]]}
                          colors={[this.state.colors[this.state.selectedPixel-1]]}
                          ownerAlts={[this.state.ownerAlts[this.state.selectedPixel-1]]}
                          renterAlts={[this.state.renterAlts[this.state.selectedPixel-1]]}
                          pixelHashes={[this.state.pixelHashes[this.state.selectedPixel-1]]}
                          setSelectedPixelName={this.setSelectedPixelName}
                          ownerOfSelectedPixel={this.state.ownerOfSelectedPixel}
                          guerrillaModeActive={this.state.guerrillaModeActive}
                          >
                      </Grid>
            }


    // Navigation Bar Buttons

    const navButtonNoWeb3 = <Button style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openMetamaskModal}><b>Connect Web3 to use BillionDollarDapp</b></Button>

    const navButtonMintPixelModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openCreateModal}><b>mint a new pixel</b></Button> : ""

    const navButtonPreOwnedPixelsModal = (this.state.dollars && !this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openPreOwnedPixelModal}><b>certified pre-owned pixels</b></Button> : ""
    const navButtonBuyDollarsModal = (this.state.dollars && !this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openBuyDollarsModal}><b>buy & sell dollars</b></Button> : ""
    
    let showAirdropButton = (this.state.signatureData && this.state.airdropSignerAuthorized && !this.state.airdropAlreadyClaimed);
    const navButtonAirdropModal = (this.state.billiondollardrop && !this.state.museumModeActive && airdropActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAirdropModal}><b>dollars airdrop</b></Button> : ""
    
    const navButtonOpenDetialsModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openDetailsModal}><b>cashflow & details</b></Button> : ""
    const navButtonOpenRentModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openRentModal}><b>rent</b></Button>  : ""
    const navButtonOpenUpdateModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openUpdateModal}><b>update</b></Button> : ""

    const navButtonCausesModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openCausesModal}><b>causes</b></Button>  : ""
    const navButtonMuseumsModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openMuseumModal}><b>galleries & museums</b></Button> : ""

    const navButtonBuyForDollarsModal = (this.state.dollars && !this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openBuyForDollarsModal}><b>buy-with-dollars</b></Button> : ""
    const navButtonSellForDollarsModal = (this.state.dollars && !this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openSellForDollarsModal}><b>sell-for-dollars</b></Button> : ""

    const navButtonNetWorthModal = (this.state.dollars && !this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openNetWorthModal}><b>net worth</b></Button> : ""

    let navButtonMarketplaceOverview = (!this.state.loading) ? (
                                          <>
                                          <ZoraNavbarButton
                                            key={`ZoraNavbarButtonOverview`}
                                            contract={this.state.contract}
                                            tokenId={false}
                                            openZoraModal={this.openZoraModal}
                                            ownerOfSelectedEdition={null}
                                          />
                                          </>
                                      ) : ("")

    let navButtonMarketplaceDetail = (!this.state.loading && !(this.state.dollars && (this.state.dollars.options.address === this.state.ownerOfSelectedPixel))) ? (
                                        <>
                                        <ZoraNavbarButton
                                          key={`ZoraNavbarButton-${this.state.selectedPixel}`}
                                          contract={this.state.contract}
                                          tokenId={this.state.currentTokenId}
                                          openZoraModal={this.openZoraModal}
                                          ownerOfSelectedEdition={this.state.ownerOfSelectedPixel}
                                        />
                                        </>
                                        ) : ("")

    const navButtonOpenFlashBondModal = (!this.state.museumModeActive) ? <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openFlashBondModal}><b>flash bonds</b></Button>  : ""

    const navButtonToggleDarkMode = (this.state.CSSStyleOverride === "") ? (
      <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleDarkMode}><b>dark mode</b></Button>
    ) : (
      <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleDarkMode}><b>light mode</b></Button>
    )

    const navButtonToggleMuseumMode = (this.state.museumModeActive) ? (
      <Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleMuseumMode}><b>stop museum mode</b></Button>
    ) : (
      <Button variant="outline-primary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.toggleMuseumMode}><b>start museum mode</b></Button>
    )
    
    // Navigation Bar Types

    const noWeb3NavbarOverview = <Navbar collapseOnSelect bg="light" expand='sm'  variant="light" fixed="bottom">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                          <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                              {navButtonNoWeb3}
                            </Nav>
                          </Navbar.Collapse>
                          <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                      </Navbar>

    const noWeb3NavbarDetail = <Navbar collapseOnSelect bg="light" expand='sm'  variant="light" fixed="bottom">
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="mr-auto">
                            {navButtonNoWeb3}
                            {/*
                            &nbsp;
                            {navButtonCausesModal}
                            &nbsp;
                            {navButtonMuseumsModal}
                            */}
                          </Nav>
                        </Navbar.Collapse>
                        <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                    </Navbar>

    const web3NavbarOverview = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          {(this.state.museumModeActive) ? (
                            <>
                            {navButtonToggleMuseumMode}
                            &nbsp;
                            </>
                          ):("")}
                          {navButtonOpenFlashBondModal}
                          &nbsp;
                          {navButtonMintPixelModal}
                          &nbsp;
                          {(airdropActive) ? (
                            <>
                            {navButtonAirdropModal}
                            &nbsp;
                            </>
                          ) : ("")}
                          {navButtonBuyDollarsModal}
                          &nbsp;
                          {navButtonPreOwnedPixelsModal}
                          &nbsp;
                          {navButtonMuseumsModal}
                          &nbsp;
                          {navButtonNetWorthModal}
                          {/*
                          &nbsp;
                          {navButtonCausesModal}
                          */}
                          &nbsp;
                          {navButtonMarketplaceOverview}
                        </Nav>
                      </Navbar.Collapse>
                      <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                    </Navbar>

    const web3NavbarDetailOwner = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          {(this.state.museumModeActive) ? (
                          <>
                            {navButtonToggleMuseumMode}
                            &nbsp;
                          </>
                          ):("")}
                          {navButtonOpenDetialsModal}
                          &nbsp;
                          {navButtonOpenUpdateModal}
                          &nbsp;
                          {navButtonSellForDollarsModal}
                          &nbsp;
                          {navButtonMarketplaceDetail}
                        </Nav>
                      </Navbar.Collapse>
                      <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                    </Navbar>

    const web3NavbarDetailCertifiedPreOwned = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="mr-auto">
                            {(this.state.museumModeActive) ? (
                            <>
                              {navButtonToggleMuseumMode}
                              &nbsp;
                            </>
                            ):("")}
                            {navButtonOpenDetialsModal}
                            &nbsp;
                            {navButtonOpenRentModal}
                            &nbsp;
                            {navButtonBuyForDollarsModal}
                            &nbsp;
                            {navButtonMarketplaceDetail}
                          </Nav>
                        </Navbar.Collapse>
                        <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                    </Navbar>
      
    const web3NavbarDetailThirdPartyOwner = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          {(this.state.museumModeActive) ? (
                            <>
                              {navButtonToggleMuseumMode}
                              &nbsp;
                            </>
                            ):("")}
                          {navButtonOpenDetialsModal}
                          &nbsp;
                          {navButtonOpenRentModal}
                          &nbsp;
                          {navButtonMarketplaceDetail}
                        </Nav>
                      </Navbar.Collapse>
                      <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.openAboutModal}>BillionDollarDapp</Navbar.Brand>
                  </Navbar>

    // Navigation Bar Logic

    let bddNavbar;
    if(!this.state.web3) { // If not web3
        if(this.state.selectedPixel == null) { // If not web3 and in the overview mode.
          bddNavbar = noWeb3NavbarOverview
        } else { // If not web3 and in the detial view
          bddNavbar = noWeb3NavbarDetail
        }
    } else { // If using web3
      if(this.state.selectedPixel == null) { // If using web3 and in the overview mode
        bddNavbar = web3NavbarOverview
      } else { // If using web3 and in the detail mode
        //console.log(`Account: ${this.state.accounts[0]}`)
        //console.log(`Pixel Owner: ${this.state.ownerOfSelectedPixel}`)
        if(this.state.accounts[0] === this.state.ownerOfSelectedPixel) { // and you are the owner of the pixel
          bddNavbar = web3NavbarDetailOwner
        } else if (this.state.dollars && (this.state.dollars.options.address === this.state.ownerOfSelectedPixel)) {
          bddNavbar = web3NavbarDetailCertifiedPreOwned
        } else { // and you are not the owner of the pixel
          bddNavbar = web3NavbarDetailThirdPartyOwner
          }
      }
    }

    let zoraModal;
    zoraModal = (this.state.networkId == 1) ? (
      <ZoraModal        
        account={this.state.contract &&this.state.accounts[0]}
        isZoraModalOpen={this.state.isZoraModalOpen}
        platform={this.state.platform} 
        contract={this.state.contract}
        closeZoraModal={this.closeZoraModal}
        setSelectedEdition={this.setSelectedPixel}
        resetSelectedEdition={this.resetSelectedPixel}

        tokenId={(this.state.selectedPixel)?this.state.selectedPixel:null}
        ownerOfSelectedEdition={this.state.ownerOfSelectedPixel}
      />
    ) : (<Modal show={this.state.isZoraModalOpen}>Not Connected to Mainnet!</Modal>)

    return (
      <div>
      {bddNavbar}
        { (this.state.loading) ? (
          <div id="loader" className="text-center mt-5">
            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black'}}>
                {this.state.platform.name}
              </h3>
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <Spinner animation="grow" />
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black'}}>
                {this.state.loadingDescription}
              </h3>
            </div>
          </div>
          ) : (
            <>
            <div className="row">
              {grid}
            </div>
            </>
          )
        }
        {aboutModal}
        {/*}{causesModal}*/}
        {museumModal}
        {createModal}
        {metamaskModal}
        {detailsModal}
        {updateModal}
        {rentModal}
        {sellForDollarsModal}
        {buyForDollarsModal}
        {buyDollarsModal}
        {preOwnedPixelsModal}
        {netWorthModal}
        {airdropModal}
        {zoraModal}
        {flashBondModal}

      {(this.state.CSSStyleOverride) ? (
            <div>
            <style>
              {this.state.CSSStyleOverride}
            </style>
            </div>
          ) : ("")}
      </div>
    );
  }
}

export default App;