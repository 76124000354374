import React, { Component } from 'react';
import {Modal, Button, Tabs, Tab, Table, Spinner, Card, CardColumns} from "react-bootstrap";

import EnsResolver from "ens-resolver";
import Countdown from 'react-countdown';

import ERC20Resolver from "./ERC20Resolver.js";
import ERC20ApprovalButton from "./ERC20ApprovalButton.js";

import ZoraModuleManager from "../../contracts/Zora/ZoraModuleManager.json";
import ERC721TransferHelper from "../../contracts/Zora/ERC721TransferHelper.json";
import ERC20TransferHelper from "../../contracts/Zora/ERC20TransferHelper.json";
import AsksV1_1 from "../../contracts/Zora/AsksV1_1.json";
import OffersV1 from "../../contracts/Zora/OffersV1.json";
import ReserveAuctionCoreErc20 from "../../contracts/Zora/ReserveAuctionCoreErc20.json";

import DOLLARS from "../../contracts/Dollars.json";

import './ZoraModal.css';

class ZoraModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      zoraModalLoading: false,
      isZoraModalOpen: false,

      contract: props.contract,
      tokenId: props.tokenId,

      zoraERC721TransferHelper: null,
      zoraERC20TransferHelper: null,
      zoraModuleManager: null,

      zoraAsks: null,
      zoraOffers: null,
      zoraAuctions: null,

      isZoraAsksApproved: false,
      isZoraOffersApproved: false,
      isZoraAuctionsApproved: false,

      askForToken: null,
      validAskForToken: false,
      validOfferCount: 0,
      auctionForToken: null,
      validAuctionForToken: false,

      selectedOfferId: null,
      selectedOfferMaker: null, 
      selectedOfferCurrency: null, 
      selectedOfferAmount: null, 

      selectedToken: "0x0000000000000000000000000000000000000000"
    }

  }

  setupZoraContracts2 = async () => {
    // Load account
    const web3 = window.web3

    // Network ID
    const networkId = await web3.eth.net.getId();

    let deployedNetwork = null;
    let zoraModuleManager = null;
    let zoraERC721TransferHelper = null;
    let zoraERC20TransferHelper = null;
    let zoraAsks = null;
    let zoraOffers = null;
    let zoraAuctions = null;
    let dollars = null;

    deployedNetwork = ZoraModuleManager.networks[networkId];
    if(deployedNetwork) {
        zoraModuleManager = new web3.eth.Contract(
        ZoraModuleManager.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraModuleManager: ", zoraModuleManager)

    deployedNetwork = ERC721TransferHelper.networks[networkId];
    if(deployedNetwork) {
        zoraERC721TransferHelper = new web3.eth.Contract(
        ERC721TransferHelper.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraERC721TransferHelper: ", zoraERC721TransferHelper)

    deployedNetwork = ERC20TransferHelper.networks[networkId];
    if(deployedNetwork) {
        zoraERC20TransferHelper = new web3.eth.Contract(
        ERC20TransferHelper.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraERC20TransferHelper: ", zoraERC20TransferHelper)

    deployedNetwork = AsksV1_1.networks[networkId];
    if(deployedNetwork) {
        zoraAsks = new web3.eth.Contract(
        AsksV1_1.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraAsks: ", zoraAsks)

    deployedNetwork = OffersV1.networks[networkId];
    if(deployedNetwork) {
        zoraOffers = new web3.eth.Contract(
        OffersV1.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraOffers: ", zoraOffers)

    // ReserveAuctionCoreErc20
    deployedNetwork = ReserveAuctionCoreErc20.networks[networkId];
    if(deployedNetwork) {
        zoraAuctions = new web3.eth.Contract(
        ReserveAuctionCoreErc20.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }
    console.log("zoraAuctions: ", zoraAuctions)

    deployedNetwork = DOLLARS.networks[networkId];
    if(deployedNetwork) {
        dollars = new web3.eth.Contract(
        DOLLARS.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }


    this.setState({
      zoraModuleManager,
      zoraERC721TransferHelper,
      zoraERC20TransferHelper,
      zoraAsks,
      zoraOffers,
      zoraAuctions,
      dollars,
      networkId,
      selectedToken: dollars ? dollars.options.address : ''
    })
  }

  getZoraLogs = async () => {
    this.setState({zoraLogsModalLoading: true})
    let asks = await this.getZoraAsksLogs();
    asks.sort((a, b) => parseFloat(a.blockNumber) - parseFloat(b.blockNumber));

    let offers = await this.getZoraOfferLogs();
    offers.sort((a, b) => parseFloat(a.blockNumber) - parseFloat(b.blockNumber));

    let auctions = await this.getZoraAuctionLogs();
    auctions.sort((a, b) => parseFloat(a.blockNumber) - parseFloat(b.blockNumber));

    let data = {
      "asks": asks,
      "offers": offers,
      "auctions": auctions
    }

    let filteredAsks={}
    for(let ask in data.asks) {
      if(data.asks[ask].event === "AskCanceled" || data.asks[ask].event === "AskFilled") {
        delete filteredAsks[data.asks[ask].tokenId];
      } else {
        filteredAsks[data.asks[ask].tokenId]={"ask": data.asks[ask]}
      }
    }

    let asksOverviewData = filteredAsks
    console.log("zora asksOverviewData: ", asksOverviewData)

    let filteredOffers={}
    for(let offer in data.offers) {
      if(data.offers[offer].event === "OfferCanceled" || data.offers[offer].event === "OfferFilled") {
        delete filteredOffers[data.offers[offer].id];
      } else {
        filteredOffers[data.offers[offer].id] = {"offer":data.offers[offer]}
      }
    }

    let offersOverviewData = filteredOffers
    console.log("zora offersOverviewData: ", offersOverviewData)

    let filteredAuctions={}
    for(let auction in data.auctions) {
      if(data.auctions[auction].event === "AuctionEnded" || data.auctions[auction].event === "AuctionCanceled") {
        delete filteredAuctions[data.auctions[auction].tokenId];
      } else {
        filteredAuctions[data.auctions[auction].tokenId] = {"auction":data.auctions[auction]}
      }
    }

    let auctionsOverviewData = filteredAuctions
    console.log("zora auctionsOverviewData: ", auctionsOverviewData)

    this.setState({zoraLogsModalLoading: false, asksOverviewData, offersOverviewData, auctionsOverviewData})
  }

  getZoraAsksLogs = async () => {
    // Load Events

    console.log("Events:")
    const web3 = window.web3;

    let currentNetworkId = this.state.networkId;
    let startBlock = this.state.fromBlock || 0;
    let currentBlock = await web3.eth.getBlockNumber();
    let incrementByBlocks = 3000;

      let asks = [];
      await this.state.zoraAsks.getPastEvents('AskCreated', {
        filter: {
          tokenContract: this.props.contract.options.address,
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let askData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AskCreated',
            tokenId: events[i].returnValues.tokenId,
            ask: events[i].returnValues.ask,
          }
          console.log("zora askData: ", askData)

          asks.push(askData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAsks.getPastEvents('AskPriceUpdated', {
        filter: {
          tokenContract: this.props.contract.options.address,
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let askData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AskPriceUpdated',
            tokenId: events[i].returnValues.tokenId,
            ask: events[i].returnValues.ask,
          }
          console.log("zora askData: ", askData)

          asks.push(askData);
        }
      }).catch(function(error) { console.log(error) });
      
      await this.state.zoraAsks.getPastEvents('AskCanceled', {
        filter: {
          tokenContract: this.props.contract.options.address,
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let askData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AskCanceled',
            tokenId: events[i].returnValues.tokenId,
            ask: events[i].returnValues.ask,
          }
          console.log("zora askData: ", askData)

          asks.push(askData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAsks.getPastEvents('AskFilled', {
        filter: {
          tokenContract: this.props.contract.options.address,
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let askData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AskFilled',
            tokenId: events[i].returnValues.tokenId,
            buyer: events[i].returnValues.buyer,
            finder: events[i].returnValues.finder,
            ask: events[i].returnValues.ask,
          }
          console.log("zora askData: ", askData)

          asks.push(askData);
        }
      }).catch(function(error) { console.log(error) });
    
      return asks;
  }
  
  getZoraOfferLogs = async () => {
    // Load Events

    console.log("Events:")
    let currentNetworkId = this.state.networkId;
    let startBlock = this.state.fromBlock || 0;
    let currentBlock = await window.web3.eth.getBlockNumber();
    let incrementByBlocks = 3000;
    let offers = [];

      await this.state.zoraOffers.getPastEvents('OfferCreated', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let offerData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'OfferCreated',
            tokenId: events[i].returnValues.tokenId,
            id: events[i].returnValues.id,
            offer: events[i].returnValues.offer
          }
          console.log("zora offerData: ", offerData)

          offers.push(offerData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraOffers.getPastEvents('OfferUpdated', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let offerData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'OfferUpdated',
            tokenId: events[i].returnValues.tokenId,
            id: events[i].returnValues.id,
            offer: events[i].returnValues.offer
          }
          console.log("zora offerData: ", offerData)

          offers.push(offerData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraOffers.getPastEvents('OfferCanceled', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let offerData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'OfferCanceled',
            tokenId: events[i].returnValues.tokenId,
            id: events[i].returnValues.id,
            offer: events[i].returnValues.offer
          }
          console.log("zora offerData: ", offerData)

          offers.push(offerData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraOffers.getPastEvents('OfferFilled', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let offerData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'OfferFilled',
            tokenId: events[i].returnValues.tokenId,
            id: events[i].returnValues.id,
            taker: events[i].returnValues.taker,
            finder: events[i].returnValues.finder,
            offer: events[i].returnValues.offer
          }
          console.log("zora offerData: ", offerData)

          offers.push(offerData);
        }
      }).catch(function(error) { console.log(error) });
    
      return offers;
  }

  getZoraAuctionLogs = async () => {
    // Load Events

    console.log("Events:")
    let currentNetworkId = this.state.networkId;
    let startBlock = this.state.fromBlock || 0;
    let currentBlock = await window.web3.eth.getBlockNumber();
    let incrementByBlocks = 3000;
    let auctions = [];

      await this.state.zoraAuctions.getPastEvents('AuctionCreated', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let auctionData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AuctionCreated',
            tokenId: events[i].returnValues.tokenId,
            auction: events[i].returnValues.auction,
          }
          console.log("zora auctionData: ", auctionData)

          auctions.push(auctionData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAuctions.getPastEvents('AuctionReservePriceUpdated', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let auctionData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AuctionReservePriceUpdated',
            tokenId: events[i].returnValues.tokenId,
            auction: events[i].returnValues.auction,
          }
          console.log("zora auctionData: ", auctionData)

          auctions.push(auctionData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAuctions.getPastEvents('AuctionCanceled', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let auctionData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AuctionCanceled',
            tokenId: events[i].returnValues.tokenId,
            auction: events[i].returnValues.auction,
          }
          console.log("zora auctionData: ", auctionData)

          auctions.push(auctionData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAuctions.getPastEvents('AuctionBid', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let auctionData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AuctionBid',
            tokenId: events[i].returnValues.tokenId,
            firstBid: events[i].returnValues.firstBid,
            extended: events[i].returnValues.extended,
            auction: events[i].returnValues.auction,
          }
          console.log("zora auctionData: ", auctionData)

          auctions.push(auctionData);
        }
      }).catch(function(error) { console.log(error) });

      await this.state.zoraAuctions.getPastEvents('AuctionEnded', {
        filter: {
          tokenContract: this.props.contract.options.address
        },
        fromBlock: 0,
        toBlock: 'latest'
      })
      .then(function(events){
        for(var i=0;i<events.length;i++){
          let auctionData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            event: 'AuctionEnded',
            tokenId: events[i].returnValues.tokenId,
            auction: events[i].returnValues.auction,
          }
          console.log("zora auctionData: ", auctionData)

          auctions.push(auctionData);
        }
      }).catch(function(error) { console.log(error) });
    
      return auctions;
  }

  updateAndNotify = async () => {
    this.setState({zoraModalLoading: true})

    const { contract, tokenId, isZoraModalOpen } = this.props;

    // Get Zora Logs
    if(!this.props.tokenId) { this.getZoraLogs(); }

    // Zora Transfer Helpers

    let isZoraERC721TransferHelperApproved = null;
    if(this.state.zoraERC721TransferHelper) { 
      if(this.props.account) {
        isZoraERC721TransferHelperApproved = await contract.methods.isApprovedForAll(this.props.account, this.state.zoraERC721TransferHelper.options.address).call()
        console.log("isZoraERC721TransferHelperApproved: ", isZoraERC721TransferHelperApproved);
      }
    }

    let isZoraERC20TransferHelperApproved = null;
    if(this.state.zoraERC20TransferHelper && this.state.dollars) { 
      if(this.props.account) {
        let allowance = await this.state.dollars.methods.allowance(this.props.account, this.state.zoraERC20TransferHelper.options.address).call()
        isZoraERC20TransferHelperApproved = (allowance > 0)
        console.log("isZoraERC20TransferHelperApproved: ", allowance, isZoraERC20TransferHelperApproved);
      }
    }

    // Zora Asks

    let isZoraAsksApproved = this.state.isZoraAsksApproved;
    if(this.state.zoraAsks && !isZoraAsksApproved) { 
      if(this.props.account) {
        isZoraAsksApproved = await this.state.zoraModuleManager.methods.isModuleApproved(this.props.account, this.state.zoraAsks.options.address).call()
        console.log("isZoraAsksApproved: ", isZoraAsksApproved);
      }
    }

    let askForToken = null;
    let validAskForToken = false;
    if(this.state.zoraAsks && tokenId) { 
      askForToken = await this.state.zoraAsks.methods.askForNFT(contract.options.address, tokenId).call()
      if(askForToken.seller !== '0x0000000000000000000000000000000000000000' && askForToken.seller === this.props.ownerOfSelectedEdition) {
        validAskForToken = true;
      }
      console.log("zora askForToken, validAskForToken: ", askForToken, validAskForToken);
    }

    // Zora Offers

    let isZoraOffersApproved = this.state.isZoraOffersApproved;
    if(this.state.zoraOffers && !isZoraOffersApproved) { 
      if(this.props.account) {
        isZoraOffersApproved = await this.state.zoraModuleManager.methods.isModuleApproved(this.props.account, this.state.zoraOffers.options.address).call()
        console.log("isZoraOffersApproved: ", isZoraOffersApproved);
      }
    }

    // offersForNFT#
    // Returns an array of offerIds for a specific ERC-721 token.
    // ERC-721 tokenAddress => ERC-721 tokenID => Offer IDs

    let offersForNFT = [];
    let nextOffer = null;
    let offerCount = 0;
    if(this.state.zoraOffers && tokenId) {
      do {
        
        try {
          nextOffer = await this.state.zoraOffers.methods.offersForNFT(contract.options.address, tokenId, offerCount).call()
        } catch (error) { 
          nextOffer = null
        }
        
        if (nextOffer !== null) { 
          offersForNFT.push(nextOffer) 
        } else {
           nextOffer = null;
        }
        
        offerCount++;
      } while(nextOffer !== null)
    }
    console.log("zora offersForNFT: ", offersForNFT);

    // offers#
    // Returns the metadata for an offer.
    //  ERC-721 Token Address => ERC-721 tokenID => offerID => Offer
    //  mapping(address => mapping(uint256 => mapping(uint256 => Offer))) public offers

    let offerDetails = []
    let offerDetailsNo = null;
    let validOfferCount = 0;

    for(let offer in offersForNFT) { 
      try {
        offerDetailsNo = await this.state.zoraOffers.methods.offers(contract.options.address, tokenId, offersForNFT[offer]).call()
      } catch(error) { 
        offerDetailsNo = null
      }

      if (offerDetailsNo !== null && offerDetailsNo.maker !== "0x0000000000000000000000000000000000000000") { 
        offerDetails.push({offerId: offersForNFT[offer], offer: offerDetailsNo})
        validOfferCount++; 
      } else {
        offerDetailsNo = null;
      }

      console.log("zora offerDetails, validOfferCount: ", offerDetails, validOfferCount);
    }

    // Zora Auctions

    //ReserveAuctionCoreErc20
    let isZoraAuctionsApproved = this.state.isZoraAuctionsApproved;
    if(this.state.zoraAuctions && !isZoraAuctionsApproved) { 
      if(this.props.account) {
        isZoraAuctionsApproved = await this.state.zoraModuleManager.methods.isModuleApproved(this.props.account, this.state.zoraAuctions.options.address).call()
        console.log("isZoraAuctionsApproved: ", isZoraAuctionsApproved);
      }
    }

    let auctionForToken = null;
    let validAuctionForToken = false;
    if(this.state.zoraAuctions && tokenId) { 
      auctionForToken = await this.state.zoraAuctions.methods.auctionForNFT(contract.options.address, tokenId).call()
      if(auctionForToken.seller !== '0x0000000000000000000000000000000000000000') {
        validAuctionForToken = true;
      }
      console.log("zora auctionForToken: ", auctionForToken, validAuctionForToken);
    }

    this.setState({
      zoraModalLoading: false,
      isZoraModalOpen,
      isZoraERC721TransferHelperApproved,
      isZoraERC20TransferHelperApproved,
      isZoraAsksApproved,
      isZoraOffersApproved,
      isZoraAuctionsApproved,
      askForToken,
      offersForNFT,
      offerDetails,
      auctionForToken,
      validAskForToken,
      validOfferCount,
      validAuctionForToken
    })
  }

  componentDidMount = async () => {
    await this.setupZoraContracts2();
    await this.updateAndNotify();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.tokenId !== this.props.tokenId || prevProps.isZoraModalOpen !== this.props.isZoraModalOpen) {
      await this.updateAndNotify();
    }
  }

  // ==== ZORA TRANSFER HELPERS

  approveZoraERC721TransferHelperOnBDD = () => {
    this.setState({zoraModalLoading: true})
    console.log("approveZoraERC721TransferHelperOnBDD contract: ", this.props.contract)
    console.log("approveZoraERC721TransferHelperOnBDD approve: ", this.state.zoraERC721TransferHelper.options.address)
    console.log("approveZoraERC721TransferHelperOnBDD for account: ", this.props.account)
    this.props.contract.methods.setApprovalForAll(this.state.zoraERC721TransferHelper.options.address, true).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("approveZoraERC721TransferHelperOnBDD receipt: ", receipt)
      this.setState({zoraModalLoading: false})
    })
  }

  approveZoraERC20TransferHelperOnDOLLARS = () => {
    this.setState({zoraModalLoading: true})
    this.state.dollars.methods.approve(this.state.zoraERC20TransferHelper.options.address, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("approveZoraERC20TransferHelperOnToken receipt: ", receipt)
      this.setState({zoraModalLoading: false})
    })
  }

  /// ==== ZORA ASKS MODULE FUNCTIONS 

  approveZoraAsksModule = () => {
    this.setState({zoraModalLoading: true})
    this.state.zoraModuleManager.methods.setApprovalForModule(this.state.zoraAsks.options.address, true).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("approveZoraAsksModule receipt: ", receipt)
      this.setState({zoraModalLoading: false})
    })
  }

  zoraCreateAsk = (askPrice, askCurrency) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    let sellerFundsRecipient = this.props.account;
    let findersFeeBps = 0;
    this.state.zoraAsks.methods.createAsk(tokenContract, tokenId, askPrice, askCurrency, sellerFundsRecipient, findersFeeBps).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraCreateAsk receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  zoraSetAskPrice = (askPrice, askCurrency) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraAsks.methods.setAskPrice(tokenContract, tokenId, askPrice, askCurrency).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraSetAskPrice receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  zoraCancelAsk = () => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraAsks.methods.cancelAsk(tokenContract, tokenId).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraCancelAsk receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  zoraFillAsk = (fillCurrency, fillAmount) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    let finder = "0x0000000000000000000000000000000000000000";
    if(fillCurrency === "0x0000000000000000000000000000000000000000") {
      this.state.zoraAsks.methods.fillAsk(tokenContract, tokenId, fillCurrency, fillAmount, finder).send({from: this.props.account, value: fillAmount})
      .once('receipt', async (receipt) => {
        console.log("zoraFillAsk receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    } else {
      this.state.zoraAsks.methods.fillAsk(tokenContract, tokenId, fillCurrency, fillAmount, finder).send({from: this.props.account})
      .once('receipt', async (receipt) => {
        console.log("zoraFillAsk receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    }
  }

  /// END ZORA ASKS MODULE FUNCTIONS

  /// ==== ZORA OFFERS MODULE FUNCTIONS 

  setSelectedOffer = async (selectedOfferId, selectedOfferMaker, selectedOfferCurrency, selectedOfferAmount) => {
    this.setState({selectedOfferId, selectedOfferMaker, selectedOfferCurrency, selectedOfferAmount})
  }

  setSelectedToken = async (selectedToken) => {
    console.log("zora Selected Token: ", selectedToken);
    this.setState({selectedToken})
  }

  approveZoraOffersModule = () => {
    this.setState({zoraModalLoading: true})
    this.state.zoraModuleManager.methods.setApprovalForModule(this.state.zoraOffers.options.address, true).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("approveZoraOffersModule receipt: ", receipt)
      this.setState({zoraModalLoading: false})
    })
  }

   /*
  /// FUNCTIONS
  createOffer#
  Creates an offer for a specific NFT which can be made in either ETH or an ERC-20 token and returns the offerId of the newly created offer.

  ZORA takes custody of the amount until either the offer is filled or canceled

  The user must first approve the ERC-20 Transfer Helper if the offer currency is an ERC-20 token

  No approvals are needed if the offer currency is ETH
  function createOffer(
      address _tokenContract,
      uint256 _tokenId,
      address _currency, //ERC-20 token address or address(0) for ETH
      uint256 _amount,
      uint16 _findersFeeBps
  ) returns (uint256)
  */
  zoraCreateOffer = (currency, amount) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    let findersFeeBps = 0;
    if(currency === "0x0000000000000000000000000000000000000000") {
      this.state.zoraOffers.methods.createOffer(tokenContract, tokenId, currency, amount, findersFeeBps).send({from: this.props.account, value: amount})
      .once('receipt', async (receipt) => {
        console.log("zoraCreateOffer receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    } else {
      this.state.zoraOffers.methods.createOffer(tokenContract, tokenId, currency, amount, findersFeeBps).send({from: this.props.account})
      .once('receipt', async (receipt) => {
        console.log("zoraCreateOffer receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    }
  }

  /*
  fillOffer#
  Fills a given offer for an owned NFT, in exchange for ETH/ERC-20 tokens.

  finder: The address of the party that helped match the offer to the buyer
  The finder can be set to address(0) if no address is known
  function fillOffer(
      address _tokenContract,
      uint256 _tokenId,
      uint256 _offerId,
      address _currency,
      uint256 _amount,
      address _finder
  )*/
  zoraFillOffer = (offerId, currency, amount) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    let finder = "0x0000000000000000000000000000000000000000";
    this.state.zoraOffers.methods.fillOffer(tokenContract, tokenId, offerId, currency, amount, finder).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraFillOffer receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /*
  setOfferAmount#
  Updates either the price, currency, or both for a given offer.
  Increasing the amount requires that the user provide more collateral
  Decreasing the amount will refund the difference back to the offer maker address
  Updating the currency to an ERC-20 will require that the user approve the ERC-20 Transfer Helper if they haven't already approved that token.
  function setOfferAmount(
      address _tokenContract,
      uint256 _tokenId,
      uint256 _offerId,
      address _currency,
      uint256 _amount
  )
  */
  zoraSetOfferAmount = (offerId, currency, amount) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    if(currency === "0x0000000000000000000000000000000000000000") {
      this.state.zoraOffers.methods.setOfferAmount(tokenContract, tokenId, offerId, currency, amount).send({from: this.props.account, value: amount})
      .once('receipt', async (receipt) => {
        console.log("zoraSetOfferAmount receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    } else {
      this.state.zoraOffers.methods.setOfferAmount(tokenContract, tokenId, offerId, currency, amount).send({from: this.props.account})
      .once('receipt', async (receipt) => {
        console.log("zoraSetOfferAmount receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    }
  }
  /*
  cancelOffer#
  Cancels and refunds the given offer.

  function cancelOffer(
      address _tokenContract,
      uint256 _tokenId,
      uint256 _offerId
  )
  */
  zoraCancelOffer = (offerId) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraOffers.methods.cancelOffer(tokenContract, tokenId, offerId).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraCancelOffer receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /// ===== START ZORA AUCTIONS (CoreERC20ReserveAuction) 

  approveZoraAuctionsModule = () => {
    this.setState({zoraModalLoading: true})
    this.state.zoraModuleManager.methods.setApprovalForModule(this.state.zoraAuctions.options.address, true).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("approveZoraAuctionsModule receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /*
  Creates an auction for a given ERC-721 NFT. There can only be one auction at a time for an NFT.
  function createAuction(
    address _tokenContract,
    uint256 _tokenId,
    uint256 _duration,
    uint256 _reservePrice,
    address _sellerFundsRecipient,
    uint256 _startTime,
    address _bidCurrency
  ) */
  zoraCreateAuction = (duration, reservePrice, bidCurrency) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    let startTime = 0;
    let sellerFundsRecipient = this.props.account;
    this.state.zoraAuctions.methods.createAuction(tokenContract, tokenId, duration, reservePrice, sellerFundsRecipient, startTime, bidCurrency).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraCreateAuction receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /*
  Updates the auction reserve price for a given NFT
  function setAuctionReservePrice(
      address _tokenContract,
      uint256 _tokenId,
      uint256 _reservePrice
  )*/
  zoraSetAuctionReservePrice = (reservePrice) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraAuctions.methods.setAuctionReservePrice(tokenContract, tokenId, reservePrice).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraSetAuctionReservePrice receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /*
  cancelAuction#
  Cancels the auction for a given NFT.
  function cancelAuction(address _tokenContract, uint256 _tokenId)
  */
  zoraCancelAuction = () => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraAuctions.methods.cancelAuction(tokenContract, tokenId).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraCancelAuction receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /*
  createBid#
  Places a bid on the auction for a given NFT. The bid percentage difference must be greater than or equal to 10% of the previous bid.

  function createBid(
      address _tokenContract,
      uint256 _tokenId,
      uint256 _amount
  )
  */
  zoraCreateBid = (amount) => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    if(this.state.auctionForToken && this.state.auctionForToken.currency === "0x0000000000000000000000000000000000000000") {
      this.state.zoraAuctions.methods.createBid(tokenContract, tokenId, amount).send({from: this.props.account, value: amount})
      .once('receipt', async (receipt) => {
        console.log("zoraCreateBid receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    } else {
      this.state.zoraAuctions.methods.createBid(tokenContract, tokenId, amount).send({from: this.props.account})
      .once('receipt', async (receipt) => {
        console.log("zoraCreateBid receipt: ", receipt)
        await this.updateAndNotify()
        this.setState({zoraModalLoading: false})
      })
    }
  }

  /*
  settleAuction#
  Ends the auction for a given NFT by sending the NFT to the highest bidder and the funds to the seller. Note, this module honors royalty payouts.
  function settleAuction(address _tokenContract, uint256 _tokenId) 
  */
  zoraSettleAuction = () => {
    this.setState({zoraModalLoading: true})
    let tokenContract = this.props.contract.options.address;
    let tokenId = this.props.tokenId;
    this.state.zoraAuctions.methods.settleAuction(tokenContract, tokenId).send({from: this.props.account})
    .once('receipt', async (receipt) => {
      console.log("zoraSettleAuction receipt: ", receipt)
      await this.updateAndNotify()
      this.setState({zoraModalLoading: false})
    })
  }

  /// END ZORA CoreERC20 Reserve Auction

 render() {
    const { closeZoraModal, isZoraModalOpen,
            ownerOfSelectedEdition, account,
            platform, contract,
            tokenId
          } = this.props;

    const { 
      isZoraERC721TransferHelperApproved,
      isZoraAsksApproved, isZoraOffersApproved, isZoraAuctionsApproved
    } = this.state;


    const loadingInsert =  <>
                            <p></p>
                            <Card><Card.Body>
                            <div align="center"><Card.Title>Loading ZORA Market Data ...</Card.Title></div>
                            <div align="center"><Spinner animation="grow" /></div>
                            </Card.Body></Card>
                            <p></p>
                            </>

    /// ASKS TABS

    const approveZoraERC721TransferHelperForm = <form onSubmit={(event) => {
                                                  event.preventDefault()
                                                  this.approveZoraERC721TransferHelperOnBDD()
                                                  }}>
                                                  <input
                                                    id="submit"
                                                    type="submit"
                                                    className="btn btn-block btn-warning"
                                                    value="Approve Zora ERC721 Transfer Helper on BillionDollarDapp"
                                                  />
                                                </form>

    const approveZoraAsksForm = <form onSubmit={(event) => {
                                event.preventDefault()
                                this.approveZoraAsksModule()
                                }}>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-warning"
                                  value="Approve Zora Asks Module on Zora Module Manager"
                                />
                              </form>

    const currencyList = <ul>
                        <li>DOLLARS: {(this.state.dollars) ? this.state.dollars.options.address : ""}</li>
                        {(this.state.networkId === 1) ? (
                        <li>FASTCASH: 0xcA5228D1fe52D22db85E02CA305cddD9E573D752</li>
                        ) : ("")}
                        <li>ETH: 0x0000000000000000000000000000000000000000</li>
                        </ul>

    const zoraCreateAskForm = <>
                                
                                    <Card>
                                      <Card.Body>
                                        <Card.Title>
                                          Create a fixed price listing.
                                        </Card.Title>
                                      <form onSubmit={(event) => {
                                        event.preventDefault()
                                        let amountInWei = window.web3.utils.toWei(event.target.amount.value);
                                        this.zoraCreateAsk(
                                          amountInWei,
                                          event.target.token.value
                                        )
                                      }}>
                                      <p></p>
                                      <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                        Token Address or ETH (0x0000000000000000000000000000000000000000):
                                      </Card.Subtitle>
                                      <Card.Title>
                                      <input type="text" style={{width: '100%'}} id="token" required 
                                      onBlur={e => this.setSelectedToken(e.target.value)}
                                      defaultValue={(this.state.selectedToken) ? this.state.selectedToken : ""}/>
                                      </Card.Title>
                                      <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                        <ERC20Resolver 
                                          key="selectedERC20TokenResolver" 
                                          tokenAddress={this.state.selectedToken} />
                                      </Card.Subtitle>
                                      <p></p>
                                      <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                      <Card.Title>
                                        <input
                                          type="text"
                                          style={{width: '100%'}} 
                                          id="amount" required
                                        />
                                      </Card.Title>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="List NFT for a Fixed Price"
                                      />
                                      </form>
                                    </Card.Body>
                                  </Card>
                            
                              </>

    const zoraSetAskPriceForm = <>
                            
                                <Card>
                                  <Card.Body>
                                <Card.Title>Update the asking price and currency.</Card.Title>
                                <form onSubmit={(event) => {
                                  event.preventDefault()
                                  let amountInWei = window.web3.utils.toWei(event.target.amount.value);
                                  this.zoraSetAskPrice(
                                    amountInWei,
                                    event.target.token.value
                                  )
                                }}>
                                  <p></p>
                                  <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                    Token Address or ETH (0x0000000000000000000000000000000000000000):
                                  </Card.Subtitle>
                                  <Card.Title>
                                  <input type="text" style={{width: '100%'}} id="token" required 
                                  onBlur={e => this.setSelectedToken(e.target.value)}
                                  defaultValue={(this.state.askForToken) ? this.state.askForToken.askCurrency : ""}/>
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                    <ERC20Resolver 
                                    key="selectedERC20TokenResolver" 
                                    tokenAddress={this.state.selectedToken} />
                                  </Card.Subtitle>
                                  <p></p>
                                  <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                  <Card.Title>
                                    <input
                                    type="text" 
                                    style={{width: '100%'}} 
                                    id="amount" 
                                    required
                                    defaultValue={(this.state.askForToken) ? window.web3.utils.fromWei(this.state.askForToken.askPrice, "ether") : ""}
                                  />
                                  </Card.Title>
                                  <p></p>
                                  <input
                                    id="submit"
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                    value="Update Ask"
                                  />
                                  </form>
                                </Card.Body>
                              </Card>
                            
                          </>

    const zoraCancelAskForm = <>
                            
                                <Card>
                                  <Card.Body>
                                <form onSubmit={(event) => {
                                  event.preventDefault()
                                  this.zoraCancelAsk()
                              }}>
                              <input
                                id="submit"
                                type="submit"
                                className="btn btn-block btn-danger"
                                value="Cancel Offered Asking Price"
                              />
                              </form>
                              </Card.Body>
                              </Card>
                           
                              </>

    const zoraFillAskForm = <>
                             <Card>
                                    <Card.Body>
                                      <form onSubmit={(event) => {
                                        event.preventDefault()
                                        let amountInWei = window.web3.utils.toWei(event.target.fillAmount.value);
                                        this.zoraFillAsk(
                                          event.target.fillCurrency.value,
                                          amountInWei
                                        )
                                      }}>

                                      <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                      <Card.Title>
                                        {(this.state.askForToken) ? window.web3.utils.fromWei(this.state.askForToken.askPrice, "ether") : ""}
                                        &nbsp;
                                        <ERC20Resolver
                                          key={`askForToken-${tokenId}`}
                                          tokenAddress={(this.state.askForToken) ? this.state.askForToken.askCurrency : ""}
                                        />
                                        </Card.Title>
                                        <Card.Title>
                                        <p></p>
                                        <ERC20ApprovalButton
                                          key={this.state.selectedToken}
                                          style={{width: '100%'}}
                                          account={this.props.account}
                                          tokenAddress={this.state.selectedToken}
                                          spenderName="Zora ERC20TransferHelper"
                                          spenderAddress={(this.state.zoraERC20TransferHelper)?this.state.zoraERC20TransferHelper.options.address:""}
                                          approvedAmount='115792089237316195423570985008687907853269984665640564039457584007913129639935'
                                        />
                                        <input 
                                          type="hidden"
                                          id="fillCurrency" required 
                                          defaultValue={(this.state.askForToken) ? this.state.askForToken.askCurrency : ""}
                                        />
                                        <input
                                          type="hidden"
                                          id="fillAmount" 
                                          required
                                          defaultValue={(this.state.askForToken) ? window.web3.utils.fromWei(this.state.askForToken.askPrice, "ether") : ""}
                                        />
                                      </Card.Title>
                                        <p></p>
                                        <input
                                          id="submit"
                                          type="submit"
                                          className="btn btn-block btn-primary"
                                          value="Buy it Now"
                                        />
                                      </form>

                                    </Card.Body>
                                  </Card>
                            </>

    const approveZoraAsksTabInsert = <>
                                        <ol>
                                          {(!isZoraERC721TransferHelperApproved) ? (
                                            <>
                                            <li>Approve ERC721TransferHelper on the BillionDollarDapp contract (only need to do this once ever).</li>
                                            {approveZoraERC721TransferHelperForm}
                                            </>
                                          ) : ("")}
                                      
                                        {(!isZoraAsksApproved) ? (
                                            <>
                                            <li>Approve the Zora AsksV1_1 module on ZoraModuleManager contract (only need to do this once ever).</li>
                                            {approveZoraAsksForm}
                                            </>
                                          ) : ("")}
                                          </ol>
                                          <p></p>
                                        </>
    
    const zoraAsksTabForOwner =   <Tab eventKey="zora_asks" title="List for a Fixed Price">
                                    {(this.state.zoraModalLoading) ? (
                                      <><p>{loadingInsert}</p></>
                                    ) : (
                                      <>
                                      <p></p>
                                        {approveZoraAsksTabInsert}
                                        <p></p>
                                          {(isZoraERC721TransferHelperApproved && isZoraAsksApproved) ? (
                                            (this.state.askForToken && 
                                              this.state.askForToken.seller !== '0x0000000000000000000000000000000000000000' &&
                                              this.state.askForToken.seller === ownerOfSelectedEdition) ? (
                                                <>
                                                <p></p>
                                                {zoraSetAskPriceForm}
                                                <p></p>
                                                {zoraCancelAskForm}
                                                <p></p>
                                                </>
                                              ) : (
                                                (this.state.askForToken && 
                                                  this.state.askForToken.seller !== '0x0000000000000000000000000000000000000000' &&
                                                  this.state.askForToken.seller !== ownerOfSelectedEdition) ? (
                                                    <>
                                                    A prior seller created an ask
                                                    {zoraCancelAskForm}
                                                    </>
                                                  ) : (
                                                    <>
                                                    {zoraCreateAskForm}
                                                    </>
                                                  )
                                              )
                                          ) : ("")}
                                        <p></p>
                                        </>
                                    )}
                                  </Tab>

    const zoraAsksTabForBuyer = <Tab eventKey="zora_asks" title="Buy it Now" onClick={() => this.setSelectedToken(this.state.askForToken.askCurrency)}>
                                  
                                  {(this.state.zoraModalLoading) ? (
                                    <><p>{loadingInsert}</p></>
                                  ) : (
                                    <>
                                    <p></p>
                                      {
                                      // If there is an Ask
                                      // TODO: Add token approval?
                                      (this.state.askForToken && 
                                        this.state.askForToken.seller !== '0x0000000000000000000000000000000000000000' &&
                                        this.state.askForToken.seller === ownerOfSelectedEdition) ? (
                                        <>
                                        <p></p>
                                        {zoraFillAskForm}
                                        </>
                                      ) : (
                                        "There is no current asking price.  Consider making an Offer."
                                      )}
                                    <p></p>
                                    </>
                                  )}
                                  </Tab>

    const zoraAsksTab = (ownerOfSelectedEdition === account) ? (zoraAsksTabForOwner) : (zoraAsksTabForBuyer)

    /// OFFERS TABS

    const approveZoraOffersForm = <form onSubmit={(event) => {
                                      event.preventDefault()
                                      this.approveZoraOffersModule()
                                    }}>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-warning"
                                      value="Approve Zora Offers Module on Zora Module Manager"
                                    />
                                  </form>

  const approveZoraOffersTabInsert = <>
                                    <ol>
                                    {(!isZoraOffersApproved) ? (
                                        <>
                                        <li>Approve the Zora OffersV1 module on ZoraModuleManager contract (only need to do this once ever).</li>
                                        {approveZoraOffersForm}
                                        </>
                                      ) : ("")}
                                    </ol>
                                    <p></p>
                                  </>

    const zoraCreateOfferForm = <>
                                    <Card>
                                    <Card.Body>
                                    <Card.Title>  
                                    Create an offer with any token, or ETH.
                                    </Card.Title>
                                    <form onSubmit={(event) => {
                                      event.preventDefault()
                                      let amountInWei = window.web3.utils.toWei(event.target.amount.value);
                                      //zoraCreateOffer = (currency, amount)
                                      this.zoraCreateOffer(
                                        event.target.token.value,
                                        amountInWei
                                      )
                                    }}>
                                      <p></p>
                                        <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                          Token Address or ETH (0x0000000000000000000000000000000000000000):
                                        </Card.Subtitle>
                                        <Card.Title>
                                          <input type="text" 
                                            style={{width: '100%'}} 
                                            id="token" 
                                            required 
                                            onBlur={e => this.setSelectedToken(e.target.value)}
                                            defaultValue={(this.state.dollars) ? this.state.dollars.options.address : ""}
                                          />
                                        </Card.Title>
                                        <p></p>
                                        <ERC20ApprovalButton
                                          key={this.state.selectedToken}
                                          account={this.props.account}
                                          tokenAddress={this.state.selectedToken}
                                          spenderName="Zora ERC20TransferHelper"
                                          spenderAddress={(this.state.zoraERC20TransferHelper)?this.state.zoraERC20TransferHelper.options.address:""}
                                          approvedAmount='115792089237316195423570985008687907853269984665640564039457584007913129639935'
                                        />
                                        <p></p>
                                        <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                        <Card.Title>
                                          <input type="text" 
                                            style={{width: '100%'}} 
                                            id="amount" 
                                            required
                                          />
                                        </Card.Title>
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Make an Offer"
                                      />
                                      </form>
                                      </Card.Body>
                                      </Card>
                                </>

    const zoraSetOfferAmountForm = <>
                            {
                              (this.state.selectedOfferMaker && this.state.selectedOfferMaker === this.props.account) ? (
                                
                                  <Card>
                                    <Card.Body>
                                    <Card.Title>
                                      Update an offer with any token, or ETH.
                                      </Card.Title>
                                  <form onSubmit={(event) => {
                                    event.preventDefault()
                                    let amountInWei = window.web3.utils.toWei(event.target.offerAmount.value);
                                    this.zoraSetOfferAmount(
                                      event.target.offerId.value,
                                      event.target.offerCurrency.value,
                                      amountInWei
                                    )
                                  }}>
                                    <p></p>
                                    <input type="hidden" style={{width: '100%'}} id="offerId" required 
                                      defaultValue={(this.state.selectedOfferId) ? this.state.selectedOfferId : ""}/>
                                  <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                    Token Address or ETH (0x0000000000000000000000000000000000000000):
                                  </Card.Subtitle>
                                  <Card.Title>
                                  <input 
                                    type="text" 
                                    style={{width: '100%'}} 
                                    id="offerCurrency" 
                                    required 
                                    onBlur={e => this.setSelectedToken(e.target.value)}
                                    defaultValue={(this.state.selectedOfferCurrency) ? this.state.selectedOfferCurrency : ""}
                                  />
                                  </Card.Title>
                                  <p></p>
                                  <ERC20ApprovalButton
                                    key={`zoraSetOfferAmountForm-${this.state.selectedOfferCurrency}`}
                                    account={this.props.account}
                                    tokenAddress={this.state.selectedOfferCurrency}
                                    spenderName="Zora ERC20TransferHelper"
                                    spenderAddress={(this.state.zoraERC20TransferHelper)?this.state.zoraERC20TransferHelper.options.address:""}
                                    approvedAmount='115792089237316195423570985008687907853269984665640564039457584007913129639935'
                                  />
                                  <p></p>
                                  <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                  <Card.Title>
                                  <input 
                                    type="text" 
                                    style={{width: '100%'}} 
                                    id="offerAmount" 
                                    required
                                    defaultValue={(this.state.selectedOfferAmount) ? window.web3.utils.fromWei(this.state.selectedOfferAmount, "ether") : ""}
                                  />
                                  </Card.Title>
                                <p></p>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-warning"
                                  value={`Update Offer (Offer ID ${this.state.selectedOfferId})`}
                                />
                                </form>
                                </Card.Body>
                                </Card>
                              
                            ):("")
                          }
                          </>
                            
    const zoraCancelOfferForm = <>
                              {
                                (this.state.selectedOfferMaker && this.state.selectedOfferMaker === this.props.account) ? (
                                  
                                    <Card>
                                      <Card.Body>
                                    <form onSubmit={(event) => {
                                      event.preventDefault()                                      
                                      this.zoraCancelOffer(
                                        event.target.offerId.value
                                      )
                                    }}>
                                    <input type="hidden" style={{width: '100%'}} id="offerId" required 
                                    defaultValue={(this.state.selectedOfferId) ? this.state.selectedOfferId : ""}/>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-danger"
                                      value={`Cancel Offer (Offer ID ${this.state.selectedOfferId})`}
                                    />
                                    </form>
                                    </Card.Body>
                                    </Card>
                                  
                              ):("")
                            }
                            </>

    const zoraFillOfferForm = <>
                              {
                                (this.state.selectedOfferMaker && 
                                  this.props.ownerOfSelectedEdition === this.props.account &&
                                  this.state.selectedOfferMaker !== this.props.account) ? (
                                
                                  <Card>
                                    <Card.Body>
                                  <form onSubmit={(event) => {
                                    event.preventDefault()
                                    let amountInWei = window.web3.utils.toWei(event.target.offerAmount.value);
                                    
                                    this.zoraFillOffer(
                                      event.target.offerId.value,
                                      event.target.offerCurrency.value,
                                      amountInWei
                                    )
                                  }}>
                                    <Card.Subtitle className="mb-2 text-muted">Offer ID:</Card.Subtitle>
                                    <Card.Title>
                                      {(this.state.selectedOfferId) ? this.state.selectedOfferId : ""}
                                        <input type="hidden" id="offerId" required 
                                      defaultValue={(this.state.selectedOfferId) ? this.state.selectedOfferId : ""}/>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                    <Card.Title style={{wordBreak: 'break-all'}}>
                                      {(this.state.selectedOfferAmount) ? window.web3.utils.fromWei(this.state.selectedOfferAmount, "ether") : ""}
                                      &nbsp;
                                      <ERC20Resolver 
                                        key="selectedERC20TokenResolver" 
                                        tokenAddress={this.state.selectedToken} />
                                      <input 
                                        type="hidden"
                                        id="offerAmount" required
                                        defaultValue={(this.state.selectedOfferAmount) ? window.web3.utils.fromWei(this.state.selectedOfferAmount, "ether") : ""}
                                        />
                                      <input 
                                        type="hidden" 
                                        id="offerCurrency" 
                                        required 
                                        defaultValue={(this.state.selectedOfferCurrency) ? this.state.selectedOfferCurrency : ""}/>     
                                    </Card.Title>                             
                                <p></p>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-success"
                                  value="Accept Offer"
                                />
                                </form>
                                </Card.Body>
                                </Card>
                              
                              ) : ("")
                            }
                            </>

    let zoraOffersList =  (this.state.offersForNFT && this.state.validOfferCount > 0) ? (
                            <Card>
                              <Card.Body>
                                <Card.Subtitle>
                                  You can select an active offer from the list to perform actions on the offer.
                                  If you are a potential buyer, you can cancel or update the offer.
                                  If you are the owner, you can accept the offer.
                                </Card.Subtitle>
                                <p></p>
                              <Table striped bordered hover responsive size="sm">
                                <thead>
                                  <tr>
                                    <th>Offer ID</th>
                                    <th>Maker</th>
                                    <th>Amount</th>
                                    <th>Currency</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    (this.state.offerDetails ) ? (
                                      this.state.offerDetails.map((offerDetailsNo,i) =>(
                                        (offerDetailsNo.offer.maker !== "0x0000000000000000000000000000000000000000") ? (
                                          <tr key={i} onClick={() => this.setSelectedOffer(offerDetailsNo.offerId, offerDetailsNo.offer.maker, offerDetailsNo.offer.currency, offerDetailsNo.offer.amount)}>
                                            <td>{offerDetailsNo.offerId}</td>
                                            <td><EnsResolver lookup={offerDetailsNo.offer.maker}/>&nbsp;
                                                ({offerDetailsNo.offer.maker})</td>
                                            <td>{(offerDetailsNo.offer.amount) ? window.web3.utils.fromWei(offerDetailsNo.offer.amount, "ether") : ("")}</td>
                                            <td><ERC20Resolver tokenAddress={offerDetailsNo.offer.currency}/></td>
                                          </tr>
                                        ) : ("")
                                      ))
                                    ) : ("")
                                      }
                                </tbody>
                                </Table>
                                </Card.Body>
                              </Card>
                              ) : (
                                <Card>
                                  <Card.Body>
                                    <Card.Title>There are no active offers.</Card.Title>
                                  </Card.Body>
                                </Card>
                              )

    const zoraOffersTabForOwner = <Tab eventKey="zora_offers" title="Offers">
                                    {(this.state.zoraModalLoading) ? (
                                      <><p>{loadingInsert}</p></>
                                    ) : (
                                      <>
                                      <p></p>
                                      {approveZoraOffersTabInsert}
                                      <p></p>
                                      {zoraOffersList}
                                      <p></p>
                                      {zoraFillOfferForm}
                                      <p></p>
                                      </>
                                    )}
                                  </Tab>

    const zoraOffersTabForBuyer = <Tab eventKey="zora_offers" title="Make Offers">
                                    {(this.state.zoraModalLoading) ? (
                                      <><p>{loadingInsert}</p></>
                                    ) : (
                                      <>
                                      <p></p>
                                      {approveZoraOffersTabInsert}
                                      <p></p>
                                      {zoraOffersList}
                                      <p></p>
                                      {zoraCreateOfferForm}
                                      <p></p>
                                      {zoraSetOfferAmountForm}
                                      <p></p>
                                      {zoraCancelOfferForm}
                                      <p></p>
                                      </>
                                    )}
                                  </Tab>

    const zoraOffersTab = (ownerOfSelectedEdition === account) ? (zoraOffersTabForOwner) : (zoraOffersTabForBuyer)

    /// AUCTIONS

    let now = new Date()
    let auctionEndTime = (this.state.auctionForToken && this.state.auctionForToken.firstBidTime > 0) ? (
                            new Date(
                              (parseInt(this.state.auctionForToken.firstBidTime) + parseInt(this.state.auctionForToken.duration))*1000
                            ) 
                          ): (
                            now
                          );
    var auctionRunning = ((auctionEndTime > 0) && (now <= auctionEndTime));

    const approveZoraAuctionsForm = <form onSubmit={(event) => {
                                    event.preventDefault()
                                    this.approveZoraAuctionsModule()
                                    }}>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-warning"
                                      value="Approve Zora Auctions Module on Zora Module Manager"
                                    />
                                  </form>

    const approveZoraAuctionsTabInsertERC721 = <>
                                    <ul>
                                      {(!isZoraERC721TransferHelperApproved) ? (
                                        <>
                                        <li>Approve ERC721TransferHelper on the BillionDollarDapp contract (only need to do this once ever).</li>
                                        {approveZoraERC721TransferHelperForm}
                                        </>
                                      ) : ("")}
                                      </ul>
                                      <p></p>
                                    </>
    
    const approveZoraAuctionsTabInsertModule = <>
                                  <ul>
                                  {(!isZoraAuctionsApproved) ? (
                                      <>
                                      <li>Approve the Zora ReserveAuctionCoreERC20 module on ZoraModuleManager contract (only need to do this once ever).</li>
                                      {approveZoraAuctionsForm}
                                      </>
                                    ) : ("")}
                                    </ul>
                                    <p></p>
                                  </>

    const zoraCreateAuctionForm = <>
                                  {
                                    (this.state.auctionForToken && this.state.auctionForToken.seller === "0x0000000000000000000000000000000000000000") ? (
                                      
                                        <Card>
                                        <Card.Body>
                                        <Card.Title>Create an auction. You can modify or cancel before a bid is placed.</Card.Title>
                                        <form onSubmit={(event) => {
                                          event.preventDefault()
                                          let durationInSeconds = event.target.duration.value * 86400;
                                          let amountInWei = window.web3.utils.toWei(event.target.reservePrice.value);
                                          this.zoraCreateAuction(
                                            durationInSeconds,
                                            amountInWei,
                                            event.target.bidCurrency.value
                                          )
                                        }}>
                                          <p></p>
                                          <Card.Subtitle className="mb-2 text-muted">Duration (days):</Card.Subtitle>
                                          <Card.Title>
                                            <input type="number" style={{width: '100%'}} id="duration" required 
                                            defaultValue="1"/>
                                          </Card.Title>
                                          <Card.Subtitle className="mb-2 text-muted">Reserve Price:</Card.Subtitle>
                                          <Card.Title>
                                              <input type="text" style={{width: '100%'}} id="reservePrice" required
                                            defaultValue="0"/>
                                            </Card.Title>
                                        
                                          <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                            Bid Currency Token Address or ETH (0x0000000000000000000000000000000000000000):
                                          </Card.Subtitle>
                                          <Card.Title>
                                            <input 
                                              type="text" 
                                              style={{width: '100%'}} 
                                              id="bidCurrency" 
                                              required
                                              onBlur={e => this.setSelectedToken(e.target.value)}
                                              defaultValue={(this.state.selectedToken) ? this.state.selectedToken : ""}
                                            />
                                          </Card.Title>
                                          <Card.Subtitle className="mb-2 text-muted" style={{wordBreak: 'break-all'}}>
                                            <ERC20Resolver 
                                              key="selectedERC20TokenResolver" 
                                              tokenAddress={this.state.selectedToken} />
                                          </Card.Subtitle>
                                        
                                      <p></p>
                                      <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-block btn-primary"
                                        value="Create Reserve Auction"
                                      />
                                      </form>
                                      </Card.Body>
                                      </Card>
                                    
                                  ) : ("")
                                }
                                </>

    const zoraUpdateReserveAuctionForm = <>
                                {
                                  (this.state.auctionForToken && this.state.auctionForToken.seller === ownerOfSelectedEdition) ? (
                                   
                                      <Card>
                                        <Card.Body>
                                          <Card.Title>
                                          You can update the reserve price until someone places a first bid.
                                          </Card.Title>
                                      <form onSubmit={(event) => {
                                        event.preventDefault()
                                        let amountInWei = window.web3.utils.toWei(event.target.reservePrice.value);
                                        this.zoraSetAuctionReservePrice(
                                          amountInWei
                                        )
                                      }}>
                                        <p></p>
                                        <Card.Subtitle className="mb-2 text-muted">Reserve Price:</Card.Subtitle>
                                        <Card.Title>
                                          <input 
                                          type="text" 
                                          style={{width: '100%'}} 
                                          id="reservePrice" 
                                          required
                                          defaultValue={(this.state.auctionForToken) ? window.web3.utils.fromWei(this.state.auctionForToken.reservePrice, "ether") : ""}/>
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">
                                          <ERC20Resolver 
                                            key={`auctionCurrencyERC20TokenResolver-${tokenId}`} 
                                            tokenAddress={this.state.auctionForToken.currency} />
                                        </Card.Subtitle>
                                    <p></p>
                                    <input
                                      id="submit"
                                      type="submit"
                                      className="btn btn-block btn-warning"
                                      value="Update Reserve Auction"
                                    />
                                    </form>
                                    </Card.Body>
                                    </Card>
                                  
                                ) : ("")
                                }
                                </>

    const zoraCancelAuctionForm = <>
                                  {
                                    (this.state.auctionForToken && this.state.auctionForToken.seller === ownerOfSelectedEdition) ? (
                                     
                                        <Card>
                                        <Card.Body>
                                        <Card.Title>You can cancel the auction until someone places the first bid.</Card.Title>
                                        <form onSubmit={(event) => {
                                          event.preventDefault()                                      
                                          this.zoraCancelAuction()
                                        }}>
                                        <input
                                          id="submit"
                                          type="submit"
                                          className="btn btn-block btn-danger"
                                          value={`Cancel Auction`}
                                        />
                                        </form>
                                        </Card.Body>
                                        </Card>
                                      
                                  ):("")
                                }
                                
                                </>

    const zoraSettleAuctionForm = <>
                                  {
                                    (this.state.auctionForToken && 
                                      this.state.auctionForToken.firstBidTime > 0 &&
                                      this.state.auctionForToken.seller !== '0x0000000000000000000000000000000000000000') ? (
                                      
                                        <Card bg={(auctionRunning) ? 'danger' : 'success'}>
                                          <Card.Body>
                                        <div align="center" style={{fontSize: 'larger', color: '#ffffff'}}>
                                        <Countdown date={(parseInt(this.state.auctionForToken.firstBidTime) + parseInt(this.state.auctionForToken.duration))*1000}>
                                          <>
                                            <form onSubmit={(event) => {
                                              event.preventDefault()                                      
                                              this.zoraSettleAuction()
                                            }}>
                                            <input
                                              id="submit"
                                              type="submit"
                                              className="btn btn-block btn-success"
                                              value={`Settle Auction`}
                                            />
                                            </form>
                                          </>
                                        </Countdown>
                                        </div>
                                        </Card.Body>
                                        </Card>
                                      
                                  ):("")
                                  }
                                  </>

    const zoraAuctionInformation = <>
    {
          (this.state.auctionForToken && this.state.auctionForToken.seller !== '0x0000000000000000000000000000000000000000') ? (
            <>
            <Card>
              <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">Seller:</Card.Subtitle>
                <Card.Title style={{wordBreak: 'break-all'}}>
                  <EnsResolver 
                          key={`auctionSeller-${tokenId}-${this.state.auctionForToken.seller}`} 
                          lookup={this.state.auctionForToken.seller}/>&nbsp;
                          ({this.state.auctionForToken.seller})
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Auction Duration:</Card.Subtitle>
                <Card.Title>
                  {(this.state.auctionForToken) ? String(parseInt(this.state.auctionForToken.duration) / (60*60*24)): ""} day(s)
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Reserve Price:</Card.Subtitle>
                <Card.Title style={{wordBreak: 'break-all'}}>
                  {(this.state.auctionForToken) ? window.web3.utils.fromWei(this.state.auctionForToken.reservePrice, "ether") : ""}
                  &nbsp;
                  <ERC20Resolver 
                    key={`auctionCurrencyERC20TokenResolver-${tokenId}`} 
                    tokenAddress={this.state.auctionForToken.currency}/>
                </Card.Title>
              </Card.Body>
            </Card>

            
            {(this.state.auctionForToken && this.state.auctionForToken.highestBidder !== '0x0000000000000000000000000000000000000000') ? (
              <>
              <p></p>
              <Card bg={(this.state.auctionForToken.highestBidder === this.props.account) ? 'success' : 'warning'}>
                <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted">Highest Bidder:</Card.Subtitle>
                  <Card.Title style={{wordBreak: 'break-all'}}>
                    <EnsResolver 
                      key={`auctionHighestBidder-${tokenId}-${this.state.auctionForToken.highestBidder}`} 
                      lookup={this.state.auctionForToken.highestBidder}/>&nbsp;
                      ({this.state.auctionForToken.highestBidder})
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Highest Bid:</Card.Subtitle>
                  <Card.Title>
                    {(this.state.auctionForToken) ? window.web3.utils.fromWei(this.state.auctionForToken.highestBid, "ether") : ""}
                    &nbsp;
                    <ERC20Resolver 
                    key={`auctionCurrencyERC20TokenResolverShort-${tokenId}`} 
                    tokenAddress={this.state.auctionForToken.currency} 
                    short={true}/>
                  </Card.Title>
                </Card.Body>
              </Card>
              </>
            ) : ("")}
          </>
    ) : ("")}
    </>

    const zoraCreateBidForm = <>
                                {
                                  (this.state.auctionForToken && auctionRunning &&
                                    this.state.auctionForToken.seller !== '0x0000000000000000000000000000000000000000' &&
                                    this.state.auctionForToken.seller !== this.props.account &&
                                    this.state.auctionForToken.highestBidder !== this.props.account) ? (

                                      
                                        <Card>
                                          <Card.Body>
                                          <Card.Title>
                                            Place a new bid.
                                            It must meet the reserve or be 10% higher than the highest bid.  
                                            The first bid will start the auction.
                                          </Card.Title>

                                        <form onSubmit={(event) => {
                                          event.preventDefault()
                                          let amountInWei = window.web3.utils.toWei(event.target.amount.value);
                                          this.zoraCreateBid(
                                            amountInWei
                                          )
                                        }}>
                                        <p></p>
                                        <Card.Subtitle className="mb-2 text-muted">Amount:</Card.Subtitle>
                                        <Card.Title>
                                          <input 
                                          type="text" 
                                          style={{width: '100%'}} 
                                          id="amount"
                                          required
                                            defaultValue={(this.state.auctionForToken.highestBid > 0) ? 
                                              (String(1.1 * parseFloat(window.web3.utils.fromWei(this.state.auctionForToken.highestBid, "ether")))
                                                ) : (
                                                  window.web3.utils.fromWei(this.state.auctionForToken.reservePrice, "ether")
                                                )
                                            }
                                            />
                                          </Card.Title>
                                          <p></p>
                                          <ERC20Resolver 
                                            key={`auctionCurrencyERC20TokenResolver-${tokenId}`} 
                                            tokenAddress={this.state.auctionForToken.currency}/>
                                          <p></p>
                                          <ERC20ApprovalButton
                                            key={`auctionCurrencyERC20TokenButton-${tokenId}`}
                                            account={this.props.account}
                                            tokenAddress={this.state.auctionForToken.currency}
                                            spenderName="Zora ERC20TransferHelper"
                                            spenderAddress={(this.state.zoraERC20TransferHelper)?this.state.zoraERC20TransferHelper.options.address:""}
                                            approvedAmount='115792089237316195423570985008687907853269984665640564039457584007913129639935'
                                          />
                                          <p></p>
                                          <input
                                            id="submit"
                                            type="submit"
                                            className="btn btn-block btn-primary"
                                            value="Place a Bid"
                                          />
                                          </form>
                                          </Card.Body>
                                          </Card>
                                      
                                  ):("")
                                }
                                </>

    const zoraAuctionsTabForOwner = <Tab eventKey="zora_sell_with_auction" title="Auction">
                                    {(this.state.zoraModalLoading) ? (
                                       <><p>{loadingInsert}</p></>
                                      ) : (
                                        <>
                                        <p></p>
                                        {approveZoraAuctionsTabInsertERC721}
                                        {approveZoraAuctionsTabInsertModule}
                                        <p></p>
                                        {zoraAuctionInformation}
                                        <p></p>
                                        {zoraSettleAuctionForm}
                                        <p></p>
                                        {zoraCreateAuctionForm}
                                        <p></p>
                                        {zoraUpdateReserveAuctionForm}
                                        <p></p>
                                        {zoraCancelAuctionForm}
                                        <p></p>
                                        </>
                                    )}
                                    </Tab>
                                    
    
    const zoraAuctionsTabForBidder = <Tab eventKey="zora_bid_with_auction" title="Auction">
                                     {(this.state.zoraModalLoading) ? (
                                        <><p>{loadingInsert}</p></>
                                      ) : (
                                        <>
                                        <p></p>
                                        {approveZoraAuctionsTabInsertModule}
                                        <p></p>
                                        {zoraAuctionInformation}
                                        <p></p>
                                        {zoraSettleAuctionForm}
                                        <p></p>
                                        {zoraCreateBidForm}
                                        <p></p>
                                        </>
                                     )}
                                     </Tab>
                                  
    const zoraAuctionsTab = (ownerOfSelectedEdition === account) ? (zoraAuctionsTabForOwner) : (zoraAuctionsTabForBidder)
    
    let showAsks = ((this.state.validAskForToken || (ownerOfSelectedEdition === account)) && !(this.state.validAuctionForToken && this.state.auctionForToken.firstBidTime > 0));
    let showOffers = (((ownerOfSelectedEdition !== account) || ((this.state.validOfferCount > 0) && (ownerOfSelectedEdition === account))) && !(this.state.validAuctionForToken && this.state.auctionForToken.firstBidTime > 0));
    let showAuctions = (this.state.validAuctionForToken || (ownerOfSelectedEdition === account));
    
    let modalTitle = (tokenId !== null) ? (
                        `zora: Pixel # ${tokenId}`
                      ) : (
                        `zora: BillionDollarDapp`
                      )
    
    
    const overviewAsksTab = <Tab eventKey="zora_asks_overview" title="Asks">
    <p></p>
    <Card>
      <Card.Body>
        <Card.Title>
          Asks Overview
        </Card.Title>
        <CardColumns>
        {
          (this.state.asksOverviewData && !(Object.keys(this.state.asksOverviewData).length === 0)) ? (
            Object.keys(this.state.asksOverviewData).map((k,i) =>(
              
                <Card key={i} 
                bg={(this.props.account === this.state.asksOverviewData[k].ask.ask[0]) ? `warning` : `light`}
                style={{cursor: 'zoom-in'}} onClick={e => this.props.setSelectedEdition(this.state.asksOverviewData[k].tokenId)}>
                  <Card.Header as="h5">
                    BillionDollarDapp Pixel # {this.state.asksOverviewData[k].tokenId}
                  </Card.Header>
                  <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted" >
                    Seller:
                  </Card.Subtitle>
                  <Card.Title>
                    <EnsResolver lookup={this.state.asksOverviewData[k].ask.ask[0]}/>&nbsp;
                    <span className="mb-2 text-muted" style={{fontSize: 'xx-small'}}>({this.state.asksOverviewData[k].ask.ask[0]})</span>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted" >
                  Asking Price:
                  </Card.Subtitle>
                  <Card.Title>
                  {window.web3.utils.fromWei(this.state.asksOverviewData[k].ask.ask[4], "ether")}&nbsp;
                    <ERC20Resolver 
                      key={`AsksOverviewERC20TokenResolverShort-${this.state.asksOverviewData[k].tokenId}`} 
                      tokenAddress={this.state.asksOverviewData[k].ask.ask[2]}
                      short={true}
                    />
                  </Card.Title>
                  </Card.Body>
                </Card>
            
            ))) : (
              <Card>
                  <Card.Body>
                  <Card.Title>
                    No Current Asks
                  </Card.Title>
                  </Card.Body>
                </Card>
            )
        }
        </CardColumns>
      </Card.Body>
    </Card>
    </Tab>

    const overviewOffersTab = <Tab eventKey="zora_offers_overview" title="Offers">
      <p></p>
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                Offers Overview
                              </Card.Title>
                              <CardColumns>
                              {
                                (this.state.offersOverviewData && !(Object.keys(this.state.offersOverviewData).length === 0)) ? (
                                  Object.keys(this.state.offersOverviewData).map((k,i) =>(
                                    
                                      <Card key={i} 
                                        bg={(this.props.account === this.state.offersOverviewData[k].offer.offer[0]) ? `warning` : `light`}
                                        style={{cursor: 'zoom-in'}} 
                                        onClick={e => this.props.setSelectedEdition(this.state.offersOverviewData[k].tokenId)}>
                                        <Card.Header as="h5">
                                          Offer # {this.state.offersOverviewData[k].offer.id}: BillionDollarDapp Pixel # {this.state.offersOverviewData[k].tokenId}
                                        </Card.Header>
                                        <Card.Body>
                                        <Card.Subtitle className="mb-2 text-muted" >
                                          Maker:
                                        </Card.Subtitle>
                                        <Card.Title>
                                          <EnsResolver lookup={this.state.offersOverviewData[k].offer.offer[0]}/>&nbsp;
                                          <span className="mb-2 text-muted" style={{fontSize: 'xx-small'}}>({this.state.offersOverviewData[k].offer.offer[0]})</span>
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted" >
                                        Offered Amount:
                                        </Card.Subtitle>
                                        <Card.Title>
                                        {window.web3.utils.fromWei(this.state.offersOverviewData[k].offer.offer[3], "ether")}&nbsp;
                                          <ERC20Resolver 
                                            key={`OffersOverviewERC20TokenResolverShort-${this.state.offersOverviewData[k].offer.id}`} 
                                            tokenAddress={this.state.offersOverviewData[k].offer.offer[1]}
                                            short={true}
                                          />
                                        </Card.Title>
                                        </Card.Body>
                                      </Card>
                                  ))) : (
                                    <Card>
                                        <Card.Body>
                                        <Card.Title>
                                          No Current Offers
                                        </Card.Title>
                                        </Card.Body>
                                      </Card>
                                  )
                              }
                              </CardColumns>
                            </Card.Body>
                          </Card>
    </Tab>
    const overviewAuctionsTab = <Tab eventKey="zora_auctions_overview" title="Auctions">
      <p></p>
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                Auctions Overview
                              </Card.Title>
                                <CardColumns>
                              {
                                (this.state.auctionsOverviewData && !(Object.keys(this.state.auctionsOverviewData).length === 0)) ? (
                                  Object.keys(this.state.auctionsOverviewData).map((k,i) =>(
                                    
                                      <Card key={i}
                                        bg={(this.props.account === this.state.auctionsOverviewData[k].auction.auction[0]) ? `warning` : `light`}
                                        style={{cursor: 'zoom-in'}} onClick={e => this.props.setSelectedEdition(this.state.auctionsOverviewData[k].tokenId)}>
                                        <Card.Header as="h5">
                                          BillionDollarDapp Pixel # {this.state.auctionsOverviewData[k].tokenId}
                                        </Card.Header>
                                        <Card.Body>
                                        <Card.Subtitle className="mb-2 text-muted" >
                                          Seller:
                                        </Card.Subtitle>
                                        <Card.Title>
                                          <EnsResolver lookup={this.state.auctionsOverviewData[k].auction.auction[0]}/>&nbsp;
                                          <span className="mb-2 text-muted" style={{fontSize: 'xx-small'}}>({this.state.auctionsOverviewData[k].auction.auction[0]})</span>
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted" >
                                        Reserve Price:
                                        </Card.Subtitle>
                                        <Card.Title>
                                        {window.web3.utils.fromWei(this.state.auctionsOverviewData[k].auction.auction[1], "ether")}&nbsp;
                                          <ERC20Resolver 
                                            key={`AuctionsOverviewReserveERC20TokenResolverShort-${this.state.auctionsOverviewData[k].tokenId}`} 
                                            tokenAddress={this.state.auctionsOverviewData[k].auction.auction[7]}
                                            short={true}
                                          />
                                        </Card.Title>
                                        {(parseInt(this.state.auctionsOverviewData[k].auction.auction[3]) > 0) ? (
                                          <>
                                          <hr/>
                                          <Card.Subtitle className="mb-2 text-muted">
                                            Highest Bidder:
                                          </Card.Subtitle>
                                          <Card.Title>
                                            <EnsResolver lookup={this.state.auctionsOverviewData[k].auction.auction[4]}/>&nbsp;
                                            <span className="mb-2 text-muted" style={{fontSize: 'xx-small'}}>({this.state.auctionsOverviewData[k].auction.auction[4]})</span>
                                          </Card.Title>
                                          <Card.Subtitle className="mb-2 text-muted" >
                                          Highest Bid:
                                          </Card.Subtitle>
                                          <Card.Title>
                                          {window.web3.utils.fromWei(this.state.auctionsOverviewData[k].auction.auction[3], "ether")}&nbsp;
                                            <ERC20Resolver 
                                              key={`AuctionsOverviewHighBidERC20TokenResolverShort-${this.state.auctionsOverviewData[k].tokenId}`} 
                                              tokenAddress={this.state.auctionsOverviewData[k].auction.auction[7]}
                                              short={true}
                                            />
                                          </Card.Title>
                                          </>
                                        ) : ("")}

                                        <hr/>
                                        <Card.Subtitle className="mb-2 text-muted">
                                          Time Left:
                                        </Card.Subtitle>
                                        <Card.Title>
                                          {(parseInt(this.state.auctionsOverviewData[k].auction.auction[8]) > 0) ? (
                                        <Countdown 
                                          date={(parseInt(this.state.auctionsOverviewData[k].auction.auction[8]) + parseInt(this.state.auctionsOverviewData[k].auction.auction[5]))*1000}>
                                            {<>Ready to Settle</>}
                                        </Countdown>
                                          ) : (<>Awaiting First Bid</>)}
                                        </Card.Title>
                                        </Card.Body>
                                      </Card>
                                  
                                  ))) : (
                                    <Card>
                                        <Card.Body>
                                        <Card.Title>
                                          No Current Auctions
                                        </Card.Title>
                                        </Card.Body>
                                      </Card>
                                  )
                              }
                              </CardColumns>
                            </Card.Body>
                          </Card>
    </Tab>

    const overviewTab = <Tab eventKey="overview" title="Overview">
                        {(this.state.zoraLogsModalLoading) ? (
                            <><p>{loadingInsert}</p></>
                        ) : (
                          <>
                          <Tabs id="uncontrolled-tab-example">
                          <p></p>
                          {overviewAsksTab}
                          {overviewOffersTab}
                          {overviewAuctionsTab}
                          </Tabs>
                          </>
                        )}
                        </Tab>

    /// RENDER
    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg"
        show={isZoraModalOpen}
        onHide={this.props.closeZoraModal}>

        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p></p>
            <Tabs id="uncontrolled-tab-example">
              {(!this.props.tokenId) ? overviewTab : `` }
              {(showAsks && this.props.tokenId) ? zoraAsksTab : ``}
              {(showOffers && this.props.tokenId) ? zoraOffersTab : ``}
              {(showAuctions && this.props.tokenId) ? zoraAuctionsTab : ``}
            </Tabs>

        </Modal.Body>
        <Modal.Footer>
          Etherscan:
          {((this.state.zoraAsks && showAsks) || !this.props.tokenId) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraAsks.options.address}`)}>zora asks</Button>
          ) : ("")}
          {((this.state.zoraOffers && showOffers) || !this.props.tokenId) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraOffers.options.address}`)}>zora offers</Button>
          ) : ("")}
          {((this.state.zoraAuctions && showAuctions) || !this.props.tokenId) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraAuctions.options.address}`)}>zora auctions</Button>
          ) : ("")}
          {(this.state.zoraERC721TransferHelper && this.props.tokenId && this.props.account === this.props.ownerOfSelectedEdition) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraERC721TransferHelper.options.address}`)}>zora erc721transferhelper</Button>
          ) : ("")}
          {(this.state.zoraERC20TransferHelper && this.props.tokenId && this.props.account !== this.props.ownerOfSelectedEdition) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraERC20TransferHelper.options.address}`)}>zora erc20transferhelper</Button>
          ) : ("")}
          {(this.state.zoraModuleManager && this.props.tokenId) ? (
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${this.state.zoraModuleManager.address}`)}>zora modulemanager</Button>
          ) : ("")}
        </Modal.Footer>
        <Modal.Footer>
        {(contract && contract.options) ? (
          <>
          {(this.state.zoraModalLoading) ? (
            <Button variant="warning btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => this.setState({zoraModalLoading: false})}>reset loading</Button>
          ) : ("")}
          {(this.props.tokenId) ? (
            <Button variant="info btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => this.props.resetSelectedEdition()}>&lt; overview</Button>
          ) : ("")}
          <Button variant="outline-info btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => this.updateAndNotify()}>reload data</Button>


          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_token_link}${contract.options.address}${platform.etherscan_token_query_after_contract}${tokenId}`)}>etherscan</Button>
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_asset_base_link}${contract.options.address}/${tokenId}`)}>opensea</Button>
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}/${tokenId}`)}>zora</Button>
          </>
        ) : ("")}
          <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeZoraModal}> close </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ZoraModal;
