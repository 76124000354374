import React from 'react';

// The minimum ABI to get ERC20 Token balance
let minERC20ABI = [
// balanceOf
{
    "constant":true,
    "inputs":[{"name":"_owner","type":"address"}],
    "name":"balanceOf",
    "outputs":[{"name":"balance","type":"uint256"}],
    "type":"function"
},
// decimals
{
    "constant":true,
    "inputs":[],
    "name":"decimals",
    "outputs":[{"name":"","type":"uint8"}],
    "type":"function"
},
// symbol
{
    "inputs": [],
    "name": "symbol",
    "outputs": [
    {
        "internalType": "string",
        "name": "",
        "type": "string"
    }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
},
// name
{
    "inputs": [],
    "name": "name",
    "outputs": [
    {
        "internalType": "string",
        "name": "",
        "type": "string"
    }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
},
// approve
{
    "constant": false,
    "inputs": [
        {
            "name": "_spender",
            "type": "address"
        },
        {
            "name": "_value",
            "type": "uint256"
        }
    ],
    "name": "approve",
    "outputs": [
        {
            "name": "",
            "type": "bool"
        }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
},
// allowance
{
    "constant": true,
    "inputs": [
        {
            "name": "_owner",
            "type": "address"
        },
        {
            "name": "_spender",
            "type": "address"
        }
    ],
    "name": "allowance",
    "outputs": [
        {
            "name": "",
            "type": "uint256"
        }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
},
];

class ERC20Resolver extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenAddress: props.tokenAddress,
            decimals: null,
            name: null,
            symbol: null
        };
    }

    componentDidMount = async () => {
        const { tokenAddress } = this.state;
        if (tokenAddress !== null) {
            this.getTokenInfo();
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.tokenAddress !== this.props.tokenAddress) {
            await this.setState({
                tokenAddress: this.props.tokenAddress,
                decimals: null,
                name: null,
                symbol: null
            })
            await this.getTokenInfo();
        }
      }

    // run this multiple times by putting in its own function
    getTokenInfo = async () => {
        const { tokenAddress } = this.state;

        let symbol = null;
        let decimals = null;
        let name = null;

        if(tokenAddress === "0x0000000000000000000000000000000000000000") {
            symbol = "Ξ ETH"
            decimals = 18;
            name = "Ethereum"
        } else {
            try {
                let tokenContract = new window.web3.eth.Contract(minERC20ABI,tokenAddress);
                symbol = await tokenContract.methods.symbol().call();
                decimals = await tokenContract.methods.decimals().call();
                name = await tokenContract.methods.name().call();
            } catch (error) {}
        }

        this.setState({decimals, name, symbol});
    }

  render() {
    const { tokenAddress, symbol } = this.state;
    let strValue = null;
    {(symbol !== null) ? (
        (this.props.short === true) ? (
            strValue = `${symbol}`
        ) : (
            strValue = `${symbol} (${tokenAddress})`
        )
    ) : (
        strValue = `${tokenAddress}`
    )};
    return(<>{strValue}</>);
  }
}

export default ERC20Resolver;